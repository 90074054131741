import makeStyles from '@mui/styles/makeStyles';
import { Box, IconButton, Typography } from '@mui/material';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';

import { ICONS } from 'utilities/icons';

interface ScenarioSummaryTeamCardHeaderProps {
  teamAbbreviation: string;
  teamName: string;
  onTeamClick: () => void;
}

function ScenarioSummaryTeamCardHeader({
  teamAbbreviation,
  teamName,
  onTeamClick,
}: ScenarioSummaryTeamCardHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box alignItems="center" display="flex" flex="1">
        <Typography className={classes.teamName} variant="h6">
          {teamName}
        </Typography>
      </Box>
      <IconButton className={classes.teamIcon} onClick={onTeamClick} size="large">
        <StanleySVGIcon height="2.25rem" imageSrc={ICONS[teamAbbreviation]} width="2.25rem" />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  teamName: {
    lineHeight: 1,
  },
  teamIcon: {
    padding: theme.spacing(0.75),
  },
}));

export default ScenarioSummaryTeamCardHeader;
