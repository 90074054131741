import { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material';

import Colors from 'styles/colors';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BRAND_GOLD,
    },
    secondary: {
      main: Colors.BRAND_BLACK,
    },
  },
});

export default { theme };
