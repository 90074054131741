import { Navigate, Route, Routes } from 'react-router-dom';

import SignIn from 'modules/auth/signIn.container';
import { ROUTES } from 'utilities/routes';
// import SignUp from 'modules/auth/signUp.container';

function PublicRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<SignIn />} path={ROUTES.SIGNIN} />
      {/* <Route component={SignUp} path={ROUTES.SIGNUP} exact /> */}
      <Route path="*" element={<Navigate to={ROUTES.SIGNIN} />} />
    </Routes>
  );
}

export default PublicRoutes;
