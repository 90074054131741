import { Box, Paper, styled } from '@mui/material';
import { Container } from '@project-stanley/cap-management-components';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Dashboard from 'modules/teams/dashboard/dashboard.component';
import Loading from 'modules/auth/loading.container';
import Research from 'modules/research/research.container';
import StanleyNavTabs from 'components/stanleyNavTabs.component';
import TeamsConferenceView from 'modules/teams/teamsConferenceView.component';
import TeamsTable from 'modules/teams/teamsTable.component';
import { Circle } from '@mui/icons-material';
import { ROUTES, TEAMS_SUB_ROUTES } from 'utilities/routes';
import { TEAMS_VIEW_OPTION, TEAMS_VIEW_OPTION_VALUES } from 'utilities/teams';
import { getAllReports } from 'modules/research/research.slice';
import {
  getRecentInjuries,
  getRecentSignings,
  getRecentTransactions,
} from 'modules/teams/recentTransactions/recentTransactions.slice';
import { getTeams, getTeamsSummary } from 'modules/teams/teams.slice';
import { selectFirebaseUser } from 'modules/user/user.selectors';
import { selectInitialRoute, selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectResearch, selectResearchIsLoading } from 'modules/research/research.selectors';
import {
  selectTeams,
  selectTeamsIsLoading,
  selectTeamsIsSummaryLoading,
  selectTeamsSummary,
} from 'modules/teams/teams.selectors';
import { setFirebaseUser } from 'modules/user/user.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isPrinting',
})<{ isPrinting: boolean }>(({ isPrinting, theme }) => {
  const styles = {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  };

  if (isPrinting) return styles;

  return {
    ...styles,
    height: `calc(100% - ${theme.spacing(6)})`,
    overflowY: 'scroll',
  };
});

function Teams(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isLoadingTeams = useSelector(selectTeamsIsLoading);
  const isLoadingReports = useSelector(selectResearchIsLoading);
  const isLoadingTeamsSummary = useSelector(selectTeamsIsSummaryLoading);
  const reports = useSelector(selectResearch);
  const teams = useSelector(selectTeams);
  const teamsSummary = useSelector(selectTeamsSummary);
  const isPrinting = useSelector(selectIsPrinting);
  const initialRoute = useSelector(selectInitialRoute);
  const firebaseUser = useSelector(selectFirebaseUser);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (!initialRoute) {
      dispatch(getTeams());
      dispatch(getTeamsSummary());
      dispatch(getAllReports());
      dispatch(getRecentInjuries());
      dispatch(getRecentSignings());
      dispatch(getRecentTransactions());
    }
  }, [dispatch, initialRoute]);

  useEffect(() => {
    const subRouteIndex = Object.values(TEAMS_SUB_ROUTES).findIndex((subRoute) => location.pathname.includes(subRoute));

    if (subRouteIndex < 0) {
      setSelectedTabIndex(0);
    } else {
      setSelectedTabIndex(subRouteIndex + 1);
    }
  }, [location]);

  const handleSelectView = useCallback(
    (tabIndex: number) => {
      const newFirebaseUser = { ...firebaseUser };

      if (tabIndex === 1) newFirebaseUser.lastResearchView = new Date().toISOString();

      writeFirestoreDataByUid({ path: 'users', data: newFirebaseUser });
      dispatch(setFirebaseUser(newFirebaseUser));

      setSelectedTabIndex(tabIndex);

      const teamsRouteBase = ROUTES.LEAGUE;

      if (tabIndex === 0) {
        navigate(teamsRouteBase);
        return;
      }

      navigate(`${teamsRouteBase}${Object.values(TEAMS_SUB_ROUTES)[tabIndex - 1]}`);
    },
    [dispatch, navigate, firebaseUser],
  );

  const renderIcon = useCallback(() => {
    if (reports === null) return '';

    const dataSinceLastLogin = reports.filter(
      (item) =>
        !firebaseUser?.lastResearchView || isAfter(new Date(item.createdAt), new Date(firebaseUser.lastResearchView)),
    );

    if (dataSinceLastLogin.length === 0) return '';

    return (
      <Box height="100%" sx={{ float: 'right' }}>
        <Circle
          color="primary"
          sx={{
            fontSize: '.75rem',
          }}
        />
      </Box>
    );
  }, [firebaseUser, reports]);

  const isLoading = useMemo(
    () =>
      isLoadingTeams ||
      isLoadingReports ||
      isLoadingTeamsSummary ||
      isNil(reports) ||
      isNil(teams) ||
      isNil(teamsSummary),
    [isLoadingTeams, isLoadingReports, isLoadingTeamsSummary, reports, teams, teamsSummary],
  );

  if (isLoading) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledPaper elevation={3} isPrinting={isPrinting}>
      <TeamsConferenceView />
      <Box marginY="1rem">
        <StanleyNavTabs
          minWidth="10rem"
          selectedTabIndex={selectedTabIndex}
          tabs={TEAMS_VIEW_OPTION.sort((a, b) => a.value - b.value).map((option) => {
            if (option.value === TEAMS_VIEW_OPTION_VALUES.RESEARCH) {
              return {
                ...option,
                icon: renderIcon(),
              };
            }

            return option;
          })}
          onTabChange={handleSelectView}
        />
      </Box>
      <Routes>
        <Route element={<Dashboard />} path="/" />
        <Route element={<TeamsTable />} path="/summary" />
        <Route element={<Research />} path={`${TEAMS_SUB_ROUTES.RESEARCH}/*`} />
        <Route path="*" element={<Navigate to={ROUTES.LEAGUE} />} />
      </Routes>
    </StyledPaper>
  );
}

export default Teams;
