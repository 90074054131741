/* eslint-disable max-len */
import makeStyles from '@mui/styles/makeStyles';
import { Box, useTheme } from '@mui/material';
import { StanleyDetailsPanel } from '@project-stanley/cap-management-components';

import PlayerTransactionSummaryPanelSection from 'modules/players/playerTransactionSummary/playerTransactionSummaryPanelSection.component';
import { CONTRACT_EXPIRY_STATUS_MAP } from 'utilities/contract';
import { Player, PlayerContractExpiration } from 'types/player';

interface PlayerTransactionSummaryPanelProps {
  player: Player;
  playerContractExpiration: PlayerContractExpiration | null;
}

function PlayerTransactionSummaryPanel({
  player: { agentInfo, ufaYear, ...player },
  playerContractExpiration,
}: PlayerTransactionSummaryPanelProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <StanleyDetailsPanel className={classes.detailPanel} title="Transactional Information">
      <Box marginTop={theme.spacing(2.5)}>
        <PlayerTransactionSummaryPanelSection
          items={agentInfo && agentInfo.fullName ? [{ text: `${agentInfo.fullName}` }] : 'N/A'}
          title="Agent:"
        />
        <PlayerTransactionSummaryPanelSection
          items={
            playerContractExpiration
              ? [
                  { label: 'Expiry Status', text: CONTRACT_EXPIRY_STATUS_MAP[playerContractExpiration.expiryStatus] },
                  { label: 'UFA Year', text: ufaYear || 'NR' },
                  { label: 'Accrued Seasons', text: playerContractExpiration.accruedSeasons },
                  { label: 'Remaining Term', text: playerContractExpiration.remainingTerm },
                  { label: 'Arb. Eligible', text: player.arbRights ? 'YES' : 'NO' },
                ]
              : 'N/A'
          }
          title="Contract Expiration:"
        />
        <PlayerTransactionSummaryPanelSection
          isLastSection
          items={[
            { label: 'Total NHL Games', text: player.totalNhlGames || 0 },
            { label: 'ELC Age', text: player.elcAge || 0 },
            { label: 'Waivers Age', text: player.waiversAge || 0 },
          ]}
          title="Waivers:"
        />
      </Box>
    </StanleyDetailsPanel>
  );
}

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    height: 'calc(100% - 32px)',
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
}));

export default PlayerTransactionSummaryPanel;
