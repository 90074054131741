import { Action } from '@material-table/core';

export interface ProjectedSummaryData {
  [key: string]:
    | Record<string, unknown>
    | {
        isDollar?: boolean;
        value: number;
      };
}

export enum TextAlign {
  center = 'center',
  inherit = 'inherit',
  justify = 'justify',
  left = 'left',
  right = 'right',
}

export enum Editable {
  always = 'always',
  never = 'never',
  onAdd = 'onAdd',
  onUpdate = 'onUpdate',
}

export enum TableSort {
  ASC = 'asc',
  DESC = 'desc',
}

export type TableRowData = Record<string, any> | Record<string, any>[];

export type TableRowClick = React.MouseEvent<Element, MouseEvent> | undefined;

export type TableAction = Action<TableRowData> & { text: string };
