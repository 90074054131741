/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { cloneDeep } from 'lodash';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'middleware/api';
import {
  Conferences,
  Divisions,
  Team,
  TeamDeadCap,
  TeamDeadCapOutlook,
  TeamDraftPick,
  TeamDraftPicksByYear,
  TeamDraftPicksByYearByRound,
  TeamDraftPicksResponse,
  TeamRosterOutlook,
  TeamRosterOutlookByPosition,
  TeamRosterOutlookReassignPlayer,
  TeamRosterOutlookRecallPlayer,
  TeamRosterUnsigned,
  TeamSummary,
  TeamTrade,
  TeamsByConferenceByDivision,
  TeamsTradeFilters,
} from 'types/teams';
import { ContractStatus } from 'types/contract';
import { Player } from 'types/player';
import {
  ROSTER_CONTRACT_FILTER_KEYS,
  generateSortedRosterOutlook,
  generateTeamContractYears,
  mapInactiveRosterByPosition,
  mapRosterByPosition,
  sortMappedInactiveRosterByPosition,
  sortMappedRosterByPosition,
} from 'utilities/roster';
import { RootState } from 'store';

export interface TeamsState {
  isDeadCapOutlookLoading: boolean;
  isDraftPicksLoading: boolean;
  isLoading: boolean;
  isOutlookLoading: boolean;
  isProjectedSummaryLoading: boolean;
  isSummaryLoading: boolean;
  isTeamSummaryLoading: boolean;
  isTradesLoading: boolean;
  team: Team | null;
  teamActiveContractYears: number[];
  teamActiveRosterOutlook: Player[] | null;
  teamActiveRosterOutlookByPosition: TeamRosterOutlookByPosition | null;
  teamContractYear: number | null;
  teamContractYears: number[];
  teamDeadCapOutlook: TeamDeadCap[] | null;
  teamDraftPicks: TeamDraftPick[] | null;
  teamDraftPicksByYear: TeamDraftPicksByYear | null;
  teamDraftPicksByYearByRound: TeamDraftPicksByYearByRound | null;
  teamRosterStatFilter: string;
  teamInactiveContractYears: number[];
  teamInactiveRosterOutlook: Player[] | null;
  teamInactiveRosterOutlookByPosition: TeamRosterOutlookByPosition | null;
  teamProjectedSummary: TeamSummary[] | null;
  teamSummary: TeamSummary | null;
  teams: Team[] | null;
  teamsByConferenceByDivision: TeamsByConferenceByDivision | null;
  teamsSummary: TeamSummary[] | null;
  trades: TeamTrade[] | null;
}

const initialState: TeamsState = {
  isDeadCapOutlookLoading: false,
  isDraftPicksLoading: false,
  isLoading: false,
  isProjectedSummaryLoading: false,
  isOutlookLoading: false,
  isSummaryLoading: false,
  isTeamSummaryLoading: false,
  isTradesLoading: false,
  team: null,
  teamActiveContractYears: [],
  teamActiveRosterOutlook: null,
  teamActiveRosterOutlookByPosition: null,
  teamContractYear: null,
  teamContractYears: [],
  teamDeadCapOutlook: null,
  teamDraftPicks: null,
  teamDraftPicksByYear: null,
  teamDraftPicksByYearByRound: null,
  teamRosterStatFilter: ROSTER_CONTRACT_FILTER_KEYS.PROJ_CAP_HIT,
  teamInactiveContractYears: [],
  teamInactiveRosterOutlook: null,
  teamInactiveRosterOutlookByPosition: null,
  teamProjectedSummary: null,
  teamSummary: null,
  teams: null,
  teamsByConferenceByDivision: null,
  teamsSummary: null,
  trades: null,
};

export const getTeams = createAsyncThunk('teams/getTeams', async () => {
  const response = (await api('teams', { method: 'GET' }, true)) as Team[];

  return response;
});

export const getTeamsSummary = createAsyncThunk('teams/getTeamsSummary', async (_, { getState }) => {
  const { scenario } = (getState() as RootState).scenarios;

  const endpoint = scenario ? `teams/summary?scenarioId=${scenario.scenarioId}` : 'teams/summary';

  return (await api(endpoint, { method: 'GET' }, true)) as TeamSummary[];
});

export const getTeam = createAsyncThunk('teams/getTeam', async (teamId: string) => {
  const response = (await api(`teams/${teamId}`, { method: 'GET' }, true)) as Team;

  return response;
});

export const getTeamSummary = createAsyncThunk('teams/getTeamSummary', async (teamId: string, { getState }) => {
  const { scenario } = (getState() as RootState).scenarios;

  const endpoint = scenario ? `teams/${teamId}/summary?scenarioId=${scenario.scenarioId}` : `teams/${teamId}/summary`;

  return (await api(endpoint, { method: 'GET' }, true)) as TeamSummary;
});

export const getProjectedTeamSummary = createAsyncThunk(
  'teams/getProjectedTeamSummary',
  async (teamId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;
    const { season } = (getState() as RootState).season;

    let { seasonYearEnd } = season;
    const requests = [];

    for (let i = 0; i < 6; i++) {
      requests.push(
        scenario
          ? `teams/${teamId}/summary?season=${seasonYearEnd.toString()}&scenarioId=${scenario.scenarioId}`
          : `teams/${teamId}/summary?season=${seasonYearEnd.toString()}`,
      );

      seasonYearEnd++;
    }

    const response = await Promise.all(requests.map((request) => api(request, { method: 'GET' }, true)));

    return response as TeamSummary[];
  },
);

export const getTeamDraftPicks = createAsyncThunk('teams/getTeamDraftPicks', async (teamId: string, { getState }) => {
  const { scenario } = (getState() as RootState).scenarios;

  const endpoint = scenario
    ? `teams/${teamId}/draft-picks?scenarioId=${scenario.scenarioId}`
    : `teams/${teamId}/draft-picks`;

  const response = (await api(endpoint, { method: 'GET' }, true)) as TeamDraftPicksResponse;

  if (response.draftPicksTradedAway) {
    return [
      ...response.draftPicks,
      ...response.draftPicksTradedAway.map((draftPick) => ({ ...draftPick, isTradedAway: true })),
    ];
  }

  return response.draftPicks;
});

export const getTeamRosterOutlook = createAsyncThunk(
  'teams/getTeamRosterOutlook',
  async (teamId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;
    const { season } = (getState() as RootState).season;

    const endpoint = scenario
      ? `teams/${teamId}/roster-outlook?scenarioId=${scenario.scenarioId}`
      : `teams/${teamId}/roster-outlook`;

    const response = (await api(endpoint, { method: 'GET' }, true)) as TeamRosterOutlook;

    return { response, season };
  },
);

export const getTeamRosterUnsigned = createAsyncThunk(
  'teams/getTeamRosterUnsigned',
  async (teamId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const endpoint = scenario
      ? `teams/${teamId}/unsigned-players?scenarioId=${scenario.scenarioId}`
      : `teams/${teamId}/unsigned-players`;

    const response = (await api(endpoint, { method: 'GET' }, true)) as TeamRosterUnsigned;

    return response;
  },
);

export const getTeamDeadCapOutlook = createAsyncThunk(
  'teams/getTeamDeadCapOutlook',
  async (teamId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const endpoint = scenario
      ? `teams/${teamId}/dead-cap-outlook?scenarioId=${scenario.scenarioId}`
      : `teams/${teamId}/dead-cap-outlook`;

    const response = (await api(endpoint, { method: 'GET' }, true)) as TeamDeadCapOutlook;

    return response;
  },
);

export const getTrades = createAsyncThunk(
  'teams/trades',
  async (tradePayload: TeamsTradeFilters) =>
    (await api('trades', { method: 'POST', body: tradePayload }, true)) as { trades: TeamTrade[] },
);

export const recallPlayer = createAction<TeamRosterOutlookRecallPlayer>('teams/recallPlayer');

export const reassignPlayer = createAction<TeamRosterOutlookReassignPlayer>('teams/reassignPlaer');

export const resetTeam = createAction('teams/resetTeam');

export const setContractYear = createAction<number | null>('teams/setContractYear');

export const setRosterStatFilter = createAction<string>('teams/setRosterStatFilter');

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTeams.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.teams = payload;

        const teamsByConferenceByDivision: TeamsByConferenceByDivision = {};

        payload.forEach((team: Team) => {
          if (!teamsByConferenceByDivision[Conferences[team.conference]]) {
            teamsByConferenceByDivision[Conferences[team.conference]] = { [Divisions[team.division]]: [team] };
            return;
          }

          if (!teamsByConferenceByDivision[Conferences[team.conference]][Divisions[team.division]]) {
            teamsByConferenceByDivision[Conferences[team.conference]][Divisions[team.division]] = [team];
            return;
          }

          teamsByConferenceByDivision[Conferences[team.conference]][Divisions[team.division]].push(team);
        });

        teamsByConferenceByDivision[Conferences[1]][Divisions[1]].sort((teamA, teamB) =>
          teamA.abrvName.localeCompare(teamB.abrvName),
        );
        teamsByConferenceByDivision[Conferences[1]][Divisions[2]].sort((teamA, teamB) =>
          teamA.abrvName.localeCompare(teamB.abrvName),
        );
        teamsByConferenceByDivision[Conferences[2]][Divisions[3]].sort((teamA, teamB) =>
          teamA.abrvName.localeCompare(teamB.abrvName),
        );
        teamsByConferenceByDivision[Conferences[2]][Divisions[4]].sort((teamA, teamB) =>
          teamA.abrvName.localeCompare(teamB.abrvName),
        );

        state.teamsByConferenceByDivision = teamsByConferenceByDivision;
      })
      .addCase(getTeams.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTeamsSummary.pending, (state) => {
        state.isTeamSummaryLoading = true;
      })
      .addCase(getTeamsSummary.fulfilled, (state, { payload }) => {
        state.isTeamSummaryLoading = false;
        state.teamsSummary = payload;
      })
      .addCase(getTeamsSummary.rejected, (state) => {
        state.isTeamSummaryLoading = false;
      })
      .addCase(getTeam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.team = payload;
      })
      .addCase(getTeam.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTeamSummary.pending, (state) => {
        state.isSummaryLoading = true;
      })
      .addCase(getTeamSummary.fulfilled, (state, { payload }) => {
        state.isSummaryLoading = false;
        state.teamSummary = payload;
      })
      .addCase(getTeamSummary.rejected, (state) => {
        state.isSummaryLoading = false;
      })
      .addCase(getProjectedTeamSummary.pending, (state) => {
        state.isProjectedSummaryLoading = true;
      })
      .addCase(getProjectedTeamSummary.fulfilled, (state, { payload }) => {
        state.isProjectedSummaryLoading = false;
        state.teamProjectedSummary = payload;
      })
      .addCase(getProjectedTeamSummary.rejected, (state) => {
        state.isProjectedSummaryLoading = false;
      })
      .addCase(getTeamDraftPicks.pending, (state) => {
        state.isDraftPicksLoading = true;
      })
      .addCase(getTeamDraftPicks.fulfilled, (state, { payload }) => {
        state.isDraftPicksLoading = false;

        if (payload) {
          const draftPicksByYear = payload.reduce((draftPicks, draftPick) => {
            const newDraftPicks = cloneDeep(draftPicks);

            if (!newDraftPicks[draftPick.year]) newDraftPicks[draftPick.year] = [];

            newDraftPicks[draftPick.year] = [...newDraftPicks[draftPick.year], draftPick].sort(
              (a, b) => a.round - b.round,
            );

            return newDraftPicks;
          }, {} as TeamDraftPicksByYear);
          const draftPicksByYearByRound: TeamDraftPicksByYearByRound = {};

          Object.keys(draftPicksByYear).forEach((key) => {
            draftPicksByYearByRound[key] = draftPicksByYear[key].reduce((draftPicks, draftPick) => {
              // eslint-disable-next-line no-param-reassign
              if (!draftPicks[draftPick.round]) draftPicks[draftPick.round] = [];

              draftPicks[draftPick.round].push(draftPick);

              return draftPicks;
            }, {} as TeamDraftPicksByYear);
          });

          state.teamDraftPicksByYear = draftPicksByYear;
          state.teamDraftPicksByYearByRound = draftPicksByYearByRound;
        }

        state.teamDraftPicks = payload;
      })
      .addCase(getTeamDraftPicks.rejected, (state) => {
        state.isDraftPicksLoading = false;
        state.teamDraftPicks = [];
      })
      .addCase(getTeamRosterOutlook.pending, (state) => {
        state.isOutlookLoading = true;
      })
      .addCase(getTeamRosterOutlook.fulfilled, (state, { payload: { response, season } }) => {
        state.isOutlookLoading = false;

        if (response.players) {
          const activeRoster: Player[] = response.players.filter(
            (player) =>
              player.status === ContractStatus.ACTIVE ||
              player.status === ContractStatus.IR ||
              player.status === ContractStatus.LTIR,
          );
          const inactiveRoster: Player[] = response.players.filter(
            (player) =>
              player.status !== ContractStatus.ACTIVE &&
              player.status !== ContractStatus.IR &&
              player.status !== ContractStatus.LTIR,
          );

          state.teamContractYears = generateTeamContractYears(response.players, season.seasonYearStart);
          state.teamActiveContractYears = generateTeamContractYears(activeRoster, season.seasonYearStart);
          state.teamActiveRosterOutlook = generateSortedRosterOutlook(activeRoster);
          state.teamActiveRosterOutlookByPosition = sortMappedRosterByPosition(
            mapRosterByPosition(activeRoster),
            ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL,
          );

          state.teamInactiveContractYears = generateTeamContractYears(inactiveRoster, season.seasonYearStart);
          state.teamInactiveRosterOutlook = generateSortedRosterOutlook(inactiveRoster);
          state.teamInactiveRosterOutlookByPosition = sortMappedInactiveRosterByPosition(
            mapInactiveRosterByPosition(inactiveRoster),
            ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL,
          );
          return;
        }

        state.teamActiveRosterOutlookByPosition = {};
        state.teamActiveRosterOutlook = [];
      })
      .addCase(getTeamRosterOutlook.rejected, (state) => {
        state.isOutlookLoading = false;
      })
      .addCase(getTeamDeadCapOutlook.pending, (state) => {
        state.isDeadCapOutlookLoading = true;
      })
      .addCase(getTeamDeadCapOutlook.fulfilled, (state, { payload }) => {
        state.isDeadCapOutlookLoading = false;
        state.teamDeadCapOutlook = payload.outlook;
      })
      .addCase(getTeamDeadCapOutlook.rejected, (state) => {
        state.isDeadCapOutlookLoading = false;
      })
      .addCase(getTrades.pending, (state) => {
        state.isTradesLoading = true;
      })
      .addCase(getTrades.fulfilled, (state, { payload }) => {
        state.isTradesLoading = false;
        state.trades = payload.trades;
      })
      .addCase(getTrades.rejected, (state) => {
        state.isTradesLoading = false;
      })
      .addCase(resetTeam, (state) => {
        state.team = null;
        state.teamSummary = null;
        state.teamDraftPicks = null;
        state.teamActiveRosterOutlook = null;
        state.teamActiveRosterOutlookByPosition = null;
        state.teamInactiveRosterOutlook = null;
        state.teamInactiveRosterOutlookByPosition = null;
        state.trades = null;
      })
      .addCase(setContractYear, (state, { payload }) => {
        state.teamContractYear = payload;
      })
      .addCase(setRosterStatFilter, (state, { payload }) => {
        state.teamRosterStatFilter = payload;
      });
  },
});

export default teamsSlice.reducer;
