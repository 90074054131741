import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useCallback } from 'react';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { ComparableContractsTableSummaryItem } from 'types/comparableContracts';
import {
  DECIMAL_DATA_FIELDS,
  DOLLAR_DATA_FIELDS,
  NON_CENTERED_DATA_FIELDS,
  PERCENT_DATA_FIELDS,
  TIME_DATA_FIELDS,
} from 'constants/comparableContracts';
import { TextAlign } from 'types/table';
import { formatTOI } from 'utilities/comparableContracts';

interface ComparableContractsTableSummaryProps {
  columnIndicies: Record<string, number>;
  comparableContractsSummary: ComparableContractsTableSummaryItem[];
}

function ComparableContractsTableSummary({
  columnIndicies,
  comparableContractsSummary,
}: ComparableContractsTableSummaryProps): JSX.Element | null {
  const classes = useStyles();

  const generateTableCells = useCallback(
    (data: ComparableContractsTableSummaryItem): ReactElement[] => {
      if (!comparableContractsSummary) return [];

      return [
        <TableCell key="empty" className={classes.tableCell} />,
        <TableCell key="title" className={classes.tableCell}>
          <Typography variant="subtitle2">{data.title}</Typography>
        </TableCell>,
        ...Object.keys(columnIndicies).map((key: string, index: number) => {
          if (data[key] !== undefined) {
            let value = data[key] as string | ReactElement | number;

            if (DOLLAR_DATA_FIELDS[key]) value = <StanleyDollar amount={value as number} />;
            if (PERCENT_DATA_FIELDS[key]) {
              value = Number.isFinite(value) ? `${(value as number).toFixed(2)}%` : `${value as string}%`;
            }
            if (DECIMAL_DATA_FIELDS[key]) {
              value = Number.isFinite(value) ? (value as number).toFixed(2) : value;
            }
            if (TIME_DATA_FIELDS[key] && typeof value === 'string') value = formatTOI(value);

            return (
              <TableCell
                key={key}
                className={classNames(classes.tableCell, !NON_CENTERED_DATA_FIELDS[key] && classes.tableCellCenter)}
              >
                {value}
              </TableCell>
            );
          }

          return <TableCell key={`empty-${index}`} className={classes.tableCell} />;
        }),
      ];
    },
    [classes, columnIndicies, comparableContractsSummary],
  );

  if (isEmpty(comparableContractsSummary)) return null;

  return (
    <TableFooter>
      {comparableContractsSummary.map((data) => (
        <TableRow key={data.title}>{generateTableCells(data)}</TableRow>
      ))}
    </TableFooter>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    padding: theme.spacing(1.25),
  },
  tableCellCenter: {
    textAlign: TextAlign.center,
  },
}));

export default ComparableContractsTableSummary;
