/* eslint-disable import/prefer-default-export */
import { Image } from '@mui/icons-material';
import { PageActionItem } from '@project-stanley/cap-management-components';
import { Typography } from '@mui/material';

export const PagePrintAction: PageActionItem = {
  text: (
    <>
      <Image color="secondary" />
      <Typography sx={{ marginLeft: 2 }} variant="body1">
        PNG
      </Typography>
    </>
  ),
};
