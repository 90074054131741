import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { ButtonSize, Container, Item, StanleySecondaryButton } from '@project-stanley/cap-management-components';
import { Close } from '@mui/icons-material';
import { isBefore } from 'date-fns';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import PlayerSearchContractDemographics from 'modules/advancedSearch/playerSearchContractDemographics.component';
import PlayerSearchMixMaxPanel from 'modules/advancedSearch/playerSearchMixMaxPanel.component';
import PlayerSearchSeasonSelection from 'modules/advancedSearch/playerSearchSeasonSelection.component';
import { Filters } from 'types/playerSearch';
import { INITIAL_FILTERS } from 'utilities/playerSearch';
import { cloneDeep } from 'lodash';
import { selectSeason } from 'modules/season/season.selectors';

interface PlayerSearchCriteriaDialogProps {
  filters: Filters;
  isLoading: boolean;
  open: boolean;
  onChange: React.Dispatch<React.SetStateAction<Filters>>;
  onClose: () => void;
  onSearch: (filters: Filters) => void;
}

function PlayerSearchCriteriaDialog({
  filters,
  isLoading,
  open,
  onChange,
  onClose,
  onSearch,
}: PlayerSearchCriteriaDialogProps) {
  const season = useSelector(selectSeason);

  const [selectedTabIndex, setSelectedTab] = useState(0);

  const handleReset = useCallback(() => {
    const baseFilters = cloneDeep(INITIAL_FILTERS);

    if (baseFilters.playerStatsSearchCriteria) {
      if (isBefore(new Date(), new Date(season.seasonStartDate))) {
        baseFilters.playerStatsSearchCriteria.seasonYear = `${season.seasonYearStart - 1}-${season.seasonYearEnd - 1}`;
      } else {
        baseFilters.playerStatsSearchCriteria.seasonYear = season.seasonId;
      }
    }

    onChange(baseFilters);
    onClose();
    onSearch(baseFilters);
  }, [season, onChange, onClose, onSearch]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{ sx: { height: '38rem', padding: 1, position: 'relative', maxHeight: '100%' } }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Container sx={{ height: '100%' }}>
          <Item
            xs={12}
            sm={4}
            sx={{
              borderRadius: 4,
              backgroundColor: Colors.LIGHT_GRAY,
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {selectedTabIndex === 0 && <PlayerSearchContractDemographics filters={filters} onChange={onChange} />}
            {selectedTabIndex === 1 && <PlayerSearchSeasonSelection filters={filters} onChange={onChange} />}
          </Item>
          <Item xs={12} sm={8} sx={{ padding: '0.25rem 1rem 1rem' }}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box flex={1} display="flex" flexDirection="column">
                <PlayerSearchMixMaxPanel
                  filters={filters}
                  selectedTabIndex={selectedTabIndex}
                  onFiltersChange={onChange}
                  onTabChange={(tabIndex: number) => setSelectedTab(tabIndex)}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" paddingTop="0.5rem">
                <Button
                  disabled={isLoading}
                  size={ButtonSize.SMALL}
                  sx={{ paddingRight: '1rem', textTransform: 'none' }}
                  onClick={handleReset}
                >
                  Reset to Default
                </Button>
                <StanleySecondaryButton
                  disabled={isLoading}
                  size={ButtonSize.SMALL}
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    onClose();
                    onSearch(filters);
                  }}
                >
                  Search
                </StanleySecondaryButton>
              </Box>
            </Box>
          </Item>
        </Container>
      </DialogContent>
      <IconButton size="small" sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={onClose}>
        <Close />
      </IconButton>
    </Dialog>
  );
}

export default PlayerSearchCriteriaDialog;
