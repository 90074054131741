import { Player, PlayerCareerStats, PlayerInfo, PlayerSeasonStats, PlayerUnsigned } from 'types/player';

export interface Team {
  teamId: number;
  abrvName: string;
  conference: number;
  division: number;
  logo: string;
  name: string;
}

export interface TeamSummary {
  teamId: number;
  activeRosterCapHit: number;
  averageAge: number;
  bonusOverage: number;
  currentCapSpace: number;
  effectiveCapHit: number;
  maxLtirPool: number;
  name: string;
  numPlayersOnRoster: number;
  numPlayersRetained: number;
  numPlayersUnderContract: number;
  potentialPerformanceBonus: number;
  projCapHit: number;
  projCapSpace: number;
  retainedCapHit: number;
  retainedSalary: number;
  salaryExpenditure: number;
  totalActiveRosterSalary: number;
  totalSigningBonuses: number;
  usingLtir: boolean;
  [key: string]: number | string | boolean;
}

export interface TeamsByDivision {
  [key: string]: Team[];
}

export interface TeamsByConferenceByDivision {
  [key: string]: TeamsByDivision
}

export enum Divisions {
  Atlantic = 1,
  Metropolitan = 2,
  Central = 3,
  Pacific = 4,
}

export enum Conferences {
  Eastern = 1,
  Western = 2,
}

export interface TeamSummaryItem {
  defaultValue?: string;
  description: string;
  isDollarAmount?: boolean;
  key: string;
}

export interface TeamDraftPick {
  draftPickId: number;
  currentTeam: TeamInfo;
  isTradedAway?: boolean;
  originalTeam: TeamInfo;
  round: number;
  year: number;
}

export interface TeamDraftPicksByYear {
  [key: string]: TeamDraftPick[]
}

export interface TeamDraftPicksByYearByRound {
  [key: string]: TeamDraftPicksByYear
}

export interface TeamRosterOutlook {
  players: Player[]
}

export interface TeamDraftPicksResponse {
  draftPicks: TeamDraftPick[];
  draftPicksTradedAway: TeamDraftPick[];
}

export interface TeamRosterOutlookByPosition {
  [key: string]: Player[]
}

export interface TeamRosterPositionDetails {
  detailAmount: number,
  numOfPlayers: number,
  [key: string]: number
}

export interface TeamRosterOutlookDroppablePosition {
  activeRoster: boolean;
  destinationIndex: number;
  destinationPosition: string;
  sourceIndex: number;
  sourcePosition: string;
}

export interface TeamRosterOutlookRecallPlayer {
  playerId: string;
}

export interface TeamRosterOutlookReassignPlayer {
  playerId: string;
}

export enum TeamDeadCapType {
  Buyout = 1,
  Retained = 2,
  Terminated = 3,
  'Recapture Penalty' = 4,
}

export interface TeamDeadCapDetails {
  seasonId: string;
  projectedCapHit: number;
  annualSalary: number;
  [key: string]: string | number;
}

export interface TeamDeadCapOutlook {
  outlook: TeamDeadCap[]
}

export interface TeamDeadCap {
  contractId: number;
  playerId: number;
  teamId: number;
  deadCapType: TeamDeadCapType;
  firstName: string;
  lastName: string;
  details: TeamDeadCapDetails[];
}

export interface TeamRosterUnsigned {
  players: PlayerUnsigned[]
}

export interface TeamsTradeFilters {
  playerIds?: number[];
  teamIds?: number[];
  draftRounds?: number[];
  startDate?: string;
  endDate?: string;
}

export interface TeamTrade {
  tradeId: number;
  transactionDate: Date;
  details: string;
  playerAssets: TeamTradePlayerAsset[];
  draftPickAssets: TeamTradeDraftAsset[];
  breakdownByTeam: TeamTradeBreakdown[];
}

export interface TeamInfo {
  teamId: number;
  abrvName: string;
  name: string;
}

export interface TeamTradePlayerAsset {
  capHit: number,
  careerStats: PlayerCareerStats;
  playerInfo: PlayerInfo;
  receivingTeamId: number;
  receivingTeamInfo: TeamInfo;
  retainedCapHit: number;
  retainedCapHitPercentage: number;
  seasonStats: PlayerSeasonStats[];
  tradeAssetId: number;
  tradeId: number;
  tradingTeamId: number;
  tradingTeamInfo: TeamInfo;
}

export interface TeamTradeDraftAsset {
  tradeId: number;
  tradingTeamId: number;
  tradingTeamInfo: TeamInfo;
  receivingTeamId: number;
  receivingTeamInfo: TeamInfo;
  draftPickInfo: TeamDraftPick;
  draftPickConditions: string;
}

export interface TeamTradeBreakdown {
  teamInfo: TeamInfo;
  playersTraded: TeamTradePlayerAsset[];
  draftPicksTraded: [];
  playersReceived: TeamTradePlayerAsset[];
  draftPicksReceived: [];
  totalCapHit: number;
  annualCapHitEffect: number;
}

export interface RosterBreakdown {
  activeDefensiveRoster: Player[];
  activeGoaliesRoster: Player[];
  activeOffensiveRoster: Player[];
  inactiveDefensiveRoster: Player[];
  inactiveGoaliesRoster: Player[];
  inactiveOffensiveRoster: Player[];
}

export interface PositionRoster {
  players: Player[];
  positionDetails: TeamRosterPositionDetails
}

export interface PositionRosterBreakdown {
  activeDefensiveRoster: PositionRoster;
  activeGoaliesRoster: PositionRoster;
  activeOffensiveRoster: PositionRoster;
  inactiveDefensiveRoster: PositionRoster;
  inactiveGoaliesRoster: PositionRoster;
  inactiveOffensiveRoster: PositionRoster;
}
