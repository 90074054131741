const COMPARABLE_CONTRACTS_DATA_FIELDS = 'COMPARABLE_CONTRACTS_DATA_FIELDS';
const PLAYER_SEARCH_DATA_FIELDS = 'PLAYER_SEARCH_DATA_FIELDS';
const COMPARABLE_CONTRACTS = 'COMPARABLE_CONTRACTS';
const DEPTH_CHART = 'DEPTH_CHART';
const PLAYER_SEARCH_CRITERIA = 'PLAYER_SEARCH_CRITERIA';

export const FIREBASE_STORAGE_PREFERENCES_KEYS = {
  COMPARABLE_CONTRACTS_DATA_FIELDS,
  PLAYER_SEARCH_DATA_FIELDS,
  COMPARABLE_CONTRACTS,
  DEPTH_CHART,
  PLAYER_SEARCH_CRITERIA,
};

const LAST_LOGIN = 'lastLogin';
const LAST_INJURIES_VIEW = 'lastInjuriesView';
const LAST_RESEARCH_VIEW = 'lastResearchView';
const LAST_SIGNINGS_VIEW = 'lastSigningsView';
const LAST_TRANSACTIONS_VIEW = 'lastTransactionsView';

export const FIREBASE_STORAGE_USER_KEYS = {
  LAST_LOGIN,
  LAST_INJURIES_VIEW,
  LAST_RESEARCH_VIEW,
  LAST_SIGNINGS_VIEW,
  LAST_TRANSACTIONS_VIEW,
};
