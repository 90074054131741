/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-curly-newline */
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { StanleyConfirmDialog, StanleyTable, StanleyTableDropdown } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ScenarioSummaryDetailPanel from 'modules/scenarios/scenarioSummary/scenarioSummaryDetailPanel.component';
import { ROUTES } from 'utilities/routes';
import { Scenario } from 'types/scenarios';
import { TableAction, TableRowClick, TableRowData } from 'types/table';
import { ToastTypes } from 'types/layout';
import { deleteScenario, getScenarios, resetScenarios, setScenario } from 'modules/scenarios/scenarios.slice';
import { selectScenario, selectScenariosIsDeletingScenario } from 'modules/scenarios/scenarios.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

interface ScenariosTableProps {
  scenarios: Scenario[] | null;
}

function ScenariosTable({ scenarios }: ScenariosTableProps): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isDeletingScenario = useSelector(selectScenariosIsDeletingScenario);
  const scenario = useSelector(selectScenario);

  const [scenarioToDelete, setScenarioToDelete] = useState<Scenario | null>(null);

  const columns = useMemo(
    () => [
      {
        title: 'Scenario Name',
        field: 'name',
        hasSortCellStyle: false,
      },
      {
        title: 'Created Date',
        hasSortCellStyle: false,
        render: (rowData: Scenario) => format(new Date(rowData.dateCreatedUtc), 'M/d/yyyy'),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: Scenario) => {
      if (rowData && rowData.scenarioId) {
        dispatch(setScenario(rowData));

        navigate(`${ROUTES.TEAMS}`);
      }
    },
    [dispatch, navigate],
  );

  const handleDeleteScenario = useCallback(async () => {
    if (isNil(scenarioToDelete)) return;

    const response = await dispatch(deleteScenario(scenarioToDelete.scenarioId.toString()));

    if (response.type === deleteScenario.rejected.toString()) {
      dispatch(
        showToast({
          toastMessage: 'Unable to delete scenario.',
          toastType: ToastTypes.ERROR,
        }),
      );

      setScenarioToDelete(null);
      return;
    }

    dispatch(
      showToast({
        toastMessage: 'Scenario deleted successfully.',
        toastType: ToastTypes.SUCCESS,
      }),
    );

    if (scenario?.scenarioId === scenarioToDelete.scenarioId) dispatch(resetScenarios());

    setScenarioToDelete(null);

    dispatch(getScenarios());
  }, [dispatch, scenario, scenarioToDelete]);

  return (
    <>
      <StanleyTable
        actions={[
          {
            icon: 'delete',
            text: 'Delete',
            onClick: (event: MouseEvent, rowData: TableRowData) => {
              event.stopPropagation();

              setScenarioToDelete(rowData as Scenario);
            },
          },
        ]}
        columns={columns}
        components={{
          Actions: ({ actions, data }: { actions: TableAction[]; data: TableRowData }) => (
            <StanleyTableDropdown rowData={data} menuItems={actions} />
          ),
        }}
        data={scenarios || []}
        detailPanel={({ rowData }: { rowData: Scenario }) =>
          (<ScenarioSummaryDetailPanel key={rowData.scenarioId} rowData={rowData} />) as ReactNode
        }
        options={{
          actionsColumnIndex: -1,
          detailPanelType: 'single',
        }}
        shouldHaveHoverStyle={false}
        onRowClick={handleRowClick}
      />
      <StanleyConfirmDialog
        cancelText="Cancel"
        confirmText="Delete"
        contentText="Are you sure you want to delete this scenario?"
        isLoading={isDeletingScenario}
        loadingText="Deleting..."
        open={!isNil(scenarioToDelete)}
        title="Delete Scenario?"
        onClose={() => setScenarioToDelete(null)}
        onConfirm={handleDeleteScenario}
      />
    </>
  );
}

export default ScenariosTable;
