/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-confusing-arrow */
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, useTheme } from '@mui/material';
import { EditComponentProps, MTableBody } from '@material-table/core';
import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line max-len
import PlayerContractSimulatorTableSummary from 'modules/players/playerContracts/playerContractSimulator/playerContractSimulatorTableSummary.component';
import StanleyTableDollarEditInput from 'components/table/stanleyTableDollarEditInput.component';
import { Editable, TextAlign } from 'types/table';
import { PlayerContractSimulationDetails } from 'types/contract';
import {
  selectScenariosIsLoadingContract,
  selectScenariosIsLoadingPreview,
} from 'modules/scenarios/scenarios.selectors';

interface PlayerContractSimulatorTableProps {
  contract: PlayerContractSimulationDetails[];
  isOneWay: boolean;

  onDataChange: () => void;
}

const PlayerContractSimulatorTable = forwardRef(
  ({ contract, isOneWay, onDataChange }: PlayerContractSimulatorTableProps, ref) => {
    const classes = useStyles();
    const theme = useTheme();

    const isLoadingContract = useSelector(selectScenariosIsLoadingContract);
    const isLoadingPreview = useSelector(selectScenariosIsLoadingPreview);

    const columns = useMemo(
      () => [
        {
          cellStyle: { minWidth: 125 },
          editable: Editable.never,
          field: 'season',
          title: 'Season',
        },
        {
          cellStyle: { padding: `0 ${theme.spacing(1)}`, textAlign: TextAlign.center },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <Checkbox
              checked={value as boolean}
              color="primary"
              disabled={isLoadingContract || isLoadingPreview}
              onChange={(e) => {
                onChange(e.target.checked);
                onDataChange();
              }}
            />
          ),
          field: 'hasNoTradeClause',
          headerStyle: { textAlign: TextAlign.center },
          title: 'NTC',
        },
        {
          cellStyle: { padding: `0 ${theme.spacing(1)}`, textAlign: TextAlign.center },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <Checkbox
              checked={value as boolean}
              color="primary"
              disabled={isLoadingContract || isLoadingPreview}
              onChange={(e) => {
                onChange(e.target.checked);
                onDataChange();
              }}
            />
          ),
          field: 'hasNoMovementClause',
          headerStyle: { textAlign: TextAlign.center },
          title: 'NMC',
        },
        {
          cellStyle: { minWidth: 70 },
          render: (rowData: PlayerContractSimulationDetails) => <StanleyDollar amount={rowData.capHit} />,
          title: 'Cap Hit',
        },
        {
          render: (rowData: PlayerContractSimulationDetails) => <StanleyDollar amount={rowData.averageAnnualValue} />,
          title: 'AAV',
        },
        {
          cellStyle: { padding: theme.spacing(0.5), minWidth: 118 },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <StanleyTableDollarEditInput
              className={classes.dollarInput}
              disabled={isLoadingContract || isLoadingPreview}
              value={value as number}
              variant="standard"
              onChange={(dollarValue: number | string) => {
                onChange(dollarValue);
                onDataChange();
              }}
            />
          ),
          field: 'performanceBonus',
          title: 'P. Bonuses',
        },
        {
          cellStyle: { padding: theme.spacing(0.5), minWidth: 118 },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <StanleyTableDollarEditInput
              className={classes.dollarInput}
              disabled={isLoadingContract || isLoadingPreview}
              value={value as number}
              variant="standard"
              onChange={(dollarValue: number | string) => {
                onChange(dollarValue);
                onDataChange();
              }}
            />
          ),
          field: 'signingBonus',
          title: 'S. Bonuses',
        },
        {
          cellStyle: { padding: theme.spacing(0.5), minWidth: 122 },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <StanleyTableDollarEditInput
              className={classes.dollarInput}
              disabled={isLoadingContract || isLoadingPreview}
              value={value as number}
              variant="standard"
              onChange={(dollarValue: number | string) => {
                onChange(dollarValue);
                onDataChange();
              }}
            />
          ),
          field: 'baseSalary',
          title: 'Base Salary',
        },
        {
          cellStyle: { minWidth: 122 },
          render: (rowData: PlayerContractSimulationDetails) => <StanleyDollar amount={rowData.totalSalary} />,
          title: 'Total Salary',
        },
        {
          cellStyle: { padding: theme.spacing(0.5), minWidth: 126 },
          editComponent: ({ value, onChange }: EditComponentProps<PlayerContractSimulationDetails>) => (
            <StanleyTableDollarEditInput
              className={classes.dollarInput}
              disabled={isLoadingContract || isLoadingPreview}
              value={value as number}
              variant="standard"
              onChange={(dollarValue: number | string) => {
                onChange(dollarValue);
                onDataChange();
              }}
            />
          ),
          editable: isOneWay ? Editable.never : Editable.always,
          field: 'minorsSalary',
          render: (rowData: PlayerContractSimulationDetails) => <StanleyDollar amount={rowData.totalSalary} />,
          title: 'Minor Salary',
        },
        {
          cellStyle: { minWidth: 114 },
          render: (rowData: PlayerContractSimulationDetails) => `${(rowData.capHitPercentage * 100).toFixed(2)}%`,
          title: 'Cap Hit(%)',
        },
      ],
      [classes, theme, isLoadingContract, isLoadingPreview, isOneWay, onDataChange],
    );

    return (
      <StanleyTable
        ref={ref}
        columns={columns}
        components={{
          Body: (props) => (
            <>
              <MTableBody {...props} />
              <PlayerContractSimulatorTableSummary contract={contract} />
            </>
          ),
        }}
        data={contract}
        options={{ actionsColumnIndex: -1, sorting: false }}
        editable={{
          onBulkUpdate: (changes) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve(changes);
              }, 1000);
            }),
        }}
      />
    );
  },
);

const useStyles = makeStyles((theme) => ({
  dollarInput: {
    backgroundColor: '#004de826',
    padding: theme.spacing(0.5),
  },
}));

export default PlayerContractSimulatorTable;
