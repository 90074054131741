import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NavItem } from 'types/drawer';
import { ToastTypes } from 'types/layout';

interface LayoutState {
  blockNavigation: BlockNavigation;
  initialRoute: string | null;
  isPrinting: boolean;
  showToast: boolean;
  subNavItems: NavItem[] | null;
  toastLoading?: boolean | null;
  toastMessage: string | null;
  toastType: ToastTypes | null;
}

export interface ShowToast {
  toastLoading?: boolean | null;
  toastMessage: string | null;
  toastType: ToastTypes | null;
}

export interface BlockNavigation {
  blockContent?: string;
  shouldBlockNavigation: boolean;
  actions: {
    storageKey: string;
    data: any;
  }[];
}

const initialState: LayoutState = {
  blockNavigation: {
    shouldBlockNavigation: false,
    actions: [],
  },
  initialRoute: null,
  isPrinting: false,
  showToast: false,
  subNavItems: null,
  toastLoading: null,
  toastMessage: null,
  toastType: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    clearToast(state) {
      state.showToast = false;
    },
    setSubNavItems(state, action: PayloadAction<NavItem[] | null>) {
      state.subNavItems = action.payload;
    },
    showToast(state, action: PayloadAction<ShowToast>) {
      state.showToast = true;
      state.toastLoading = action.payload.toastLoading;
      state.toastMessage = action.payload.toastMessage;
      state.toastType = action.payload.toastType;
    },
    setInitialRoute(state, action: PayloadAction<string>) {
      state.initialRoute = action.payload;
    },
    setIsPrinting(state, action: PayloadAction<boolean>) {
      state.isPrinting = action.payload;
    },
    setBlockNavigation(state, action: PayloadAction<BlockNavigation>) {
      state.blockNavigation = action.payload;
    },
  },
});

export const { setInitialRoute, setSubNavItems, showToast, clearToast, setIsPrinting, setBlockNavigation } =
  layoutSlice.actions;

export default layoutSlice.reducer;
