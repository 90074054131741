import { Box, Paper, styled, useTheme } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { isNil, uniq } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import RosterComparisonRoster from 'modules/rosterComparison/rosterComparisonRoster.component';
import RosterViewHeader from 'modules/teams/rosterView/rosterViewHeader.component';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useQueryParams from 'hooks/useQueryParams';
import { GA_ACTIONS, GA_CATEGORIES, getReportingTeamAnalyticsAction, logGAEvent } from 'utilities/analytics';
import { Player } from 'types/player';
import { ROSTER_CONTRACT_FILTER_MAP, generateTeamContractYears } from 'utilities/roster';
import { ROUTES } from 'utilities/routes';
import { getTeams, setContractYear, setRosterStatFilter } from 'modules/teams/teams.slice';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import {
  selectTeamContractYear,
  selectTeamRosterStatFilter,
  selectTeams,
  selectTeamsIsLoading,
} from 'modules/teams/teams.selectors';
import { useAppDispatch } from 'store';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop: string) => prop !== 'isPrinting',
})<{ isPrinting: boolean }>(({ isPrinting, theme }) => {
  const styles = {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  };

  if (!isPrinting) {
    return {
      ...styles,
      height: `calc(100% - ${theme.spacing(6)})`,
      overflowY: 'scroll',
    };
  }

  return styles;
});

function RosterComparison(): JSX.Element {
  const rosterRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const copyToClipboard = useCopyToClipboard()[1];
  const queryParams = useQueryParams();
  const theme = useTheme();

  const teams = useSelector(selectTeams);
  const isLoading = useSelector(selectTeamsIsLoading);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const isPrinting = useSelector(selectIsPrinting);
  const contractYear = useSelector(selectTeamContractYear);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);

  const [contractYears, setContractYears] = useState<number[]>([]);
  const [showTerms, setShowTerms] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [teamIdOne, setTeamIdOne] = useState<number | null>(null);
  const [teamIdTwo, setTeamIdTwo] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    if (!teamIdOne && !teamIdTwo) {
      const teamOne = queryParams.get('teamOne');
      const teamTwo = queryParams.get('teamTwo');

      if (teamOne && teamTwo) {
        setTeamIdOne(Number(teamOne));
        setTeamIdTwo(Number(teamTwo));
        return;
      }

      if (teams) {
        setTeamIdOne(teams[0].teamId);
        setTeamIdTwo(teams[1].teamId);
      }
    }
  }, [queryParams, teamIdOne, teamIdTwo, teams]);

  useEffect(() => {
    dispatch(setContractYear(curSeasonStartYear));

    return () => {
      dispatch(setContractYear(null));
    };
  }, [dispatch, curSeasonStartYear]);

  const handleChangeRosterStatFilter = useCallback(
    (newRosterStatFilter: string) => {
      dispatch(setRosterStatFilter(newRosterStatFilter));

      logGAEvent({
        category: GA_CATEGORIES.ROSTER_COMPARISON,
        action: GA_ACTIONS.TEAM_STAT_FILTER_CHANGE,
        label: ROSTER_CONTRACT_FILTER_MAP[newRosterStatFilter],
      });
    },
    [dispatch],
  );

  const handleChangeContractYear = useCallback(
    (newContractYear: number) => {
      dispatch(setContractYear(newContractYear));
    },
    [dispatch],
  );

  const handleToggleTerms = useCallback(() => setShowTerms(!showTerms), [showTerms, setShowTerms]);

  const handleAfterPrint = useCallback(() => {
    logGAEvent({
      category: GA_CATEGORIES.ROSTER_COMPARISON,
      action: getReportingTeamAnalyticsAction({
        showDepthChart: selectedTabIndex === 0,
        showTerms,
      }),
    });
  }, [selectedTabIndex, showTerms]);

  const updateContractYears = useCallback(
    (players: Player[]) => {
      const teamContractYears = generateTeamContractYears(players, curSeasonStartYear);

      setContractYears((prevContractYears) => uniq([...prevContractYears, ...teamContractYears]).sort());
    },
    [curSeasonStartYear],
  );

  const handleCopyToClipBoard = useCallback(() => {
    if (teamIdOne && teamIdTwo) {
      copyToClipboard(
        `${window.location.origin.toString()}${ROUTES.ROSTER_COMPARISON}?teamOne=${teamIdOne}&teamTwo=${teamIdTwo}`,
      );
    }
  }, [teamIdOne, teamIdTwo, copyToClipboard]);

  if (isLoading || isNil(teams) || !teamIdOne || !teamIdTwo) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledPaper ref={rosterRef} elevation={3} isPrinting={isPrinting}>
      <Box marginBottom={theme.spacing(2)}>
        <RosterViewHeader
          activeFilter={rosterStatFilter}
          contractYear={contractYear}
          contractYears={contractYears}
          rosterRef={rosterRef}
          selectedTabIndex={selectedTabIndex}
          showDepthChart={selectedTabIndex === 0}
          showTerms={showTerms}
          tabs={[{ label: 'Depth Chart' }, { label: 'Capital Outlook' }]}
          onAfterPrint={handleAfterPrint}
          onChangeContractYear={handleChangeContractYear}
          onChangeRosterStatFilter={handleChangeRosterStatFilter}
          onCopyToClipboard={handleCopyToClipBoard}
          onToggleTerms={handleToggleTerms}
          onViewChange={(tabIndex: number) => setSelectedTabIndex(tabIndex)}
        />
      </Box>
      <Container spacing={2}>
        <Item xs={12} sm={12} md={6}>
          <RosterComparisonRoster
            showDepthChart={selectedTabIndex === 0}
            showTerms={showTerms}
            teamId={teamIdOne}
            teams={teams}
            onUpdateTeam={(teamId) => setTeamIdOne(teamId)}
            updateContractYears={updateContractYears}
          />
        </Item>
        <Item xs={12} sm={12} md={6}>
          <RosterComparisonRoster
            showDepthChart={selectedTabIndex === 0}
            showTerms={showTerms}
            teamId={teamIdTwo}
            teams={teams}
            onUpdateTeam={(teamId) => setTeamIdTwo(teamId)}
            updateContractYears={updateContractYears}
          />
        </Item>
      </Container>
    </StyledPaper>
  );
}

export default RosterComparison;
