import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StanleyTableTeamIcon from 'components/table/stanleyTableTeamIcon.component';
import { ROUTES } from 'utilities/routes';
import { RecentSigning } from 'types/transactions';
import { TableRowClick } from 'types/table';
import { getFullName } from 'utilities/player';
import { selectRecentSignings } from 'modules/teams/recentTransactions/recentTransactions.selectors';

function RecentSignings() {
  const navigate = useNavigate();

  const signings = useSelector(selectRecentSignings);

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        render: () => 'Signed',
      },
      {
        title: 'Player',
        render: (signing: RecentSigning) => getFullName(signing.playerInfo),
      },
      {
        title: 'Team',
        render: ({ signingTeam }: RecentSigning) => <StanleyTableTeamIcon teamInfo={signingTeam} />,
      },
      {
        title: 'AAV',
        render: ({ averageAnnualValue }: RecentSigning) => <StanleyDollar amount={averageAnnualValue} />,
      },
      {
        title: 'Cap Hit',
        render: ({ capHit }: RecentSigning) => <StanleyDollar amount={capHit} />,
      },
      {
        title: 'Length',
        render: ({ contractLength }: RecentSigning) => contractLength,
      },
      {
        title: 'Date',
        render: ({ signingDate }: RecentSigning) => format(new Date(signingDate), 'MMM dd, yyyy'),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: RecentSigning) => {
      if (rowData && rowData.playerInfo) navigate(`${ROUTES.PLAYERS}/${rowData.playerInfo.playerId}`);
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      data={signings ? [...signings.slice(0, 12)] : []}
      options={{
        sorting: false,
      }}
      onRowClick={handleRowClick}
    />
  );
}

export default RecentSignings;
