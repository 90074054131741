import { ContractStatus, PlayerContract } from 'types/contract';
import { PlayerPosition, PlayerShotHand } from 'types/player';
import { TeamDraftPick, TeamInfo } from 'types/teams';
import { TradeDraft, TradePlayer } from 'types/trade';

export interface Scenario {
  scenarioId: number;
  dateCreatedUtc: string;
  firebaseUserId: string;
  name: string;
}

export enum ScenarioChangeReason {
  Recall = 1,
  Reassign = 2,
}

export interface ScenarioSummaryStatusChange {
  playerId: number;
  player: {
      playerId: number;
      firstName: string;
      lastName: string;
      primaryPosition: PlayerPosition;
      shotHand: PlayerShotHand
  };
  teamInfo: TeamInfo;
  status: ContractStatus;
  changeReason: ScenarioChangeReason;
  startDateUtc: Date;
  endDateUtc: Date;
  scenarioId: number
}

export interface ScenarioSummary {
  playerContracts: PlayerContract[],
  statusChanges: ScenarioSummaryStatusChange[],
  trades: ScenarioSummaryTrade[]
}

export interface ScenarioSummaryTradeTeam {
  teamId: number;
  draftAssets: (TradeDraft & { draftPick: TeamDraftPick })[];
  details: string;
  playerAssets: TradePlayer[];
}

export interface ScenarioSummaryTrade {
  fifo: boolean;
  requiredPhysical: boolean;
  teamFrom: ScenarioSummaryTradeTeam;
  teamTo: ScenarioSummaryTradeTeam;
  createdOnUtc?: Date;
}

export interface ScenarioSummaryByTeam {
  [key: string]: (PlayerContract | ScenarioSummaryTrade | ScenarioSummaryStatusChange)[]
}
