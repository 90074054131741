/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import RosterDraggablePositionListItem from 'modules/teams/rosterView/rosterDraggablePositionListItem.component';
import RosterPositionListItem from 'modules/teams/rosterView/rosterPositionListItem.component';
import { Player } from 'types/player';
import { ROSTER_CONTRACT_FILTER_KEYS, ROSTER_CONTRACT_FILTER_MAP, ROSTER_PLAYER_FILTER_KEYS } from 'utilities/roster';
import { selectTeamContractYear, selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';

interface RosterPositionListProps {
  isDnD?: boolean;
  players: Player[];
  title: string;
}

const EMPTY_PLAYER = 'EMPTY_PLAYER';

function RosterPositionList({ isDnD, players, title }: RosterPositionListProps): JSX.Element {
  const classes = useStyles();

  const contractYear = useSelector(selectTeamContractYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);

  const positionAmount = useMemo(
    () =>
      players.reduce((acc, cur) => {
        if (ROSTER_CONTRACT_FILTER_MAP[rosterStatFilter]) {
          if (contractYear) {
            const playerContract = cur.contractDetails?.find(
              (contract) => contract?.seasonInfo.seasonYearStart === contractYear,
            );

            return playerContract ? acc + (playerContract[rosterStatFilter] as number) : acc;
          }

          return cur.currentContractDetail && cur.currentContractDetail[rosterStatFilter]
            ? acc + (cur.currentContractDetail[rosterStatFilter] as number)
            : acc;
        }

        switch (rosterStatFilter) {
          case ROSTER_PLAYER_FILTER_KEYS.HEIGHT:
          case ROSTER_PLAYER_FILTER_KEYS.WEIGHT:
            return acc;

          case ROSTER_PLAYER_FILTER_KEYS.TOTAL_NHL_GAMES:
            return cur.careerStats ? acc + cur.careerStats.regularSeasonStats.gamesPlayed : acc;

          case ROSTER_PLAYER_FILTER_KEYS.POINTS:
            return cur.currentSeasonStats && cur.currentSeasonStats.regularSeasonStats
              ? acc +
                  cur.currentSeasonStats.regularSeasonStats.assists +
                  cur.currentSeasonStats.regularSeasonStats.goals
              : acc;

          default:
            return cur.currentSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats[rosterStatFilter]
              ? acc + cur.currentSeasonStats.regularSeasonStats[rosterStatFilter]
              : acc;
        }
      }, 0),
    [contractYear, rosterStatFilter, players],
  );

  const renderedPlayers = useMemo(() => {
    const playersList: (Player | string)[] = [...players];

    if (playersList.length < 5) {
      for (let i = players.length; i < 5; i++) {
        playersList[i] = EMPTY_PLAYER;
      }
    }

    return playersList.map((player, index) => {
      if (player === EMPTY_PLAYER) {
        return <Box key={`empty-${index}`} className={classes.positionContainer} />;
      }

      return isDnD ? (
        <RosterDraggablePositionListItem key={(player as Player).playerId} index={index} player={player as Player} />
      ) : (
        <RosterPositionListItem key={(player as Player).playerId} player={player as Player} />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, isDnD, players, players.length]);

  const showSum =
    rosterStatFilter !== ROSTER_PLAYER_FILTER_KEYS.HEIGHT && rosterStatFilter !== ROSTER_PLAYER_FILTER_KEYS.WEIGHT;

  const stat = useMemo(() => {
    if (ROSTER_CONTRACT_FILTER_MAP[rosterStatFilter]) {
      return rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP ? (
        `${positionAmount.toFixed(2)} %`
      ) : (
        <StanleyDollar amount={positionAmount} />
      );
    }

    if (rosterStatFilter === ROSTER_PLAYER_FILTER_KEYS.PPG) {
      return positionAmount.toFixed(2);
    }

    return positionAmount;
  }, [positionAmount, rosterStatFilter]);

  return (
    <>
      <Typography className={classes.positionTitle} align="center">
        {`${title} (${players.length})`}
        {showSum && ' - '}
        {showSum && <span>{stat}</span>}
      </Typography>
      {renderedPlayers}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  positionTitle: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(0.5),
  },
  positionContainer: {
    background: Colors.LIGHT_GRAY,
    borderRadius: theme.spacing(1.25),
    height: '2.5rem',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

export default RosterPositionList;
