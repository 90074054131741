import { Add, Remove } from '@mui/icons-material';
import { Button, Checkbox, Collapse, ListSubheader, MenuItem, Typography, styled } from '@mui/material';

interface ComparableContractDisplayFieldItemProps {
  dataFields: (number | string)[];
  open: boolean;
  options: { label: string; value: number | string }[];
  title: string;
  onChange: (value: string | number) => void;
  onToggle: () => void;
}

const StyledListSubHeader = styled(ListSubheader)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.black,
  display: 'flex',
  fontSize: '1rem',
  height: '2rem',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.white,
  height: '1.25rem',
  paddingLeft: 0,
  '.icon': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '.25rem',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.75),
  },
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    '.icon': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
}));

function ComparableContractDisplayFieldItem({
  dataFields,
  open,
  options,
  title,
  onChange,
  onToggle,
}: ComparableContractDisplayFieldItemProps) {
  return (
    <>
      <StyledListSubHeader>
        <StyledIconButton onClick={onToggle}>
          {!open ? (
            <Add className="icon" color="secondary" fontSize="small" />
          ) : (
            <Remove className="icon" color="secondary" fontSize="small" />
          )}
          <Typography color="secondary" noWrap textTransform="none" variant="subtitle2">
            {title}
          </Typography>
        </StyledIconButton>
      </StyledListSubHeader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {options.map(({ label, value }) => (
          <StyledMenuItem key={`${label}-${value}`} value={value} onClick={() => onChange(value)}>
            <Checkbox
              sx={{
                padding: 0.5,
              }}
              checked={dataFields.indexOf(value) > -1}
            />
            {label}
          </StyledMenuItem>
        ))}
      </Collapse>
    </>
  );
}

export default ComparableContractDisplayFieldItem;
