import { Box } from '@mui/material';
import { StanleyTable, TableSort, TextAlign } from '@project-stanley/cap-management-components';
import { format, isBefore } from 'date-fns';
import { saveAs } from 'file-saver';
import { useCallback, useMemo, useState } from 'react';

import StanleyTableTeamIcon from 'components/table/stanleyTableTeamIcon.component';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { Report, ReportType } from 'types/research';
import { TableRowClick } from 'types/table';
import { ToastTypes } from 'types/layout';
import { getReport } from 'modules/research/research.slice';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

interface ResearchTableProps {
  reports: Report[];
}

function ResearchTable({ reports }: ResearchTableProps) {
  const dispatch = useAppDispatch();

  logGAEvent({
    category: GA_CATEGORIES.RESEARCH,
    action: GA_ACTIONS.VIEW_REPORTS,
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: 'Report Title',
        field: 'title',
      },
      {
        title: 'Team',
        cellStyle: { minWidth: 50, width: 50, textAlign: TextAlign.center },
        headerStyle: { minWidth: 50, width: 50, textAlign: TextAlign.center },
        sorting: false,
        render: ({ teamInfo }: Report) => <StanleyTableTeamIcon teamInfo={teamInfo} />,
      },
      {
        title: 'Report Type',
        cellStyle: { minWidth: 75, width: 75 },
        headerStyle: { minWidth: 75, width: 75 },
        customSort: (rowA: Report, rowB: Report) => rowA.reportType - rowB.reportType,
        render: ({ reportType }: Report) => ReportType[reportType],
      },
      {
        title: 'Date Published',
        cellStyle: { minWidth: 100, width: 100 },
        defaultSort: TableSort.DESC,
        headerStyle: { minWidth: 100, width: 100 },
        customSort: (rowA: Report, rowB: Report) => {
          const createdAtRowA = new Date(rowA.createdAt);
          const createdAtRowB = new Date(rowB.createdAt);

          return isBefore(createdAtRowA, createdAtRowB) ? -1 : 1;
        },
        render: ({ createdAt }: Report) => format(new Date(createdAt), 'MMM dd, yyyy'),
      },
      {
        title: 'Description',
        render: ({ description }: Report) => (
          <Box
            sx={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '2',
              overflow: 'hidden',
            }}
          >
            {description}
          </Box>
        ),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    async (event: TableRowClick, { reportId, title }: Report) => {
      if (isDownloading) return;

      logGAEvent({
        category: GA_CATEGORIES.DASHBOARD,
        action: GA_ACTIONS.DOWNLOAD_REPORT,
        label: title,
        value: reportId,
      });

      setIsDownloading(true);
      const reportResponse = await dispatch(getReport(reportId));
      setIsDownloading(false);

      if (reportResponse.type === getReport.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Download failed. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      const { filename, preSignedUrl } = reportResponse.payload as Report;

      saveAs(preSignedUrl, filename);
    },
    [dispatch, isDownloading],
  );

  return (
    <StanleyTable
      columns={columns}
      data={reports}
      defaultSortIndex={3}
      options={{
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
      }}
      onRowClick={handleRowClick}
    />
  );
}

export default ResearchTable;
