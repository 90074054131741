import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useSubRoute = (subRouteIndex: number) => {
  const location = useLocation();

  const [subRoute, setSubRoute] = useState<string>('');

  useEffect(() => {
    const pathParts = location.pathname.split('/');

    setSubRoute(pathParts[subRouteIndex] ? `/${pathParts[subRouteIndex]}` : '/');
  }, [location, subRouteIndex]);

  return subRoute;
};

export default useSubRoute;
