import { RecentSigning } from 'types/transactions';
import { RootState } from 'store';

export const selectRecentSignings = (state: RootState): RecentSigning[] | null =>
  state.recentTransactions.recentSignings;

export const selectRecentInjuries = (state: RootState): RecentSigning[] | null =>
  state.recentTransactions.recentInjuries;

export const selectRecentTransactions = (state: RootState): RecentSigning[] | null =>
  state.recentTransactions.recentTransactions;

export const selectRecentTransactionsIsLoadingSignings = (state: RootState): boolean =>
  state.recentTransactions.isLoadingSignings;

export const selectRecentTransactionsIsLoadingInjuries = (state: RootState): boolean =>
  state.recentTransactions.isLoadingInjuries;

export const selectRecentTransactionsIsLoadingTransactions = (state: RootState): boolean =>
  state.recentTransactions.isLoadingTransactions;
