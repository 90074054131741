/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import format from 'date-fns/format';
import { Box, useTheme } from '@mui/material';
import { StanleyDetailsPanel } from '@project-stanley/cap-management-components';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';

import PlayerBioItem from 'modules/players/playerBio/playerBioPanelItem.component';
import PlayerBioPanelHeader from 'modules/players/playerBio/playerBioPanelHeader.component';
import { PLAYER_POSITION_MAP, PLAYER_SHOT_HAND_MAP, convertToFeetInches, convertToLBS } from 'utilities/player';
import { Player } from 'types/player';

interface PlayerBioPanelProps {
  player: Player;
}

function PlayerBioPanel({
  player: {
    age,
    birthDate,
    city,
    country,
    currentTeam,
    firstName,
    heightCm,
    lastName,
    primaryPosition,
    shot,
    weightKg,
  },
}: PlayerBioPanelProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const renderedHeight = useMemo(
    () => (heightCm ? `${convertToFeetInches(heightCm)} | ${heightCm.toFixed(1)}cm` : '--'),
    [heightCm],
  );

  const renderedWeight = useMemo(
    () => (weightKg ? `${convertToLBS(weightKg)} | ${weightKg.toFixed(1)}kg` : '--'),
    [weightKg],
  );

  return (
    <StanleyDetailsPanel
      className={classes.bioPanel}
      titleComponent={
        <PlayerBioPanelHeader
          firstName={firstName}
          lastName={lastName}
          teamAbbreviation={currentTeam ? currentTeam.abrvName : '--'}
          teamId={currentTeam ? currentTeam.teamId : null}
          teamName={currentTeam ? currentTeam.name : '--'}
        />
      }
    >
      <Box marginTop={theme.spacing(2.5)}>
        <PlayerBioItem label="Position:" value={PLAYER_POSITION_MAP[primaryPosition] || '--'} />
        <PlayerBioItem label="Shot:" value={PLAYER_SHOT_HAND_MAP[shot] || '--'} />
        <PlayerBioItem label="City:" value={city} />
        <PlayerBioItem label="Country:" value={country} />
        <PlayerBioItem label="Birthdate:" value={format(new Date(birthDate), 'MMM dd, yyyy')} />
        <PlayerBioItem label="Age:" value={age} />
        <PlayerBioItem label="Height:" value={renderedHeight} />
        <PlayerBioItem label="Weight:" value={renderedWeight} />
      </Box>
    </StanleyDetailsPanel>
  );
}

const useStyles = makeStyles((theme) => ({
  bioPanel: {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
}));

export default PlayerBioPanel;
