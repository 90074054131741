import { Box, SelectChangeEvent, Typography, styled } from '@mui/material';
import {
  Container,
  Item,
  StanleySVGIcon,
  StanleySelect,
  StanleySelectOption,
} from '@project-stanley/cap-management-components';
import { useSelector } from 'react-redux';

import { CONTRACT_EXPIRY_STATUS_MAP, CONTRACT_TYPE_MAP } from 'utilities/contract';
import { ContractExpiryStatus, ContractType } from 'types/contract';
import { Filters } from 'types/playerSearch';
import { ICONS } from 'utilities/icons';
import { PLAYER_POSITION_MAP } from 'utilities/player';
import { PlayerPosition } from 'types/player';
import { selectTeams } from 'modules/teams/teams.selectors';
import { useCallback, useMemo } from 'react';

const StyledStanleySVGIcon = styled(StanleySVGIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

interface PlayerSearchContractDemographicsProps {
  filters: Filters;
  onChange: React.Dispatch<React.SetStateAction<Filters>>;
}

function PlayerSearchContractDemographics({ filters, onChange }: PlayerSearchContractDemographicsProps) {
  const teams = useSelector(selectTeams);

  const handleBaseFiltersChange = useCallback(
    (filterKey: string) =>
      ({ target }: SelectChangeEvent<unknown>) =>
        onChange((curFilters) => ({
          ...curFilters,
          [filterKey]: target.value as number | PlayerPosition[],
        })),
    [onChange],
  );

  const handleContractFiltersChange = useCallback(
    (filterKey: string) =>
      ({ target }: SelectChangeEvent<unknown>) =>
        onChange((curFilters) => ({
          ...curFilters,
          contractSearchCriteria: {
            ...curFilters.contractSearchCriteria,
            [filterKey]: target.value as number | ContractExpiryStatus[] | ContractType[],
          },
        })),
    [onChange],
  );

  const teamOptions = useMemo((): StanleySelectOption[] => {
    if (!teams) return [];

    return [...teams]
      .sort((teamA, teamB) => teamA.name.localeCompare(teamB.name))
      .map((team) => ({
        label: team.name,
        value: team.teamId,
      }));
  }, [teams]);

  return (
    <Container direction="column" spacing={5} flex={1}>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyDisabled
          emptyText="Teams"
          fullWidth
          multiple
          options={teamOptions}
          renderOption={(option: StanleySelectOption) => {
            const team = teams?.find((curTeam) => curTeam.teamId === option.value);

            return !team ? null : (
              <Box alignItems="center" display="flex">
                <Typography>{team.name}</Typography>
                <StyledStanleySVGIcon height="1.5rem" imageSrc={ICONS[team.abrvName]} width="1.5rem" />
              </Box>
            );
          }}
          renderValue={(selected) => {
            if ((selected as number[]).length === 0) {
              return 'Teams';
            }

            return teams
              ?.reduce((teamAbrvs, team) => {
                if ((selected as number[]).includes(team.teamId)) teamAbrvs.push(team.abrvName);

                return teamAbrvs;
              }, [] as string[])
              .join(', ');
          }}
          value={filters.teamId || []}
          onChange={handleBaseFiltersChange('teamId')}
        />
      </Item>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyDisabled
          emptyText="Position"
          fullWidth
          multiple
          options={Object.keys(PLAYER_POSITION_MAP)
            .filter((positionKey) => positionKey !== '7')
            .map((positionKey) => ({
              label: PLAYER_POSITION_MAP[positionKey],
              value: positionKey,
            }))}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return 'Position';
            }

            return (selected as string[]).map((position) => PLAYER_POSITION_MAP[Number(position)]).join(', ');
          }}
          value={filters.primaryPosition}
          onChange={handleBaseFiltersChange('primaryPosition')}
        />
      </Item>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyText="Signing Status"
          fullWidth
          options={Object.keys(CONTRACT_EXPIRY_STATUS_MAP).map((statusKey) => ({
            label: CONTRACT_EXPIRY_STATUS_MAP[statusKey],
            value: statusKey,
          }))}
          value={filters.contractSearchCriteria?.signingStatus}
          onChange={handleContractFiltersChange('signingStatus')}
        />
      </Item>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyDisabled
          emptyText="Expiry Status"
          fullWidth
          multiple
          options={Object.keys(CONTRACT_EXPIRY_STATUS_MAP).map((statusKey) => ({
            label: CONTRACT_EXPIRY_STATUS_MAP[statusKey],
            value: statusKey,
          }))}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return 'Expiry Status';
            }

            return (selected as string[]).map((position) => CONTRACT_EXPIRY_STATUS_MAP[Number(position)]).join(', ');
          }}
          value={filters.contractSearchCriteria?.expiryStatus}
          onChange={handleContractFiltersChange('expiryStatus')}
        />
      </Item>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyText="Arbitration Eligible"
          fullWidth
          options={[
            {
              label: 'Yes',
              value: 'true',
            },
            {
              label: 'No',
              value: 'false',
            },
          ]}
          value={filters.contractSearchCriteria?.arbRights}
          onChange={handleContractFiltersChange('arbRights')}
        />
      </Item>
      <Item sx={{ maxWidth: '100% !important' }}>
        <StanleySelect
          displayEmpty
          emptyDisabled
          emptyText="Contract Type"
          fullWidth
          multiple
          options={Object.keys(CONTRACT_TYPE_MAP).map((contractTypeKey) => ({
            label: CONTRACT_TYPE_MAP[contractTypeKey],
            value: contractTypeKey,
          }))}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return 'Contract Type';
            }

            return (selected as string[]).map((position) => CONTRACT_TYPE_MAP[Number(position)]).join(', ');
          }}
          value={filters.contractSearchCriteria?.contractType}
          onChange={handleContractFiltersChange('contractType')}
        />
      </Item>
    </Container>
  );
}

export default PlayerSearchContractDemographics;
