import { StanleySelectOption } from '@project-stanley/cap-management-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectSeasonStartYear } from 'modules/season/season.selectors';

const useStatsSeasonsOptions = () => {
  const curSeasonStartYear = useSelector(selectSeasonStartYear);

  const [statsSeasonsOptions, setStatsSeasonsOptions] = useState<StanleySelectOption[]>([]);

  useEffect(() => {
    const options: StanleySelectOption[] = [];

    for (let i = 0; i < 4; i++) {
      options.push({
        label: `${curSeasonStartYear - i} - ${curSeasonStartYear - i + 1}`,
        value: `${curSeasonStartYear - i}-${curSeasonStartYear - i + 1}`,
      });
    }

    setStatsSeasonsOptions(options);
  }, [curSeasonStartYear]);

  return statsSeasonsOptions;
};

export default useStatsSeasonsOptions;
