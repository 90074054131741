/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/function-component-definition */
/* eslint-disable func-names */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import MaterialTable from '@material-table/core';
import { Check } from '@mui/icons-material';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import {
  StanleyDollar,
  StanleySVGIcon,
  StanleyTable,
  StanleyTransparentButton,
} from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PlayerSearchContractBreakdown from 'modules/advancedSearch/playerSearchContractBreakdown.component';
import usePrevious from 'hooks/usePrevious';
import {
  CONTRACT_DATA_FIELD_VALUES,
  CONTRACT_YEAR_DATA_FIELD_VALUES,
  CONTRACT_YEAR_YEAR_OPTIONS,
  EXPECTED_GOALS_DATA_FIELD_VALUES,
  PLAYER_DATA_FIELD_VALUES,
  PLAYER_EV_STATS_DATA_FIELD_VALUES,
  PLAYER_PP_STATS_DATA_FIELD_VALUES,
  PLAYER_SH_STATS_DATA_FIELD_VALUES,
  PLAYER_STATS_DATA_FIELD_VALUES,
  PLAYER_TOTAL_STATS_DATA_FIELD_VALUES,
} from 'constants/playerDataFields';
import { CONTRACT_EXPIRY_STATUS_MAP, CONTRACT_TYPE_MAP } from 'utilities/contract';
import { ICONS } from 'utilities/icons';
import { PLAYER_POSITION_ABBR_MAP } from 'utilities/player';
import { PlayerSearchResult, PlayerSearchTable as PlayerSearchTableType } from 'types/playerSearch';
import { ROUTES } from 'utilities/routes';
import { TableRowClick, TextAlign } from 'types/table';
import { mapPlayerSearchResult } from 'utilities/playerSearch';
import { selectSearchIsLoadingAdvancedSearch } from 'modules/advancedSearch/search.selectors';
import { sortByDate } from 'utilities/table';

interface PlayerSearchTableProps {
  tableRef: MutableRefObject<MaterialTable<any> | undefined>;
  dataFields: (number | string)[];
  players: PlayerSearchResult[];
  rowsPerPage?: number;
  shouldAdjustPageSize?: boolean;
  setSelectedRows: Dispatch<SetStateAction<(PlayerSearchResult & { id: number })[]>>;
}

function PlayerSearchTable({
  tableRef,
  dataFields,
  players,
  rowsPerPage,
  shouldAdjustPageSize = true,
  setSelectedRows,
}: PlayerSearchTableProps): JSX.Element {
  const navigate = useNavigate();

  const isLoading = useSelector(selectSearchIsLoadingAdvancedSearch);

  const isPrevLoading = usePrevious(isLoading) as boolean;

  const [mappedPlayerData, setMappedPlayerData] = useState<PlayerSearchTableType[]>([]);

  useEffect(() => {
    setMappedPlayerData(mapPlayerSearchResult(players));
  }, [players]);

  useEffect(() => {
    if (tableRef.current) {
      if (isLoading || isEmpty(players)) {
        (tableRef.current as any).onRowsPerPageChange({ target: { value: rowsPerPage || 10 } });
      }

      if (!isEmpty(players) && shouldAdjustPageSize) {
        (tableRef.current as any).onRowsPerPageChange({ target: { value: players.length < 50 ? players.length : 50 } });
      }

      if (isPrevLoading && !isLoading && shouldAdjustPageSize) {
        (tableRef.current as any).onRowsPerPageChange({ target: { value: 50 } });
      }
    }
  }, [tableRef, isLoading, isPrevLoading, players, rowsPerPage, shouldAdjustPageSize]);

  const renderPlayerName = useCallback(
    ({ playerInfo: { firstName, lastName, playerId } }: PlayerSearchResult) => (
      <StanleyTransparentButton
        color="secondary"
        sx={{
          fontWeight: 400,
          padding: 0,
          textTransform: 'none',
          '&:hover': {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        }}
        onClick={() => navigate(`${ROUTES.PLAYERS}/${playerId}`)}
      >
        <>
          {lastName}
          ,&nbsp;
          {firstName}
        </>
      </StanleyTransparentButton>
    ),
    [navigate],
  );

  const sortDateColumn = useCallback(
    (contractKey: string) => (rowA: PlayerSearchResult, rowB: PlayerSearchResult) =>
      sortByDate(new Date(rowA[contractKey] as string), new Date(rowB[contractKey] as string)),
    [],
  );

  const sortTableColumn = useCallback(
    (contractKey: string) => (rowA: PlayerSearchResult, rowB: PlayerSearchResult) =>
      (rowA[contractKey] as number) - (rowB[contractKey] as number),
    [],
  );

  const sortTablePlayerInfoColumn = useCallback(
    (playerKey: string) => (rowA: PlayerSearchResult, rowB: PlayerSearchResult) =>
      (rowA.playerInfo[playerKey] as number) - (rowB.playerInfo[playerKey] as number),
    [],
  );

  const columns = useMemo(() => {
    const newColumns: Record<string, any>[] = [
      {
        title: 'Player',
        cellStyle: { minWidth: 165, width: 165 },
        hasSortCellStyle: false,
        headerStyle: { minWidth: 165, width: 165 },
        width: 185,
        customSort: (rowA: PlayerSearchResult, rowB: PlayerSearchResult) =>
          `${rowA.playerInfo.lastName}, ${rowA.playerInfo.firstName}`.localeCompare(
            `${rowB.playerInfo.lastName}, ${rowB.playerInfo.firstName}`,
          ),
        render: renderPlayerName,
      },
    ];

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_POSITION)) {
      newColumns.push({
        title: 'Position',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        customSort: sortTablePlayerInfoColumn('primaryPosition'),
        render: ({ playerInfo: { primaryPosition } }: PlayerSearchResult) => PLAYER_POSITION_ABBR_MAP[primaryPosition],
      });
    }

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_TEAM)) {
      newColumns.push({
        title: 'Current Team',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        sorting: false,
        render: ({ currentTeam }: PlayerSearchResult) => {
          if (!currentTeam) return '--';

          return (
            <IconButton
              onClick={(event: TableRowClick) => {
                event?.stopPropagation();

                navigate(`${ROUTES.TEAMS}/${currentTeam.teamId}`);
              }}
              size="small"
            >
              <StanleySVGIcon height="1.75rem" imageSrc={ICONS[currentTeam.abrvName]} width="1.75rem" />
            </IconButton>
          );
        },
      });
    }

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_AGE)) {
      newColumns.push({
        title: 'Current Age',
        field: 'age',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_AGE)) {
      newColumns.push({
        title: 'Signing Age',
        field: 'signingAge',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_TEAM)) {
      newColumns.push({
        title: 'Signing Team',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        sorting: false,
        render: ({ signingTeam }: PlayerSearchResult) => {
          if (!signingTeam) return '--';

          return (
            <IconButton
              onClick={(event: TableRowClick) => {
                event?.stopPropagation();

                navigate(`${ROUTES.TEAMS}/${signingTeam.teamId}`);
              }}
              size="small"
            >
              <StanleySVGIcon height="1.75rem" imageSrc={ICONS[signingTeam.abrvName]} width="1.75rem" />
            </IconButton>
          );
        },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_DATE)) {
      newColumns.push({
        title: 'Signing Date',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        customSort: sortDateColumn('signingDate'),
        render: ({ signingDate }: PlayerSearchResult) => {
          if (!signingDate) return '--';

          return format(new Date(signingDate), 'MM/dd/yyyy');
        },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_STATUS)) {
      newColumns.push({
        title: 'Signed As',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTablePlayerInfoColumn('signingStatus'),
        render: ({ playerInfo }: PlayerSearchResult) =>
          playerInfo && playerInfo.signingStatus !== null
            ? CONTRACT_EXPIRY_STATUS_MAP[playerInfo.signingStatus as number]
            : 'ELC',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_EXPIRATION_STATUS)) {
      newColumns.push({
        title: 'Expiry',
        cellStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
        headerStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
        customSort: sortTableColumn('expiryStatus'),
        render: ({ expiryStatus }: PlayerSearchResult) =>
          expiryStatus ? CONTRACT_EXPIRY_STATUS_MAP[expiryStatus] : '--',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_PLATFORM_YEAR)) {
      newColumns.push({
        title: 'Platform Year',
        cellStyle: { minWidth: 120, width: 120 },
        headerStyle: { minWidth: 120, width: 120 },
        customSort: sortTableColumn('platformYear'),
        render: ({ platformYear }: PlayerSearchResult) => {
          if (!platformYear) return '--';

          return `${platformYear} - ${platformYear + 1}`;
        },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SEASON_START)) {
      newColumns.push({
        title: 'Season Start',
        cellStyle: { minWidth: 110, width: 110 },
        headerStyle: { minWidth: 110, width: 110 },
        customSort: sortTableColumn('startingSeason'),
        render: ({ startingSeason }: PlayerSearchResult) => {
          if (!startingSeason) return '--';

          return `${startingSeason} - ${startingSeason + 1}`;
        },
      });
    }

    if (dataFields.includes(CONTRACT_YEAR_DATA_FIELD_VALUES.CONTRACT_YEAR)) {
      newColumns.push({
        title: 'Contract Year (CY)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: () => '--',
      });
    }

    if (dataFields.includes(CONTRACT_YEAR_DATA_FIELD_VALUES.CONTRACT_YEAR_STATUS)) {
      newColumns.push({
        title: 'CY Status',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: () => '--',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_ARB_ELIGIBILITY)) {
      newColumns.push({
        title: 'Arb. Rights',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        customSort: sortTablePlayerInfoColumn('contractArbRights'),
        render: ({ playerInfo }: PlayerSearchResult) =>
          playerInfo.contractArbRights ? <Check color="primary" fontSize="small" /> : 'NO',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_QO_VALUE)) {
      newColumns.push({
        title: 'QO',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTablePlayerInfoColumn('qoValue'),
        render: ({ playerInfo }: PlayerSearchResult) =>
          playerInfo && playerInfo.qoValue ? <StanleyDollar amount={playerInfo.qoValue} /> : 'NR',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_LENGTH)) {
      newColumns.push({
        title: 'Length',
        field: 'contractLength',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.UFA_YEARS)) {
      newColumns.push({
        title: 'UFA Years',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: 'ufaYears',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.RFA_YEARS)) {
      newColumns.push({
        title: 'RFA Years',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: 'rfaYears',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_TYPE)) {
      newColumns.push({
        title: 'Type',
        cellStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        headerStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        customSort: sortTableColumn('contractType'),
        render: ({ contractType }: PlayerSearchResult) => (contractType ? CONTRACT_TYPE_MAP[contractType] : '--'),
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_AAV)) {
      newColumns.push({
        title: 'AAV',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTableColumn('averageAnnualValue'),
        render: ({ averageAnnualValue }: PlayerSearchResult) => <StanleyDollar amount={averageAnnualValue} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT)) {
      newColumns.push({
        title: 'Cap Hit',
        cellStyle: { minWidth: 80, width: 80, textAlign: TextAlign.center },
        headerStyle: { minWidth: 80, width: 80, textAlign: TextAlign.center },
        customSort: sortTableColumn('capHit'),
        render: ({ capHit }: PlayerSearchResult) => <StanleyDollar amount={capHit} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT_PER)) {
      newColumns.push({
        title: 'Cap Hit(%)',
        cellStyle: { minWidth: 95, width: 95, textAlign: TextAlign.center },
        headerStyle: { minWidth: 95, width: 95, textAlign: TextAlign.center },
        customSort: sortTableColumn('capHitPercent'),
        render: ({ capHitPercent }: PlayerSearchResult) =>
          capHitPercent ? `${(capHitPercent * 100).toFixed(2)}%` : '--',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_SALARY)) {
      newColumns.push({
        title: 'Total Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.TOTAL_SALARY,
        render: ({ totalValue }: PlayerSearchResult) => <StanleyDollar amount={totalValue} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.BASE_SALARY)) {
      newColumns.push({
        title: 'Total Base Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.BASE_SALARY,
        render: ({ baseSalary }: PlayerSearchResult) => <StanleyDollar amount={baseSalary} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.SIGNING_BONUS)) {
      newColumns.push({
        title: 'Total S. Bonus',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.SIGNING_BONUS,
        render: ({ signingBonus }: PlayerSearchResult) => <StanleyDollar amount={signingBonus} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.PERFORMANCE_BONUS)) {
      newColumns.push({
        title: 'Total P. Bonus',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.PERFORMANCE_BONUS,
        render: ({ performanceBonus }: PlayerSearchResult) => <StanleyDollar amount={performanceBonus} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_BASE_SALARY_PCT)) {
      newColumns.push({
        title: 'Total Base Salary (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.TOTAL_BASE_SALARY_PCT,
        render: ({ totalBaseSalaryPct }: PlayerSearchResult) => `${totalBaseSalaryPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_BONUS_PCT)) {
      newColumns.push({
        title: 'Total Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.TOTAL_BONUS_PCT,
        render: ({ totalBonusPct }: PlayerSearchResult) => `${totalBonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_SIGNING_BONUS_PCT)) {
      newColumns.push({
        title: 'Total S. Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.TOTAL_SIGNING_BONUS_PCT,
        render: ({ totalSigningBonusPct }: PlayerSearchResult) => `${totalSigningBonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_PERFORMANCE_BONUS_PCT)) {
      newColumns.push({
        title: 'Total P. Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.TOTAL_PERFORMANCE_BONUS_PCT,
        render: ({ totalPerformanceBonusPct }: PlayerSearchResult) => `${totalPerformanceBonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.MINORS_SALARY)) {
      newColumns.push({
        title: 'Total Minors Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: CONTRACT_DATA_FIELD_VALUES.MINORS_SALARY,
        render: ({ minorsSalary }: PlayerSearchResult) => <StanleyDollar amount={minorsSalary} />,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED)) {
      newColumns.push({
        title: 'Total - GP',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS)) {
      newColumns.push({
        title: 'Total - G',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS)) {
      newColumns.push({
        title: 'Total - A',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR)) {
      newColumns.push({
        title: 'Total - PTS',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME)) {
      newColumns.push({
        title: 'Total - G/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - A/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - P/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL)) {
      newColumns.push({
        title: 'Total - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS)) {
      newColumns.push({
        title: 'Total - TOI Shifts',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS)) {
      newColumns.push({
        title: 'Total - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT)) {
      newColumns.push({
        title: 'Total - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON)) {
      newColumns.push({
        title: 'Total - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST)) {
      newColumns.push({
        title: 'Total - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT)) {
      newColumns.push({
        title: 'Total - Shooting(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS)) {
      newColumns.push({
        title: 'Total - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES)) {
      newColumns.push({
        title: 'Total - PIMS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS)) {
      newColumns.push({
        title: 'Total - Hits',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY)) {
      newColumns.push({
        title: 'PP - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY)) {
      newColumns.push({
        title: 'PP - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_FOR)) {
      newColumns.push({
        title: 'Expected Goals For',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_FOR,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECT_GOALS_FOR)) {
      newColumns.push({
        title: 'Actual Expected Goals For',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECT_GOALS_FOR,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_AGAINST)) {
      newColumns.push({
        title: 'Expected Goals Against',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_AGAINST,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST)) {
      newColumns.push({
        title: 'Actual Expected Goals Against',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST,
      });
    }

    return newColumns;
  }, [navigate, dataFields, renderPlayerName, sortTableColumn, sortTablePlayerInfoColumn, sortDateColumn]);

  return (
    <StanleyTable
      ref={tableRef}
      columns={columns}
      data={mappedPlayerData || []}
      isLoading={isLoading}
      detailPanel={({ rowData }) => (
        <PlayerSearchContractBreakdown dataFields={dataFields} player={rowData as PlayerSearchResult} />
      )}
      options={{
        paging: (players || []).length > 5,
        pageSize: rowsPerPage || 10,
        pageSizeOptions: [10, 25, 50],
        showDetailPanelIcon: CONTRACT_YEAR_YEAR_OPTIONS.some((option) => dataFields.indexOf(option.value) !== -1),
        sorting: true,
        selection: true,
      }}
      showSelectAll
      onSelectionChange={(data) => setSelectedRows(data)}
    />
  );
}

export default PlayerSearchTable;
