/* eslint-disable no-confusing-arrow */
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { Filters } from 'types/playerSearch';
import { FirebaseUser, SavedCompSet, SavedDepthChart, User, UserPreferences } from 'types/user';
import { RootState } from 'store';

export const selectUserIsLoading = (state: RootState): boolean => state.user.isLoading;

export const selectUser = (state: RootState): User | null => state.user.user;

export const selectFirebaseUser = (state: RootState): FirebaseUser | null => state.user.firebaseUser;

export const selectPlayerSearchCriteria = (state: RootState): Filters | null =>
  state.user.preferences
    ? (state.user.preferences[FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_CRITERIA] as Filters)
    : null;

export const selectPlayerSearchDataFields = (state: RootState): number[] | null =>
  state.user.preferences
    ? (state.user.preferences[FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_DATA_FIELDS] as number[])
    : null;

export const selectContractComparablesDataFields = (state: RootState): (number | string)[] | null =>
  state.user.preferences
    ? (state.user.preferences[FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS_DATA_FIELDS] as (
        | number
        | string
      )[])
    : null;

export const selectDepthCharts = (state: RootState): SavedDepthChart | null =>
  state.user.preferences
    ? (state.user.preferences[FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART] as SavedDepthChart)
    : null;

export const selectPreferences = (state: RootState): UserPreferences => state.user.preferences || {};

export const selectPlayerCompSets = (state: RootState): SavedCompSet | null =>
  state.user.preferences
    ? (state.user.preferences[FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS] as SavedCompSet)
    : null;
