import makeStyles from '@mui/styles/makeStyles';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';

import { PlayerContractDetails } from 'types/contract';

interface PlayerContractTableSummaryProps {
  contract: PlayerContractDetails[];
}

function PlayerContractTableSummary({ contract }: PlayerContractTableSummaryProps): JSX.Element {
  const classes = useStyles();

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">Total</Typography>
        </TableCell>
        <TableCell />
        <TableCell className={classes.tableCell}>
          <StanleyDollar amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.capHit, 0)} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.averageAnnualValue, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.performanceBonus, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.signingBonus, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.baseSalary, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.totalSalary, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractDetails) => acc + cur.minorsSalary, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell} />
      </TableRow>
    </TableFooter>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    padding: theme.spacing(1.25),
  },
}));

export default PlayerContractTableSummary;
