import makeStyles from '@mui/styles/makeStyles';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';

import { PlayerContractSimulationDetails } from 'types/contract';

interface PlayerContractSimulatorTableSummaryProps {
  contract: PlayerContractSimulationDetails[];
}

function PlayerContractSimulatorTableSummary({ contract }: PlayerContractSimulatorTableSummaryProps): JSX.Element {
  const classes = useStyles();

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">Total</Typography>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractSimulationDetails) => acc + cur.capHit, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce(
              (acc: number, cur: PlayerContractSimulationDetails) => acc + cur.averageAnnualValue,
              0,
            )}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce(
              (acc: number, cur: PlayerContractSimulationDetails) => acc + cur.performanceBonus,
              0,
            )}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractSimulationDetails) => acc + cur.signingBonus, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractSimulationDetails) => acc + cur.baseSalary, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractSimulationDetails) => acc + cur.totalSalary, 0)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <StanleyDollar
            amount={contract.reduce((acc: number, cur: PlayerContractSimulationDetails) => acc + cur.minorsSalary, 0)}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    padding: theme.spacing(1.25),
  },
}));

export default PlayerContractSimulatorTableSummary;
