/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import xlsx from 'json-as-xlsx';
import { Box, Paper, styled, useTheme } from '@mui/material';
import { Container, Item, StanleySVGIcon } from '@project-stanley/cap-management-components';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import BlockNavigation from 'modules/layout/blockNavigation.component';
import Loading from 'modules/auth/loading.container';
import RosterOutlookView from 'modules/teams/rosterView/rosterOutlookView.component';
import RosterTradeHistory from 'modules/teams/rosterView/rosterTradeHistory.component';
import RosterViewHeader from 'modules/teams/rosterView/rosterViewHeader.component';
import TeamDepthChart from 'modules/teams/teamDepthChart.component';
// import TeamDraftPicks from 'modules/teams/teamDraftPicks/teamDraftPicks.component';
import TeamSummaryPanel from 'modules/teams/teamSummary/teamSummaryPanel.component';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import usePrevious from 'hooks/usePrevious';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import { GA_ACTIONS, GA_CATEGORIES, getReportingTeamAnalyticsAction, logGAEvent } from 'utilities/analytics';
import { ICONS } from 'utilities/icons';
import {
  ROSTER_CONTRACT_FILTER_KEYS,
  ROSTER_CONTRACT_FILTER_MAP,
  ROSTER_CONTRACT_FILTER_OPTIONS,
  mapFutureRoster,
} from 'utilities/roster';
import { ROUTES, TEAM_SUB_ROUTES } from 'utilities/routes';
import { generateProjectionDataContent, generateYearColumns, mapPlayersForCSV } from 'utilities/csv';
import {
  getProjectedTeamSummary,
  getTeam,
  getTeamDeadCapOutlook,
  getTeamDraftPicks,
  getTeamRosterOutlook,
  getTeamSummary,
  getTrades,
  resetTeam,
  setContractYear,
  setRosterStatFilter,
} from 'modules/teams/teams.slice';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import {
  selectTeam,
  selectTeamActiveRosterOutlook,
  selectTeamContractYear,
  selectTeamInactiveRosterOutlook,
  selectTeamProjectedSummary,
  selectTeamRosterStatFilter,
  selectTeamSummary,
  selectTeamsIsDeadCapOutlookLoading,
  selectTeamsIsLoading,
  selectTeamsIsOutlookLoading,
  selectTeamsIsSummaryLoading,
} from 'modules/teams/teams.selectors';
import { useAppDispatch } from 'store';

export interface TeamParamTypes {
  teamId: string;
  [key: string]: string;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isPrinting' && prop !== 'isSmallScreen',
})<{ isPrinting: boolean; isSmallScreen: boolean }>(({ isPrinting, isSmallScreen, theme }) => {
  const styles = {
    display: 'flex',
    padding: theme.spacing(2),
  };

  if (isPrinting || isSmallScreen) return styles;

  return {
    ...styles,
    height: 'calc(100% - 2.25rem)',
    overflowY: 'hidden',
  };
});

const StyledStanleySVGIcon = styled(StanleySVGIcon)({
  left: '50%',
  opacity: '0.1',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
  paddingBottom: 0,
}));

const StyledItem = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{ isSmallScreen: boolean }>(({ isSmallScreen }) => {
  if (isSmallScreen) return {};

  return { height: '100%', overflowY: 'auto', paddingBottom: 0 };
});

function Team(): JSX.Element {
  const rosterRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const copyToClipboard = useCopyToClipboard()[1];
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const width = useWidth();

  const { teamId } = useParams<TeamParamTypes>();

  const contractYear = useSelector(selectTeamContractYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const isPrinting = useSelector(selectIsPrinting);
  const team = useSelector(selectTeam);
  const teamSummary = useSelector(selectTeamSummary);
  const isLoading = useSelector(selectTeamsIsLoading);
  const isOutlookLoading = useSelector(selectTeamsIsOutlookLoading);
  const isSummaryLoading = useSelector(selectTeamsIsSummaryLoading);
  const isDeadCapOutlookLoading = useSelector(selectTeamsIsDeadCapOutlookLoading);
  const seasonStartYear = useSelector(selectSeasonStartYear);
  const projectedTeamSummary = useSelector(selectTeamProjectedSummary);
  const activeRoster = useSelector(selectTeamActiveRosterOutlook);
  const inactiveRoster = useSelector(selectTeamInactiveRosterOutlook);

  const prevContractYear = usePrevious(contractYear) as number | null;

  const [showTerms, setShowTerms] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [hasInitialTabIndex, setHasInitialTabIndex] = useState(false);

  useEffect(() => {
    if (seasonStartYear) dispatch(setContractYear(seasonStartYear));
  }, [dispatch, seasonStartYear]);

  useEffect(() => {
    if (!(teamId && contractYear && prevContractYear === null)) return;

    dispatch(getTeam(teamId));
    dispatch(getTeamDeadCapOutlook(teamId));
    dispatch(getTeamDraftPicks(teamId));
    dispatch(getTeamSummary(teamId));
    dispatch(getTeamRosterOutlook(teamId));
    dispatch(getProjectedTeamSummary(teamId));
    dispatch(getTrades({ teamIds: [Number(teamId)] }));
  }, [dispatch, contractYear, prevContractYear, teamId, seasonStartYear]);

  useEffect(
    () => () => {
      dispatch(resetTeam());
      dispatch(setContractYear(null));
      dispatch(setRosterStatFilter(ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL));
    },
    [dispatch],
  );

  useEffect(() => {
    if (hasInitialTabIndex) return;
    setHasInitialTabIndex(true);

    const subRouteIndex = Object.values(TEAM_SUB_ROUTES).findIndex((subRoute) => location.pathname.includes(subRoute));

    if (subRouteIndex < 0) {
      setSelectedTabIndex(0);
    } else {
      setSelectedTabIndex(subRouteIndex + 1);
    }
  }, [location, hasInitialTabIndex]);

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS || width === BREAK_POINTS.SM, [width]);

  const showCapitalOutlook = useMemo(
    () => location.pathname.includes(TEAM_SUB_ROUTES.TEAM_CAPITAL_OUTLOOK),
    [location],
  );
  const showDraftPicks = useMemo(
    () =>
      // location.pathname.includes(TEAM_SUB_ROUTES.TEAM_DRAFT_PICKS);
      false,
    [],
  );
  const showTradeHistory = useMemo(() => location.pathname.includes(TEAM_SUB_ROUTES.TEAM_TRADE_HISTORY), [location]);
  const showDepthChart = useMemo(
    () => !showCapitalOutlook && !showDraftPicks && !showTradeHistory,
    [showCapitalOutlook, showDraftPicks, showTradeHistory],
  );

  const handleChangeContractYear = useCallback(
    (newContractYear: number) => {
      dispatch(setContractYear(newContractYear));

      if (team) {
        logGAEvent({
          category: GA_CATEGORIES.TEAM_PROFILE,
          action: GA_ACTIONS.TEAM_YEAR_FILTER_CHANGE,
          label: team.name,
          value: newContractYear,
        });
      }
    },
    [dispatch, team],
  );

  const handleChangeRosterStatFilter = useCallback(
    (newRosterStatFilter: string) => {
      dispatch(setRosterStatFilter(newRosterStatFilter));

      if (team) {
        logGAEvent({
          category: GA_CATEGORIES.TEAM_PROFILE,
          action: GA_ACTIONS.TEAM_STAT_FILTER_CHANGE,
          label: team.name,
          value: ROSTER_CONTRACT_FILTER_MAP[newRosterStatFilter],
        });
      }
    },
    [dispatch, team],
  );

  const handleToggleTerms = useCallback(() => {
    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAM_PROFILE,
        action: showTerms ? GA_ACTIONS.TEAM_PROFILE_VIEW_TERMS : GA_ACTIONS.TEAM_PROFILE_VIEW_OUTLOOK,
        label: team.abrvName,
        value: team.teamId,
      });
    }

    setShowTerms(!showTerms);
  }, [showTerms, team, setShowTerms]);

  const handleAfterPrint = useCallback(() => {
    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAM_PROFILE,
        action: getReportingTeamAnalyticsAction({
          showDepthChart,
          showTerms,
        }),
        label: team.abrvName,
        value: team.teamId,
      });
    }
  }, [showDepthChart, showTerms, team]);

  const handleViewChange = useCallback(
    (tabIndex: number) => {
      setSelectedTabIndex(tabIndex);

      if (!teamId) return;

      const teamsRouteBase = `${ROUTES.TEAMS}/${teamId}`;

      if (tabIndex === 0) {
        navigate(teamsRouteBase);
        return;
      }

      navigate(`${teamsRouteBase}${Object.values(TEAM_SUB_ROUTES)[tabIndex - 1]}`);
    },
    [navigate, teamId],
  );

  const handleDownloadCSV = useCallback(() => {
    if (!team || !projectedTeamSummary || !activeRoster || !inactiveRoster) return;

    logGAEvent({
      category: GA_CATEGORIES.TEAM_PROFILE,
      action: GA_ACTIONS.DOWNLOAD_TEAM_OUTLOOK,
      label: team.abrvName,
      value: team.teamId,
    });

    const csvData = [
      {
        sheet: 'Team Projection Summary',
        columns: [{ label: '', value: 'label' }, ...generateYearColumns(seasonStartYear)],
        content: generateProjectionDataContent(projectedTeamSummary, seasonStartYear),
      },
      ...ROSTER_CONTRACT_FILTER_OPTIONS.map((option) => ({
        sheet: option.label,
        columns: [
          { label: 'Name', value: 'name' },
          {
            label: 'Position',
            value: 'primaryPosition',
          },
          { label: 'Age', value: 'age' },
          { label: 'Status', value: 'status' },
          { label: 'Expiry', value: 'expiryStatus' },
          ...generateYearColumns(seasonStartYear),
        ],
        content: mapPlayersForCSV(
          mapFutureRoster([...activeRoster, ...inactiveRoster], seasonStartYear),
          seasonStartYear,
          option.value,
        ),
      })),
    ];

    const settings = {
      fileName: `${team.name}-${format(new Date(), 'MM/dd/yyyy')}.csv`,
    };

    xlsx(csvData, settings); // Will download the excel file
  }, [team, seasonStartYear, projectedTeamSummary, activeRoster, inactiveRoster]);

  if (
    isLoading ||
    isDeadCapOutlookLoading ||
    isOutlookLoading ||
    isSummaryLoading ||
    isNil(team) ||
    isNil(teamSummary)
  ) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer direction={isSmallScreen ? 'column' : 'row'} spacing={1} wrap={isSmallScreen ? 'nowrap' : 'wrap'}>
      <StyledItem isSmallScreen={isSmallScreen} sm={isSmallScreen ? false : 12} md={isSmallScreen ? false : 3} lg={2}>
        <TeamSummaryPanel teamSummary={teamSummary} teamAbrvName={team.abrvName} />
      </StyledItem>
      <StyledItem isSmallScreen={isSmallScreen} sm={isSmallScreen ? false : 12} md={isSmallScreen ? false : 9} lg={10}>
        <StyledPaper
          ref={rosterRef}
          elevation={3}
          isPrinting={isPrinting}
          isSmallScreen={isSmallScreen}
          sx={{ flexDirection: 'column', position: 'relative' }}
        >
          <RosterViewHeader
            activeFilter={rosterStatFilter}
            contractYear={contractYear}
            rosterRef={rosterRef}
            selectedTabIndex={selectedTabIndex}
            showDepthChart={showDepthChart}
            showCapitalOutlook={showCapitalOutlook}
            showDraftPicks={showDraftPicks}
            showTerms={showTerms}
            tabs={[
              { label: 'Depth Chart' },
              { label: 'Capital Outlook' },
              // { label: 'Draft Picks' },
              { label: 'Trade History' },
            ]}
            onAfterPrint={handleAfterPrint}
            onChangeContractYear={handleChangeContractYear}
            onChangeRosterStatFilter={handleChangeRosterStatFilter}
            onCopyToClipboard={() => copyToClipboard(window.location.href)}
            onDownloadCSV={selectedTabIndex === 1 ? handleDownloadCSV : undefined}
            onToggleTerms={handleToggleTerms}
            onViewChange={handleViewChange}
          />
          <Box
            flex="1"
            marginTop={theme.spacing(0.125)}
            overflow={!isPrinting ? 'auto' : 'hidden'}
            position="relative"
            zIndex="1000"
          >
            <Routes>
              <Route
                element={
                  <BlockNavigation>
                    <TeamDepthChart />
                  </BlockNavigation>
                }
                path="/"
              />
              <Route
                element={<RosterOutlookView showTerms={showTerms} />}
                path={TEAM_SUB_ROUTES.TEAM_CAPITAL_OUTLOOK}
              />
              {/* <Route element={<TeamDraftPicks />} path={TEAM_SUB_ROUTES.TEAM_DRAFT_PICKS} /> */}
              <Route element={<RosterTradeHistory />} path={TEAM_SUB_ROUTES.TEAM_TRADE_HISTORY} />
              <Route path="*" element={<Navigate to={`${ROUTES.TEAMS}/${teamId || 1}`} />} />
            </Routes>
          </Box>
          <StyledStanleySVGIcon height="16rem" imageSrc={ICONS[team.abrvName]} width="16rem" />
        </StyledPaper>
      </StyledItem>
    </StyledContainer>
  );
}

export default Team;
