import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box } from '@mui/material';
import { StanleyTable } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StanleyTableTeamIcon from 'components/table/stanleyTableTeamIcon.component';
import { ROUTES } from 'utilities/routes';
import { RecentTransaction, TransactionType } from 'types/transactions';
import { TableRowClick } from 'types/table';
import { getFullName } from 'utilities/player';
import { selectRecentTransactions } from 'modules/teams/recentTransactions/recentTransactions.selectors';

function RecentTransactions() {
  const navigate = useNavigate();

  const transactions = useSelector(selectRecentTransactions);

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        render: (transaction: RecentTransaction) => (
          <Box display="flex" alignItems="center">
            {TransactionType[transaction.type]}
            {transaction.type === TransactionType.Reassign ? (
              <ArrowDownward sx={{ marginLeft: 2 }} color="error" />
            ) : (
              <ArrowUpward sx={{ marginLeft: 2 }} color="success" />
            )}
          </Box>
        ),
      },
      {
        title: 'Player',
        render: ({ playerInfo }: RecentTransaction) => getFullName(playerInfo),
      },
      {
        title: 'Team',
        render: ({ teamInfo }: RecentTransaction) => <StanleyTableTeamIcon teamInfo={teamInfo} />,
      },
      {
        title: 'Date',
        render: ({ date }: RecentTransaction) => format(new Date(date), 'MMM dd, yyyy'),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: RecentTransaction) => {
      if (rowData && rowData.playerInfo) navigate(`${ROUTES.PLAYERS}/${rowData.playerInfo.playerId}`);
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      data={transactions ? [...transactions.slice(0, 12)] : []}
      options={{
        sorting: false,
      }}
      onRowClick={handleRowClick}
    />
  );
}

export default RecentTransactions;
