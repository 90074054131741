/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable function-paren-newline */
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JsonArray, download } from 'json-to-csv-in-browser';
import { MutableRefObject, ReactInstance, useCallback, useMemo } from 'react';
import { PageActionItem, StanleyHoverSelect, StanleyPageActions } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import StanleyNavTabs from 'components/stanleyNavTabs.component';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useShareMenuItem from 'hooks/useShareMenuItem';
import useSubRoute from 'hooks/useSubRoute';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { PLAYER_CONTRACT_COMPARABLES, PLAYER_SUB_ROUTES, ROUTES } from 'utilities/routes';
import {
  PLAYER_VIEW_OPTIONS,
  PLAYER_VIEW_OPTION_VALUES,
  getPlayerFullname,
  getPlayerReverseFullname,
} from 'utilities/player';
import { PagePrintAction } from 'utilities/pageActions';
import { Player, PlayerPosition } from 'types/player';
import { ToastTypes } from 'types/layout';
import { generateContractComparablesCSVHeader, mapComparableContractForCSV } from 'utilities/csv';
import {
  selectPlayerComparableContracts,
  selectPlayerComparableContractsSubject,
} from 'modules/players/players.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

const CONTRACT_ACTIVE_VIEW_HEADER_MAP = {
  [PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS]: 'Career Contracts',
  [PLAYER_VIEW_OPTION_VALUES.REGULAR_SEASON_PLAYER_STATS]: 'Career Statistics',
  [PLAYER_VIEW_OPTION_VALUES.PLAYOFFS_PLAYER_STATS]: 'Career Statistics',
  [PLAYER_VIEW_OPTION_VALUES.CONTRACT_COMPARABLES]: 'Contract Comparables',
};

interface PlayerHeaderProps {
  player: Player;
  playerRef: MutableRefObject<ReactInstance | null>;
  selectedTabIndex: number;
  onAfterPrint: () => void;
  onSelectViewOption: (tabIndex: number) => void;
}

function PlayerHeader({
  player,
  playerRef,
  selectedTabIndex,
  onAfterPrint,
  onSelectViewOption,
}: PlayerHeaderProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const copyToClipboard = useCopyToClipboard()[1];
  const subRoute = useSubRoute(3);
  const width = useWidth();

  const contractComparables = useSelector(selectPlayerComparableContracts);
  const contractComparablesSubject = useSelector(selectPlayerComparableContractsSubject);
  const currentSeasonStartYear = useSelector(selectSeasonStartYear);

  const handleCopyToClipBoard = useCallback(() => {
    switch (subRoute) {
      case PLAYER_SUB_ROUTES.PLAYER_CONTRACT_COMPARABLES: {
        if (contractComparables) {
          const playerIds = contractComparables?.map((contract) => contract.playerInfo.playerId).join(',');

          copyToClipboard(
            `${window.location.origin.toString()}${ROUTES.PLAYERS}/${
              player.playerId
            }${PLAYER_CONTRACT_COMPARABLES}?playerIds=${playerIds}`,
          );

          logGAEvent({
            category: GA_CATEGORIES.PLAYER_PROFILE,
            action: GA_ACTIONS.SHARE_CONTRACT_COMPS,
            label: getPlayerFullname(player),
            value: playerIds,
          });
        }
        break;
      }

      default: {
        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.SHARE_PLAYER,
          label: getPlayerFullname(player),
          value: player.playerId,
        });

        copyToClipboard(window.location.href);
      }
    }

    dispatch(
      showToast({
        toastMessage: 'Copied Successfully to Clipboard',
        toastType: ToastTypes.SUCCESS,
      }),
    );
  }, [copyToClipboard, dispatch, player, subRoute, contractComparables]);

  const shareMenuItem = useShareMenuItem({ onClick: handleCopyToClipBoard });

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS, [width]);

  const handleDownloadCSV = useCallback(() => {
    switch (subRoute) {
      case PLAYER_SUB_ROUTES.PLAYER_CONTRACT_COMPARABLES: {
        if (!contractComparables || !currentSeasonStartYear || !contractComparablesSubject) break;

        const comparableContractsData = [contractComparablesSubject, ...contractComparables];
        const csvJSON: { [key: string]: string | number }[] = [
          generateContractComparablesCSVHeader(currentSeasonStartYear),
        ];

        csvJSON.push(
          ...comparableContractsData.map((comparableContract) =>
            mapComparableContractForCSV({ comparableContract, currentSeasonStartYear }),
          ),
        );

        const jsonArray = new JsonArray(csvJSON);
        const str = jsonArray.convertToCSVstring();

        const fileName = `${getPlayerReverseFullname(player)}-${format(new Date(), 'MM/dd/yyyy')}.csv`;
        download(fileName, str);

        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.DOWNLOAD_CONTRACT_COMPS,
          label: getPlayerFullname(player),
          value: contractComparables.map((contractComparable) => contractComparable.playerInfo.playerId).join(','),
        });

        break;
      }

      default:
        break;
    }
  }, [contractComparables, contractComparablesSubject, currentSeasonStartYear, player, subRoute]);

  const pageMenuItems: PageActionItem[] = useMemo(() => {
    const items: PageActionItem[] = [shareMenuItem, PagePrintAction];

    switch (subRoute) {
      case PLAYER_SUB_ROUTES.PLAYER_CONTRACT_COMPARABLES:
        items.push({
          text: (
            <>
              <FontAwesomeIcon
                style={{ width: '1.5rem' }}
                color={Colors.BRAND_BLACK}
                icon={solid('file-csv')}
                size="lg"
              />
              <Typography sx={{ marginLeft: 2 }} variant="body1">
                CSV
              </Typography>
            </>
          ),
          onClick: handleDownloadCSV,
        });
        break;

      default:
        break;
    }

    return items;
  }, [subRoute, handleDownloadCSV, shareMenuItem]);

  const playerViewOptions = PLAYER_VIEW_OPTIONS.filter(
    (option) =>
      !(
        option.value === PLAYER_VIEW_OPTION_VALUES.CONTRACT_COMPARABLES &&
        player.primaryPosition === PlayerPosition.Goalie
      ),
  ).sort((a, b) => a.value - b.value);

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      {!isSmallScreen && (
        <>
          <StanleyNavTabs
            minWidth="10rem"
            selectedTabIndex={selectedTabIndex}
            tabs={playerViewOptions}
            onTabChange={(tabIndex: number) => onSelectViewOption(tabIndex)}
          />
          <StanleyPageActions
            printRef={playerRef}
            className={classNames(classes.printButton, classes.printButtonSmall)}
            actions={pageMenuItems.length > 1 ? pageMenuItems : undefined}
            tooltipTitle="Actions"
            onAfterPrint={onAfterPrint}
          />
        </>
      )}
      {isSmallScreen && (
        <>
          <Typography variant="h6">{CONTRACT_ACTIVE_VIEW_HEADER_MAP[selectedTabIndex]}</Typography>
          <Box>
            <StanleyPageActions
              printRef={playerRef}
              className={classNames(classes.printButton, classes.printButtonSmall)}
              actions={pageMenuItems.length > 1 ? pageMenuItems : undefined}
              onAfterPrint={onAfterPrint}
            />
            <StanleyHoverSelect
              options={playerViewOptions}
              selectClasses={{ select: classes.select }}
              value={selectedTabIndex}
              onChange={(value) => onSelectViewOption(value as number)}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  printButton: {
    padding: theme.spacing(0.75),
  },
  printButtonSmall: {
    marginRight: theme.spacing(1),
  },
  select: {
    width: '10rem',
  },
}));

export default PlayerHeader;
