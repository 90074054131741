import { Box, Typography } from '@mui/material';

import { CONTRACT_CLAUSE_MAP } from 'utilities/contract';
import { Player } from 'types/player';
import { isEmpty } from 'lodash';

interface RosterTermColumnProps {
  contractIndex: number;
  player: Player;
}

function RosterTermColumn({ contractIndex, player }: RosterTermColumnProps): JSX.Element {
  const { contractDetails } = player;

  if (
    isEmpty(contractDetails) ||
    isEmpty(contractDetails[contractIndex]) ||
    isEmpty(contractDetails[contractIndex]!.clauses)
  ) {
    return <Box>--</Box>;
  }

  return (
    <Typography noWrap variant="body2">
      {contractDetails[contractIndex]!.clauses.map((clause): string => CONTRACT_CLAUSE_MAP[clause]).join(', ')}
    </Typography>
  );
}

export default RosterTermColumn;
