const HELP_CENTER = '/help-center';
const LEAGUE = '/league';
const LOADING = '/loading';
const PLAYERS = '/players';
const ROSTER_COMPARISON = '/comparison';
const SCENARIOS = '/scenarios';
const SEARCH = '/search';
const SEARCH_TRADES = '/search/trades';
const SIGNIN = '/signin';
const SIGNUP = '/signup';
const TEAMS = '/teams';
const TRADE_SIMULATOR = '/trade-simulator';
const TEAM_CAPITAL_OUTLOOK = '/capital-outlook';
// const TEAM_DRAFT_PICKS = '/draft-picks';
const TEAM_TRADE_HISTORY = '/trade-history';
const PLAYER_REGULAR_SEASON_STATS = '/regular-season-stats';
const PLAYER_PLAYOFF_STATS = '/playoff-stats';
const RESEARCH = '/research';
const SUMMARY = '/summary';
export const PLAYER_CONTRACT_COMPARABLES = '/contract-comparables';
const PLAYER_TRADE_COMPARABLES = '/trade-comparables';

export const PLAYER_ID = 'playerId';
export const TEAM_ID = 'teamId';
export const SCENARIO_ID = 'scenarioId';

const ROUTE_PARAMS = {
  PLAYER_ID,
  TEAM_ID,
};

const TEAM_SUB_ROUTES = {
  TEAM_CAPITAL_OUTLOOK,
  // TEAM_DRAFT_PICKS,
  TEAM_TRADE_HISTORY,
};

const TEAMS_SUB_ROUTES = {
  RESEARCH,
  SUMMARY,
};

const PLAYER_SUB_ROUTES: { [key: string]: string } = {
  PLAYER_CONTRACT_COMPARABLES,
  PLAYER_TRADE_COMPARABLES,
  PLAYER_REGULAR_SEASON_STATS,
  PLAYER_PLAYOFF_STATS,
};

const ROUTES = {
  HELP_CENTER,
  LEAGUE,
  RESEARCH: `${LEAGUE}${RESEARCH}`,
  LOADING,
  PLAYER: `${PLAYERS}/:${ROUTE_PARAMS.PLAYER_ID}`,
  PLAYERS,
  ROSTER_COMPARISON,
  SCENARIOS,
  SEARCH,
  SEARCH_TRADES,
  SIGNIN,
  SIGNUP,
  TEAM: `${TEAMS}/:${ROUTE_PARAMS.TEAM_ID}`,
  TEAMS,
  TRADE_SIMULATOR,
};

export { ROUTES, ROUTE_PARAMS, TEAM_SUB_ROUTES, PLAYER_SUB_ROUTES, TEAMS_SUB_ROUTES };
