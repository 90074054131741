/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-confusing-arrow */
import { MTableBody } from '@material-table/core';
import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { cloneDeep, isEmpty } from 'lodash';
import { useMemo } from 'react';

import PlayerContractTableSummary from 'modules/players/playerContracts/playerContractTableSummary.component';
import { CONTRACT_CLAUSE_MAP } from 'utilities/contract';
import { PlayerContractDetails } from 'types/contract';

interface PlayerContractTableProps {
  contract: PlayerContractDetails[];
}

function PlayerContractTable({ contract }: PlayerContractTableProps): JSX.Element {
  const columns = useMemo(
    () => [
      {
        cellStyle: { minWidth: 125 },
        render: (rowData: PlayerContractDetails): string =>
          `${rowData.seasonInfo.seasonYearStart} - ${rowData.seasonInfo.seasonYearEnd}`,
        title: 'Season',
        sorting: false,
      },
      {
        title: 'Clause',
        render: ({ clauses }: PlayerContractDetails): string =>
          !isEmpty(clauses) ? clauses.map((clause) => CONTRACT_CLAUSE_MAP[clause]).join(', ') : '--',
        sorting: false,
      },
      {
        title: 'Cap Hit',
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) => rowA.capHit - rowB.capHit,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.capHit} />,
      },
      {
        title: 'AAV',
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) =>
          rowA.averageAnnualValue - rowB.averageAnnualValue,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.averageAnnualValue} />,
      },
      {
        title: 'P. Bonuses',
        cellStyle: { minWidth: 118 },
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) =>
          rowA.performanceBonus - rowB.performanceBonus,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.performanceBonus} />,
      },
      {
        title: 'S. Bonuses',
        cellStyle: { minWidth: 118 },
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) => rowA.signingBonus - rowB.signingBonus,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.signingBonus} />,
      },
      {
        title: 'Base Salary',
        cellStyle: { minWidth: 122 },
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) => rowA.baseSalary - rowB.baseSalary,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.baseSalary} />,
      },
      {
        title: 'Total Salary',
        cellStyle: { minWidth: 122 },
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) => rowA.totalSalary - rowB.totalSalary,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.totalSalary} />,
      },
      {
        title: 'Minors Salary',
        cellStyle: { minWidth: 126 },
        customSort: (rowA: PlayerContractDetails, rowB: PlayerContractDetails) => rowA.minorsSalary - rowB.minorsSalary,
        render: (rowData: PlayerContractDetails) => <StanleyDollar amount={rowData.minorsSalary} />,
      },
      {
        title: 'Cap Hit(%)',
        cellStyle: { minWidth: 114 },
        render: (rowData: PlayerContractDetails) => `${(rowData.capHitPercentage * 100).toFixed(2)}%`,
      },
    ],
    [],
  );

  const sortedContracts = useMemo(() => {
    const contractClone = cloneDeep(contract);

    return contractClone.sort((a, b) => a.seasonInfo.seasonYearStart - b.seasonInfo.seasonYearStart);
  }, [contract]);

  return (
    <StanleyTable
      columns={columns}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            <PlayerContractTableSummary contract={contract} />
          </>
        ),
      }}
      data={sortedContracts}
    />
  );
}

export default PlayerContractTable;
