/* eslint-disable @typescript-eslint/unbound-method */
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import RosterPositionListItem from 'modules/teams/rosterView/rosterPositionListItem.component';
import { Player } from 'types/player';

interface RosterDraggablePositionListItemProps {
  index: number;
  player: Player;
}

function RosterDraggablePositionListItem({ index, player }: RosterDraggablePositionListItemProps): JSX.Element {
  const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'inherit',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable draggableId={player.playerId.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <RosterPositionListItem player={player} />
        </div>
      )}
    </Draggable>
  );
}

export default RosterDraggablePositionListItem;
