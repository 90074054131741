import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper, Typography } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';

import { HELP_CENTER_VIDEOS } from 'utilities/helpCenter';

function HelpCenter(): JSX.Element {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <Container spacing={4}>
          {HELP_CENTER_VIDEOS.map((video: { title: string; url: string }) => (
            <Item xs={12} sm={6} md={4} key={video.url}>
              <Box>
                <Typography className={classes.title} variant="subtitle2">
                  {video.title}
                </Typography>
                <Box className={classes.videoContainer}>
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    className={classes.video}
                    frameBorder="0"
                    src={video.url}
                    title={video.title}
                  />
                </Box>
              </Box>
            </Item>
          ))}
        </Container>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'scroll',
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    fontSize: '1rem',
  },
  videoContainer: {
    paddingTop: '56.25%',
    position: 'relative',
  },
  video: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

export default HelpCenter;
