import { Navigate, Route, Routes } from 'react-router-dom';

import BlockNavigation from 'modules/layout/blockNavigation.component';
import PlayerSearch from 'modules/advancedSearch/playerSearch.component';
import TradesSearch from 'modules/advancedSearch/tradesSearch.component';
import { ROUTES } from 'utilities/routes';

function Search() {
  return (
    <Routes>
      <Route
        element={
          <BlockNavigation>
            <PlayerSearch />
          </BlockNavigation>
        }
        path="/"
      />
      <Route element={<TradesSearch />} path="/trades" />
      <Route path="*" element={<Navigate to={ROUTES.SEARCH} />} />
    </Routes>
  );
}

export default Search;
