/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { auth } from 'utilities/firebase';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

const BASE_URL = `${SERVER_URL}/`;

export default async (endpoint: string, config: Record<string, unknown>, isAuthenticated?: boolean): Promise<any> => {
  const options: RequestInit = { ...config,
    headers: {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*',
      } as Record<string, string> };

  if (isAuthenticated && auth.currentUser) {
    const token = await auth.currentUser.getIdToken();

    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  if (config.body) {
    options.body = JSON.stringify(config.body);
  }

  let data: any;

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (response.ok) {
      try {
        data = await response.json();

        return data;
      } catch (err) {
        return {};
      }
    }

    const error = (data && data.message) || response.status;

    return await Promise.reject(error);
  } catch (err) {
    return Promise.reject(err || data);
  }
};
