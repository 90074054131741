import { Typography, styled } from '@mui/material';

interface PlayerDraftStatusPanelItemProps {
  children?: JSX.Element;
  label: string;
  value?: string | number;
}

const StyledSpan = styled('span')(({ theme }) => ({
  fontWeight: 'normal',
  marginRight: theme.spacing(1),
}));

const StyledTypography = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
});

function PlayerDraftStatusPanelItem({ children, label, value }: PlayerDraftStatusPanelItemProps): JSX.Element {
  if (children) {
    return (
      <StyledTypography variant="subtitle2">
        {label}
        &nbsp;
        <StyledSpan>{value}</StyledSpan>
        {children}
      </StyledTypography>
    );
  }

  return (
    <StyledTypography variant="subtitle2">
      {label}
      &nbsp;
      <StyledSpan>{value}</StyledSpan>
    </StyledTypography>
  );
}

export default PlayerDraftStatusPanelItem;
