import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { ROUTES } from 'utilities/routes';
import { TableRowClick, TableSort } from 'types/table';
import { Team, TeamSummary } from 'types/teams';
import { selectTeamsSummary } from 'modules/teams/teams.selectors';

function TeamsTable(): JSX.Element {
  const navigate = useNavigate();

  const teamsSummary = useSelector(selectTeamsSummary);

  logGAEvent({
    category: GA_CATEGORIES.TEAMS,
    action: GA_ACTIONS.TEAMS_LEAGUE,
  });

  const columns = useMemo(
    () => [
      {
        title: 'Team',
        field: 'name',
        cellStyle: {
          width: 195,
          minWidth: 195,
        },
        defaultSort: TableSort.ASC,
        hasSortCellStyle: false,
      },
      {
        field: 'numPlayersOnRoster',
        title: 'Roster',
      },
      {
        title: 'Contracts',
        field: 'numPlayersUnderContract',
      },
      {
        title: 'Retained',
        field: 'numPlayersRetained',
      },
      {
        title: 'Avg. Age',
        customSort: (rowA: TeamSummary, rowB: TeamSummary) => rowA.averageAge - rowB.averageAge,
        render: (rowData: TeamSummary) => Math.round(rowData.averageAge),
      },
      {
        title: 'Proj. Cap Hit',
        customSort: (rowA: TeamSummary, rowB: TeamSummary) => rowA.projCapHit - rowB.projCapHit,
        render: (rowData: TeamSummary) => <StanleyDollar amount={rowData.projCapHit} />,
      },
      {
        title: 'Proj. Cap Space',
        customSort: (rowA: TeamSummary, rowB: TeamSummary) => rowA.projCapSpace - rowB.projCapSpace,
        render: (rowData: TeamSummary) => <StanleyDollar amount={rowData.projCapSpace} />,
      },
      {
        title: 'Active Salaries',
        customSort: (rowA: TeamSummary, rowB: TeamSummary) =>
          rowA.totalActiveRosterSalary - rowB.totalActiveRosterSalary,
        render: (rowData: TeamSummary) => <StanleyDollar amount={rowData.totalActiveRosterSalary} />,
      },
      {
        title: 'Bonus Overage',
        customSort: (rowA: TeamSummary, rowB: TeamSummary) => rowA.bonusOverage - rowB.bonusOverage,
        render: (rowData: TeamSummary) => <StanleyDollar amount={rowData.bonusOverage} />,
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: Team) => {
      if (rowData && rowData.teamId) {
        navigate(`${ROUTES.TEAMS}/${rowData.teamId}`);
      }
    },
    [navigate],
  );

  return <StanleyTable columns={columns} data={teamsSummary || []} defaultSortIndex={0} onRowClick={handleRowClick} />;
}

export default TeamsTable;
