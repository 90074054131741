import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { CAP_SUMMARY_COLUMN_MAP } from 'utilities/teams';
import { ProjectedSummaryData } from 'types/table';
import { TeamSummary } from 'types/teams';
import { formatYearRange } from 'utilities/date';
import { selectSeasonStartYear } from 'modules/season/season.selectors';

interface TeamProjectionTableProps {
  projectedTeamSummary: TeamSummary[] | null;
}

function TeamProjectionTable({ projectedTeamSummary }: TeamProjectionTableProps): JSX.Element {
  const curSeasonStartYear = useSelector(selectSeasonStartYear);

  const [projectedSummary, setProjectedSummary] = useState<ProjectedSummaryData[] | null>(null);

  useEffect(() => {
    if (!isNil(projectedTeamSummary)) {
      const tableData: ProjectedSummaryData[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

      projectedTeamSummary.forEach((teamSummary, index: number) => {
        tableData[0][`year${index}`] = { value: teamSummary.numPlayersOnRoster };
        tableData[1][`year${index}`] = { isDollar: true, value: teamSummary.projCapHit };
        tableData[2][`year${index}`] = { isDollar: true, value: teamSummary.activeRosterCapHit };
        tableData[3][`year${index}`] = { isDollar: true, value: teamSummary.totalActiveRosterSalary };
        tableData[4][`year${index}`] = { isDollar: true, value: teamSummary.projCapSpace };
        tableData[5][`year${index}`] = { isDollar: true, value: teamSummary.currentCapSpace };
        tableData[6][`year${index}`] = { isDollar: true, value: teamSummary.maxLtirPool };
        tableData[7][`year${index}`] = { isDollar: true, value: teamSummary.totalSigningBonuses };
        tableData[8][`year${index}`] = { isDollar: true, value: teamSummary.potentialPerformanceBonus };
        tableData[9][`year${index}`] = { isDollar: true, value: teamSummary.bonusOverage };
      });

      setProjectedSummary(tableData);
    }
  }, [projectedTeamSummary]);

  const renderCapSummaryColumn = useCallback((rowData: ProjectedSummaryData, yearNumber: number) => {
    const yearData = rowData[`year${yearNumber}`];

    if (!yearData || (typeof yearData.value !== 'number' && !yearData.value)) return '--';

    if (yearData.isDollar) {
      return <StanleyDollar amount={yearData.value as number} showNegativeOnly />;
    }

    return yearData.value;
  }, []);

  const columns = useMemo(
    () => [
      {
        cellStyle: { minWidth: 235 },
        title: 'Projected Cap Summary',
        render: (rowData: ProjectedSummaryData) =>
          CAP_SUMMARY_COLUMN_MAP[(rowData.tableData as { [key: string]: number }).id],
      },
      {
        cellStyle: { minWidth: 100 },
        sorting: false,
      },
      {
        cellStyle: { minWidth: 100 },
        sorting: false,
      },
      {
        cellStyle: { minWidth: 100 },
        sorting: false,
      },
      {
        title: formatYearRange(curSeasonStartYear),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 0),
      },
      {
        title: formatYearRange(curSeasonStartYear, 1),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 1),
      },
      {
        title: formatYearRange(curSeasonStartYear, 2),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 2),
      },
      {
        title: formatYearRange(curSeasonStartYear, 3),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 3),
      },
      {
        title: formatYearRange(curSeasonStartYear, 4),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 4),
      },
      {
        title: formatYearRange(curSeasonStartYear, 5),
        cellStyle: { minWidth: 100 },
        render: (rowData: ProjectedSummaryData) => renderCapSummaryColumn(rowData, 5),
      },
    ],
    [curSeasonStartYear, renderCapSummaryColumn],
  );

  return <StanleyTable columns={columns} data={projectedSummary || []} options={{ sorting: false }} />;
}

export default TeamProjectionTable;
