/* eslint-disable @typescript-eslint/no-unsafe-return */
import { DocumentData, DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Loading from 'modules/auth/loading.container';
import useQueryParams from 'hooks/useQueryParams';
import { FIREBASE_STORAGE_USER_KEYS } from 'constants/firebase';
import { FirebaseUser, UserPreferences } from 'types/user';
import { LoggedInState } from 'types/auth';
import { ROUTES } from 'utilities/routes';
import { SavedCompListItem } from 'types/comparableContracts';
import { getCurrentUser, setLoggedInState } from 'modules/auth/auth.slice';
import { getPlayers } from 'modules/advancedSearch/search.slice';
import { getScenario } from 'modules/scenarios/scenarios.slice';
import { getScores } from 'modules/scoreBoard/scoreboard.slice';
import { getSeason } from 'modules/season/season.slice';
import { getTeams } from 'modules/teams/teams.slice';
import { readFirestoreData, writeFirestoreDataByUid } from 'utilities/firebase';
import { setCompLists } from 'modules/players/playerComparableContracts/comparableContracts.slice';
import { setFirebaseUser, setPreferences } from 'modules/user/user.slice';
import { useAppDispatch } from 'store';

function LoadingRoutes(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!isLoading) {
      (async () => {
        const dataToLoad: any[] = [getCurrentUser, getTeams, getScores, getSeason, getPlayers];

        const scenarioId = queryParams.get('scenarioId');

        if (scenarioId) dataToLoad.push(() => getScenario(scenarioId));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await Promise.all(dataToLoad.map((load) => dispatch(load())));

        const user = await readFirestoreData({ path: 'users' });

        if ((user as DocumentSnapshot)?.exists()) {
          dispatch(setFirebaseUser((user as DocumentSnapshot).data() as FirebaseUser));
        }

        writeFirestoreDataByUid({
          path: 'users',
          data: { [FIREBASE_STORAGE_USER_KEYS.LAST_LOGIN]: new Date().toISOString() },
        });

        const preferences = await readFirestoreData({ path: 'preferences' });

        if ((preferences as DocumentSnapshot)?.exists()) {
          dispatch(setPreferences((preferences as DocumentSnapshot)?.data() as UserPreferences));
        }

        const compLists = await readFirestoreData({
          path: 'compLists',
          byUid: false,
          singleDoc: false,
          whereClause: {
            key: 'owner',
            operator: '==',
            value: 'uid',
          },
        });

        if (!(compLists as QuerySnapshot<DocumentData>).empty) {
          const compListData: SavedCompListItem[] = [];

          (compLists as QuerySnapshot<DocumentData>).forEach((doc) => {
            const docData = doc.data() as SavedCompListItem;

            compListData.push({
              ...docData,
              id: doc.id,
            });
          });

          dispatch(setCompLists(compListData));
        }

        dispatch(setLoggedInState(LoggedInState.LOGGED_IN));
      })();
    }
  }, [dispatch, navigate, isLoading, queryParams]);

  return (
    <Routes>
      <Route path={ROUTES.LOADING} element={<Loading />} />
      <Route path="*" element={<Navigate to={ROUTES.LOADING} />} />
    </Routes>
  );
}

export default LoadingRoutes;
