import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { LocalizationProvider } from '@mui/lab';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { User, onAuthStateChanged } from 'firebase/auth';
import { setUserProperties } from 'firebase/analytics';

import MainLayout from 'modules/layout/mainLayout.component';
import ThemeUtils from 'utilities/theme';
import { CUSTOM_GA_DIMENSIONS } from 'utilities/analytics';
import { LoggedInState } from 'types/auth';
import { ROUTES } from 'utilities/routes';
import { analytics, auth } from 'utilities/firebase';
import { resetUser, setLoggedInState } from 'modules/auth/auth.slice';
import { setInitialRoute } from 'modules/layout/layout.slice';
import { store } from 'store';

import 'index.css';

let isAppStarted = false;

// eslint-disable-next-line no-console
console.log('ENVIRONMENT NAME: ', process.env.REACT_APP_ENVIRONMENT_NAME);

if (
  process.env.REACT_APP_ENVIRONMENT_NAME === 'production' ||
  process.env.REACT_APP_ENVIRONMENT_NAME === 'development'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  });
}

onAuthStateChanged(auth, (user: User | null): void => {
  if (user) {
    setUserProperties(analytics, {
      [CUSTOM_GA_DIMENSIONS.USER_EMAIL]: user.email,
      [CUSTOM_GA_DIMENSIONS.USER_IDENTIFIER]: user.uid,
    });

    Sentry.setUser({ email: user.email || '' });

    store.dispatch(setLoggedInState(LoggedInState.LOADING));
  } else {
    store.dispatch(resetUser());

    Sentry.configureScope((scope) => scope.setUser(null));
  }

  if (!isAppStarted) {
    startApp();
    isAppStarted = true;
  }
});

const startApp = () => {
  if (window.location.pathname !== ROUTES.LOADING) {
    store.dispatch(setInitialRoute(`${window.location.pathname}${window.location.search}`));
  }

  ReactDOM.render(
    <Router>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={ThemeUtils.theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MainLayout />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </Router>,
    document.getElementById('root'),
  );
};
