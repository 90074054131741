import { SeasonSelection } from 'types/playerSearch';

export const FILTERS_TOP_LEVEL = ['maxAge', 'minAge', 'primaryPosition', 'scenarioId', 'teamId'];
export const CONTRACT_FILTERS = [
  'contractExpirationSeasonId',
  'contractType',
  'expiryStatus',
  'maxAverageAnnualValue',
  'minAverageAnnualValue',
  'maxSigningAge',
  'minSigningAge',
  'maxContractLength',
  'minContractLength',
  'maxCapHit',
  'minCapHit',
  'maxContractValue',
  'minContractValue',
  'arbRights',
  'signingStatus',
];
export const ARRAY_FILTERS = ['contractType', 'expiryStatus', 'primaryPosition', 'teamId'];

export const SEASON_SELECTIONS_ORDER = Object.keys(SeasonSelection);
