const DATA_FIELD_PLATFORM_YEAR = 1;
const DATA_FIELD_CAP_HIT = 2;
const DATA_FIELD_CAP_HIT_PER = 'capHitPercentage';
const DATA_FIELD_ARB_ELIGIBILITY = 'arbRights';
const DATA_FIELD_QO_VALUE = 'qoValue';
const DATA_FIELD_CURRENT_AGE = 'age';
const DATA_FIELD_CONTRACT_LENGTH = 'contractLength';
const DATA_FIELD_POSITION = 'primaryPosition';
const DATA_FIELD_CONTRACT_TYPE = 'contractType';
const DATA_FIELD_AAV = 'averageAnnualValue';
const DATA_FIELD_SIGNING_TEAM = 11;
const DATA_FIELD_SIGNING_DATE = 'signingDate';
const DATA_FIELD_SIGNING_STATUS = 'signingStatus';
const DATA_FIELD_EXPIRATION_STATUS = 'expiryStatus';
const DATA_FIELD_CURRENT_TEAM = 15;
const DATA_FIELD_SIGNING_AGE = 'signingAge';
const DATA_FIELD_SEASON_START = 'startingSeason';

const ASSISTS = 'assists';
const ASSISTS_EVEN_STRENGTH = 'assistsEvenStrength';
const ASSISTS_POWERPLAY = 'assistsPowerplay';
const ASSISTS_SHORTHANDED = 'assistsShorthanded';
const ASSISTS_PER_GAME = 'assistsPerGame';
const ASSISTS_PER_GAME_EVEN_STRENGTH = 'assistsPerGameEvenStrength';
const ASSISTS_PER_GAME_POWERPLAY = 'assistsPerGamePowerplay';
const ASSISTS_PER_GAME_SHORTHANDED = 'assistsPerGameShorthanded';
const AVERAGE_TIME_ON_ICE_TOTAL = 'timeOnIcePerGame';
const AVERAGE_TIME_ON_ICE_POWER_PLAY = 'timeOnIcePowerPlayPerGame';
const AVERAGE_TIME_ON_ICE_SHORTHANDED = 'timeOnIceShorthandedPerGame';
const AVERAGE_TIME_ON_ICE_EVEN_STRENGTH = 'timeOnIceEvenStrengthPerGame';
const BLOCKED_ATT = 'blockedAtt';
const BLOCKED_SHOTS = 'blockedShots';
const FACEOFFS = 'faceoffs';
const FACEOFFS_EVEN_STRENGTH = 'faceoffsEvenStrength';
const FACEOFFS_LOST = 'faceoffsLost';
const FACEOFFS_LOST_EVEN_STRENGTH = 'faceoffsLostEvenStrength';
const FACEOFFS_LOST_POWERPLAY = 'faceoffsLostPowerplay';
const FACEOFFS_LOST_SHORTHANDED = 'faceoffsLostShorthanded';
const FACEOFFS_POWERPLAY = 'faceoffsPowerplay';
const FACEOFFS_SHORTHANDED = 'faceoffsShorthanded';
const FACEOFFS_WIN_PCT = 'faceoffsWinPct';
const FACEOFFS_WIN_PCT_EVEN_STRENGTH = 'faceoffsWinPctEvenStrength';
const FACEOFFS_WIN_PCT_POWERPLAY = 'faceoffsWinPctPowerplay';
const FACEOFFS_WIN_PCT_SHORTHANDED = 'faceoffsWinPctShorthanded';
const FACEOFFS_WON = 'faceoffsWon';
const FACEOFFS_WON_EVEN_STRENGTH = 'faceoffsWonEvenStrength';
const FACEOFFS_WON_POWERPLAY = 'faceoffsWonPowerplay';
const FACEOFFS_WON_SHORTHANDED = 'faceoffsWonShorthanded';
const GAMES_PLAYED = 'gamesPlayed';
const GIVEAWAYS = 'giveaways';
const GOALS = 'goals';
const GOALS_AGAINST_AVERAGE = 'goalsAgainstAverage';
const GOALS_EVEN_STRENGTH = 'goalsEvenStrength';
const GOALS_POWERPLAY = 'goalsPowerplay';
const GOALS_SHORTHANDED = 'goalsShorthanded';
const GOALS_PER_GAME = 'goalsPerGame';
const GOALS_PER_GAME_EVEN_STRENGTH = 'goalsPerGameEvenStrength';
const GOALS_PER_GAME_POWERPLAY = 'goalsPerGamePowerplay';
const GOALS_PER_GAME_SHORTHANDED = 'goalsPerGameShortHanded';
const HITS = 'hits';
const MISSED_SHOTS = 'missedShots';
const MISSED_SHOTS_EVEN_STRENGTH = 'missedShotsEvenStrength';
const MISSED_SHOTS_POWERPLAY = 'missedShotsPowerplay';
const MISSED_SHOTS_SHORTHANDED = 'missedShotsShorthanded';
const PENALTIES = 'penalties';
const PENALTY_GOALS = 'penaltyGoals';
const PENALTY_INFRACTION_MINUTES = 'penaltyMinutes';
const PENALTY_MINUTES = 'penaltyMinutes';
const PENALTY_MISSED_SHOTS = 'penaltyMissedShots';
const PENALTY_SHOTS = 'penaltyShots';
const PLUS_MINUS = 'plusMinus';
const PLUS_MINUS_SR = 'plusMinusSR';
const POINTS = 'points';
const POINTS_PER_GAME = 'pointsPerGame';
const POINTS_SR_PER_GAME_EVEN_STRENGTH = 'pointsSRPerGameEvenStrength';
const POINTS_SR_PER_GAME_POWERPLAY = 'pointsSRPerGamePowerPlay';
const POINTS_SR_PER_GAME_SHORTHANDED = 'pointsSRPerGameShorthanded';
const POINTS_SR = 'pointsSR';
const POINTS_SR_EVEN_STRENGTH = 'pointsSREvenStrength';
const POINTS_SR_POWERPLAY = 'pointsSRPowerPlay';
const POINTS_SR_SHORTHANDED = 'pointsSRShorthanded';
const POWER_PLAY_GOALS = 'powerPlayGoals';
const SAVE_PERCENTAGE = 'savePercentage';
const SHOOTING_PCT = 'shootingPct';
const SHOOTOUT_GOALS = 'shootoutGoals';
const SHOOTOUT_MISSED_SHOTS = 'shootoutMissedShots';
const SHOOTOUT_SHOTS = 'shootoutShots';
const SHOTS = 'shots';
const SHOTS_EVEN_STRENGTH = 'shotsEvenStrength';
const SHOTS_POWERPLAY = 'shotsPowerplay';
const SHOTS_SHORTHANDED = 'shotsShorthanded';
const STARTER_GAMES = 'starterGames';
const TAKEAWAYS = 'takeaways';
const TIME_ON_ICE_AVG = 'timeOnIceAvg';
const TIME_ON_ICE_EVEN_STRENGTH = 'timeOnIceEvenStrength';
const TIME_ON_ICE_POWER_PLAY = 'timeOnIcePowerPlay';
const TIME_ON_ICE_SHIFTS = 'timeOnIceShifts';
const TIME_ON_ICE_SHORTHANDED = 'timeOnIceShorthanded';
const TIME_ON_ICE_TOTAL = 'timeOnIceTotal';

const UFA_YEARS = 'ufaYears';
const RFA_YEARS = 'rfaYears';
const TOTAL_BONUS_PCT = 'totalBonusPct';
const TOTAL_PERFORMANCE_BONUS_PCT = 'totalPerformanceBonusPct';
const TOTAL_SIGNING_BONUS_PCT = 'totalSigningBonusPct';
const TOTAL_BASE_SALARY_PCT = 'totalBaseSalaryPct';
const TOTAL_SALARY = 'totalValue';
const BASE_SALARY = 'baseSalary';
const SIGNING_BONUS = 'signingBonus';
const MINORS_SALARY = 'minorsSalary';
const PERFORMANCE_BONUS = 'performanceBonus';

const CONTRACT_YEAR_1 = 'year-1';
const CONTRACT_YEAR_2 = 'year-2';
const CONTRACT_YEAR_3 = 'year-3';
const CONTRACT_YEAR_4 = 'year-4';
const CONTRACT_YEAR_5 = 'year-5';
const CONTRACT_YEAR_6 = 'year-6';
const CONTRACT_YEAR_7 = 'year-7';
const CONTRACT_YEAR_8 = 'year-8';
const CONTRACT_YEAR = 'contractYear';
const CONTRACT_SEASON_YEAR = 'seasonYear';
const CONTRACT_YEAR_STATUS = 'cyStatus';

const EXPECTED_GOALS_FOR = 'expectedGoalsFor';
const ACTUAL_TO_EXPECT_GOALS_FOR = 'actualToExpectedGoalsFor';
// const ACTUAL_FENWICKS_FOR = 'actualFenwicksFor';
const EXPECTED_GOALS_AGAINST = 'expectedGoalsAgainst';
const ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST = 'actualToExpectedGoalsForAgainst';
// const ACTUAL_FENWICKS_FOR_AGAINST = 'actualFenwicksForAgainst';
// const EXPECTED_GOALS_SHOOTER = 'expectedGoalsShooter';
// const ACTUAL_TO_EXPECTED_GOALS_SHOOTER = 'actualToExpectedGoalsShooter';
// const ACTUAL_FENWICKS_SHOOTER = 'actualFenwicksShooter';
// const EXPECTED_GOALS_PCT = 'expectedGoalsPct';

export const DEFAULT_DATA_FIELD_OPTIONS = [
  DATA_FIELD_PLATFORM_YEAR,
  DATA_FIELD_CONTRACT_LENGTH,
  DATA_FIELD_POSITION,
  DATA_FIELD_SIGNING_TEAM,
  DATA_FIELD_SIGNING_AGE,
  DATA_FIELD_SIGNING_STATUS,
  DATA_FIELD_EXPIRATION_STATUS,
  DATA_FIELD_AAV,
  GAMES_PLAYED,
  GOALS,
  ASSISTS,
  POINTS_SR,
  POINTS_PER_GAME,
  CONTRACT_YEAR_1,
  CONTRACT_YEAR_2,
  CONTRACT_YEAR_3,
  CONTRACT_YEAR_4,
];

export const CONTRACT_YEAR_DATA_FIELD_VALUES = {
  CONTRACT_YEAR_1,
  CONTRACT_YEAR_2,
  CONTRACT_YEAR_3,
  CONTRACT_YEAR_4,
  CONTRACT_YEAR_5,
  CONTRACT_YEAR_6,
  CONTRACT_YEAR_7,
  CONTRACT_YEAR_8,
  CONTRACT_YEAR,
  CONTRACT_SEASON_YEAR,
  CONTRACT_YEAR_STATUS,
};

export const EXPECTED_GOALS_DATA_FIELD_VALUES = {
  EXPECTED_GOALS_FOR,
  ACTUAL_TO_EXPECT_GOALS_FOR,
  EXPECTED_GOALS_AGAINST,
  ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST,
};

export const PLAYER_DATA_FIELD_VALUES = {
  DATA_FIELD_PLATFORM_YEAR,
  DATA_FIELD_CAP_HIT,
  DATA_FIELD_CAP_HIT_PER,
  DATA_FIELD_ARB_ELIGIBILITY,
  DATA_FIELD_QO_VALUE,
  DATA_FIELD_CURRENT_AGE,
  DATA_FIELD_CONTRACT_LENGTH,
  DATA_FIELD_POSITION,
  DATA_FIELD_CONTRACT_TYPE,
  DATA_FIELD_CURRENT_TEAM,
};

export const CONTRACT_DATA_FIELD_VALUES = {
  DATA_FIELD_AAV,
  DATA_FIELD_CAP_HIT,
  DATA_FIELD_CAP_HIT_PER,
  DATA_FIELD_CONTRACT_LENGTH,
  DATA_FIELD_CONTRACT_TYPE,
  DATA_FIELD_PLATFORM_YEAR,
  DATA_FIELD_ARB_ELIGIBILITY,
  DATA_FIELD_QO_VALUE,
  DATA_FIELD_SIGNING_AGE,
  DATA_FIELD_SIGNING_TEAM,
  DATA_FIELD_SIGNING_DATE,
  DATA_FIELD_SIGNING_STATUS,
  DATA_FIELD_EXPIRATION_STATUS,
  DATA_FIELD_SEASON_START,
  UFA_YEARS,
  RFA_YEARS,
  TOTAL_BONUS_PCT,
  TOTAL_PERFORMANCE_BONUS_PCT,
  TOTAL_SIGNING_BONUS_PCT,
  TOTAL_BASE_SALARY_PCT,
  TOTAL_SALARY,
  BASE_SALARY,
  SIGNING_BONUS,
  MINORS_SALARY,
  PERFORMANCE_BONUS,
};

export const PLAYER_TOTAL_STATS_DATA_FIELD_VALUES = {
  ASSISTS,
  ASSISTS_PER_GAME,
  GAMES_PLAYED,
  GOALS,
  GOALS_PER_GAME,
  POINTS,
  POINTS_SR,
  POINTS_PER_GAME,
  AVERAGE_TIME_ON_ICE_TOTAL,
  TIME_ON_ICE_SHIFTS,
  FACEOFFS,
  FACEOFFS_WIN_PCT,
  FACEOFFS_WON,
  FACEOFFS_LOST,
  SHOOTING_PCT,
  SHOTS,
  PENALTY_INFRACTION_MINUTES,
  HITS,
};

export const PLAYER_EV_STATS_DATA_FIELD_VALUES = {
  ASSISTS_EVEN_STRENGTH,
  ASSISTS_PER_GAME_EVEN_STRENGTH,
  AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
  FACEOFFS_EVEN_STRENGTH,
  FACEOFFS_LOST_EVEN_STRENGTH,
  FACEOFFS_WIN_PCT_EVEN_STRENGTH,
  FACEOFFS_WON_EVEN_STRENGTH,
  GOALS_EVEN_STRENGTH,
  GOALS_PER_GAME_EVEN_STRENGTH,
  POINTS_SR_EVEN_STRENGTH,
  POINTS_SR_PER_GAME_EVEN_STRENGTH,
  SHOTS_EVEN_STRENGTH,
  TIME_ON_ICE_EVEN_STRENGTH,
};

export const PLAYER_PP_STATS_DATA_FIELD_VALUES = {
  ASSISTS_PER_GAME_POWERPLAY,
  ASSISTS_POWERPLAY,
  AVERAGE_TIME_ON_ICE_POWER_PLAY,
  FACEOFFS_LOST_POWERPLAY,
  FACEOFFS_POWERPLAY,
  FACEOFFS_WIN_PCT_POWERPLAY,
  FACEOFFS_WON_POWERPLAY,
  GOALS_PER_GAME_POWERPLAY,
  GOALS_POWERPLAY,
  POINTS_SR_PER_GAME_POWERPLAY,
  POINTS_SR_POWERPLAY,
  SHOTS_POWERPLAY,
  TIME_ON_ICE_POWER_PLAY,
};

export const PLAYER_SH_STATS_DATA_FIELD_VALUES = {
  ASSISTS_PER_GAME_SHORTHANDED,
  ASSISTS_SHORTHANDED,
  AVERAGE_TIME_ON_ICE_SHORTHANDED,
  FACEOFFS_LOST_SHORTHANDED,
  FACEOFFS_SHORTHANDED,
  FACEOFFS_WIN_PCT_SHORTHANDED,
  FACEOFFS_WON_SHORTHANDED,
  GOALS_PER_GAME_SHORTHANDED,
  GOALS_SHORTHANDED,
  POINTS_SR_PER_GAME_SHORTHANDED,
  POINTS_SR_SHORTHANDED,
  SHOTS_SHORTHANDED,
  TIME_ON_ICE_SHORTHANDED,
};

export const PLAYER_STATS_DATA_FIELD_VALUES = {
  ASSISTS,
  ASSISTS_PER_GAME,
  ASSISTS_EVEN_STRENGTH,
  ASSISTS_PER_GAME_EVEN_STRENGTH,
  ASSISTS_POWERPLAY,
  ASSISTS_PER_GAME_POWERPLAY,
  ASSISTS_SHORTHANDED,
  ASSISTS_PER_GAME_SHORTHANDED,
  AVERAGE_TIME_ON_ICE_TOTAL,
  AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
  AVERAGE_TIME_ON_ICE_POWER_PLAY,
  AVERAGE_TIME_ON_ICE_SHORTHANDED,
  BLOCKED_ATT,
  BLOCKED_SHOTS,
  FACEOFFS,
  FACEOFFS_EVEN_STRENGTH,
  FACEOFFS_LOST,
  FACEOFFS_LOST_EVEN_STRENGTH,
  FACEOFFS_LOST_POWERPLAY,
  FACEOFFS_LOST_SHORTHANDED,
  FACEOFFS_POWERPLAY,
  FACEOFFS_SHORTHANDED,
  FACEOFFS_WIN_PCT,
  FACEOFFS_WIN_PCT_EVEN_STRENGTH,
  FACEOFFS_WIN_PCT_POWERPLAY,
  FACEOFFS_WIN_PCT_SHORTHANDED,
  FACEOFFS_WON,
  FACEOFFS_WON_EVEN_STRENGTH,
  FACEOFFS_WON_POWERPLAY,
  FACEOFFS_WON_SHORTHANDED,
  GAMES_PLAYED,
  GIVEAWAYS,
  GOALS_AGAINST_AVERAGE,
  GOALS,
  GOALS_PER_GAME,
  GOALS_EVEN_STRENGTH,
  GOALS_PER_GAME_EVEN_STRENGTH,
  GOALS_POWERPLAY,
  GOALS_PER_GAME_POWERPLAY,
  GOALS_SHORTHANDED,
  GOALS_PER_GAME_SHORTHANDED,
  HITS,
  MISSED_SHOTS,
  MISSED_SHOTS_EVEN_STRENGTH,
  MISSED_SHOTS_POWERPLAY,
  MISSED_SHOTS_SHORTHANDED,
  PENALTIES,
  PENALTY_GOALS,
  PENALTY_INFRACTION_MINUTES,
  PENALTY_MINUTES,
  PENALTY_MISSED_SHOTS,
  PENALTY_SHOTS,
  PLUS_MINUS,
  PLUS_MINUS_SR,
  POINTS,
  POINTS_PER_GAME,
  POINTS_SR,
  POINTS_SR_EVEN_STRENGTH,
  POINTS_SR_PER_GAME_EVEN_STRENGTH,
  POINTS_SR_POWERPLAY,
  POINTS_SR_PER_GAME_POWERPLAY,
  POINTS_SR_SHORTHANDED,
  POINTS_SR_PER_GAME_SHORTHANDED,
  POWER_PLAY_GOALS,
  SAVE_PERCENTAGE,
  SHOOTING_PCT,
  SHOOTOUT_GOALS,
  SHOOTOUT_MISSED_SHOTS,
  SHOOTOUT_SHOTS,
  SHOTS,
  SHOTS_EVEN_STRENGTH,
  SHOTS_POWERPLAY,
  SHOTS_SHORTHANDED,
  STARTER_GAMES,
  TAKEAWAYS,
  TIME_ON_ICE_AVG,
  TIME_ON_ICE_EVEN_STRENGTH,
  TIME_ON_ICE_POWER_PLAY,
  TIME_ON_ICE_SHIFTS,
  TIME_ON_ICE_SHORTHANDED,
  TIME_ON_ICE_TOTAL,
};

export const CONTRACT_DATA_FIELD_OPTIONS = [
  {
    label: 'Signing Team',
    value: DATA_FIELD_SIGNING_TEAM,
  },
  {
    label: 'Signing Date',
    value: DATA_FIELD_SIGNING_DATE,
  },
  {
    label: 'Sign. Status',
    value: DATA_FIELD_SIGNING_STATUS,
  },
  {
    label: 'Expir. Status',
    value: DATA_FIELD_EXPIRATION_STATUS,
  },
  {
    label: 'Platform Year',
    value: DATA_FIELD_PLATFORM_YEAR,
  },
  {
    label: 'Season Start',
    value: DATA_FIELD_SEASON_START,
  },
  {
    label: 'Arb. Rights at Sign.',
    value: DATA_FIELD_ARB_ELIGIBILITY,
  },
  {
    label: 'Qualifying Offer (QO)',
    value: DATA_FIELD_QO_VALUE,
  },
  {
    label: 'UFA Years Signed',
    value: UFA_YEARS,
  },
  {
    label: 'RFA Years Signed',
    value: RFA_YEARS,
  },
  {
    label: 'Contract Type',
    value: DATA_FIELD_CONTRACT_TYPE,
  },
];

export const PLAYER_DATA_FIELD_OPTIONS = [
  {
    label: 'Position',
    value: DATA_FIELD_POSITION,
  },
  {
    label: 'Current Team',
    value: DATA_FIELD_CURRENT_TEAM,
  },
  {
    label: 'Current Age',
    value: DATA_FIELD_CURRENT_AGE,
  },
];

export const CONTRACT_YEAR_YEAR_OPTIONS = [
  {
    label: 'Year 1',
    value: CONTRACT_YEAR_1,
  },
  {
    label: 'Year 2',
    value: CONTRACT_YEAR_2,
  },
  {
    label: 'Year 3',
    value: CONTRACT_YEAR_3,
  },
  {
    label: 'Year 4',
    value: CONTRACT_YEAR_4,
  },
  {
    label: 'Year 5',
    value: CONTRACT_YEAR_5,
  },
  {
    label: 'Year 6',
    value: CONTRACT_YEAR_6,
  },
  {
    label: 'Year 7',
    value: CONTRACT_YEAR_7,
  },
  {
    label: 'Year 8',
    value: CONTRACT_YEAR_8,
  },
  {
    label: 'CY No.',
    value: CONTRACT_YEAR,
  },
  {
    label: 'CY Status',
    value: CONTRACT_YEAR_STATUS,
  },
];

export const EXPECTED_GOALS_FIELD_OPTIONS = [
  {
    label: 'Expected Goals For',
    value: EXPECTED_GOALS_FOR,
  },
  {
    label: 'Actual Expected Goals For',
    value: ACTUAL_TO_EXPECT_GOALS_FOR,
  },
  {
    label: 'Expected Goals Against',
    value: EXPECTED_GOALS_AGAINST,
  },
  {
    label: 'Actual XG Against',
    value: ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST,
  },
];

export const PLAYER_TOTAL_CONTRACT_FIELD_OPTIONS = [
  {
    label: 'AAV',
    value: DATA_FIELD_AAV,
  },
  {
    label: 'Cap Hit',
    value: DATA_FIELD_CAP_HIT,
  },
  {
    label: 'Cap Hit (%)',
    value: DATA_FIELD_CAP_HIT_PER,
  },
  {
    label: 'Contract Length',
    value: DATA_FIELD_CONTRACT_LENGTH,
  },
  {
    label: 'Signing Age',
    value: DATA_FIELD_SIGNING_AGE,
  },
  {
    label: 'Total Salary',
    value: TOTAL_SALARY,
  },
  {
    label: 'Total Base Salary',
    value: BASE_SALARY,
  },
  {
    label: 'Total S. Bonuses',
    value: SIGNING_BONUS,
  },
  {
    label: 'Total P. Bonuses',
    value: PERFORMANCE_BONUS,
  },
  {
    label: 'Total Base Salary (%)',
    value: TOTAL_BASE_SALARY_PCT,
  },
  {
    label: 'Total Bonuses (%)',
    value: TOTAL_BONUS_PCT,
  },
  {
    label: 'Total S. Bonuses (%)',
    value: TOTAL_SIGNING_BONUS_PCT,
  },
  {
    label: 'Total P. Bonuses (%)',
    value: TOTAL_PERFORMANCE_BONUS_PCT,
  },
  {
    label: 'Total Minors Salary',
    value: MINORS_SALARY,
  },
];

export const PLAYER_TOTAL_STAT_FIELD_OPTIONS = [
  {
    label: 'Total - GP',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED,
  },
  {
    label: 'Total - G',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS,
  },
  {
    label: 'Total - A',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS,
  },
  {
    label: 'Total - PTS',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR,
  },
  {
    label: 'Total - G/PG',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME,
  },
  {
    label: 'Total - A/PG',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME,
  },
  {
    label: 'Total - P/PG',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME,
  },
  {
    label: 'Total - ATOI',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL,
  },
  {
    label: 'Total - TOI Shifts',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS,
  },
  {
    label: 'Total - FOs',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS,
  },
  {
    label: 'Total - FO Win(%)',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT,
  },
  {
    label: 'Total - FO Wins',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON,
  },
  {
    label: 'Total - FO Losses',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST,
  },
  {
    label: 'Total - Shooting(%)',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT,
  },
  {
    label: 'Total - Shots',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS,
  },
  {
    label: 'Total - PIMS',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES,
  },
  {
    label: 'Total - Hits',
    value: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS,
  },
];

export const PLATER_EV_STAT_FIELD_OPTIONS = [
  {
    label: 'EV - G',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH,
  },
  {
    label: 'EV - A',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH,
  },
  {
    label: 'EV - PTS',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH,
  },
  {
    label: 'EV - G/PG',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH,
  },
  {
    label: 'EV - A/PG',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH,
  },
  {
    label: 'EV - P/PG',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH,
  },
  {
    label: 'EV - ATOI',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
  },
  {
    label: 'EV - FOs',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH,
  },
  {
    label: 'EV - FO Win(%)',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH,
  },
  {
    label: 'EV - FO Wins',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH,
  },
  {
    label: 'EV - FO Losses',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH,
  },
  {
    label: 'EV - Shots',
    value: PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH,
  },
];

export const PLAYER_PP_STAT_FIELD_OPTIONS = [
  {
    label: 'PP - G',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY,
  },
  {
    label: 'PP - A',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY,
  },
  {
    label: 'PP - PTS',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY,
  },
  {
    label: 'PP - G/PG',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY,
  },
  {
    label: 'PP - A/PG',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY,
  },
  {
    label: 'PP - P/PG',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY,
  },
  {
    label: 'PP - ATOI',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY,
  },
  {
    label: 'PP - FO',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY,
  },
  {
    label: 'PP - FO Win(%)',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY,
  },
  {
    label: 'PP - FO Wins',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY,
  },
  {
    label: 'PP - FO Losses',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY,
  },
  {
    label: 'PP - Shots',
    value: PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY,
  },
];

export const PLAYER_SH_STAT_FIELD_OPTIONS = [
  {
    label: 'SH - G',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED,
  },
  {
    label: 'SH - A',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED,
  },
  {
    label: 'SH - PTS',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED,
  },
  {
    label: 'SH - G/PG',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED,
  },
  {
    label: 'SH - A/PG',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED,
  },
  {
    label: 'SH - P/PG',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED,
  },
  {
    label: 'SH - ATOI',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED,
  },
  {
    label: 'SH - FO',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED,
  },
  {
    label: 'SH - FO Win(%)',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED,
  },
  {
    label: 'SH - FO Wins',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED,
  },
  {
    label: 'SH - FO Losses',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED,
  },
  {
    label: 'SH - Shots',
    value: PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED,
  },
];
