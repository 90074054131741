import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { useMemo } from 'react';

interface TeamSummaryPanelItemProps {
  isDollarAmount: boolean;
  label: string;
  text: string | number | boolean;
}

function TeamSummaryPanelItem({ isDollarAmount, label, text }: TeamSummaryPanelItemProps): JSX.Element {
  const classes = useStyles();

  const renderedPanelItemText = useMemo(() => {
    if (typeof text !== 'number' && !text) return '--';

    return isDollarAmount ? <StanleyDollar amount={Number(text)} showNegativeOnly /> : text;
  }, [isDollarAmount, text]);

  return (
    <Box lineHeight={1.35} marginTop={1.5}>
      <Typography className={classes.summaryItem} noWrap variant="subtitle1">
        {label}
      </Typography>
      <Typography className={classes.summaryItem} variant="subtitle1">
        {renderedPanelItemText}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  summaryItem: {
    lineHeight: 'inherit',
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
}));

export default TeamSummaryPanelItem;
