/* eslint-disable max-len */
import {
  Container,
  Item,
  StanleyPrimaryButton,
  StanleySecondaryButton,
  StanleySelect,
} from '@project-stanley/cap-management-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectComparableContractsLists } from 'modules/players/playerComparableContracts/comparableContracts.selectors';

interface ComparableContractsDialogProps {
  isLoading: boolean;
  open: boolean;

  onClose: () => void;
  onSubmit: (value: string, actionType: CompSetActionTypes) => void;
}

export enum CompSetActionTypes {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

function ComparableContractsDialog({
  isLoading,
  open,
  onClose,
  onSubmit,
}: ComparableContractsDialogProps): JSX.Element {
  const savedCompLists = useSelector(selectComparableContractsLists);

  const [compSetName, setCompSetName] = useState('');
  const [selectedCompSetId, setSelectedCompSetId] = useState<string | null>(null);

  useEffect(() => {
    if (!open) setCompSetName('');
  }, [open]);

  const handleCompSetSelectChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      setCompSetName('');
      setSelectedCompSetId(target.value as string);
    },
    [setCompSetName, setSelectedCompSetId],
  );

  const handleCompSetNameChange = useCallback(
    (value: string) => {
      setSelectedCompSetId(null);
      setCompSetName(value);
    },
    [setCompSetName, setSelectedCompSetId],
  );

  const handleSubmit = useCallback(() => {
    if (selectedCompSetId) {
      onSubmit(selectedCompSetId, CompSetActionTypes.UPDATE);
      return;
    }

    onSubmit(compSetName, CompSetActionTypes.CREATE);
  }, [compSetName, selectedCompSetId, onSubmit]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} PaperProps={{ sx: { padding: 2 } }}>
      <DialogTitle sx={{ textAlign: 'center' }}>Create or Save as a List</DialogTitle>
      <DialogContent sx={{ paddingX: 1, marginBottom: 1 }}>
        <Container direction="column" spacing={2}>
          <Item>
            <TextField
              autoFocus
              disabled={isLoading}
              fullWidth
              margin="dense"
              placeholder="List Name"
              size="small"
              value={compSetName}
              variant="outlined"
              onChange={(e) => handleCompSetNameChange(e.target.value)}
            />
          </Item>
          {!isEmpty(savedCompLists) && (
            <>
              <Item>
                <Typography align="center">-OR-</Typography>
              </Item>
              <Item>
                <StanleySelect
                  disabled={isLoading}
                  displayEmpty
                  emptyText="Select Saved List"
                  fullWidth
                  options={savedCompLists.map((compSet) => ({
                    label: compSet.name,
                    value: compSet.id,
                  }))}
                  value={selectedCompSetId}
                  onChange={handleCompSetSelectChange}
                />
              </Item>
            </>
          )}
        </Container>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StanleySecondaryButton disabled={isLoading} variant="outlined" onClick={onClose}>
          Cancel
        </StanleySecondaryButton>
        <StanleyPrimaryButton
          disabled={(isEmpty(compSetName) && isNil(selectedCompSetId)) || isLoading}
          onClick={handleSubmit}
        >
          Save
        </StanleyPrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default ComparableContractsDialog;
