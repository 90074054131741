import makeStyles from '@mui/styles/makeStyles';
import { Container, Item, StanleySVGIcon, StanleyTransparentButton } from '@project-stanley/cap-management-components';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Colors from 'styles/colors';
import { ButtonSize } from 'types/buttons';
import { ICONS } from 'utilities/icons';
import { ROUTES } from 'utilities/routes';
import { Team } from 'types/teams';

interface TeamsDivisionProps {
  division: string;
  teams: Team[];
}

function TeamsDivision({ division, teams }: TeamsDivisionProps): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Typography className={classes.divisionTitle} align="center" variant="subtitle1">
        {division}
      </Typography>
      <Container>
        {teams.map(
          (team: Team): JSX.Element => (
            <Item key={team.teamId} className={classes.team} xs={6}>
              <StanleyTransparentButton
                className={classes.teamBtn}
                color="secondary"
                size={ButtonSize.SMALL}
                onClick={() => navigate(`${ROUTES.TEAMS}/${team.teamId}`)}
              >
                <>
                  <StanleySVGIcon height="2.5rem" imageSrc={ICONS[team.abrvName]} width="2.5rem" />
                  <Typography variant="subtitle1">{team.abrvName}</Typography>
                </>
              </StanleyTransparentButton>
            </Item>
          ),
        )}
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divisionTitle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  team: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  teamBtn: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    '&:hover': {
      backgroundColor: Colors.LIGHT_GRAY,
    },
  },
}));

export default TeamsDivision;
