/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { ContractStatus } from 'types/contract';
import { Player, PlayerPosition } from 'types/player';
import { TeamRosterOutlookByPosition, TeamRosterPositionDetails } from 'types/teams';
import { cloneDeep } from 'lodash';

export const ROSTER_CONTRACT_FILTER_KEYS: { [key: string]: string } = Object.freeze({
  DAILY_CAP_HIT: 'dailyCapHit',
  PROJ_CAP_HIT: 'projectedCapHit',
  PER_OF_PROJ_CAP: 'percentageOverallCap',
  ANNAUL_SALARY: 'annualSalary',
  AVERAGED_ANNUAL: 'averageAnnualValue',
  PERFORMANCE_BONUSES: 'performanceBonus',
  SIGNING_BONUSES: 'signingBonus',
});

export const ROSTER_CONTRACT_FILTER_MAP: { [key: string]: string } = Object.freeze({
  [ROSTER_CONTRACT_FILTER_KEYS.DAILY_CAP_HIT]: 'Daily Cap Hit',
  [ROSTER_CONTRACT_FILTER_KEYS.PROJ_CAP_HIT]: 'Projected Cap Hit',
  [ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP]: '% of Cap',
  [ROSTER_CONTRACT_FILTER_KEYS.ANNAUL_SALARY]: 'Annual Salary',
  [ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL]: 'AAV',
  [ROSTER_CONTRACT_FILTER_KEYS.PERFORMANCE_BONUSES]: 'Performance Bonuses',
  [ROSTER_CONTRACT_FILTER_KEYS.SIGNING_BONUSES]: 'Signing Bonuses',
});

export const ROSTER_PLAYER_FILTER_KEYS: { [key: string]: string } = Object.freeze({
  TOTAL_NHL_GAMES: 'totalGamesPlayed',
  GAMES_PLAYED: 'gamesPlayed',
  POINTS: 'points',
  GOALS: 'goals',
  ASSISTS: 'assists',
  PPG: 'pointsPerGame',
  HEIGHT: 'height',
  WEIGHT: 'weight',
});

export const ROSTER_CONTRACT_FILTER_OPTIONS = Object.freeze([
  {
    label: 'Daily Cap Hit',
    value: ROSTER_CONTRACT_FILTER_KEYS.DAILY_CAP_HIT,
  },
  {
    label: 'Projected Cap Hit',
    value: ROSTER_CONTRACT_FILTER_KEYS.PROJ_CAP_HIT,
  },
  {
    label: '% of Cap',
    value: ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP,
  },
  {
    label: 'Annual Salary',
    value: ROSTER_CONTRACT_FILTER_KEYS.ANNAUL_SALARY,
  },
  {
    label: 'AAV',
    value: ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL,
  },
  {
    label: 'Performance Bonuses',
    value: ROSTER_CONTRACT_FILTER_KEYS.PERFORMANCE_BONUSES,
  },
  {
    label: 'Signing Bonuses',
    value: ROSTER_CONTRACT_FILTER_KEYS.SIGNING_BONUSES,
  },
]);

export const ROSTER_PLAYER_FILTER_OPTIONS = Object.freeze([
  {
    label: 'Total NHL Games',
    value: ROSTER_PLAYER_FILTER_KEYS.TOTAL_NHL_GAMES,
  },
  {
    label: 'Games Played',
    value: ROSTER_PLAYER_FILTER_KEYS.GAMES_PLAYED,
  },
  {
    label: 'Points',
    value: ROSTER_PLAYER_FILTER_KEYS.POINTS,
  },
  {
    label: 'Goals',
    value: ROSTER_PLAYER_FILTER_KEYS.GOALS,
  },
  {
    label: 'Assists',
    value: ROSTER_PLAYER_FILTER_KEYS.ASSISTS,
  },
  {
    label: 'P/PG',
    value: ROSTER_PLAYER_FILTER_KEYS.PPG,
  },
  {
    label: 'Height',
    value: ROSTER_PLAYER_FILTER_KEYS.HEIGHT,
  },
  {
    label: 'Weight',
    value: ROSTER_PLAYER_FILTER_KEYS.WEIGHT,
  },
]);

export const ROSTER_FILTER_OPTIONS = [...ROSTER_CONTRACT_FILTER_OPTIONS, ...ROSTER_PLAYER_FILTER_OPTIONS];

export const isForwardPosition = (position: string): boolean =>
  position === PlayerPosition[1] || position === PlayerPosition[2] || position === PlayerPosition[3];

interface GetPositionDetailsArgs {
  contractYear: number | null;
  filterKey: string;
  players: Player[];
}

export const getPositionDetails = ({
  contractYear,
  filterKey,
  players,
}: GetPositionDetailsArgs): TeamRosterPositionDetails => {
  if (!players) return { detailAmount: 0, numOfPlayers: 0 };

  return players.reduce(
    (acc, cur) => {
      if (ROSTER_CONTRACT_FILTER_MAP[filterKey]) {
        acc.numOfPlayers += 1;

        if (contractYear) {
          const playerContract = cur.contractDetails?.find(
            (contract) => contract?.seasonInfo.seasonYearStart === contractYear,
          );

          if (playerContract) acc.detailAmount += playerContract[filterKey] as number;

          return acc;
        }

        const detailAmount =
          cur.currentContractDetail && cur.currentContractDetail[filterKey]
            ? acc.detailAmount + (cur.currentContractDetail[filterKey] as number)
            : acc.detailAmount;

        return { ...acc, detailAmount };
      }

      switch (filterKey) {
        case ROSTER_PLAYER_FILTER_KEYS.HEIGHT:
        case ROSTER_PLAYER_FILTER_KEYS.WEIGHT:
          return { ...acc, numOfPlayers: acc.numOfPlayers + 1 };

        case ROSTER_PLAYER_FILTER_KEYS.TOTAL_NHL_GAMES:
          return {
            detailAmount: cur.careerStats
              ? acc.detailAmount + cur.careerStats.regularSeasonStats.gamesPlayed
              : acc.detailAmount,
            numOfPlayers: acc.numOfPlayers + 1,
          };

        case ROSTER_PLAYER_FILTER_KEYS.POINTS:
          return {
            detailAmount:
              cur.currentSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats.assists &&
              cur.currentSeasonStats.regularSeasonStats.assists
                ? acc.detailAmount +
                  cur.currentSeasonStats.regularSeasonStats.assists +
                  cur.currentSeasonStats.regularSeasonStats.goals
                : acc.detailAmount,
            numOfPlayers: acc.numOfPlayers + 1,
          };

        default:
          return {
            detailAmount:
              cur.currentSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats &&
              cur.currentSeasonStats.regularSeasonStats[filterKey]
                ? acc.detailAmount + cur.currentSeasonStats.regularSeasonStats[filterKey]
                : acc.detailAmount,
            numOfPlayers: acc.numOfPlayers + 1,
          };
      }
    },
    { detailAmount: 0, numOfPlayers: 0 },
  );
};

export const mapRosterByPosition = (players: Player[]): TeamRosterOutlookByPosition =>
  players.reduce((acc: TeamRosterOutlookByPosition, cur) => {
    if (!cur.lineupInfo) {
      if (cur.status === ContractStatus.IR) {
        if (acc[PlayerPosition[8]]) acc[PlayerPosition[8]].push(cur);
        else acc[PlayerPosition[8]] = [cur];

        return acc;
      }

      if (cur.status === ContractStatus.LTIR) {
        if (acc[PlayerPosition[9]]) acc[PlayerPosition[9]].push(cur);
        else acc[PlayerPosition[9]] = [cur];

        return acc;
      }

      if (acc[PlayerPosition[7]]) acc[PlayerPosition[7]].push(cur);
      else acc[PlayerPosition[7]] = [cur];

      return acc;
    }

    if (acc[PlayerPosition[cur.lineupInfo.position]]) {
      acc[PlayerPosition[cur.lineupInfo.position]].push(cur);

      return acc;
    }

    acc[PlayerPosition[cur.lineupInfo.position]] = [cur];

    return acc;
  }, {});

export const sortMappedRosterByPosition = (
  rosterByPosition: TeamRosterOutlookByPosition,
  key: string,
): TeamRosterOutlookByPosition => {
  const sortedRosterByPosition: TeamRosterOutlookByPosition = { ...rosterByPosition };

  Object.keys(rosterByPosition).forEach((positionKey: string) => {
    if (positionKey !== PlayerPosition[7]) {
      sortedRosterByPosition[positionKey].sort((playerA, playerB) => {
        if (!playerA.lineupInfo || !playerA.lineupInfo.line || !playerB.lineupInfo || !playerB.lineupInfo.line) {
          return 0;
        }

        return playerA.lineupInfo.line - playerB.lineupInfo.line;
      });
    } else {
      sortedRosterByPosition[positionKey].sort((playerA, playerB) => {
        if (!playerA.currentContractDetail || !playerB.currentContractDetail) return 0;

        return (playerB.currentContractDetail[key] as number) - (playerA.currentContractDetail[key] as number);
      });
    }
  });

  return sortedRosterByPosition;
};

export const mapInactiveRosterByPosition = (players: Player[]): TeamRosterOutlookByPosition =>
  players.reduce((acc: TeamRosterOutlookByPosition, cur) => {
    if (acc[PlayerPosition[cur.primaryPosition]]) {
      acc[PlayerPosition[cur.primaryPosition]].push(cur);
      return acc;
    }

    acc[PlayerPosition[cur.primaryPosition]] = [cur];

    return acc;
  }, {});

export const sortMappedInactiveRosterByPosition = (
  rosterByPosition: TeamRosterOutlookByPosition,
  key: string,
): TeamRosterOutlookByPosition => {
  const sortedRosterByPosition: TeamRosterOutlookByPosition = { ...rosterByPosition };

  Object.keys(rosterByPosition).forEach((positionKey: string) => {
    sortedRosterByPosition[positionKey].sort((playerA, playerB) => {
      if (playerA.unsigned && playerB.unsigned) return 0;
      if (playerA.unsigned || playerB.unsigned) return playerA.unsigned ? 1 : -1;

      if (!playerA.currentContractDetail || !playerB.currentContractDetail) return 0;

      return (playerB.currentContractDetail[key] as number) - (playerA.currentContractDetail[key] as number);
    });
  });

  return sortedRosterByPosition;
};

export const mapRosterByPositionWithIds = (
  rosterByPosition: TeamRosterOutlookByPosition,
): { [key: string]: string[] } =>
  Object.keys(rosterByPosition).reduce((acc, position) => {
    acc[position] = rosterByPosition[position].map((player) => player.playerId.toString());
    return acc;
  }, {} as { [key: string]: string[] });

export const sortRosterByPositionByLocalStorage = (
  localRosterByPosition: { [key: string]: string[] },
  players: Player[],
): TeamRosterOutlookByPosition =>
  Object.keys(localRosterByPosition).reduce((acc, key) => {
    acc[key] = localRosterByPosition[key]
      .map((playerId) => {
        const player = players.find((rosterPlayer) => rosterPlayer.playerId.toString() === playerId);

        if (player) return player;

        return undefined;
      })
      .filter((rosterPlayer) => rosterPlayer !== undefined) as Player[];

    return acc;
  }, {} as TeamRosterOutlookByPosition);

export const moveRosterPlayer = (
  rosterDepthChart: TeamRosterOutlookByPosition,
  dndDetails: {
    destinationIndex: number;
    destinationPosition: string;
    sourceIndex: number;
    sourcePosition: string;
  },
): TeamRosterOutlookByPosition => {
  const updatedRosterDepthChart = cloneDeep(rosterDepthChart);

  let destinationPlayers = updatedRosterDepthChart[dndDetails.destinationPosition];

  if (!destinationPlayers) destinationPlayers = [];

  if (dndDetails.destinationPosition === dndDetails.sourcePosition) {
    const [removedPlayer] = destinationPlayers.splice(dndDetails.sourceIndex, 1);

    destinationPlayers.splice(dndDetails.destinationIndex, 0, removedPlayer);

    updatedRosterDepthChart[dndDetails.destinationPosition] = destinationPlayers;
    return updatedRosterDepthChart;
  }

  const sourcePlayers = updatedRosterDepthChart[dndDetails.sourcePosition];

  const [removedPlayer] = sourcePlayers.splice(dndDetails.sourceIndex, 1);
  destinationPlayers.splice(dndDetails.destinationIndex, 0, removedPlayer);

  updatedRosterDepthChart[dndDetails.destinationPosition] = destinationPlayers;

  return updatedRosterDepthChart;
};

export const generateTeamContractYears = (players: Player[], curSeasonStartYear: number): number[] =>
  players.reduce(
    (acc: number[], player): number[] => {
      if (!player.contractDetails) return acc;

      player.contractDetails.forEach((contract) => {
        if (!contract) return;

        if (
          !acc.includes(contract?.seasonInfo.seasonYearStart) &&
          contract?.seasonInfo.seasonYearStart > curSeasonStartYear
        ) {
          acc.push(contract?.seasonInfo.seasonYearStart);
        }
      });

      return acc;
    },
    [curSeasonStartYear],
  );

export const generateSortedRosterOutlook = (players: Player[]): Player[] => {
  let endForwardIndex = 0;

  players
    .sort((a, b) => (a.primaryPosition < b.primaryPosition ? -1 : 1))
    .forEach((a, index) => {
      if (a.primaryPosition === 4 && players[index === 0 ? 0 : index - 1].primaryPosition === 3) {
        endForwardIndex = index;
      }
    });

  const sortedForwards = players.slice(0, endForwardIndex).sort(sortRosterPosition);
  const sortedDefense = players.slice(endForwardIndex, players.length).sort(sortRosterPosition);

  return [...sortedForwards, ...sortedDefense];
};

export const filterRosterByContractYear = (roster: Player[], contractYear: number): Player[] =>
  roster.filter(
    (player) =>
      player.contractDetails === null ||
      player.contractDetails.some((contract) => contract?.seasonInfo.seasonYearStart === contractYear),
  );

export const sortRosterPosition = (a: Player, b: Player): number => {
  if (a.unsigned && b.unsigned) return 0;
  if (a.unsigned || b.unsigned) return a.unsigned ? 1 : -1;

  if (!a.currentContractDetail || !b.currentContractDetail) {
    return a.currentContractDetail ? 1 : -1;
  }

  return a.currentContractDetail.averageAnnualValue < b.currentContractDetail.averageAnnualValue ? 1 : -1;
};

export const mapFutureRoster = (roster: Player[], seasonStartYear: number) =>
  roster.map((player) => {
    const filteredContractPlayer = cloneDeep(player);

    if (!filteredContractPlayer.currentContractDetail) {
      if (filteredContractPlayer.contractDetails && filteredContractPlayer.contractDetails[0]) {
        const futureContractStartYear = filteredContractPlayer.contractDetails[0].seasonInfo.seasonYearStart;

        filteredContractPlayer.contractDetails.unshift(
          ...Array(futureContractStartYear - seasonStartYear).map(() => null),
        );
      }

      return filteredContractPlayer;
    }

    const currentContractYearIndex = filteredContractPlayer.contractDetails.findIndex(
      (contract) =>
        contract && contract.seasonInfo.seasonId === filteredContractPlayer.currentContractDetail.seasonInfo.seasonId,
    );

    filteredContractPlayer.contractDetails.splice(0, currentContractYearIndex);

    return filteredContractPlayer;
  });
