/* eslint-disable max-len */
import { isEmpty } from 'lodash';

import { Player } from 'types/player';

// eslint-disable-next-line max-len
export const rosterContractFilterSort =
  (contractIndex: number, filterKey: string) =>
  (rowA: Player, rowB: Player): number => {
    if (!contractIndex) {
      if (isEmpty(rowA.currentContractDetail)) return isEmpty(rowB.currentContractDetail) ? -1 : 1;
      if (isEmpty(rowB.currentContractDetail)) return isEmpty(rowA.currentContractDetail) ? 1 : -1;

      return (rowA.currentContractDetail[filterKey] as number) - (rowB.currentContractDetail[filterKey] as number);
    }

    const isRowADetailsEmpty =
      isEmpty(rowA.contractDetails) || (rowA.contractDetails && isEmpty(rowA.contractDetails[contractIndex]));
    const isRowBDetailsEmpty =
      isEmpty(rowB.contractDetails) || (rowB.contractDetails && isEmpty(rowB.contractDetails[contractIndex]));

    if (isRowADetailsEmpty) return isRowBDetailsEmpty ? -1 : 1;
    if (isRowBDetailsEmpty) return isRowADetailsEmpty ? 1 : -1;

    const contractDetailA = rowA.contractDetails[contractIndex]![filterKey];
    const contractDetailB = rowB.contractDetails[contractIndex]![filterKey];

    return (contractDetailA as number) - (contractDetailB as number);
  };

export const rosterContractTermsSort =
  (contractIndex: number) =>
  (rowA: Player, rowB: Player): number => {
    if (!contractIndex) {
      const isRowADetailsEmpty = isEmpty(rowA.currentContractDetail) || isEmpty(rowA.currentContractDetail.clauses);
      const isRowBDetailsEmpty = isEmpty(rowB.currentContractDetail) || isEmpty(rowB.currentContractDetail.clauses);

      if (isRowADetailsEmpty) return isRowBDetailsEmpty ? 1 : -1;
      if (isRowBDetailsEmpty) return isRowADetailsEmpty ? -1 : 1;

      return rowA.currentContractDetail.clauses[0] > rowB.currentContractDetail.clauses[0] ? 1 : -1;
    }

    const isRowADetailsEmpty =
      isEmpty(rowA.contractDetails) || (rowA.contractDetails && isEmpty(rowA.contractDetails[contractIndex]));
    const isRowBDetailsEmpty =
      isEmpty(rowB.contractDetails) || (rowB.contractDetails && isEmpty(rowB.contractDetails[contractIndex]));

    if (isRowADetailsEmpty) return isRowBDetailsEmpty ? 1 : -1;
    if (isRowBDetailsEmpty) return isRowADetailsEmpty ? -1 : 1;

    if (isEmpty(rowA.contractDetails[contractIndex]!.clauses)) {
      return rowB.contractDetails[contractIndex]!.clauses.length ? -1 : 1;
    }
    if (isEmpty(rowB.contractDetails[contractIndex]!.clauses)) {
      return rowA.contractDetails[contractIndex]!.clauses.length ? 1 : -1;
    }

    return rowA.contractDetails[contractIndex]!.clauses[0] > rowB.contractDetails[contractIndex]!.clauses[0] ? 1 : -1;
  };

export const sortByDate = (a: Date, b: Date) => a.getTime() - b.getTime();
