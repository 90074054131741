import { Button, Checkbox, ListSubheader, MenuItem, Typography, styled } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBoxOutlined } from '@mui/icons-material';
import { StanleySelectOption } from '@project-stanley/cap-management-components';
import { useCallback, useMemo } from 'react';

interface PlayerDataFieldsGroupProps {
  dataFields: (number | string)[];
  options: StanleySelectOption[];
  title: string;
  onChange: (value: string | number) => void;
  onSelectAll: (values: StanleySelectOption[], removeAll?: boolean) => void;
}

const StyledListSubHeader = styled(ListSubheader)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.black,
  display: 'flex',
  fontSize: '1rem',
  height: '2rem',
  position: 'unset',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  height: '1.25rem',
  paddingLeft: 0,
  '.icon': {
    marginRight: theme.spacing(0.75),
  },
}));

function PlayerDataFieldsGroup({ dataFields, options, title, onChange, onSelectAll }: PlayerDataFieldsGroupProps) {
  const selectedDataFields = useMemo(
    () => options.filter((option) => dataFields.indexOf(option.value) !== -1),
    [dataFields, options],
  );
  const unselectedDataFields = useMemo(
    () => options.filter((option) => dataFields.indexOf(option.value) === -1),
    [dataFields, options],
  );

  const renderedIcon = useMemo(() => {
    if (selectedDataFields.length === options.length) {
      return <CheckBox className="icon" color="primary" />;
    }

    return selectedDataFields.length === 0 ? (
      <CheckBoxOutlineBlank className="icon" color="secondary" />
    ) : (
      <IndeterminateCheckBoxOutlined className="icon" color="secondary" />
    );
  }, [selectedDataFields, options]);

  const handleSelectAll = useCallback(() => {
    if (selectedDataFields.length === options.length) {
      onSelectAll(selectedDataFields, true);
      return;
    }

    onSelectAll(unselectedDataFields);
  }, [selectedDataFields, unselectedDataFields, options, onSelectAll]);

  return (
    <>
      <StyledListSubHeader>
        <StyledIconButton onClick={handleSelectAll}>
          {renderedIcon}
          <Typography color="secondary" noWrap textTransform="none" variant="subtitle2">
            {title}
          </Typography>
        </StyledIconButton>
      </StyledListSubHeader>
      {options.map(({ label, value }) => (
        <StyledMenuItem key={`${label.toString()}-${value}`} value={value} onClick={() => onChange(value)}>
          <Checkbox
            sx={{
              padding: 0.5,
            }}
            checked={dataFields.indexOf(value) > -1}
          />
          {label}
        </StyledMenuItem>
      ))}
    </>
  );
}

export default PlayerDataFieldsGroup;
