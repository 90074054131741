/* eslint-disable no-confusing-arrow */
import { StanleyTable } from '@project-stanley/cap-management-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlayerSeasonStats, PlayerStats } from 'types/player';
import { TableSort } from 'types/table';
import { isEmpty, isNil } from 'lodash';
import { selectPlayersIsLoadingStats } from 'modules/players/players.selectors';

interface PlayerStatsTableProps {
  isGoalie: boolean;
  showPlayoffs?: boolean;
  stats: PlayerStats;
}

const renderPlayerStatColumn =
  (field: string, showPlayoffs: boolean) =>
  (rowData: PlayerSeasonStats): number | string => {
    if (showPlayoffs) {
      return rowData.postSeasonStats && !isNil(rowData.postSeasonStats[field]) ? rowData.postSeasonStats[field] : '--';
    }

    return rowData.regularSeasonStats && !isNil(rowData.regularSeasonStats[field])
      ? rowData.regularSeasonStats[field]
      : '--';
  };

const sortPlayerStatColumn =
  (field: string, showPlayoffs: boolean) => (rowA: PlayerSeasonStats, rowB: PlayerSeasonStats) => {
    if (!showPlayoffs) {
      const isRowAStatEmpty =
        isEmpty(rowA.regularSeasonStats) || (rowA.regularSeasonStats && isNil(rowA.regularSeasonStats[field]));
      const isRowBStatEmpty =
        isEmpty(rowB.regularSeasonStats) || (rowB.regularSeasonStats && isNil(rowB.regularSeasonStats[field]));

      if (isRowAStatEmpty) return isRowBStatEmpty ? -1 : 1;
      if (isRowBStatEmpty) return isRowAStatEmpty ? 1 : -1;

      const statA = rowA.regularSeasonStats[field];
      const statB = rowB.regularSeasonStats[field];

      return statA - statB;
    }

    const isRowAStatEmpty =
      isEmpty(rowA.regularSeasonStats) || (rowA.regularSeasonStats && isNil(rowA.regularSeasonStats[field]));
    const isRowBStatEmpty =
      isEmpty(rowB.regularSeasonStats) || (rowB.regularSeasonStats && isNil(rowB.regularSeasonStats[field]));

    if (isRowAStatEmpty) return isRowBStatEmpty ? -1 : 1;
    if (isRowBStatEmpty) return isRowAStatEmpty ? 1 : -1;

    const statA = rowA.postSeasonStats[field];
    const statB = rowB.postSeasonStats[field];

    return statA - statB;
  };

function PlayerStatsTable({ isGoalie, showPlayoffs = false, stats }: PlayerStatsTableProps): JSX.Element {
  const isLoadingStats = useSelector(selectPlayersIsLoadingStats);

  const playerColumns = useMemo(
    () => [
      {
        customSort: sortPlayerStatColumn('gamesPlayed', showPlayoffs),
        render: renderPlayerStatColumn('gamesPlayed', showPlayoffs),
        title: 'GP',
      },
      {
        customSort: sortPlayerStatColumn('goals', showPlayoffs),
        render: renderPlayerStatColumn('goals', showPlayoffs),
        title: 'G',
      },
      {
        customSort: sortPlayerStatColumn('assists', showPlayoffs),
        render: renderPlayerStatColumn('assists', showPlayoffs),
        title: 'A',
      },
      {
        customSort: sortPlayerStatColumn('points', showPlayoffs),
        render: renderPlayerStatColumn('points', showPlayoffs),
        title: 'P',
      },
      {
        customSort: sortPlayerStatColumn('pointsPerGame', showPlayoffs),
        render: renderPlayerStatColumn('pointsPerGame', showPlayoffs),
        title: 'P/PG',
      },
      {
        customSort: sortPlayerStatColumn('penaltyInfractionMinutes', showPlayoffs),
        render: renderPlayerStatColumn('penaltyInfractionMinutes', showPlayoffs),
        title: 'PIM',
      },
      {
        customSort: sortPlayerStatColumn('plusMinus', showPlayoffs),
        render: renderPlayerStatColumn('plusMinus', showPlayoffs),
        title: '+/-',
      },
    ],
    [showPlayoffs],
  );

  const goalieColumns = useMemo(
    () => [
      {
        customSort: sortPlayerStatColumn('gamesPlayed', showPlayoffs),
        render: renderPlayerStatColumn('gamesPlayed', showPlayoffs),
        title: 'GP',
      },
      {
        customSort: sortPlayerStatColumn('goalDifferential', showPlayoffs),
        render: renderPlayerStatColumn('goalDifferential', showPlayoffs),
        title: 'GD',
      },
      {
        customSort: sortPlayerStatColumn('goalsAgainstAverage', showPlayoffs),
        render: renderPlayerStatColumn('goalsAgainstAverage', showPlayoffs),
        title: 'GAA',
      },
      {
        customSort: sortPlayerStatColumn('savePercentage', showPlayoffs),
        render: renderPlayerStatColumn('savePercentage', showPlayoffs),
        title: 'SV%',
      },
      {
        customSort: sortPlayerStatColumn('goalsAgainst', showPlayoffs),
        render: renderPlayerStatColumn('goalsAgainst', showPlayoffs),
        title: 'GA',
      },
      {
        customSort: sortPlayerStatColumn('saves', showPlayoffs),
        render: renderPlayerStatColumn('saves', showPlayoffs),
        title: 'SV',
      },
      {
        customSort: sortPlayerStatColumn('shutouts', showPlayoffs),
        render: renderPlayerStatColumn('shutouts', showPlayoffs),
        title: 'SO',
      },
      {
        cellStyle: { minWidth: 85 },
        customSort: sortPlayerStatColumn('wlt', showPlayoffs),
        render: renderPlayerStatColumn('wlt', showPlayoffs),
        title: 'W-L-T',
      },

      {
        customSort: sortPlayerStatColumn('toi', showPlayoffs),
        render: renderPlayerStatColumn('toi', showPlayoffs),
        title: 'TOI',
      },
    ],
    [showPlayoffs],
  );

  const statColumns = useMemo(
    () => (isGoalie ? goalieColumns : playerColumns),
    [isGoalie, playerColumns, goalieColumns],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Season',
        cellStyle: { minWidth: 125 },
        defaultSort: TableSort.DESC,
        hasSortCellStyle: false,
        render: (rowData: PlayerSeasonStats): string =>
          `${rowData.season.seasonYearStart} - ${rowData.season.seasonYearEnd}`,
        customSort: (rowDataA: PlayerSeasonStats, rowDataB: PlayerSeasonStats): number =>
          rowDataA.season.seasonYearStart - rowDataB.season.seasonYearStart,
      },
      {
        title: 'Team',
        cellStyle: { minWidth: 225 },
        hasSortCellStyle: false,
        field: 'teamName',
      },
      {
        title: 'League',
        cellStyle: { minWidth: 150 },
        hasSortCellStyle: false,
        field: 'leagueName',
      },
      ...statColumns,
    ],
    [statColumns],
  );

  return <StanleyTable columns={columns} data={stats.stats} defaultSortIndex={0} isLoading={isLoadingStats} />;
}

export default PlayerStatsTable;
