import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Container, Item, StanleyPrimaryButton } from '@project-stanley/cap-management-components';

import Colors from 'styles/colors';
import { ButtonSize } from 'types/buttons';
import { TradePlayer } from 'types/trade';

interface TradeSimulatorPlayerAssetsHeaderProps {
  isPrinting: boolean;
  playerAssets: TradePlayer[];
  onAddPlayer: () => void;
  onRemovePlayer: () => void;
}

function TradeSimulatorPlayerAssetsHeader({
  isPrinting,
  playerAssets,
  onAddPlayer,
  onRemovePlayer,
}: TradeSimulatorPlayerAssetsHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container>
      <Item xs={isPrinting ? 6 : 12} sm={6}>
        <Container>
          <Item xs={isPrinting ? 4 : 12} sm={4}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2">Asset(s) Included:</Typography>
              <StanleyPrimaryButton
                className={classes.playerAssetButtons}
                disabled={playerAssets.length === 1}
                size={ButtonSize.SMALL}
                onClick={onRemovePlayer}
              >
                <Remove />
              </StanleyPrimaryButton>
              <StanleyPrimaryButton
                className={classes.playerAssetButtons}
                size={ButtonSize.SMALL}
                onClick={onAddPlayer}
              >
                <Add />
              </StanleyPrimaryButton>
            </Box>
          </Item>
          <Item xs={isPrinting ? 6 : 12} sm={6} />
          <Container item xs={isPrinting ? 2 : 12} sm={2} alignItems="center" justifyContent="center">
            <Typography variant="body2">NMC Waived</Typography>
          </Container>
        </Container>
      </Item>
      <Container item xs={isPrinting ? 6 : 12} sm={6} spacing={2}>
        <Container
          item
          xs={isPrinting ? 2 : 12}
          sm={2}
          alignItems="center"
          justifyContent="center"
          className={classes.retainedGrid}
        >
          <Typography variant="body2">Retained</Typography>
        </Container>
        <Item xs={isPrinting ? 5 : 12} sm={5} />
        <Item xs={isPrinting ? 5 : 12} sm={5} />
      </Container>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  playerAssetButtons: {
    backgroundColor: Colors.LIGHT_GRAY,
    color: theme.palette.secondary.main,
    height: '2rem',
    marginLeft: theme.spacing(1),
    minWidth: 'unset',
    width: '2rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  retainedGrid: {
    height: '100%',
  },
}));

export default TradeSimulatorPlayerAssetsHeader;
