import ordinal from 'ordinal';
import { PositionRoster, TeamSummaryItem } from 'types/teams';

const DASHBOARD = 0;
const RESEARCH = 1;
const TEAMS_SUMMARY = 2;

export const TEAMS_VIEW_OPTION_VALUES = {
  DASHBOARD,
  TEAMS_SUMMARY,
  RESEARCH,
};

export const TEAMS_VIEW_OPTION = [
  { label: 'Dashboard', value: TEAMS_VIEW_OPTION_VALUES.DASHBOARD },
  { label: 'Research', value: TEAMS_VIEW_OPTION_VALUES.RESEARCH },
  { label: 'Summary', value: TEAMS_VIEW_OPTION_VALUES.TEAMS_SUMMARY },
];

export const TEAM_SUMMARY_CAP_SUMMARY_ITEMS: TeamSummaryItem[] = [
  {
    description: 'Projected Cap Hit',
    isDollarAmount: true,
    key: 'projCapHit',
  },
  {
    description: 'Projected Cap Space',
    isDollarAmount: true,
    key: 'projCapSpace',
  },
  {
    description: 'Active Roster Cap Hit',
    isDollarAmount: true,
    key: 'activeRosterCapHit',
  },
  {
    description: 'Current Cap Space',
    isDollarAmount: true,
    key: 'currentCapSpace',
  },
  {
    description: 'Total LTIR Pool',
    isDollarAmount: true,
    key: 'maxLtirPool',
  },
];

export const TEAM_SUMMARY_SALARY_BONUS_SUMMARY_ITEMS: TeamSummaryItem[] = [
  {
    description: 'Active Roster Salaries',
    isDollarAmount: true,
    key: 'totalActiveRosterSalary',
  },
  {
    description: 'Total Signing Bonuses',
    isDollarAmount: true,
    key: 'totalSigningBonuses',
  },
  {
    description: 'Potential Perf. Bonuses',
    isDollarAmount: true,
    key: 'potentialPerformanceBonus',
  },
  {
    description: 'Carry Bonus Overage',
    isDollarAmount: true,
    key: 'bonusOverage',
  },
];

export const TEAM_SUMMARY_CONTRACT_ITEMS: TeamSummaryItem[] = [
  {
    description: 'Roster Size',
    key: 'numPlayersOnRoster',
  },
  {
    description: 'Contracts',
    key: 'numPlayersUnderContract',
  },
];

export const TEAMS_DRAFT_SELECTION_OPTIONS = [1, 2, 3, 4, 5, 6, 7].map((round) => ({
  label: `${ordinal(round)} Round`,
  value: round,
}));

export const generateTradeStatsOptions = (currentSeasonStartYear: number): { value: string; label: string }[] =>
  [currentSeasonStartYear, currentSeasonStartYear - 1, currentSeasonStartYear - 2].map((seasonStartYear) => ({
    label: `${seasonStartYear}-${seasonStartYear + 1}`,
    value: `${seasonStartYear}-${seasonStartYear + 1}`,
  }));

export const INITIAL_POSITION_ROSTER: PositionRoster = {
  players: [],
  positionDetails: {
    numOfPlayers: 0,
    detailAmount: 0,
  },
};

export const CAP_SUMMARY_COLUMN_MAP: { [key: number]: string } = {
  0: 'Roster Size',
  1: 'Projected Cap Hit',
  2: 'Active Roster Cap Hit',
  3: 'Active Roster Salaries',
  4: 'Projected Cap Space',
  5: 'Current Cap Space',
  6: 'Total LTIR Pool',
  7: 'Total Signing Bonuses',
  8: 'Potential Performance Bonuses',
  9: 'Carryover Bonus Overage',
};

export const CAP_SUMMARY_COLUMN_KEY_MAP: { [key: number]: string } = {
  0: 'numPlayersOnRoster',
  1: 'projCapHit',
  2: 'activeRosterCapHit',
  3: 'totalActiveRosterSalary',
  4: 'projCapSpace',
  5: 'currentCapSpace',
  6: 'maxLtirPool',
  7: 'totalSigningBonuses',
  8: 'potentialPerformanceBonus',
  9: 'bonusOverage',
};
