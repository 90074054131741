import makeStyles from '@mui/styles/makeStyles';
import {
  Container,
  Item,
  StanleyPrimaryButton,
  StanleySecondaryButton,
  StanleySelect,
} from '@project-stanley/cap-management-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getScenarios } from 'modules/scenarios/scenarios.slice';
import { selectScenarios, selectScenariosIsLoading } from 'modules/scenarios/scenarios.selectors';
import { useAppDispatch } from 'store';

interface ScenarioDialogProps {
  description?: string;
  open: boolean;
  title?: string;

  onClose: () => void;
  onSubmit: (scenario: string | number, scenarioActionType: ScenarioActionTypes) => void;
}

export enum ScenarioActionTypes {
  CREATE = 'CREATE',
  SELECT = 'SELECT',
}

function ScenarioDialog({ description, open, title, onClose, onSubmit }: ScenarioDialogProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectScenariosIsLoading);
  const scenarios = useSelector(selectScenarios);

  useEffect(() => {
    if (open) {
      dispatch(getScenarios());
    }
  }, [dispatch, open]);

  const [scenarioName, setScenarioName] = useState('');
  const [selectedScenarioId, setSelectedScenarioId] = useState<number | null>(null);

  useEffect(() => {
    if (!open) setScenarioName('');
  }, [open]);

  const handleScenarioSelectChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      setScenarioName('');
      setSelectedScenarioId(target.value as number);
    },
    [setScenarioName, setSelectedScenarioId],
  );

  const handleScenarioNameChange = useCallback(
    (value: string) => {
      setSelectedScenarioId(null);
      setScenarioName(value);
    },
    [setScenarioName, setSelectedScenarioId],
  );

  const handleSubmit = useCallback(() => {
    if (selectedScenarioId) {
      onSubmit(selectedScenarioId, ScenarioActionTypes.SELECT);
      return;
    }

    onSubmit(scenarioName, ScenarioActionTypes.CREATE);
  }, [scenarioName, selectedScenarioId, onSubmit]);

  const localTitle = useMemo(
    () => (isEmpty(scenarios) ? 'Create a Scenario' : 'Create or Select a Scenario'),
    [scenarios],
  );

  return (
    <Dialog classes={{ paper: classes.container }} fullWidth maxWidth="xs" open={open}>
      <DialogTitle className={classes.title}>{title || localTitle}</DialogTitle>
      <DialogContent className={classes.content}>
        <Container direction="column" spacing={2}>
          <Item>{description && <Typography align="center">{description}</Typography>}</Item>
          <Item>
            <TextField
              autoFocus
              disabled={isLoading}
              fullWidth
              margin="dense"
              placeholder="Scenario Name"
              size="small"
              value={scenarioName}
              variant="outlined"
              onChange={(e) => handleScenarioNameChange(e.target.value)}
            />
          </Item>
          {!isNil(scenarios) && !isEmpty(scenarios) && (
            <>
              <Item>
                <Typography align="center">-OR-</Typography>
              </Item>
              <Item>
                <StanleySelect
                  displayEmpty
                  emptyText="Select Scenario"
                  fullWidth
                  options={scenarios.map((scenario) => ({
                    label: scenario.name,
                    value: scenario.scenarioId,
                  }))}
                  selectClasses={{
                    select: classes.select,
                  }}
                  value={selectedScenarioId}
                  onChange={handleScenarioSelectChange}
                />
              </Item>
            </>
          )}
        </Container>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <StanleySecondaryButton disabled={isLoading} variant="outlined" onClick={onClose}>
          Cancel
        </StanleySecondaryButton>
        <StanleyPrimaryButton
          disabled={isLoading || (isEmpty(scenarioName) && isNil(selectedScenarioId))}
          onClick={handleSubmit}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </StanleyPrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    paddingBottom: `${theme.spacing(1)} !important`,
    paddingTop: `${theme.spacing(1)} !important`,
  },
}));

export default ScenarioDialog;
