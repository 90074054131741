import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { Container, Item, StanleyDollar } from '@project-stanley/cap-management-components';
import { useCallback, useMemo } from 'react';

import TradeSimulatorTeamSummaryItem from 'modules/tradeSimulator/tradeSimulatorTeamSummaryItem.component';
import { TeamSummary } from 'types/teams';

const TEAM_SUMMARY_FIELDS = {
  TEAM_ID: 'teamId',
  ACCUMULATED_CAP_HIT: 'accumulatedCapHit',
  ACTIVE_ROSTER_CAP_HIT: 'activeRosterCapHit',
  AVERAGE_AGE: 'averageAge',
  CURRENT_CAP_SPACE: 'currentCapSpace',
  EFFECTIVE_CAP_HIT: 'effectiveCapHit',
  MAX_LTIR_POOL: 'maxLtirPool',
  NAME: 'name',
  NUM_PLAYERS_ON_ROSTER: 'numPlayersOnRoster',
  NUM_PLAYERS_RETAINED: 'numPlayersRetained',
  NUM_PLAYERS_UNDER_CONTRACT: 'numPlayersUnderContract',
  POTENTIAL_PERFORMANCE_BONUS: 'potentialPerformanceBonus',
  PROJ_CAP_HIT: 'projCapHit',
  PROJ_CAP_SPACE: 'projCapSpace',
  REMAINING_CAP_HIT: 'remainingCapHit',
  RETAINED_CAP_HIT: 'retainedCapHit',
  RETAINED_SALARAY: 'retainedSalary',
  SALARY_EXPENDITURE: 'salaryExpenditure',
  TOTAL_ACTIVE_ROSTER_SALARY: 'totalActiveRosterSalary',
  TOTAL_SIGNING_BONUSES: 'totalSigningBonuses',
};

interface TradeSimulatorTeamSummaryProps {
  isCapAdjustment?: boolean;
  isPrinting: boolean;
  teamSummaryPreview?: TeamSummary | null;
  title: string;
}

function TradeSimulatorTeamSummary({
  isCapAdjustment = false,
  isPrinting,
  teamSummaryPreview,
  title,
}: TradeSimulatorTeamSummaryProps): JSX.Element {
  const classes = useStyles();

  const renderSummaryPreviewField = useCallback(
    (field: string, isDollar = false): number | string | JSX.Element => {
      if (!teamSummaryPreview || !teamSummaryPreview[field]) return '--';

      if (isDollar) return <StanleyDollar amount={teamSummaryPreview[field] as number} showColor />;

      return teamSummaryPreview[field] as number | string;
    },
    [teamSummaryPreview],
  );

  const renderSummaryRosterSizeField = useCallback(
    (field?: string): string | number => {
      if (!teamSummaryPreview || (field && !teamSummaryPreview[field])) return '--';

      if (field === TEAM_SUMMARY_FIELDS.NUM_PLAYERS_UNDER_CONTRACT) {
        return isCapAdjustment ? (teamSummaryPreview[field] as string) : `${teamSummaryPreview[field] as string} / 50`;
      }
      if (field === TEAM_SUMMARY_FIELDS.NUM_PLAYERS_ON_ROSTER) {
        return isCapAdjustment ? (teamSummaryPreview[field] as string) : `${teamSummaryPreview[field] as string} / 23`;
      }

      return `${
        (teamSummaryPreview[TEAM_SUMMARY_FIELDS.NUM_PLAYERS_UNDER_CONTRACT] as number) +
        (teamSummaryPreview[TEAM_SUMMARY_FIELDS.NUM_PLAYERS_ON_ROSTER] as number)
      } / 90`;
    },
    [isCapAdjustment, teamSummaryPreview],
  );

  const renderedUsinLtir = useMemo(() => {
    if (!teamSummaryPreview) return '--';

    return teamSummaryPreview.usingLtir ? 'YES' : 'NO';
  }, [teamSummaryPreview]);

  const ltirClassName = useMemo(() => {
    if (!teamSummaryPreview) return '';

    return teamSummaryPreview.usingLtir ? classes.positive : classes.negative;
  }, [classes, teamSummaryPreview]);

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      <Typography className={classes.title} align="center" variant="subtitle1">
        {title}
      </Typography>
      <Container className={classes.statsContainer}>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title={isCapAdjustment ? 'Effective Cap Hit' : 'Projected Cap Hit'}>
            {renderSummaryPreviewField(
              isCapAdjustment ? TEAM_SUMMARY_FIELDS.EFFECTIVE_CAP_HIT : TEAM_SUMMARY_FIELDS.PROJ_CAP_HIT,
              true,
            )}
          </TradeSimulatorTeamSummaryItem>
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          {isCapAdjustment ? (
            <TradeSimulatorTeamSummaryItem title="Accumulated Cap">
              {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.ACCUMULATED_CAP_HIT, true)}
            </TradeSimulatorTeamSummaryItem>
          ) : (
            <TradeSimulatorTeamSummaryItem labelClassname={ltirClassName} title="LTIR Activated">
              {renderedUsinLtir}
            </TradeSimulatorTeamSummaryItem>
          )}
        </Item>
      </Container>
      <Container className={classes.statsContainer}>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title="Projected Cap Space">
            {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.PROJ_CAP_SPACE, true)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title={isCapAdjustment ? 'Annual Salary Change' : 'Total LTIR Pool'}>
            {renderSummaryPreviewField(
              isCapAdjustment ? TEAM_SUMMARY_FIELDS.SALARY_EXPENDITURE : TEAM_SUMMARY_FIELDS.MAX_LTIR_POOL,
              true,
            )}
          </TradeSimulatorTeamSummaryItem>
        </Item>
      </Container>
      <Container className={classes.statsContainer}>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title="Current Cap Space">
            {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.CURRENT_CAP_SPACE, true)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title="Active Roster Cap Hit">
            {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.ACTIVE_ROSTER_CAP_HIT, true)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
      </Container>
      <Container className={classes.statsContainer}>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title="Retained Cap Hit">
            {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.RETAINED_CAP_HIT, true)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem title="Retained Salary">
            {renderSummaryPreviewField(TEAM_SUMMARY_FIELDS.RETAINED_SALARAY, true)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
      </Container>
      <Container>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem isDiff title="Roster Size">
            {renderSummaryRosterSizeField(TEAM_SUMMARY_FIELDS.NUM_PLAYERS_ON_ROSTER)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummaryItem isDiff title="Contracts">
            {renderSummaryRosterSizeField(TEAM_SUMMARY_FIELDS.NUM_PLAYERS_UNDER_CONTRACT)}
          </TradeSimulatorTeamSummaryItem>
        </Item>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
  statsContainer: {
    marginBottom: theme.spacing(1.5),
  },
  negative: {
    color: theme.palette.error.main,
  },
  positive: {
    color: 'green',
  },
}));

export default TradeSimulatorTeamSummary;
