/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { Add, Close, Remove, Send } from '@mui/icons-material';
import { AppBar, Box, Dialog, IconButton, Toolbar as MuiToolbar, Slide, Typography, styled } from '@mui/material';
import { RenderCurrentScaleProps, RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import { ToolbarSlot, toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { TransitionProps } from '@mui/material/transitions';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from 'modules/auth/loading.container';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { ROUTES } from 'utilities/routes';
import { Report } from 'types/research';
import { ToastTypes } from 'types/layout';
import { getReport } from 'modules/research/research.slice';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
`;

const StyledAdd = styled(Add)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledRemove = styled(Remove)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledMuiToolbar = styled(MuiToolbar)`
  justify-content: space-between;
  position: relative;
`;

const StyledActionsBox = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

function ResearchViewer() {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const width = useWidth();

  const copyToClipboard = useCopyToClipboard()[1];

  const [open, setOpen] = useState(true);
  const [report, setReport] = useState<Report | null>(null);

  useEffect(() => {
    if (report) {
      logGAEvent({
        category: GA_CATEGORIES.RESEARCH,
        action: report.reportType === 1 ? GA_ACTIONS.VIEW_LEAGUE_REPORT : GA_ACTIONS.VIEW_PLAYER_REPORT,
        label: report.title,
        value: report.reportId,
      });
    }
  }, [report]);

  useEffect(() => {
    (async () => {
      if (reportId) {
        const playerResponse = await dispatch(getReport(reportId));

        if (playerResponse.type === getReport.fulfilled.toString()) {
          setReport(playerResponse.payload as Report);
          return;
        }

        dispatch(
          showToast({
            toastMessage: 'Unable to find report.',
            toastType: ToastTypes.ERROR,
          }),
        );
      }
    })();
  }, [dispatch, reportId]);

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS, [width]);

  const handleClose = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      navigate(ROUTES.RESEARCH);
    }, 1000);
  }, [navigate]);

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(window.location.href);

    if (report) {
      logGAEvent({
        category: GA_CATEGORIES.RESEARCH,
        action: GA_ACTIONS.SHARE_REPORT,
        label: report.title,
        value: report.reportId,
      });
    }

    dispatch(
      showToast({
        toastMessage: 'Copied Successfully to Clipboard',
        toastType: ToastTypes.SUCCESS,
      }),
    );
  }, [dispatch, copyToClipboard, report]);

  return (
    <Dialog
      fullScreen
      open={open}
      PaperProps={{
        sx: { backgroundColor: '#2b2b2b', color: '#2b2b2b' },
      }}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
        <AppBar position="fixed" sx={{ backgroundColor: '#323639' }}>
          <StyledMuiToolbar sx={isSmallScreen ? { justifyContent: 'flex-end' } : {}}>
            {!isSmallScreen && (
              <Box maxWidth="20rem">
                <Typography
                  sx={{
                    ml: 2,
                    flex: 1,
                    fontWeight: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  variant="h6"
                  component="div"
                >
                  {report ? report.title : ''}
                </Typography>
              </Box>
            )}
            <Toolbar>
              {({ CurrentPageInput, CurrentScale, NumberOfPages, ZoomIn, ZoomOut }: ToolbarSlot) => (
                <StyledActionsBox>
                  <Box display="flex" alignItems="center">
                    <CurrentPageInput /> /
                    <Box sx={{ paddingLeft: '0.5rem' }}>
                      <NumberOfPages />
                    </Box>
                  </Box>
                  <Typography sx={{ display: 'inline', color: 'rgb(255, 255, 255)', paddingLeft: '1rem' }}>
                    |
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <ZoomOut>
                      {({ onClick }: RenderZoomOutProps) => (
                        <StyledIconButton onClick={onClick}>
                          <StyledRemove color="action" />
                        </StyledIconButton>
                      )}
                    </ZoomOut>
                    <CurrentScale>
                      {({ scale }: RenderCurrentScaleProps) => <span>{`${Math.round(scale * 100)}%`}</span>}
                    </CurrentScale>
                    <ZoomIn>
                      {({ onClick }: RenderZoomInProps) => (
                        <StyledIconButton onClick={onClick}>
                          <StyledAdd color="action" />
                        </StyledIconButton>
                      )}
                    </ZoomIn>
                  </Box>
                </StyledActionsBox>
              )}
            </Toolbar>
            <Box>
              <StyledIconButton color="inherit" onClick={handleCopyToClipboard}>
                <Send />
              </StyledIconButton>
              <StyledIconButton color="inherit" onClick={handleClose}>
                <Close />
              </StyledIconButton>
            </Box>
          </StyledMuiToolbar>
        </AppBar>
        <Box marginTop="5rem" marginBottom="1rem" overflow="hidden">
          {!report ? (
            <Box margin="auto">
              <Loading />
            </Box>
          ) : (
            <Viewer
              defaultScale={isSmallScreen ? 0.75 : 1.5}
              fileUrl={report.preSignedUrl}
              plugins={[toolbarPluginInstance]}
            />
          )}
        </Box>
      </Worker>
    </Dialog>
  );
}

export default ResearchViewer;
