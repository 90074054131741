/* eslint-disable no-confusing-arrow */
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Container, Item, StanleyDollar } from '@project-stanley/cap-management-components';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import RosterDroppablePositionList from 'modules/teams/rosterView/rosterDroppablePositionList.component';
import RosterPositionList from 'modules/teams/rosterView/rosterPositionList.component';
import classNames from 'classnames';
import { PLAYER_POSITIONS } from 'utilities/player';
import { Player, PlayerPosition } from 'types/player';
import {
  ROSTER_CONTRACT_FILTER_KEYS,
  ROSTER_CONTRACT_FILTER_MAP,
  ROSTER_PLAYER_FILTER_KEYS,
  getPositionDetails,
} from 'utilities/roster';
import { TeamRosterOutlookByPosition, TeamRosterPositionDetails } from 'types/teams';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectTeamContractYear, selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';

interface RosterDepthChartProps {
  isActiveRoster?: boolean;
  isDnD?: boolean;
  isLoading?: boolean;
  roster: TeamRosterOutlookByPosition | null;
}

function RosterDepthChart({ isActiveRoster, isDnD, isLoading, roster }: RosterDepthChartProps): JSX.Element | null {
  const classes = useStyles();

  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const isPrinting = useSelector(selectIsPrinting);
  const contractYear = useSelector(selectTeamContractYear);

  const positionGroupDetails = useMemo(() => {
    if (isNil(roster) || isEmpty(roster)) {
      return { defense: { detailAmount: 0, numOfPlayers: 0 }, forwards: { detailAmount: 0, numOfPlayers: 0 } };
    }

    const leftWings = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[1]],
    });
    const rightWings = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[2]],
    });
    const centers = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[3]],
    });
    const leftDefense = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[4]],
    });
    const rightDefense = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[5]],
    });
    const goalies = getPositionDetails({
      contractYear,
      filterKey: rosterStatFilter,
      players: roster[PlayerPosition[6]],
    });

    return {
      defense: {
        detailAmount: leftDefense.detailAmount + rightDefense.detailAmount + goalies.detailAmount,
        numOfPlayers: leftDefense.numOfPlayers + rightDefense.numOfPlayers + goalies.numOfPlayers,
      },
      forwards: {
        detailAmount: leftWings.detailAmount + rightWings.detailAmount + centers.detailAmount,
        numOfPlayers: leftWings.numOfPlayers + rightWings.numOfPlayers + centers.numOfPlayers,
      },
    };
  }, [contractYear, rosterStatFilter, roster]);

  const renderRosterPositionList = useCallback(
    (positionList: Player[], position: PlayerPosition | string, title: string) => (
      <Item xs={isPrinting ? 4 : 12} sm={6} md={4}>
        {isDnD ? (
          <RosterDroppablePositionList players={positionList} position={position} title={title} />
        ) : (
          <RosterPositionList players={positionList} title={title} />
        )}
      </Item>
    ),
    [isDnD, isPrinting],
  );

  const renderRosterPositionGroupHeader = useCallback(
    (positionTitle: string, positionDetails: TeamRosterPositionDetails) => {
      if (
        rosterStatFilter === ROSTER_PLAYER_FILTER_KEYS.HEIGHT ||
        rosterStatFilter === ROSTER_PLAYER_FILTER_KEYS.WEIGHT
      ) {
        return (
          <Typography className={classes.groupTitle} variant="h6">
            {`${isActiveRoster ? positionTitle : `Inactive ${positionTitle}`} (${positionDetails.numOfPlayers})`}
          </Typography>
        );
      }

      let stat;

      if (ROSTER_CONTRACT_FILTER_MAP[rosterStatFilter]) {
        stat =
          rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP ? (
            `${positionDetails.detailAmount.toFixed(2)} %`
          ) : (
            <StanleyDollar amount={positionDetails.detailAmount} />
          );
      } else if (rosterStatFilter === ROSTER_PLAYER_FILTER_KEYS.PPG) {
        stat = positionDetails.detailAmount.toFixed(2);
      } else {
        stat = positionDetails.detailAmount;
      }

      return (
        <Typography className={classes.groupTitle} variant="h6">
          {`${isActiveRoster ? positionTitle : `Inactive ${positionTitle}`} (${positionDetails.numOfPlayers}) - `}
          <span>{stat}</span>
        </Typography>
      );
    },
    [classes, isActiveRoster, rosterStatFilter],
  );

  if (isNil(roster)) return null;

  return (
    <Box className={classNames(isLoading !== undefined && classes.container)}>
      {renderRosterPositionGroupHeader('Forwards', positionGroupDetails.forwards)}
      <Container justifyContent="center" spacing={3}>
        {renderRosterPositionList(roster[PlayerPosition[1]] || [], PlayerPosition[1], 'Left Wing')}
        {renderRosterPositionList(roster[PlayerPosition[3]] || [], PlayerPosition[3], 'Center')}
        {renderRosterPositionList(roster[PlayerPosition[2]] || [], PlayerPosition[2], 'Right Wing')}
      </Container>
      {renderRosterPositionGroupHeader('Defense', positionGroupDetails.defense)}
      <Container justifyContent="center" spacing={3}>
        {renderRosterPositionList(roster[PlayerPosition[4]] || [], PlayerPosition[4], 'Left Defense')}
        {renderRosterPositionList(roster[PlayerPosition[5]] || [], PlayerPosition[5], 'Right Defense')}
        {renderRosterPositionList(roster[PlayerPosition[6]] || [], PlayerPosition[6], 'Goalie')}
      </Container>
      {isActiveRoster && (
        <>
          <Typography className={classes.groupTitle} variant="h6">
            Other Professionals
          </Typography>
          <Container justifyContent="center" spacing={3}>
            {renderRosterPositionList(roster[PlayerPosition[7]] || [], PlayerPosition[7], 'Scratched')}
            {renderRosterPositionList(roster[PLAYER_POSITIONS.IR] || [], PLAYER_POSITIONS.IR, 'Injured Reserve')}
            {renderRosterPositionList(
              roster[PLAYER_POSITIONS.LTIR] || [],
              PLAYER_POSITIONS.LTIR,
              'Long-Term Injured Reserve',
            )}
          </Container>
        </>
      )}
      {isLoading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  loadingContainer: {
    background: '#FFFFFFCC',
    bottom: 0,
    paddingTop: '10rem',
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  groupTitle: {
    fontWeight: 'unset',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default RosterDepthChart;
