import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Score } from 'types/scoreboard';
import { isAfter } from 'date-fns';

interface ScoreboardState {
  isLoading: boolean;
  scores: Score[] | null;
  scoreRequestTime: string | null;
}

const initialState: ScoreboardState = {
  isLoading: false,
  scores: null,
  scoreRequestTime: null,
};

export const getScores = createAsyncThunk('scores/getScores', async () => {
  const response = await api('games', { method: 'GET' }, true) as { scores: Score[] };

  return response;
});

export const scoreboardSlice = createSlice({
  name: 'scoreboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getScores.pending, (state) => {
        state.isLoading = true;
        state.scoreRequestTime = new Date().toISOString();
      })
      .addCase(getScores.fulfilled, (state, action: { payload: { scores: Score[] } }) => {
        state.isLoading = false;
        state.scores = action.payload.scores;

        if (action.payload.scores.some(({ gameDate }) => isAfter(new Date(gameDate), new Date()))) {
          state.scoreRequestTime = null;
        }
      })
      .addCase(getScores.rejected, (state) => {
        state.isLoading = false;
        state.scores = [];
      });
  },
});

export default scoreboardSlice.reducer;
