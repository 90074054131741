import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';

import { ICONS } from 'utilities/icons';

interface TeamSummaryPanelHeaderProps {
  season: string;
  teamAbbreviation: string;
  teamName: string;
}

function TeamSummaryPanelHeader({ season, teamAbbreviation, teamName }: TeamSummaryPanelHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box flex="1">
        <Typography className={classes.teamName} variant="h6">
          {teamName}
        </Typography>
        <Typography noWrap variant="subtitle1">{`Season: ${season}`}</Typography>
      </Box>
      <StanleySVGIcon height="2.5rem" imageSrc={ICONS[teamAbbreviation]} width="2.5rem" />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  teamName: {
    lineHeight: 1,
  },
}));

export default TeamSummaryPanelHeader;
