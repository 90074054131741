import makeStyles from '@mui/styles/makeStyles';
import { Add, Clear, Remove } from '@mui/icons-material';
import { Button, Collapse, IconButton, TextField, Typography, styled } from '@mui/material';
import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { Container, Item } from '@project-stanley/cap-management-components';

import StanleyTOIInput from 'components/fields/stanleyTOIInput.component';
import StanleyTableDollarEditInput from 'components/table/stanleyTableDollarEditInput.component';

interface MinMaxFilterProps {
  addCollapse?: boolean;
  isDollarFilter?: boolean;
  isLoading?: boolean;
  isTOIFilter?: boolean;
  maxValue: number | string;
  minValue: number | string;
  placeholders?: {
    maxPlaceholder: string;
    minPlaceholder: string;
  };
  title: string;
  onChangeDollarMax?: (value: number | string) => void;
  onChangeDollarMin?: (value: number | string) => void;
  onChangeMax?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeMin?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearMax?: () => void;
  onClearMin?: () => void;
}

const StyledIconButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.white,
  height: '1.25rem',
  paddingLeft: 0,
  '.icon': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '.25rem',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.75),
  },
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    '.icon': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
}));

function MinMaxFilter({
  addCollapse = false,
  isDollarFilter = false,
  isTOIFilter = false,
  isLoading,
  maxValue,
  minValue,
  placeholders,
  title,
  onChangeDollarMax,
  onChangeDollarMin,
  onChangeMax,
  onChangeMin,
  onClearMax,
  onClearMin,
}: MinMaxFilterProps): JSX.Element {
  const classes = useStyles();

  const [filtersOpen, setFiltersOpen] = useState(false);

  const renderedMinComponent = useMemo(() => {
    if (isDollarFilter && onChangeDollarMin) {
      return (
        <StanleyTableDollarEditInput
          disabled={isLoading}
          fullWidth
          inputProps={{
            className: classes.inputFilter,
          }}
          isClearable
          placeholder={placeholders?.minPlaceholder || '1,000,000'}
          value={minValue}
          variant="outlined"
          onChange={onChangeDollarMin}
          onClear={onChangeDollarMin ? () => onChangeDollarMin('') : undefined}
        />
      );
    }

    if (isTOIFilter) {
      return (
        <StanleyTOIInput
          disabled={Boolean(isLoading)}
          placeholder={placeholders?.minPlaceholder || 'MM:SS'}
          value={minValue as string}
          onChange={onChangeMin}
          onClear={onClearMin}
        />
      );
    }

    return (
      <TextField
        disabled={isLoading}
        fullWidth
        inputProps={{
          className: classes.inputFilter,
        }}
        InputProps={{
          endAdornment: (
            <IconButton sx={{ visibility: minValue ? 'visible' : 'hidden' }} size="small" onClick={onClearMin}>
              <Clear fontSize="small" />
            </IconButton>
          ),
        }}
        placeholder={placeholders?.minPlaceholder}
        sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0.375rem' } }}
        value={minValue}
        variant="outlined"
        onChange={onChangeMin}
      />
    );
  }, [
    classes,
    isDollarFilter,
    isLoading,
    isTOIFilter,
    minValue,
    placeholders,
    onChangeDollarMin,
    onChangeMin,
    onClearMin,
  ]);

  const renderedMaxComponent = useMemo(() => {
    if (isDollarFilter && onChangeDollarMax) {
      return (
        <StanleyTableDollarEditInput
          disabled={isLoading}
          fullWidth
          inputProps={{
            className: classes.inputFilter,
          }}
          isClearable
          placeholder={placeholders?.maxPlaceholder || '1,000,000'}
          value={maxValue}
          variant="outlined"
          onChange={onChangeDollarMax}
          onClear={onChangeDollarMax ? () => onChangeDollarMax('') : undefined}
        />
      );
    }

    if (isTOIFilter) {
      return (
        <StanleyTOIInput
          disabled={Boolean(isLoading)}
          placeholder={placeholders?.maxPlaceholder || 'MM:SS'}
          value={maxValue as string}
          onChange={onChangeMax}
          onClear={onClearMax}
        />
      );
    }

    return (
      <TextField
        disabled={isLoading}
        fullWidth
        inputProps={{
          className: classes.inputFilter,
        }}
        InputProps={{
          endAdornment: (
            <IconButton sx={{ visibility: maxValue ? 'visible' : 'hidden' }} size="small" onClick={onClearMax}>
              <Clear fontSize="small" />
            </IconButton>
          ),
        }}
        placeholder={placeholders?.maxPlaceholder}
        sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0.375rem' } }}
        value={maxValue}
        variant="outlined"
        onChange={onChangeMax}
      />
    );
  }, [
    classes,
    isDollarFilter,
    isLoading,
    isTOIFilter,
    maxValue,
    placeholders,
    onChangeDollarMax,
    onChangeMax,
    onClearMax,
  ]);

  const MixMaxComponents = useMemo(
    (): ReactNode => (
      <>
        {!addCollapse && (
          <Typography color="secondary" textTransform="none" variant="body1">
            {title}
          </Typography>
        )}
        <Container className={classes.sectionContainer} direction="column" spacing={2}>
          <Container item className={classes.filterItem} alignItems="center">
            <Item xs={3}>
              <Typography variant="body1">Min</Typography>
            </Item>
            <Item xs={9}>{renderedMinComponent}</Item>
          </Container>
          <Container item className={classes.filterItem} alignItems="center">
            <Item xs={3}>
              <Typography variant="body1">Max</Typography>
            </Item>
            <Item xs={9}>{renderedMaxComponent}</Item>
          </Container>
        </Container>
      </>
    ),
    [classes, addCollapse, title, renderedMaxComponent, renderedMinComponent],
  );

  return (
    <>
      {addCollapse && (
        <StyledIconButton onClick={() => setFiltersOpen((open) => !open)}>
          {!filtersOpen ? (
            <Add className="icon" color="secondary" fontSize="small" />
          ) : (
            <Remove className="icon" color="secondary" fontSize="small" />
          )}
          <Typography color="secondary" textTransform="none" variant="body1">
            {title}
          </Typography>
        </StyledIconButton>
      )}
      {addCollapse ? <Collapse in={filtersOpen}>{MixMaxComponents}</Collapse> : MixMaxComponents}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    paddingTop: theme.spacing(2),
  },
  filterItem: {
    width: '100%',
  },
  inputFilter: {
    paddingBottom: theme.spacing(0.875),
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(0.875),
  },
}));

export default MinMaxFilter;
