import makeStyles from '@mui/styles/makeStyles';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import RosterDeadCapTable from 'modules/teams/rosterView/rosterDeadCapTable.component';
import RosterOutlookTable from 'modules/teams/rosterView/rosterOutlookTable.component';
import TeamProjectionTable from 'modules/teams/teamProjectionTable.component';
import useRosterBreakdown from 'hooks/roster/useRosterBreakdown';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { PositionRoster } from 'types/teams';
import { ROSTER_CONTRACT_FILTER_KEYS } from 'utilities/roster';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import {
  selectTeam,
  selectTeamDeadCapOutlook,
  selectTeamProjectedSummary,
  selectTeamRosterStatFilter,
} from 'modules/teams/teams.selectors';

interface RosterOutlookViewProps {
  showTerms: boolean;
}

function RosterOutlookView({ showTerms }: RosterOutlookViewProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const positionRosterBreakdown = useRosterBreakdown();

  const projectedTeamSummary = useSelector(selectTeamProjectedSummary);
  const deadCapOutlook = useSelector(selectTeamDeadCapOutlook);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);
  const team = useSelector(selectTeam);

  useEffect(() => {
    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAMS,
        action: GA_ACTIONS.TEAM_PROFILE_VIEW_OUTLOOK,
        label: team?.name,
        value: team?.teamId,
      });
    }
  }, [team]);

  const deadCapTotal = useMemo(
    () =>
      deadCapOutlook?.reduce((total, curItem) => {
        if (!curSeasonStartYear) return total;

        const seasonInfo = curItem.details.find(
          (season) => season.seasonId === `${curSeasonStartYear}-${curSeasonStartYear + 1}`,
        );

        if (!seasonInfo || !seasonInfo[rosterStatFilter]) return total;

        return total + (seasonInfo[rosterStatFilter] as number);
      }, 0) || 0,
    [curSeasonStartYear, deadCapOutlook, rosterStatFilter],
  );

  const renderPositionTitle = useCallback(
    (title: string, positionRoster: PositionRoster) => (
      <Typography className={classes.groupTitle} variant="h6">
        {`${title} (${positionRoster.positionDetails.numOfPlayers}) - `}
        {rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP ? (
          `${positionRoster.positionDetails.detailAmount.toFixed(2)} %`
        ) : (
          <StanleyDollar amount={positionRoster.positionDetails.detailAmount} />
        )}
      </Typography>
    ),
    [classes, rosterStatFilter],
  );

  return (
    <>
      <Box marginTop={theme.spacing(0.25)}>
        <Typography className={classes.groupTitle} variant="h6">
          Team Summary
        </Typography>
        <TeamProjectionTable projectedTeamSummary={projectedTeamSummary} />
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Forwards', positionRosterBreakdown.activeOffensiveRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.activeOffensiveRoster.players} showTerms={showTerms} />
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Defense', positionRosterBreakdown.activeDefensiveRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.activeDefensiveRoster.players} showTerms={showTerms} />
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Goalies', positionRosterBreakdown.activeGoaliesRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.activeGoaliesRoster.players} showTerms={showTerms} />
      </Box>
      {!isEmpty(deadCapOutlook) && (
        <Box marginTop={theme.spacing(0.25)}>
          <Typography className={classes.groupTitle} variant="h6">
            Dead&nbsp;Cap&nbsp;-&nbsp;
            {rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP ? (
              `${deadCapTotal.toFixed(2)} %`
            ) : (
              <StanleyDollar amount={deadCapTotal} />
            )}
          </Typography>
          <RosterDeadCapTable deadCapOutlook={deadCapOutlook} />
        </Box>
      )}
      <Box marginTop={theme.spacing(3)}>
        <Divider>
          <Typography className={classes.inactiveDivider} variant="h6">
            Inactive Roster
          </Typography>
        </Divider>
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Inactive Forwards', positionRosterBreakdown.inactiveOffensiveRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.inactiveOffensiveRoster.players} showTerms={showTerms} />
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Inactive Defense', positionRosterBreakdown.inactiveDefensiveRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.inactiveDefensiveRoster.players} showTerms={showTerms} />
      </Box>
      <Box marginTop={theme.spacing(0.25)}>
        {renderPositionTitle('Inactive Goalies', positionRosterBreakdown.inactiveGoaliesRoster)}
        <RosterOutlookTable roster={positionRosterBreakdown.inactiveGoaliesRoster.players} showTerms={showTerms} />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  groupTitle: {
    fontWeight: 'unset',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  inactiveDivider: {
    fontWeight: 'unset',
  },
}));

export default RosterOutlookView;
