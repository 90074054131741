import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Player } from 'types/player';
import { RootState } from 'store';

export interface RosterComparisonState {
  isLoading: boolean;
  [key: string]: any;
}

const initialState: RosterComparisonState = {
  isLoading: false,
};

export const getRosterComparisonTeamOutlook = createAsyncThunk(
  'teams/getRosterComparisonTeamOutlook',
  async (teamId: number, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const endpoint = scenario
      ? `teams/${teamId}/roster-outlook?scenarioId=${scenario.scenarioId}`
      : `teams/${teamId}/roster-outlook`;

    const response = (await api(endpoint, { method: 'GET' }, true)) as { players: Player[] };

    return response;
  },
);

export const rosterComparisonSlice = createSlice({
  name: 'rosterComparison',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRosterComparisonTeamOutlook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRosterComparisonTeamOutlook.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getRosterComparisonTeamOutlook.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default rosterComparisonSlice.reducer;
