import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from '@mui/icons-material';
import { Box } from '@mui/material';
import { StanleyPrimaryButton, StanleySecondaryButton } from '@project-stanley/cap-management-components';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import { ButtonSize } from 'types/buttons';
import { PlayerContractSimulation } from 'types/contract';
import {
  selectScenariosIsLoadingContract,
  selectScenariosIsLoadingPreview,
} from 'modules/scenarios/scenarios.selectors';

interface PlayerContractSimulatorActionsProps {
  contract: PlayerContractSimulation;
  disableComplete: boolean;
  onAddContractYear: () => void;
  onComplete: () => void;
  onPreview: () => void;
  onRemoveContractYear: () => void;
}

function PlayerContractSimulatorActions({
  contract,
  disableComplete,
  onAddContractYear,
  onComplete,
  onPreview,
  onRemoveContractYear,
}: PlayerContractSimulatorActionsProps): JSX.Element {
  const classes = useStyles();

  const isLoadingContract = useSelector(selectScenariosIsLoadingContract);
  const isLoadingPreview = useSelector(selectScenariosIsLoadingPreview);

  return (
    <Box>
      <StanleyPrimaryButton
        className={classes.seasonsButton}
        disabled={contract.contractDetails.length === 1}
        size={ButtonSize.SMALL}
        onClick={onRemoveContractYear}
      >
        <Remove />
      </StanleyPrimaryButton>
      <StanleyPrimaryButton className={classes.seasonsButton} size={ButtonSize.SMALL} onClick={onAddContractYear}>
        <Add />
      </StanleyPrimaryButton>
      <StanleySecondaryButton
        className={classes.actionButton}
        disabled={isLoadingPreview || isLoadingContract}
        size={ButtonSize.SMALL}
        onClick={onPreview}
      >
        Preview
      </StanleySecondaryButton>
      <StanleySecondaryButton
        className={classes.actionButton}
        disabled={disableComplete || isLoadingPreview || isLoadingContract}
        size={ButtonSize.SMALL}
        onClick={onComplete}
      >
        Complete
      </StanleySecondaryButton>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  seasonsButton: {
    backgroundColor: Colors.LIGHT_GRAY,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    minWidth: 'unset',
    width: '2.25rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  actionButton: {
    marginLeft: theme.spacing(1),
    width: '6rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

export default PlayerContractSimulatorActions;
