/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper, useTheme } from '@mui/material';
import { Container } from '@project-stanley/cap-management-components';
import { cloneDeep, isNil } from 'lodash';
import { differenceInDays, isValid, parseISO } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import ScenarioDialog, { ScenarioActionTypes } from 'modules/scenarios/scenarioDialog.component';
import TradeSimulatorHeader from 'modules/tradeSimulator/tradeSimulatorHeader.component';
import TradeSimulatorTeamForm from 'modules/tradeSimulator/tradeSimulatorTeamForm.component';
import classNames from 'classnames';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import {
  INITIAL_DRAFT_ASSET,
  INITIAL_PLAYER_ASSET,
  INITIAL_TRADE_TEAM,
  updateTradePlayerAssets,
} from 'utilities/trade';
import { ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { TradeSimulation, TradeTeam } from 'types/trade';
import {
  addScenarioTrade,
  createScenario,
  getScenario,
  getScenarioTradePreview,
} from 'modules/scenarios/scenarios.slice';
import { getTeams } from 'modules/teams/teams.slice';
import {
  selectDaysInSeason,
  selectDaysIntoSeason,
  selectSeasonEndDay,
  selectSeasonStartDay,
} from 'modules/season/season.selectors';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';
import { selectTeams, selectTeamsIsLoading } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

function TradeSimulator(): JSX.Element {
  const tradeRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isLoading = useSelector(selectTeamsIsLoading);
  const teams = useSelector(selectTeams);
  const scenario = useSelector(selectScenario);
  const isPrinting = useSelector(selectIsPrinting);
  const daysIntoSeason = useSelector(selectDaysIntoSeason);
  const totalDaysInSeason = useSelector(selectDaysInSeason);
  const seasonStartDate = useSelector(selectSeasonStartDay);
  const seasonEndDate = useSelector(selectSeasonEndDay);

  const [selectedTransactionDate, setSelectedTransactionDate] = useState<Date | null>(new Date());
  const [transactionDay, setTransactionDay] = useState<number | undefined>(daysIntoSeason);
  const [daysRemaining, setDaysRemaining] = useState<number | undefined>(
    totalDaysInSeason && daysIntoSeason ? totalDaysInSeason - daysIntoSeason : undefined,
  );

  const [hasPreview, setHasPreview] = useState(false);
  const [createScenarioDialogOpen, setCreateScenarioDialogOpen] = useState(false);
  const [trade, setTrade] = useState<TradeSimulation | null>(null);

  const [teamFrom, setTeamFrom] = useState(INITIAL_TRADE_TEAM);
  const [teamTo, setTeamTo] = useState({ ...INITIAL_TRADE_TEAM, teamId: 2 });

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  const handleDateChange = (date: Date | null, keyboardInputValue?: string) => {
    if (date !== selectedTransactionDate) setHasPreview(false);

    setSelectedTransactionDate(date);

    if (keyboardInputValue && !isValid(new Date(keyboardInputValue))) return;

    if (seasonStartDate && date && seasonEndDate) {
      setTransactionDay(-1 * differenceInDays(parseISO(seasonStartDate), date));
      setDaysRemaining(2 + differenceInDays(parseISO(seasonEndDate), date));
    }
  };

  const handleTeamFromAddPlayer = useCallback(() => {
    setTeamFrom({ ...teamFrom, playerAssets: [...teamFrom.playerAssets, INITIAL_PLAYER_ASSET] });
  }, [teamFrom, setTeamFrom]);

  const handleTeamFromRemovePlayer = useCallback(() => {
    const playerAssets = cloneDeep(teamFrom.playerAssets);

    playerAssets.pop();

    setTeamFrom({ ...teamFrom, playerAssets });
  }, [teamFrom, setTeamFrom]);

  const handleTeamToAddPlayer = useCallback(() => {
    setTeamTo({ ...teamTo, playerAssets: [...teamTo.playerAssets, INITIAL_PLAYER_ASSET] });
  }, [teamTo, setTeamTo]);

  const handleTeamToRemovePlayer = useCallback(() => {
    const playerAssets = cloneDeep(teamTo.playerAssets);

    playerAssets.pop();

    setTeamTo({ ...teamTo, playerAssets });
  }, [teamTo, setTeamTo]);

  const handleTeamFromAddDraft = useCallback(() => {
    setTeamFrom({ ...teamFrom, draftAssets: [...teamFrom.draftAssets, INITIAL_DRAFT_ASSET] });
  }, [teamFrom, setTeamFrom]);

  const handleTeamFromRemoveDraft = useCallback(() => {
    const draftAssets = cloneDeep(teamFrom.draftAssets);

    draftAssets.pop();

    setTeamFrom({ ...teamFrom, draftAssets });
  }, [teamFrom, setTeamFrom]);

  const handleTeamToAddDraft = useCallback(() => {
    setTeamTo({ ...teamTo, draftAssets: [...teamTo.draftAssets, INITIAL_DRAFT_ASSET] });
  }, [teamTo, setTeamTo]);

  const handleTeamToRemoveDraft = useCallback(() => {
    const draftAssets = cloneDeep(teamTo.draftAssets);

    draftAssets.pop();

    setTeamTo({ ...teamTo, draftAssets });
  }, [teamTo, setTeamTo]);

  const handleTeamFromChange = useCallback(
    (teamId: number | number[]) => {
      if (Array.isArray(teamId)) return;

      setTeamFrom({ ...teamFrom, teamId, playerAssets: [INITIAL_PLAYER_ASSET] });
      setHasPreview(false);
    },
    [teamFrom, setHasPreview, setTeamFrom],
  );

  const handleTeamToChange = useCallback(
    (teamId: number | number[]) => {
      if (Array.isArray(teamId)) return;

      setTeamTo({ ...teamTo, teamId, playerAssets: [INITIAL_PLAYER_ASSET] });
      setHasPreview(false);
    },
    [teamTo, setHasPreview, setTeamTo],
  );

  const handleTeamFromPlayerAssetChange = useCallback(
    (field: string, value: number | boolean, index: number) => {
      const playerAssets = updateTradePlayerAssets(teamFrom.playerAssets, field, value, index);

      setTeamFrom({ ...teamFrom, playerAssets });
      setHasPreview(false);
    },
    [teamFrom, setHasPreview, setTeamFrom],
  );

  const handleTeamToPlayerAssetChange = useCallback(
    (field: string, value: number | boolean, index: number) => {
      const playerAssets = updateTradePlayerAssets(teamTo.playerAssets, field, value, index);

      setTeamTo({ ...teamTo, playerAssets });
      setHasPreview(false);
    },
    [teamTo, setHasPreview, setTeamTo],
  );

  const handleTeamFromDraftAssetChange = useCallback(
    (field: string, value: number | boolean, index: number) => {
      const draftAsset = cloneDeep(teamFrom.draftAssets[index]);
      const draftAssets = cloneDeep(teamFrom.draftAssets);

      draftAsset[field] = value;
      draftAssets[index] = draftAsset;

      setTeamFrom({ ...teamFrom, draftAssets });
      setHasPreview(false);
    },
    [teamFrom, setHasPreview, setTeamFrom],
  );

  const handleTeamToDraftAssetChange = useCallback(
    (field: string, value: number | boolean, index: number) => {
      const draftAsset = cloneDeep(teamTo.draftAssets[index]);
      const draftAssets = cloneDeep(teamTo.draftAssets);

      draftAsset[field] = value;
      draftAssets[index] = draftAsset;

      setTeamTo({ ...teamTo, draftAssets });
      setHasPreview(false);
    },
    [teamTo, setHasPreview, setTeamTo],
  );

  const handleTeamFromDetailsChange = useCallback(
    (details: string) => {
      setTeamFrom({ ...teamFrom, details });
    },
    [teamFrom, setTeamFrom],
  );

  const handleTeamToDetailsChange = useCallback(
    (details: string) => {
      setTeamTo({ ...teamTo, details });
    },
    [teamTo, setTeamTo],
  );

  const toggleScenarioDialog = useCallback(
    () => setCreateScenarioDialogOpen(!createScenarioDialogOpen),
    [createScenarioDialogOpen, setCreateScenarioDialogOpen],
  );

  const mapFormsToTeamData = useCallback((): { teamFrom: TradeTeam; teamTo: TradeTeam } => {
    const updateTeamFrom = cloneDeep(teamFrom);
    const updateTeamTo = cloneDeep(teamTo);

    updateTeamFrom.playerAssets = updateTeamFrom.playerAssets.filter((playerAsset) => playerAsset.playerId);
    updateTeamFrom.draftAssets = updateTeamFrom.draftAssets.filter((draftAsset) => draftAsset.draftPickId);
    updateTeamTo.playerAssets = updateTeamTo.playerAssets.filter((playerAsset) => playerAsset.playerId);
    updateTeamTo.draftAssets = updateTeamTo.draftAssets.filter((draftAsset) => draftAsset.draftPickId);

    return { teamFrom: updateTeamFrom, teamTo: updateTeamTo };
  }, [teamFrom, teamTo]);

  const handleTradeSimulationPreview = useCallback(() => {
    if (!scenario) {
      toggleScenarioDialog();
    }

    (async () => {
      const teamFormData = mapFormsToTeamData();

      const response = await dispatch(
        getScenarioTradePreview({
          fifo: false,
          requiredPhysical: false,
          ...teamFormData,
          transactionDateUtc: selectedTransactionDate?.toISOString(),
        }),
      );

      if (response.type === getScenarioTradePreview.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Previewing your trade failed. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      setTrade(response.payload as TradeSimulation);
      setTeamFrom((response.payload as TradeSimulation).trade.teamFrom);
      setTeamTo((response.payload as TradeSimulation).trade.teamTo);
      setHasPreview(true);
    })();
  }, [dispatch, scenario, selectedTransactionDate, setHasPreview, mapFormsToTeamData, toggleScenarioDialog]);

  const handleTradeSimulationComplete = useCallback(() => {
    (async () => {
      const teamFormData = mapFormsToTeamData();
      const response = await dispatch(addScenarioTrade({ fifo: false, requiredPhysical: false, ...teamFormData }));

      if (response.type === addScenarioTrade.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Unable to complete your trade simulation. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      const teamFromArbv = teams && teams.find((team) => team.teamId === teamFormData.teamFrom.teamId)?.abrvName;
      const teamToArbv = teams && teams.find((team) => team.teamId === teamFormData.teamTo.teamId)?.abrvName;

      logGAEvent({
        category: GA_CATEGORIES.SCENARIOS,
        action: GA_ACTIONS.SCENARIO_TRADE_SIMULATED,
        label: `${teamFromArbv || ''} to ${teamToArbv || ''}`,
      });

      dispatch(
        showToast({
          toastMessage: 'Trade Successful.',
          toastType: ToastTypes.SUCCESS,
        }),
      );
      navigate(ROUTES.TEAMS);
    })();
  }, [dispatch, navigate, teams, mapFormsToTeamData]);

  const handleSubmitScenario = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (scenario: string | number, scenarioActionType: ScenarioActionTypes) => {
      (async () => {
        if (scenarioActionType === ScenarioActionTypes.SELECT) {
          await dispatch(getScenario(scenario as string));
          toggleScenarioDialog();
          handleTradeSimulationPreview();

          return;
        }
        const response = await dispatch(createScenario(scenario as string));

        if (response.type === createScenario.rejected.toString()) {
          dispatch(
            showToast({ toastMessage: 'There was an error creating the scenario', toastType: ToastTypes.ERROR }),
          );
          return;
        }

        await dispatch(getScenario((response.payload as string).toString()));

        toggleScenarioDialog();
        handleTradeSimulationPreview();
      })();
    },
    [dispatch, handleTradeSimulationPreview, toggleScenarioDialog],
  );

  const handleAfterPrint = useCallback(() => {
    const teamFromArbv = teams && teams.find((team) => team.teamId === teamFrom.teamId)?.abrvName;
    const teamToArbv = teams && teams.find((team) => team.teamId === teamTo.teamId)?.abrvName;

    logGAEvent({
      category: GA_CATEGORIES.SCENARIOS,
      action: GA_ACTIONS.REPORTING_TRADE_SIMULATOR,
      label: `${teamFromArbv || ''} to ${teamToArbv || ''}`,
    });
  }, [teams, teamFrom, teamTo]);

  if (isLoading || isNil(teams)) {
    return (
      <Container className={classes.container}>
        <Loading />
      </Container>
    );
  }

  return (
    <Box className={classes.container}>
      <Paper ref={tradeRef} className={classNames(classes.paper, !isPrinting && classes.noPrintPaper)} elevation={3}>
        <TradeSimulatorHeader
          disableComplete={!hasPreview}
          isPrinting={isPrinting}
          tradeRef={tradeRef}
          transactionDateInfo={{
            selectedTransactionDate,
            transactionDay,
            daysRemaining,
          }}
          onAfterPrint={handleAfterPrint}
          onComplete={handleTradeSimulationComplete}
          onDateChange={handleDateChange}
          onPreview={handleTradeSimulationPreview}
        />
        <Box marginTop={theme.spacing(2)} overflow="auto">
          <Box marginBottom={theme.spacing(2)}>
            <TradeSimulatorTeamForm
              otherTeamId={teamTo.teamId}
              teamSummaryCapAdj={trade ? trade.teamFromCapitalAdj : null}
              teamSummaryPreview={trade ? trade.teamFromPreview : null}
              tradeDetails={teamFrom}
              onAddDraft={handleTeamFromAddDraft}
              onAddPlayer={handleTeamFromAddPlayer}
              onDetailsChange={handleTeamFromDetailsChange}
              onDraftAssetChange={handleTeamFromDraftAssetChange}
              onPlayerAssetChange={handleTeamFromPlayerAssetChange}
              onRemoveDraft={handleTeamFromRemoveDraft}
              onRemovePlayer={handleTeamFromRemovePlayer}
              onTeamChange={handleTeamFromChange}
            />
          </Box>
          <Box>
            <TradeSimulatorTeamForm
              otherTeamId={teamFrom.teamId}
              teamSummaryCapAdj={trade ? trade.teamToCaptialAdj : null}
              teamSummaryPreview={trade ? trade.teamToPreview : null}
              tradeDetails={teamTo}
              onAddDraft={handleTeamToAddDraft}
              onAddPlayer={handleTeamToAddPlayer}
              onDetailsChange={handleTeamToDetailsChange}
              onDraftAssetChange={handleTeamToDraftAssetChange}
              onPlayerAssetChange={handleTeamToPlayerAssetChange}
              onRemoveDraft={handleTeamToRemoveDraft}
              onRemovePlayer={handleTeamToRemovePlayer}
              onTeamChange={handleTeamToChange}
            />
          </Box>
        </Box>
      </Paper>
      <ScenarioDialog
        description="You must be in scenario mode first. Please provide a name and submit or select an existing scenario."
        open={createScenarioDialogOpen}
        onClose={toggleScenarioDialog}
        onSubmit={handleSubmitScenario}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: theme.spacing(1),
    width: '100%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  noPrintPaper: {
    height: `calc(100% - ${theme.spacing(6)})`,
  },
}));

export default TradeSimulator;
