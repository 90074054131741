/* eslint-disable object-curly-newline */
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ScenarioSummaryTable, {
  ScenarioSummaryRow,
} from 'modules/scenarios/scenarioSummary/scenarioSummaryTable.component';
import ScenarioSummaryTeamCardHeader from 'modules/scenarios/scenarioSummary/scenarioSummaryTeamCardHeader.component';
import ordinal from 'ordinal';
import { PlayerContract } from 'types/contract';
import { ROUTES } from 'utilities/routes';
import {
  Scenario,
  ScenarioChangeReason,
  ScenarioSummaryStatusChange as ScenarioSummaryStatusChangeType,
  ScenarioSummaryTrade,
} from 'types/scenarios';
import { TradePlayerRetentionDetails } from 'types/trade';
import { selectTeams } from 'modules/teams/teams.selectors';
import { setScenario } from 'modules/scenarios/scenarios.slice';
import { useAppDispatch } from 'store';

interface ScenarioSummaryTeamCardProps {
  scenario: Scenario;
  teamId: string;
  teamScenarioSummary: (PlayerContract | ScenarioSummaryStatusChangeType | ScenarioSummaryTrade)[];
}

function ScenarioSummaryTeamCard({ scenario, teamId, teamScenarioSummary }: ScenarioSummaryTeamCardProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const teams = useSelector(selectTeams);

  const team = useMemo(() => teams?.find((teamsTeam) => teamsTeam.teamId === Number(teamId)), [teamId, teams]);

  const scenarioSummaryTableData: (ScenarioSummaryRow | ScenarioSummaryRow[])[] = useMemo(
    () =>
      teamScenarioSummary.map((scenarioSummary) => {
        /*
          3 Scenario Types
            * Contract
            * Player Status Change
            * Trade
        */
        if ('contractId' in scenarioSummary && 'playerInfo' in scenarioSummary && scenarioSummary.playerInfo) {
          return {
            actionDirection: 'add',
            playerId: scenarioSummary.playerInfo.playerId,
            scenarioText: `${scenarioSummary.playerInfo.firstName} ${scenarioSummary.playerInfo.lastName}`,
            scenarioType: 'Contract',
          };
        }

        if ('playerId' in scenarioSummary && !('contractId' in scenarioSummary)) {
          return {
            actionDirection: scenarioSummary.changeReason === ScenarioChangeReason.Reassign ? 'remove' : 'add',
            playerId: scenarioSummary.player.playerId,
            scenarioText: `${scenarioSummary.player.firstName} ${scenarioSummary.player.lastName}`,
            scenarioType: ScenarioChangeReason[scenarioSummary.changeReason],
          };
        }

        const { teamTo, teamFrom } = scenarioSummary as ScenarioSummaryTrade;
        const isTeamFrom = team && team.teamId === teamFrom.teamId;

        const tradeAssets: ScenarioSummaryRow[] = [];

        teamFrom.playerAssets.forEach(({ player }) => {
          tradeAssets.push({
            actionDirection: isTeamFrom ? 'remove' : 'add',
            playerId: (player as TradePlayerRetentionDetails).playerId,
            scenarioText: `${(player as TradePlayerRetentionDetails).firstName} ${
              (player as TradePlayerRetentionDetails).lastName
            }`,
            scenarioType: 'Trade',
          });
        });

        teamTo.playerAssets.forEach(({ player }) => {
          tradeAssets.push({
            actionDirection: isTeamFrom ? 'add' : 'remove',
            playerId: (player as TradePlayerRetentionDetails).playerId,
            scenarioText: `${(player as TradePlayerRetentionDetails).firstName} ${
              (player as TradePlayerRetentionDetails).lastName
            }`,
            scenarioType: 'Trade',
          });
        });

        teamFrom.draftAssets.forEach(({ draftPick: { originalTeam, round, year } }) => {
          tradeAssets.push({
            actionDirection: isTeamFrom ? 'remove' : 'add',
            scenarioText: `${year} ${ordinal(round)} Rd${
              team && team.teamId !== originalTeam.teamId ? ` ${originalTeam.abrvName}` : ''
            }`,
            scenarioType: 'Trade',
          });
        });

        teamTo.draftAssets.forEach(({ draftPick: { originalTeam, round, year } }) => {
          tradeAssets.push({
            actionDirection: isTeamFrom ? 'add' : 'remove',
            scenarioText: `${
              team && team.teamId !== originalTeam.teamId ? `${originalTeam.abrvName} - ` : ''
            }${year} ${ordinal(round)} Rd`,
            scenarioType: 'Trade',
          });
        });

        return tradeAssets;
      }),
    [team, teamScenarioSummary],
  );

  const handleTeamClick = useCallback(() => {
    dispatch(setScenario(scenario));

    navigate(`${ROUTES.TEAMS}/${teamId}?scenarioId=${scenario.scenarioId}`);
  }, [dispatch, navigate, scenario, teamId]);

  return (
    <Paper className={classes.paper}>
      {team && (
        <ScenarioSummaryTeamCardHeader
          teamAbbreviation={team.abrvName}
          teamName={team.name}
          onTeamClick={handleTeamClick}
        />
      )}
      <ScenarioSummaryTable summary={scenarioSummaryTableData.flat()} />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: `calc(100% - ${theme.spacing(3)})`,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

export default ScenarioSummaryTeamCard;
