/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  SetOptions,
  WhereFilterOp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { FirebaseData, FirebaseWriteType } from 'types/firebase';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const readFirestoreData = ({
  path,
  byUid = true,
  singleDoc = true,
  whereClause = undefined,
}: {
  path: string;
  byUid?: boolean;
  singleDoc?: boolean;
  whereClause?: {
    key: string;
    operator: WhereFilterOp;
    value: any;
  };
}): Promise<DocumentSnapshot | QuerySnapshot<DocumentData> | Error> => {
  try {
    const uid = auth.currentUser?.uid;

    if (uid && byUid) {
      return getDoc(doc(db, path, uid));
    }

    if (singleDoc) return getDoc(doc(db, path));
    if (whereClause) {
      const { key, operator, value } = whereClause;

      return getDocs(query(collection(db, path), where(key, operator, value === 'uid' ? uid : value)));
    }

    return getDocs(collection(db, path));
  } catch (e) {
    console.log('ERROR', e);
    return new Promise((_, reject) => {
      reject(new Error('NO UID'));
    });
  }
};

export const writeFirestoreDataByUid = async ({
  addOwner = false,
  byUid = true,
  data,
  options = { merge: true },
  path,
  type = FirebaseWriteType.SET,
}: {
  addOwner?: boolean;
  byUid?: boolean;
  data: FirebaseData;
  options?: SetOptions;
  path: string;
  type?: FirebaseWriteType;
}) => {
  const uid = auth.currentUser?.uid;
  let newData = { ...data };

  if (uid) {
    if (addOwner) {
      newData = {
        ...data,
        owner: uid,
      };
    }

    if (type === FirebaseWriteType.SET) {
      if (byUid) {
        return setDoc(doc(db, path, uid), newData, options || {});
      }

      return setDoc(doc(db, path), newData, options || {});
    }

    return addDoc(collection(db, path), newData);
  }

  return new Promise((_, reject) => {
    reject(new Error('NO UID'));
  });
};

export const deleteFirestoreData = async ({ path }: { path: string }) => deleteDoc(doc(db, path));
