import { Box, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { isAfter, isBefore } from 'date-fns';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import RecentInjuries from 'modules/teams/recentTransactions/recentInjuries.component';
import RecentSignings from 'modules/teams/recentTransactions/recentSignings.component';
import RecentTransactions from 'modules/teams/recentTransactions/recentTransactions.component';
import ResearchCard from 'modules/teams/dashboard/researchCard.component';
import StanleyNavTabs from 'components/stanleyNavTabs.component';
import { DASHBOARD_GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { FIREBASE_STORAGE_USER_KEYS } from 'constants/firebase';
import { RecentInjury, RecentSigning, RecentTransaction } from 'types/transactions';
import { Report } from 'types/research';
import { ToastTypes } from 'types/layout';
import { getReport } from 'modules/research/research.slice';
import { selectFirebaseUser } from 'modules/user/user.selectors';
import {
  selectRecentInjuries,
  selectRecentSignings,
  selectRecentTransactions,
} from 'modules/teams/recentTransactions/recentTransactions.selectors';
import { selectResearch } from 'modules/research/research.selectors';
import { setFirebaseUser } from 'modules/user/user.slice';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

function Dashboard(): JSX.Element {
  const dispatch = useAppDispatch();

  const reports = useSelector(selectResearch);
  const injuries = useSelector(selectRecentInjuries);
  const transactions = useSelector(selectRecentTransactions);
  const signings = useSelector(selectRecentSignings);
  const firebaseUser = useSelector(selectFirebaseUser);

  const [dailyIndex, setDailyIndex] = useState(0);
  const [hasInitialRender, setHasInitialRender] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    writeFirestoreDataByUid({ path: 'users', data: { lastInjuriesView: new Date().toISOString() } });
  }, []);

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      const newFirebaseUser = { ...firebaseUser };

      if (dailyIndex === 0) newFirebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_INJURIES_VIEW] = new Date().toISOString();

      switch (tabIndex) {
        case 0:
          newFirebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_INJURIES_VIEW] = new Date().toISOString();
          break;

        case 1:
          newFirebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_TRANSACTIONS_VIEW] = new Date().toISOString();
          break;

        default:
          newFirebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_SIGNINGS_VIEW] = new Date().toISOString();
      }

      writeFirestoreDataByUid({ path: 'users', data: newFirebaseUser });
      dispatch(setFirebaseUser(newFirebaseUser));

      setDailyIndex(tabIndex);
    },
    [dispatch, dailyIndex, firebaseUser],
  );

  const renderedDailyTable = useMemo(() => {
    if (hasInitialRender) {
      logGAEvent({
        category: GA_CATEGORIES.DASHBOARD,
        action: DASHBOARD_GA_ACTIONS[dailyIndex],
      });
    }

    setHasInitialRender(true);

    switch (dailyIndex) {
      case 0:
        return <RecentInjuries />;

      case 1:
        return <RecentTransactions />;

      default:
        return <RecentSignings />;
    }
  }, [dailyIndex, hasInitialRender]);

  const renderIcon = useCallback(
    (data: (RecentInjury | RecentSigning | RecentTransaction)[] | null) => {
      if (data === null || !firebaseUser) return '';

      const dataSinceLastLogin = data.filter((item) => {
        const itemDate = (item as RecentInjury)?.date || (item as RecentSigning)?.signingDate;

        if ('transaction' in item) {
          return (
            !firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_INJURIES_VIEW] ||
            isAfter(new Date(itemDate), new Date(firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_INJURIES_VIEW] as string))
          );
        }

        if ('type' in item) {
          return (
            !firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_TRANSACTIONS_VIEW] ||
            isAfter(
              new Date(itemDate),
              new Date(firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_TRANSACTIONS_VIEW] as string),
            )
          );
        }

        return (
          !firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_SIGNINGS_VIEW] ||
          isAfter(new Date(itemDate), new Date(firebaseUser[FIREBASE_STORAGE_USER_KEYS.LAST_SIGNINGS_VIEW] as string))
        );
      });

      if (dataSinceLastLogin.length === 0) return '';

      return (
        <Box height="100%" sx={{ float: 'right' }}>
          <Circle
            color="primary"
            sx={{
              fontSize: '.75rem',
            }}
          />
        </Box>
      );
    },
    [firebaseUser],
  );

  const handleDownload = useCallback(
    async (reportId: string) => {
      setIsDownloading(true);
      const reportResponse = await dispatch(getReport(reportId));
      setIsDownloading(false);

      if (reportResponse.type === getReport.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Download failed. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      const { filename, preSignedUrl } = reportResponse.payload as Report;

      saveAs(preSignedUrl, filename);
    },
    [dispatch],
  );

  if (!reports) return <Box />;

  return (
    <Container spacing={6}>
      <Item xs={12} md={6}>
        <Box display="flex" alignItems="center" height="3rem" paddingLeft={2} paddingBottom={2}>
          <Typography variant="subtitle2">Recent Reports</Typography>
        </Box>
        <Container spacing={4}>
          {[...reports]
            .sort((reportA, reportB) => {
              const createdAtRowA = new Date(reportA.createdAt);
              const createdAtRowB = new Date(reportB.createdAt);

              return isBefore(createdAtRowA, createdAtRowB) ? 1 : -1;
            })
            .slice(0, 6)
            .map((report) => (
              <Item key={report.reportId} xs={12} md={6} lg={4}>
                <ResearchCard isDownloading={isDownloading} report={report} onDownload={handleDownload} />
              </Item>
            ))}
        </Container>
      </Item>
      <Item xs={12} md={6}>
        <StanleyNavTabs
          minWidth="10rem"
          selectedTabIndex={dailyIndex}
          tabs={[
            {
              label: 'Injury Reserve',
              icon: renderIcon(injuries),
            },
            {
              label: 'Recall & Reassignments',
              icon: renderIcon(transactions),
            },
            {
              label: 'Contract Signings',
              icon: renderIcon(signings),
            },
          ]}
          onTabChange={handleTabChange}
        />
        <Box marginTop={2}>{renderedDailyTable}</Box>
      </Item>
    </Container>
  );
}

export default Dashboard;
