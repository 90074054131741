import { Box, Typography } from '@mui/material';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { makeStyles } from '@mui/styles';

import Colors from 'styles/colors';
import { CONTRACT_EXPIRY_STATUS_MAP } from 'utilities/contract';
import { ContractExpiryStatus } from 'types/contract';
import { Player } from 'types/player';
import { ROSTER_CONTRACT_FILTER_KEYS } from 'utilities/roster';

interface RosterContractColumnProps {
  contractIndex: number;
  rosterStatFilter: string;
  player: Player;
}

function RosterContractColumn({ contractIndex, rosterStatFilter, player }: RosterContractColumnProps): JSX.Element {
  const classes = useStyles();

  const { contractDetails } = player;

  if (!contractDetails) return <Box>--</Box>;

  if (contractIndex === contractDetails.length) {
    if (player.expiryStatus) {
      return (
        <Box>
          <Typography
            noWrap
            variant="caption"
            className={
              player.expiryStatus === ContractExpiryStatus.UNRESTRICTED_FREE_AGENT
                ? classes.lastYearExpiryUFA
                : classes.lastYearExpiryRFA
            }
          >
            {CONTRACT_EXPIRY_STATUS_MAP[player.expiryStatus]}
          </Typography>
        </Box>
      );
    }
    return <Box>--</Box>;
  }

  // Checking if contract info exists
  if (!contractDetails[contractIndex] || !contractDetails[contractIndex]![rosterStatFilter]) {
    return <Box>--</Box>;
  }

  if (rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP) {
    return (
      <Box>
        {(contractDetails[contractIndex]![rosterStatFilter] as number).toFixed(2)}
        &nbsp;%
      </Box>
    );
  }

  return <StanleyDollar amount={contractDetails[contractIndex]![rosterStatFilter] as number} />;
}

const useStyles = makeStyles((theme) => ({
  lastYearExpiryUFA: {
    backgroundColor: `${Colors.ERROR}22`,
    borderRadius: theme.spacing(1.25),
    color: Colors.ERROR,
    padding: '0.5rem 1rem',
  },
  lastYearExpiryRFA: {
    backgroundColor: `${Colors.BLUE}22`,
    borderRadius: theme.spacing(1.25),
    color: Colors.BLUE,
    padding: '0.5rem 1rem',
  },
}));

export default RosterContractColumn;
