import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { isNil } from 'lodash';

import RosterDepthChart from 'modules/teams/rosterView/rosterDepthChart.component';
import { TeamRosterOutlookByPosition } from 'types/teams';

interface RosterDnDDepthChartProps {
  isActiveRoster?: boolean;
  isLoading?: boolean;
  roster: TeamRosterOutlookByPosition | null;
  onDragEnd: (dropResult: DropResult, isActiveRoster?: boolean) => void;
}

function RosterDnDDepthChart({
  isActiveRoster,
  isLoading,
  roster,
  onDragEnd,
}: RosterDnDDepthChartProps): JSX.Element | null {
  if (isNil(roster)) return null;

  return (
    <DragDropContext onDragEnd={(dropResult: DropResult) => onDragEnd(dropResult, isActiveRoster)}>
      <RosterDepthChart isActiveRoster={isActiveRoster} isDnD isLoading={isLoading} roster={roster} />
    </DragDropContext>
  );
}

export default RosterDnDDepthChart;
