import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';

interface TradeSimulatorHeaderItemProps {
  content: JSX.Element | string;
  label: JSX.Element | string;
}

function TradeSimulatorHeaderItem({ content, label }: TradeSimulatorHeaderItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.label} variant="subtitle1">
        {label}
      </Typography>
      {content}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
  },
}));

export default TradeSimulatorHeaderItem;
