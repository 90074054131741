import { Box, Paper, styled } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import Trades from 'components/trades/trades.component';
import { TeamsTradeFilters } from 'types/teams';
import { getTrades } from 'modules/teams/teams.slice';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectSeasonEndDay } from 'modules/season/season.selectors';
import { selectTrades, selectTradesIsLoading } from 'modules/teams/teams.selectors';
import { useAppDispatch } from 'store';

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isPrinting',
})<{ isPrinting: boolean }>(({ isPrinting, theme }) => {
  const styles = {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  };

  if (isPrinting) return styles;

  return {
    ...styles,
    height: `calc(100% - ${theme.spacing(6)})`,
    overflowY: 'scroll',
  };
});

function TradesSearch(): JSX.Element {
  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectTradesIsLoading);
  const trades = useSelector(selectTrades);
  const seasonEndDate = useSelector(selectSeasonEndDay);
  const isPrinting = useSelector(selectIsPrinting);

  const [initialTradeSearch, setInitialTradeSearch] = useState<TeamsTradeFilters>({});

  useEffect(() => {
    if (seasonEndDate) {
      const seasonEnd = new Date(seasonEndDate);
      const startDate = seasonEnd;

      startDate.setFullYear(seasonEnd.getFullYear() - 4);

      const tradeSearch: TeamsTradeFilters = {
        startDate: startDate.toISOString(),
        endDate: new Date().toISOString(),
      };

      dispatch(getTrades(tradeSearch));

      setInitialTradeSearch(tradeSearch);
    }
  }, [dispatch, seasonEndDate]);

  if (isLoading || isNil(trades)) {
    return (
      <Box display="flex" height="100%" width="100%">
        <Loading />
      </Box>
    );
  }

  return (
    <StyledPaper elevation={3} isPrinting={isPrinting}>
      <Trades initialTradeSearch={initialTradeSearch} trades={trades} />
    </StyledPaper>
  );
}

export default TradesSearch;
