import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RecentSigning } from 'types/transactions';

export interface RecentTransactionsState {
  isLoadingSignings: boolean;
  isLoadingInjuries: boolean;
  isLoadingTransactions: boolean;
  recentSignings: RecentSigning[] | null;
  recentInjuries: RecentSigning[] | null;
  recentTransactions: RecentSigning[] | null;
}

const initialState: RecentTransactionsState = {
  isLoadingSignings: false,
  isLoadingInjuries: false,
  isLoadingTransactions: false,
  recentSignings: null,
  recentInjuries: null,
  recentTransactions: null,
};

export const getRecentSignings = createAsyncThunk('players/getRecentSignings', async () => {
  const response = (await api('players/recent-signings', { method: 'GET' }, true)) as RecentSigning[];

  return response;
});

export const getRecentInjuries = createAsyncThunk('players/getRecentInjuries', async () => {
  const response = (await api('players/injuries', { method: 'GET' }, true)) as RecentSigning[];

  return response;
});

export const getRecentTransactions = createAsyncThunk('players/getRecentTransactions', async () => {
  const response = (await api('players/transactions', { method: 'GET' }, true)) as RecentSigning[];

  return response;
});

export const recentTransactionsSlice = createSlice({
  name: 'recentTransactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecentSignings.pending, (state) => {
        state.isLoadingSignings = true;
      })
      .addCase(getRecentSignings.fulfilled, (state, { payload }) => {
        state.isLoadingSignings = false;
        state.recentSignings = payload;
      })
      .addCase(getRecentSignings.rejected, (state) => {
        state.isLoadingSignings = false;
        state.recentSignings = [];
      })
      .addCase(getRecentInjuries.pending, (state) => {
        state.isLoadingInjuries = true;
      })
      .addCase(getRecentInjuries.fulfilled, (state, { payload }) => {
        state.isLoadingInjuries = false;
        state.recentInjuries = payload;
      })
      .addCase(getRecentInjuries.rejected, (state) => {
        state.isLoadingInjuries = false;
        state.recentInjuries = [];
      })
      .addCase(getRecentTransactions.pending, (state) => {
        state.isLoadingTransactions = true;
      })
      .addCase(getRecentTransactions.fulfilled, (state, { payload }) => {
        state.isLoadingTransactions = false;
        state.recentTransactions = payload;
      })
      .addCase(getRecentTransactions.rejected, (state) => {
        state.isLoadingTransactions = false;
        state.recentTransactions = [];
      });
  },
});

export default recentTransactionsSlice.reducer;
