import { Box, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CONTRACT_DATA_FIELD_VALUES,
  CONTRACT_YEAR_DATA_FIELD_VALUES,
  EXPECTED_GOALS_DATA_FIELD_VALUES,
  PLAYER_DATA_FIELD_VALUES,
  PLAYER_EV_STATS_DATA_FIELD_VALUES,
  PLAYER_PP_STATS_DATA_FIELD_VALUES,
  PLAYER_SH_STATS_DATA_FIELD_VALUES,
  PLAYER_STATS_DATA_FIELD_VALUES,
  PLAYER_TOTAL_STATS_DATA_FIELD_VALUES,
} from 'constants/playerDataFields';
import { ContractYearStats } from 'types/contracts';
import { PlayerSearchResult } from 'types/playerSearch';
import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { TextAlign } from 'types/table';
import { getContractStats } from 'modules/contracts/contracts.slice';
import { mapContractYearResult } from 'utilities/playerSearch';
import { selectContracts } from 'modules/contracts/contracts.selectors';
import { useAppDispatch } from 'store';

interface PlayerSearchContractBreakdownProps {
  dataFields: (number | string)[];
  player: PlayerSearchResult;
}

function PlayerSearchContractBreakdown({ dataFields, player }: PlayerSearchContractBreakdownProps): JSX.Element {
  const dispatch = useAppDispatch();

  const playerContracts = useSelector(selectContracts);

  const [contractDetail, setContractDetail] = useState<ContractYearStats[] | null>(null);

  useEffect(() => {
    if (playerContracts[player.contractId]) {
      setContractDetail(mapContractYearResult(playerContracts[player.contractId]));
      return;
    }

    (async () => {
      const contractStatsRes = await dispatch(getContractStats(player.contractId.toString()));

      if (contractStatsRes.type === getContractStats.fulfilled.toString()) {
        setContractDetail(mapContractYearResult(contractStatsRes.payload as ContractYearStats[]));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    const newColumns: Record<string, any>[] = [
      {
        title: 'T',
        cellStyle: { minWidth: 20, width: 20 },
        headerStyle: { minWidth: 20, width: 20 },
        sorting: false,
        width: 24,
      },
      {
        title: 'T',
        cellStyle: { minWidth: 30, width: 30 },
        headerStyle: { minWidth: 30, width: 30 },
        sorting: false,
        width: 24,
      },
      {
        title: 'Player',
        cellStyle: { minWidth: 165, width: 165 },
        hasSortCellStyle: false,
        headerStyle: { minWidth: 165, width: 165 },
        width: 185,
      },
    ];

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_POSITION)) {
      newColumns.push({
        title: 'Position',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_TEAM)) {
      newColumns.push({
        title: 'Current Team',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_AGE)) {
      newColumns.push({
        title: 'Current Age',
        field: 'age',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_AGE)) {
      newColumns.push({
        title: 'Signing Age',
        field: 'signingAge',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_TEAM)) {
      newColumns.push({
        title: 'Signing Team',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_DATE)) {
      newColumns.push({
        title: 'Signing Date',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_STATUS)) {
      newColumns.push({
        title: 'Signed As',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_EXPIRATION_STATUS)) {
      newColumns.push({
        title: 'Expiry',
        cellStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
        headerStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_PLATFORM_YEAR)) {
      newColumns.push({
        title: 'Platform Year',
        cellStyle: { minWidth: 120, width: 120 },
        headerStyle: { minWidth: 120, width: 120 },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SEASON_START)) {
      newColumns.push({
        title: 'Season Start',
        cellStyle: { minWidth: 110, width: 110 },
        headerStyle: { minWidth: 110, width: 110 },
        field: 'seasonYear',
        render: (data: ContractYearStats) => `${data.seasonYear} - ${data.seasonYear + 1}`,
      });
    }

    if (dataFields.includes(CONTRACT_YEAR_DATA_FIELD_VALUES.CONTRACT_YEAR)) {
      newColumns.push({
        title: 'Contract Year (CY)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: 'contractYear',
      });
    }

    if (dataFields.includes(CONTRACT_YEAR_DATA_FIELD_VALUES.CONTRACT_YEAR_STATUS)) {
      newColumns.push({
        title: 'CY Status',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        field: 'cyStatus',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_ARB_ELIGIBILITY)) {
      newColumns.push({
        title: 'Arb. Rights',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_QO_VALUE)) {
      newColumns.push({
        title: 'QO',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_LENGTH)) {
      newColumns.push({
        title: 'Length',
        field: 'contractLength',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.UFA_YEARS)) {
      newColumns.push({
        title: 'UFA Years',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: 'ufaYears',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.RFA_YEARS)) {
      newColumns.push({
        title: 'RFA Years',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: 'rfaYears',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_TYPE)) {
      newColumns.push({
        title: 'Type',
        cellStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        headerStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_AAV)) {
      newColumns.push({
        title: 'AAV',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        render: ({ averageAnnualValue }: ContractYearStats) => <StanleyDollar amount={averageAnnualValue} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT)) {
      newColumns.push({
        title: 'Cap Hit',
        cellStyle: { minWidth: 80, width: 80, textAlign: TextAlign.center },
        headerStyle: { minWidth: 80, width: 80, textAlign: TextAlign.center },
        render: ({ capHit }: ContractYearStats) => (capHit ? <StanleyDollar amount={capHit} /> : '--'),
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT_PER)) {
      newColumns.push({
        title: 'Cap Hit(%)',
        cellStyle: { minWidth: 95, width: 95, textAlign: TextAlign.center },
        headerStyle: { minWidth: 95, width: 95, textAlign: TextAlign.center },
        // render: ({ capHitPercent }: ContractYearStats) =>
        //   capHitPercent ? `${(capHitPercent * 100).toFixed(2)}%` : '--',
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_SALARY)) {
      newColumns.push({
        title: 'Total Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ baseSalary, totalbonus }: ContractYearStats) => <StanleyDollar amount={baseSalary + totalbonus} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.BASE_SALARY)) {
      newColumns.push({
        title: 'Total Base Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ baseSalary }: ContractYearStats) => <StanleyDollar amount={baseSalary} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.SIGNING_BONUS)) {
      newColumns.push({
        title: 'Total S. Bonus',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ signingBonus }: ContractYearStats) => <StanleyDollar amount={signingBonus} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.PERFORMANCE_BONUS)) {
      newColumns.push({
        title: 'Total P. Bonus',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ performanceBonus }: ContractYearStats) => <StanleyDollar amount={performanceBonus} />,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_BASE_SALARY_PCT)) {
      newColumns.push({
        title: 'Total Base Salary (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ baseSalaryPct }: ContractYearStats) => `${baseSalaryPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_BONUS_PCT)) {
      newColumns.push({
        title: 'Total Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ bonusPct }: ContractYearStats) => `${bonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_SIGNING_BONUS_PCT)) {
      newColumns.push({
        title: 'Total S. Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ signingBonusPct }: ContractYearStats) => `${signingBonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.TOTAL_PERFORMANCE_BONUS_PCT)) {
      newColumns.push({
        title: 'Total P. Bonus (%)',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ performanceBonusPct }: ContractYearStats) => `${performanceBonusPct.toFixed(1)}%`,
      });
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.MINORS_SALARY)) {
      newColumns.push({
        title: 'Total Minors Salary',
        cellStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        headerStyle: { minWidth: 110, width: 110, textAlign: TextAlign.center },
        render: ({ minorsSalary }: ContractYearStats) => <StanleyDollar amount={minorsSalary} />,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED)) {
      newColumns.push({
        title: 'Total - GP',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS)) {
      newColumns.push({
        title: 'Total - G',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS)) {
      newColumns.push({
        title: 'Total - A',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR)) {
      newColumns.push({
        title: 'Total - PTS',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME)) {
      newColumns.push({
        title: 'Total - G/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - A/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - P/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL)) {
      newColumns.push({
        title: 'Total - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS)) {
      newColumns.push({
        title: 'Total - TOI Shifts',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS)) {
      newColumns.push({
        title: 'Total - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT)) {
      newColumns.push({
        title: 'Total - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON)) {
      newColumns.push({
        title: 'Total - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST)) {
      newColumns.push({
        title: 'Total - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT)) {
      newColumns.push({
        title: 'Total - Shooting(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS)) {
      newColumns.push({
        title: 'Total - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES)) {
      newColumns.push({
        title: 'Total - PIMS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES,
      });
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS)) {
      newColumns.push({
        title: 'Total - Hits',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY)) {
      newColumns.push({
        title: 'PP - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY)) {
      newColumns.push({
        title: 'PP - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED,
      });
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        field: PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_FOR)) {
      newColumns.push({
        title: 'Expected Goals For',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_FOR,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECT_GOALS_FOR)) {
      newColumns.push({
        title: 'Actual Expected Goals For',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECT_GOALS_FOR,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_AGAINST)) {
      newColumns.push({
        title: 'Expected Goals Against',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.EXPECTED_GOALS_AGAINST,
      });
    }

    if (dataFields.includes(EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST)) {
      newColumns.push({
        title: 'Actual Expected Goals Against',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        field: EXPECTED_GOALS_DATA_FIELD_VALUES.ACTUAL_TO_EXPECTED_GOALS_FOR_AGAINST,
      });
    }

    return newColumns;
  }, [dataFields]);

  if (contractDetail === null) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StanleyTable
      columns={columns}
      data={contractDetail.filter((data, index) => dataFields.includes(`year-${index + 1}`)) || []}
      isLoading={!contractDetail}
      shouldAlternateRows={false}
      shouldHaveHoverStyle={false}
      shouldMarkHeaderInvisible
    />
  );
}

export default PlayerSearchContractBreakdown;
