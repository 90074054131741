import { Navigate, Route, Routes } from 'react-router-dom';

import BlockNavigation from 'modules/layout/blockNavigation.component';
import Demo from 'modules/helpCenter/helpCenter.container';
import Player from 'modules/players/player.container';
import RosterComparison from 'modules/rosterComparison/rosterComparison.container';
import Scenarios from 'modules/scenarios/scenarios.container';
import Search from 'modules/advancedSearch/search.container';
import Team from 'modules/teams/team.container';
import Teams from 'modules/teams/teams.container';
import TradeSimulator from 'modules/tradeSimulator/tradeSimulator.container';
import { ROUTES } from 'utilities/routes';

function PrivateRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<Demo />} path={ROUTES.HELP_CENTER} />
      <Route element={<Teams />} path={`${ROUTES.LEAGUE}/*`} />
      <Route element={<Team />} path={`${ROUTES.TEAM}/*`} />
      <Route
        element={
          <BlockNavigation>
            <Player />
          </BlockNavigation>
        }
        path={`${ROUTES.PLAYER}/*`}
      />
      <Route element={<RosterComparison />} path={ROUTES.ROSTER_COMPARISON} />
      <Route element={<Search />} path={`${ROUTES.SEARCH}/*`} />
      <Route element={<Scenarios />} path={ROUTES.SCENARIOS} />
      <Route element={<TradeSimulator />} path={ROUTES.TRADE_SIMULATOR} />
      <Route path="*" element={<Navigate to={ROUTES.LEAGUE} />} />
    </Routes>
  );
}

export default PrivateRoutes;
