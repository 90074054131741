import { Container, styled } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import ResearchTable from 'modules/research/researchTable.component';
import ResearchViewer from 'modules/research/researchViewer.container';
import { selectResearch, selectResearchIsLoading } from 'modules/research/research.selectors';

import 'modules/research/research.css';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

function Research() {
  const reports = useSelector(selectResearch);
  const isLoading = useSelector(selectResearchIsLoading);

  if (isLoading) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <>
      <ResearchTable reports={reports || []} />
      <Routes>
        <Route element={<ResearchViewer />} path="/:reportId" />
      </Routes>
    </>
  );
}

export default Research;
