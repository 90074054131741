/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
/* eslint-disable function-paren-newline */
import MaterialTable from '@material-table/core';
import { Paper } from '@mui/material';
import { cloneDeep, eachRight, isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PlayerSearchTable from 'modules/advancedSearch/playerSearchTable.component';
import PlayerSearchToolbar from 'modules/advancedSearch/playerSearchToolbar.component';
import { CompSetActionTypes } from 'modules/players/playerComparableContracts/comparableContractsDialog.component';
import { DEFAULT_DATA_FIELD_OPTIONS } from 'constants/playerDataFields';
import { DocumentData, DocumentReference, FirestoreError } from 'firebase/firestore';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { FirebaseData, FirebaseWriteType } from 'types/firebase';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { PlayerSearchResult } from 'types/playerSearch';
import { SavedCompListItem } from 'types/comparableContracts';
import { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { ToastTypes } from 'types/layout';
import {
  addCompListItem,
  updateCompListItem,
} from 'modules/players/playerComparableContracts/comparableContracts.slice';
import { getAdvancedPlayerData } from 'modules/advancedSearch/search.slice';
import { getSeasons } from 'modules/season/season.slice';
import { getTeams } from 'modules/teams/teams.slice';
import { selectBlockNavigation } from 'modules/layout/layout.selectors';
import { selectComparableContractsLists } from 'modules/players/playerComparableContracts/comparableContracts.selectors';
import { selectPlayerSearchDataFields } from 'modules/user/user.selectors';
import { setBlockNavigation, showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

function PlayerSearch(): JSX.Element {
  const playersPaperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<MaterialTable<any>>();

  const dispatch = useAppDispatch();

  const blockNavigation = useSelector(selectBlockNavigation);
  const playerSearchDataFields = useSelector(selectPlayerSearchDataFields);
  const savedLists = useSelector(selectComparableContractsLists);

  const [playerResults, setPlayerResults] = useState<PlayerSearchResult[]>([]);
  const [dataFields, setDataFields] = useState<(number | string)[]>(
    playerSearchDataFields || DEFAULT_DATA_FIELD_OPTIONS,
  );
  const [selectedRows, setSelectedRows] = useState<(PlayerSearchResult & { id: number })[]>([]);
  const [selectedCompSet, setSelectedCompSet] = useState('');

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getSeasons());
  }, [dispatch]);

  const handleDataFieldsChange = useCallback(
    (newDataFields) => {
      if (isEqual(newDataFields, dataFields)) return;

      setDataFields([...(newDataFields as number[])]);

      dispatch(
        setBlockNavigation({
          ...blockNavigation,
          shouldBlockNavigation: true,
          actions: [
            ...blockNavigation.actions,
            {
              storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_DATA_FIELDS,
              data: newDataFields as number[],
            },
          ],
        }),
      );
    },
    [dispatch, blockNavigation, dataFields],
  );

  const handleSelectedListChange = useCallback(
    async (value: string, seasonYear: string) => {
      if (value === selectedCompSet) return;

      if (savedLists.length > 0 && value !== '') {
        const selectedSavedList = savedLists.find((list) => list.id === value);

        if (selectedSavedList) {
          const response = await dispatch(
            getAdvancedPlayerData({ playerIds: selectedSavedList.playerIds.join(','), seasonYear }),
          );

          if (response.type === getAdvancedPlayerData.rejected.toString()) {
            dispatch(
              showToast({
                toastMessage: 'Unable to get saved list data',
                toastType: ToastTypes.ERROR,
              }),
            );
            return;
          }

          setPlayerResults((response.payload as { matchingPlayerList: PlayerSearchResult[] }).matchingPlayerList);

          logGAEvent({
            category: GA_CATEGORIES.ADVANCED_SEARCH,
            action: GA_ACTIONS.CONTRACT_COMP_LIST_CHANGE,
            label: selectedSavedList.name,
            value,
          });
        }
      }

      setSelectedCompSet(value);
    },
    [dispatch, savedLists, selectedCompSet],
  );

  const handleSaveCompSet = useCallback(
    async (nameOrId: string, type: CompSetActionTypes, seasonYear: string) => {
      try {
        const data: FirebaseData = {
          playerIds: playerResults.reduce((acc, comp) => {
            acc.push(comp.playerInfo.playerId);

            return acc;
          }, [] as number[]),
        };

        if (type === CompSetActionTypes.CREATE) {
          data.name = nameOrId;
        }

        const response = (await writeFirestoreDataByUid({
          path: type === CompSetActionTypes.CREATE ? 'compLists' : `compLists/${nameOrId}`,
          data,
          byUid: type !== CompSetActionTypes.UPDATE,
          type: type === CompSetActionTypes.CREATE ? FirebaseWriteType.ADD : FirebaseWriteType.SET,
          addOwner: true,
        })) as DocumentReference<DocumentData>;

        if (type === CompSetActionTypes.CREATE) {
          dispatch(addCompListItem({ ...data, id: response.id } as SavedCompListItem));

          handleSelectedListChange(response.id, seasonYear);
        } else {
          dispatch(updateCompListItem({ ...data, id: nameOrId } as SavedCompListItem));

          handleSelectedListChange(nameOrId, seasonYear);
        }

        logGAEvent({
          category: GA_CATEGORIES.ADVANCED_SEARCH,
          action: GA_ACTIONS.SAVE_LIST,
          label: data.name as string,
          value: response.id,
        });
      } catch (error) {
        console.log('FIRESTORE ERROR: ', error as FirestoreError);
      }
    },
    [dispatch, playerResults, handleSelectedListChange],
  );

  const handlePlayerOptionSelected = useCallback(
    async (optionSelected: StanleyPlayerSearchOption | null, seasonYear: string) => {
      if (optionSelected) {
        const { label, value } = optionSelected;

        logGAEvent({
          category: GA_CATEGORIES.ADVANCED_SEARCH,
          action: GA_ACTIONS.ADD_PLAYER,
          label,
          value: Number(value),
        });

        const response = await dispatch(getAdvancedPlayerData({ playerIds: value, seasonYear }));

        if (response.type === getAdvancedPlayerData.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Unable to get player data',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }

        const { matchingPlayerList } = response.payload as {
          matchingPlayerList: PlayerSearchResult[];
        };

        if (matchingPlayerList.length > 0) {
          setPlayerResults((curPlayerResults) => [matchingPlayerList[0], ...curPlayerResults]);
        }
      }
    },
    [dispatch],
  );

  const handlePlayersRemoved = useCallback(() => {
    const newPlayerResults = cloneDeep(playerResults);

    eachRight(selectedRows, (playerResult) => newPlayerResults?.splice(playerResult.id, 1));

    (tableRef?.current as any)?.onAllSelected(false);

    setPlayerResults(newPlayerResults);

    logGAEvent({
      category: GA_CATEGORIES.ADVANCED_SEARCH,
      action: GA_ACTIONS.PLAYER_PROFILE_REMOVE_CONTRACT_COMPARABLE,
    });
  }, [playerResults, selectedRows]);

  return (
    <Paper
      ref={playersPaperRef}
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        paddingTop: 1,
        width: '100%',
        margin: '1rem',
        overflowY: 'scroll',
      }}
    >
      <PlayerSearchToolbar
        tableRef={tableRef}
        dataFields={dataFields}
        paperRef={playersPaperRef}
        selectedRows={selectedRows}
        selectedCompSet={selectedCompSet}
        onDataFieldsChange={handleDataFieldsChange}
        onRemovePlayers={handlePlayersRemoved}
        onOptionSelected={handlePlayerOptionSelected}
        onSaveCompSet={handleSaveCompSet}
        onSearch={setPlayerResults}
        onSelectCompSet={handleSelectedListChange}
      />
      <PlayerSearchTable
        tableRef={tableRef}
        dataFields={dataFields}
        players={playerResults}
        setSelectedRows={setSelectedRows}
      />
    </Paper>
  );
}

export default PlayerSearch;
