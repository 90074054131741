import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface PlayerBioPanelItemProps {
  label: string;
  value: string | number;
}

function PlayerBioPanelItem({ label, value }: PlayerBioPanelItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2">
      {label}
      &nbsp;
      <span className={classes.itemValue}>{value}</span>
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  itemValue: {
    fontWeight: 'normal',
  },
}));

export default PlayerBioPanelItem;
