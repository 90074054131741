import { Box, Checkbox, CircularProgress, SelectChangeEvent, Typography, styled } from '@mui/material';
import { Container, Item, StanleySVGIcon } from '@project-stanley/cap-management-components';
import { Virtuoso } from 'react-virtuoso';
import { cloneDeep } from 'lodash';
import { format, isBefore } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TradeTable from 'components/trades/tradeTable.component';
import TradesHeader from 'components/trades/tradesHeader.component';
import { ICONS } from 'utilities/icons';
import { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { TeamTrade, TeamsTradeFilters } from 'types/teams';
import { selectSeason } from 'modules/season/season.selectors';

interface TradesProps {
  initialTradeSearch?: TeamsTradeFilters;
  showRemove?: boolean;
  trades: TeamTrade[] | null;
  onRemoveTrades?: (trades: TeamTrade[]) => void;
  onSearchOptionSelected?: (optionSelected?: StanleyPlayerSearchOption | null) => void;
}

const StyledStanleySVGIcon = styled(StanleySVGIcon)({
  left: '50%',
  opacity: '0.1',
  position: 'absolute',
  top: '60%',
  transform: 'translate(-50%, -50%)',
});

function Trades({
  initialTradeSearch,
  showRemove,
  trades,
  onRemoveTrades,
  onSearchOptionSelected,
}: TradesProps): JSX.Element {
  const classes = useStyles();

  const season = useSelector(selectSeason);

  const [selectedSeason, setSelectedSeason] = useState<string>('');
  const [localTrades, setLocalTrades] = useState(trades);
  const [tradesToRemove, setTradesToRemove] = useState<number[]>([]);

  useEffect(() => {
    if (trades) setLocalTrades(trades);
  }, [trades]);

  useEffect(() => {
    if (isBefore(new Date(), new Date(season.seasonStartDate))) {
      setSelectedSeason(`${season.seasonYearStart - 1}-${season.seasonYearEnd - 1}`);
    } else {
      setSelectedSeason(`${season.seasonYearStart}-${season.seasonYearEnd}`);
    }
  }, [season]);

  const handleSeasonChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => setSelectedSeason(target.value as string),
    [setSelectedSeason],
  );

  const renderTradeBreakdowns = useCallback(
    (trade: TeamTrade) => {
      let rowsNeeded = 0;

      trade.breakdownByTeam.forEach((breakdown) => {
        rowsNeeded = Math.max([...breakdown.playersReceived, ...breakdown.draftPicksReceived].length, rowsNeeded);
      });

      return trade.breakdownByTeam.map((breakdown) => (
        <Item key={`${trade.tradeId}-${breakdown.teamInfo.teamId}`} className={classes.tradeTeam} xs={12} sm={6}>
          <TradeTable breakdown={breakdown} totalRows={rowsNeeded} selectedSeason={selectedSeason} />
          <StyledStanleySVGIcon height="6rem" imageSrc={ICONS[breakdown.teamInfo.abrvName]} width="6rem" />
        </Item>
      ));
    },
    [classes, selectedSeason],
  );

  const handleRemoveTrades = useCallback(() => {
    const newLocalTrades = cloneDeep(localTrades);

    if (!newLocalTrades) return;

    tradesToRemove.forEach((tradeId) => {
      const tradeIndex = newLocalTrades.findIndex((trade) => trade.tradeId === tradeId);

      if (tradeIndex !== -1) newLocalTrades.splice(tradeIndex, 1);
    });

    setTradesToRemove([]);

    if (onRemoveTrades) onRemoveTrades(newLocalTrades);
  }, [localTrades, tradesToRemove, onRemoveTrades]);

  const handleChangeTradesToRemove = useCallback((checked: boolean, tradeId: number) => {
    setTradesToRemove((prevTradesToRemove) => {
      if (checked) return [...prevTradesToRemove, tradeId];

      const tradeIndex = prevTradesToRemove.findIndex((curTradeId) => curTradeId === tradeId);

      if (tradeIndex !== -1) {
        const newTradesToRemove = cloneDeep(prevTradesToRemove);

        newTradesToRemove.splice(tradeIndex, 1);

        return newTradesToRemove;
      }

      return prevTradesToRemove;
    });
  }, []);

  const renderTradeItem = useCallback(
    (index: number) => {
      if (!localTrades) return null;

      const trade = localTrades[index];

      return (
        <Box key={trade.tradeId} marginBottom={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={showRemove ? 0.5 : 1}>
            <Typography variant="subtitle1">
              Transaction&nbsp;Date:&nbsp;
              {format(new Date(trade.transactionDate), 'MMM dd, yyyy')}
            </Typography>
            {showRemove && (
              <Checkbox
                checked={tradesToRemove.includes(trade.tradeId)}
                onChange={(_, checked) => handleChangeTradesToRemove(checked, trade.tradeId)}
              />
            )}
          </Box>
          <Container spacing={1}>{renderTradeBreakdowns(trade)}</Container>
        </Box>
      );
    },
    [localTrades, showRemove, tradesToRemove, handleChangeTradesToRemove, renderTradeBreakdowns],
  );

  const handleSelectAllTrades = useCallback(() => {
    if (!localTrades) {
      setLocalTrades([]);
      return;
    }

    const selectedTrades =
      localTrades.length === tradesToRemove.length ? [] : localTrades.map((trade) => trade.tradeId);

    setTradesToRemove(selectedTrades);
  }, [localTrades, tradesToRemove]);

  if (!localTrades) {
    return (
      <Box margin="3rem 0" textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TradesHeader
        initialTradeSearch={initialTradeSearch}
        numOfTrades={localTrades.length}
        numOfTradesToRemove={tradesToRemove.length}
        selectedSeason={selectedSeason}
        onRemoveTrades={handleRemoveTrades}
        onSearchOptionSelected={onSearchOptionSelected}
        onSeasonChange={handleSeasonChange}
        onSelectAllTrades={handleSelectAllTrades}
      />
      {localTrades.length === 0 ? (
        <Box margin="3rem 0" textAlign="center">
          <Typography>No trades found for this search criteria. Please check dates.</Typography>
        </Box>
      ) : (
        <Virtuoso style={{ height: '100vh' }} totalCount={localTrades.length} itemContent={renderTradeItem} />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  transactionDate: {
    marginBottom: theme.spacing(1),
  },
  tradeTeam: {
    position: 'relative',
  },
}));

export default Trades;
