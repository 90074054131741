import { Button, FormControl, TextField, styled } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Container, Item } from '@project-stanley/cap-management-components';

import Colors from 'styles/colors';
import { SignInUser } from 'types/auth';

interface SignInFormProps {
  isLoading: boolean;

  onSubmit: (signInUser: SignInUser) => void;
}

const StyledFormControl = styled(FormControl)({ width: '100%' });

const StyledTextField = styled(TextField)`
  background-color: ${Colors.BRAND_LIGHT_GOLD};
  border-radius: 1rem;
  & .MuiFilledInput-input {
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
  }
  & .MuiFilledInput-root {
    border-radius: 1rem;
    &:after,
    &:before,
    &:hover {
      border: none !important;
    }
  }
`;

function SignInForm({ isLoading, onSubmit }: SignInFormProps): JSX.Element {
  const [signInUser, setSignInUser] = useState<SignInUser>({ email: '', password: '' });

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => setSignInUser({ ...signInUser, [name]: value }),
    [signInUser, setSignInUser],
  );

  const handleSignIn = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit(signInUser);
    },
    [signInUser, onSubmit],
  );

  return (
    <form onSubmit={handleSignIn}>
      <Container direction="column" spacing={3}>
        <Item>
          <StyledFormControl>
            <StyledTextField
              disabled={isLoading}
              fullWidth
              name="email"
              placeholder="E-mail Address"
              value={signInUser.email}
              variant="filled"
              onChange={handleInputChange}
            />
          </StyledFormControl>
        </Item>
        <Item>
          <StyledFormControl>
            <StyledTextField
              disabled={isLoading}
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              value={signInUser.password}
              variant="filled"
              onChange={handleInputChange}
            />
          </StyledFormControl>
        </Item>
        <Item>
          <Button color="secondary" disabled={isLoading} size="medium" type="submit" variant="contained">
            Sign In
          </Button>
        </Item>
      </Container>
    </form>
  );
}

export default SignInForm;
