/* eslint-disable react/jsx-curly-newline */
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import { Container, Item, StanleyDollar, StanleySelect } from '@project-stanley/cap-management-components';
import { useCallback } from 'react';

import StanleyTableDollarEditInput from 'components/table/stanleyTableDollarEditInput.component';
import StanleyTablePercentageEditInput from 'components/table/stanleyTablePercentageEdit.component';
import { Player } from 'types/player';
import { TradePlayer } from 'types/trade';

export const PLAYER_ASSETS_FIELDS = {
  PLAYER_ID: 'playerId',
  WAIVE_NMC: 'waiveNmc',
  RETAINED: 'retained',
  PERCENT_RETAINED: 'percentRetained',
};

interface TradeSimulatorPlayerAssetItemProps {
  isPrinting: boolean;
  players: Player[];
  playerAsset: TradePlayer;
  playerAssetsSelected: TradePlayer[];
  onChange: (field: string, value: number | boolean) => void;
}

function TradeSimulatorPlayerAssetItem({
  isPrinting,
  players,
  playerAsset,
  playerAssetsSelected,
  onChange,
}: TradeSimulatorPlayerAssetItemProps): JSX.Element {
  const classes = useStyles();

  const renderPlayerLabel = useCallback((player: Player) => {
    if (!player.currentContractDetail) return `${player.firstName} ${player.lastName}`;

    return (
      <>
        {player.firstName}
        &nbsp;
        {player.lastName}
        &nbsp;-&nbsp;
        <StanleyDollar amount={player.currentContractDetail.averageAnnualValue} />
        &nbsp;AAV
      </>
    );
  }, []);

  return (
    <Container>
      <Item xs={isPrinting ? 6 : 12} sm={6}>
        <Container alignItems="center">
          <Item xs={isPrinting ? 4 : 12} sm={4}>
            Reserved
          </Item>
          <Item xs={isPrinting ? 6 : 12} sm={6}>
            <StanleySelect
              displayEmpty
              emptyText="Reserved Player"
              fullWidth
              options={players.map((player) => ({
                disabled:
                  playerAssetsSelected.some(
                    (playerAssetSelected) => playerAssetSelected.playerId === player.playerId,
                  ) && playerAsset.playerId !== player.playerId,
                label: renderPlayerLabel(player),
                value: player.playerId,
              }))}
              selectClasses={{ select: classes.select }}
              value={playerAsset.playerId}
              onChange={({ target }: SelectChangeEvent<unknown>) =>
                onChange(PLAYER_ASSETS_FIELDS.PLAYER_ID, target.value as number)
              }
            />
          </Item>
          <Container item xs={isPrinting ? 2 : 12} sm={2} justifyContent="center">
            <Checkbox
              checked={playerAsset.waiveNmc}
              color="primary"
              onChange={({ target }) => onChange(PLAYER_ASSETS_FIELDS.WAIVE_NMC, target.checked)}
            />
          </Container>
        </Container>
      </Item>
      <Item xs={isPrinting ? 6 : 12} sm={6}>
        <Container alignItems="center" spacing={2}>
          <Container item xs={isPrinting ? 2 : 12} sm={2} justifyContent="center">
            <Checkbox
              checked={Boolean(playerAsset.retentionDetails.percentRetained)}
              color="primary"
              onChange={({ target }) => onChange(PLAYER_ASSETS_FIELDS.RETAINED, target.checked)}
            />
          </Container>
          <Item xs={isPrinting ? 5 : 12} sm={5}>
            <StanleyTablePercentageEditInput
              fullWidth
              value={playerAsset.retentionDetails.percentRetained}
              variant="outlined"
              onChange={(value) => onChange(PLAYER_ASSETS_FIELDS.PERCENT_RETAINED, value)}
            />
          </Item>
          <Item xs={isPrinting ? 5 : 12} sm={5}>
            <StanleyTableDollarEditInput
              disabled
              fullWidth
              inputProps={{
                style: { paddingBottom: '6px', paddingTop: '6px' },
              }}
              value={Number(playerAsset.retentionDetails.amountRetained.toFixed(2))}
            />
          </Item>
        </Container>
      </Item>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: `${theme.spacing(0.75)} !important`,
    paddingTop: `${theme.spacing(0.75)} !important`,
  },
}));

export default TradeSimulatorPlayerAssetItem;
