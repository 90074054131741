import makeStyles from '@mui/styles/makeStyles';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { useCallback } from 'react';

import { Player } from 'types/player';
import { ROSTER_CONTRACT_FILTER_KEYS } from 'utilities/roster';

interface RosterOutlookTableSummaryProps {
  players: Player[];
  rosterStatFilter: string;
}

function RosterOutlookTableSummary({ players, rosterStatFilter }: RosterOutlookTableSummaryProps): JSX.Element {
  const classes = useStyles();

  const calcRosterStatTotal = useCallback(
    (yearIndex: number) =>
      players.reduce((acc: number, cur: Player) => {
        if (cur.contractDetails && cur.contractDetails[yearIndex]) {
          return acc + ((cur.contractDetails[yearIndex]![rosterStatFilter] as number) || 0);
        }

        return acc;
      }, 0),
    [players, rosterStatFilter],
  );

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography variant="subtitle2">Total</Typography>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        {rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP ? (
          <>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(0).toFixed(2)} %`}</TableCell>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(1).toFixed(2)} %`}</TableCell>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(2).toFixed(2)} %`}</TableCell>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(3).toFixed(2)} %`}</TableCell>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(4).toFixed(2)} %`}</TableCell>
            <TableCell className={classes.tableCell}>{`${calcRosterStatTotal(5).toFixed(2)} %`}</TableCell>
          </>
        ) : (
          <>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(0)} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(1)} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(2)} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(3)} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(4)} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StanleyDollar amount={calcRosterStatTotal(5)} />
            </TableCell>
          </>
        )}
      </TableRow>
    </TableFooter>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    padding: theme.spacing(1.25),
  },
}));

export default RosterOutlookTableSummary;
