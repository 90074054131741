import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { Tab, Tabs } from '@mui/material';

interface StanleyTab {
  label: string;
  icon?: ReactElement | string;
  iconPosition?: 'end' | 'start' | 'top' | 'bottom';
}

interface StanleyNavTabsProps {
  minWidth?: string;
  selectedTabIndex: number;
  tabs: StanleyTab[];
  onTabChange: (tabIndex: number) => void;
}

function StanleyNavTabs({ minWidth, selectedTabIndex, tabs, onTabChange }: StanleyNavTabsProps): JSX.Element {
  const classes = useStyles({ minWidth });

  return (
    <Tabs
      // sx={{ maxHeight: '3rem' }}
      indicatorColor="primary"
      scrollButtons={false}
      textColor="primary"
      value={selectedTabIndex}
      variant="scrollable"
      onChange={(e, tabIndex) => onTabChange(tabIndex as number)}
    >
      {tabs.map(({ label, icon, iconPosition = 'end' }) => (
        <Tab key={label} classes={{ root: classes.tab }} label={label} icon={icon} iconPosition={iconPosition} />
      ))}
    </Tabs>
  );
}

const useStyles = makeStyles(() => ({
  tab: {
    minHeight: '3rem',
    minWidth: ({ minWidth }: { minWidth?: string }) => minWidth || '8rem',
    padding: 0,
    textTransform: 'none',
  },
}));

export default StanleyNavTabs;
