import { Container, Item, StanleySelectOption } from '@project-stanley/cap-management-components';

import ComparableContractDisplayFieldGroup from 'components/playerDataFields/playerDataFieldsGroup.component';
import {
  EXPECTED_GOALS_FIELD_OPTIONS,
  PLATER_EV_STAT_FIELD_OPTIONS,
  PLAYER_PP_STAT_FIELD_OPTIONS,
  PLAYER_SH_STAT_FIELD_OPTIONS,
  PLAYER_TOTAL_STAT_FIELD_OPTIONS,
} from 'constants/playerDataFields';

interface PlayerStatsDataFieldsProps {
  dataFields: (number | string)[];

  onChange: (value: string | number) => void;
  onSelectAll: (values: StanleySelectOption[], removeAll?: boolean) => void;
}

function PlayerStatsDataFields({ dataFields, onChange, onSelectAll }: PlayerStatsDataFieldsProps) {
  return (
    <Container>
      <Item>
        <ComparableContractDisplayFieldGroup
          dataFields={dataFields}
          options={PLAYER_TOTAL_STAT_FIELD_OPTIONS}
          title="Total"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <ComparableContractDisplayFieldGroup
          dataFields={dataFields}
          options={PLATER_EV_STAT_FIELD_OPTIONS}
          title="Even Strength(EV)"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <ComparableContractDisplayFieldGroup
          dataFields={dataFields}
          options={PLAYER_SH_STAT_FIELD_OPTIONS}
          title="Short Handed(SH)"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <ComparableContractDisplayFieldGroup
          dataFields={dataFields}
          options={PLAYER_PP_STAT_FIELD_OPTIONS}
          title="Power Play(PP)"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <ComparableContractDisplayFieldGroup
          dataFields={dataFields}
          options={EXPECTED_GOALS_FIELD_OPTIONS}
          title="Expected Goals"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
    </Container>
  );
}

export default PlayerStatsDataFields;
