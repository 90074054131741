/* eslint-disable import/prefer-default-export */
import ANA from 'assets/images/ANA.svg';
import ARI from 'assets/images/ARI.svg';
import BOS from 'assets/images/BOS.svg';
import BUF from 'assets/images/BUF.svg';
import CAR from 'assets/images/CAR.svg';
import CBJ from 'assets/images/CBJ.svg';
import CGY from 'assets/images/CGY.svg';
import CHI from 'assets/images/CHI.svg';
import COL from 'assets/images/COL.svg';
import DAL from 'assets/images/DAL.svg';
import DET from 'assets/images/DET.svg';
import EDM from 'assets/images/EDM.svg';
import FLA from 'assets/images/FLA.svg';
import LAK from 'assets/images/LAK.svg';
import MIN from 'assets/images/MIN.svg';
import MTL from 'assets/images/MTL.svg';
import NHL from 'assets/images/NHL.svg';
import NJD from 'assets/images/NJD.svg';
import NSH from 'assets/images/NSH.svg';
import NYI from 'assets/images/NYI.svg';
import NYR from 'assets/images/NYR.svg';
import OTT from 'assets/images/OTT.svg';
import PHI from 'assets/images/PHI.svg';
import PIT from 'assets/images/PIT.svg';
import SEA from 'assets/images/SEA.svg';
import SJS from 'assets/images/SJS.svg';
import STL from 'assets/images/STL.svg';
import TBL from 'assets/images/TBL.svg';
import TOR from 'assets/images/TOR.svg';
import VAN from 'assets/images/VAN.svg';
import VGK from 'assets/images/VGK.svg';
import WPG from 'assets/images/WPG.svg';
import WSH from 'assets/images/WSH.svg';
import blackLogo from 'assets/images/black-logo.svg';
import projectStanley from 'assets/images/project-stanley.svg';
import whiteLogo from 'assets/images/white-gold-logo.svg';

export const ICONS: {
  [key: string]: string;
} = {
  ANA,
  ARI,
  BOS,
  BUF,
  CAR,
  CBJ,
  CGY,
  CHI,
  COL,
  DAL,
  DET,
  EDM,
  FLA,
  NSH,
  LAK,
  MIN,
  MTL,
  NHL,
  NJD,
  NYI,
  NYR,
  OTT,
  PHI,
  PIT,
  SJS,
  SEA,
  STL,
  TBL,
  TOR,
  VAN,
  VGK,
  WPG,
  WSH,
  blackLogo,
  projectStanley,
  whiteLogo,
};
