/* eslint-disable react/no-unstable-nested-components */
import ordinal from 'ordinal';
import { MTableBody } from '@material-table/core';
import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { cloneDeep, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import TradeTableSummary from 'components/trades/tradeTableSummary.component';
import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { TeamTradeBreakdown, TeamTradeDraftAsset, TeamTradePlayerAsset } from 'types/teams';

interface TradeTableProps {
  breakdown: TeamTradeBreakdown;
  selectedSeason: string;
  totalRows: number;
}

function renderAssetColumn(asset: TeamTradePlayerAsset | TeamTradeDraftAsset) {
  if ('draftPickInfo' in asset) {
    return `${asset.draftPickInfo.year} ${ordinal(asset.draftPickInfo.round)} round`;
  }

  if ('playerInfo' in asset) {
    return `${asset.playerInfo.firstName} ${asset.playerInfo.lastName}`;
  }

  return null;
}

function renderPlayerInfoColumn(asset: TeamTradePlayerAsset | TeamTradeDraftAsset, field: string) {
  if ('playerInfo' in asset) {
    return asset.playerInfo[field] as string;
  }

  return 'draftPickInfo' in asset ? '-' : null;
}

function renderPlayerStatColumn(
  asset: TeamTradePlayerAsset | TeamTradeDraftAsset,
  field: string,
  selectedSeason: string,
) {
  if (!selectedSeason && 'careerStats' in asset) {
    const value = asset.careerStats.regularSeasonStats[field];

    return field === 'pointsPerGame' ? value.toFixed(2) : value;
  }

  if ('seasonStats' in asset) {
    const seasonStats = asset.seasonStats.find((season) => season.season.seasonId === selectedSeason);

    const value = seasonStats ? seasonStats.regularSeasonStats[field] : null;

    if (isNil(value)) return '-';

    return field === 'pointsPerGame' ? value.toFixed(2) : value;
  }

  return 'draftPickInfo' in asset ? '-' : null;
}

function renderPlayerFinancialColumn(asset: TeamTradePlayerAsset | TeamTradeDraftAsset) {
  if ('capHit' in asset) {
    return <StanleyDollar amount={asset.capHit} />;
  }

  return 'draftPickInfo' in asset ? '-' : null;
}

function renderPlayerPercentageColumn(asset: TeamTradePlayerAsset | TeamTradeDraftAsset) {
  if ('retainedCapHitPercentage' in asset) {
    return asset.retainedCapHitPercentage === 0 ? '-' : `${(asset.retainedCapHitPercentage * 100).toFixed(2)}%`;
  }

  return null;
}

function addRows(
  tradeAssets: Array<TeamTradePlayerAsset | TeamTradeDraftAsset | Record<string, unknown>>,
  totalRows: number,
) {
  const newRows = cloneDeep(tradeAssets);

  while (newRows.length < totalRows) {
    newRows.push({});
  }

  return [...newRows, {}];
}

function TradeTable({ breakdown, selectedSeason, totalRows }: TradeTableProps): JSX.Element {
  const navigate = useNavigate();

  const tradeAssets = useMemo(() => [...breakdown.playersReceived, ...breakdown.draftPicksReceived], [breakdown]);

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: TeamTradePlayerAsset | TeamTradeDraftAsset) => {
      if (rowData && 'playerInfo' in rowData) navigate(`${ROUTES.PLAYERS}/${rowData.playerInfo.playerId}`);
    },
    [navigate],
  );

  const columns = useMemo(
    () => [
      {
        cellStyle: { minWidth: 125, width: 125 },
        headerStyle: { minWidth: 125, width: 125 },
        title: 'Asset',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) => renderAssetColumn(rowData),
      },
      {
        cellStyle: { minWidth: 45, width: 45 },
        headerStyle: { minWidth: 45, width: 45 },
        title: 'T. Age',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) => renderPlayerInfoColumn(rowData, 'age'),
      },
      {
        title: 'GP',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) =>
          renderPlayerStatColumn(rowData, 'gamesPlayed', selectedSeason),
      },
      {
        title: 'G',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) =>
          renderPlayerStatColumn(rowData, 'goals', selectedSeason),
      },
      {
        title: 'A',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) =>
          renderPlayerStatColumn(rowData, 'assists', selectedSeason),
      },
      {
        title: 'P/PG',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) =>
          renderPlayerStatColumn(rowData, 'pointsPerGame', selectedSeason),
      },
      {
        title: 'AAV',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) => renderPlayerFinancialColumn(rowData),
      },
      {
        cellStyle: { minWidth: 60, width: 60 },
        headerStyle: { minWidth: 60, width: 60 },
        title: 'Retain. %',
        render: (rowData: TeamTradePlayerAsset | TeamTradeDraftAsset) => renderPlayerPercentageColumn(rowData),
      },
    ],
    [selectedSeason],
  );

  return (
    <StanleyTable
      columns={columns}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            <TradeTableSummary annualCapHitEffect={breakdown.annualCapHitEffect} totalCapHit={breakdown.totalCapHit} />
          </>
        ),
      }}
      data={addRows(tradeAssets, totalRows)}
      options={{ showEmptyDataSourceMessage: false, sorting: false }}
      onRowClick={handleRowClick}
    />
  );
}

export default TradeTable;
