/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box } from '@mui/material';
import { Container, Item, StanleyDetailsPanel } from '@project-stanley/cap-management-components';
import { makeStyles, useTheme } from '@mui/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TeamSummaryPanelHeader from 'modules/teams/teamSummary/teamSummaryPanelHeader.component';
import TeamSummaryPanelItem from 'modules/teams/teamSummary/teamSummaryPanelItem.component';
import classNames from 'classnames';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import {
  TEAM_SUMMARY_CAP_SUMMARY_ITEMS,
  TEAM_SUMMARY_CONTRACT_ITEMS,
  TEAM_SUMMARY_SALARY_BONUS_SUMMARY_ITEMS,
} from 'utilities/teams';
import { TeamSummary } from 'types/teams';
import { formatYearRange } from 'utilities/date';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeamContractYear, selectTeamProjectedSummary } from 'modules/teams/teams.selectors';

interface TeamSummaryProps {
  teamAbrvName: string;
  teamSummary: TeamSummary;
}

function TeamSummaryPanel({ teamAbrvName, teamSummary }: TeamSummaryProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();

  const contractYear = useSelector(selectTeamContractYear);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);
  const projectedTeamSummary = useSelector(selectTeamProjectedSummary);

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS || width === BREAK_POINTS.SM, [width]);

  const currentTeamSummary = useMemo(() => {
    if (!curSeasonStartYear || contractYear === curSeasonStartYear || !contractYear || !projectedTeamSummary) {
      return teamSummary;
    }

    return projectedTeamSummary[contractYear - curSeasonStartYear] || teamSummary;
  }, [contractYear, curSeasonStartYear, projectedTeamSummary, teamSummary]);

  return (
    <StanleyDetailsPanel
      className={classNames(classes.panel, !isSmallScreen && classes.panelFull)}
      titleComponent={
        <TeamSummaryPanelHeader
          season={contractYear ? `${contractYear}-${contractYear + 1}` : formatYearRange(curSeasonStartYear)}
          teamAbbreviation={teamAbrvName}
          teamName={currentTeamSummary.name}
        />
      }
    >
      <Box flex="1" overflow="auto">
        <Box marginBottom={theme.spacing(2)} />
        <Container>
          {TEAM_SUMMARY_CAP_SUMMARY_ITEMS.map((summaryItem) => (
            <Item key={summaryItem.description} xs={12} sm={6} md={12}>
              <TeamSummaryPanelItem
                label={summaryItem.description}
                text={summaryItem.defaultValue || currentTeamSummary[summaryItem.key]}
                isDollarAmount={summaryItem.defaultValue ? false : !!summaryItem.isDollarAmount}
              />
            </Item>
          ))}
        </Container>
        <Box className={classes.sectionSpacer} />
        <Container>
          {TEAM_SUMMARY_SALARY_BONUS_SUMMARY_ITEMS.map((summaryItem) => (
            <Item key={summaryItem.description} xs={12} sm={6} md={12}>
              <TeamSummaryPanelItem
                label={summaryItem.description}
                text={currentTeamSummary[summaryItem.key]}
                isDollarAmount={!!summaryItem.isDollarAmount}
              />
            </Item>
          ))}
        </Container>
        <Box className={classes.sectionSpacer} />
        {TEAM_SUMMARY_CONTRACT_ITEMS.map((summaryItem) => (
          <TeamSummaryPanelItem
            key={summaryItem.description}
            label={summaryItem.description}
            text={currentTeamSummary[summaryItem.key]}
            isDollarAmount={!!summaryItem.isDollarAmount}
          />
        ))}
      </Box>
    </StanleyDetailsPanel>
  );
}

const useStyles = makeStyles((theme) => ({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
  panelFull: {
    height: '100%',
    maxHeight: 'calc(100vh - 7.25rem)',
  },
  sectionSpacer: {
    marginTop: theme.spacing(4.25),
  },
}));

export default TeamSummaryPanel;
