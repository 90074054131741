import { ContractClause, ContractExpiryStatus, ContractStatus } from 'types/contract';
import { TeamInfo, TeamTrade } from 'types/teams';

export interface Player {
  playerId: number;
  age: number;
  agentInfo: PlayerAgentInfo;
  arbRights: boolean;
  birthDate: Date;
  careerStats: PlayerCareerStats;
  city: string;
  clauses: ContractClause[];
  contractDetails: (PlayerContract | null)[];
  contractExpiration: PlayerContractExpiration;
  country: string;
  currentContractDetail: PlayerContract;
  currentSeasonStats: PlayerCurrentSeasonStats;
  currentTeam: TeamInfo;
  draftInfo: PlayerDraftInfo;
  elcAge: number;
  expiryStatus: ContractExpiryStatus;
  firstName: string;
  heightCm: number;
  heightInches: number;
  lastName: string;
  lineupInfo: PlayerLineupInfo;
  primaryPosition: number;
  qoValue: number;
  secondaryPositions: number[];
  shot: PlayerShotHand;
  status: ContractStatus;
  totalNhlGames: number;
  ufaYear: number;
  unsigned: boolean;
  waiversAge: number;
  weightKg: number;
  weightLbs: number;
  yearsUntilUfa: number;
  // eslint-disable-next-line max-len
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | PlayerContract | number | null>
    | PlayerContract
    | PlayerDraftInfo
    | Date
    | TeamInfo
    | PlayerAgentInfo
    | PlayerContractExpiration
    | PlayerLineupInfo
    | PlayerCurrentSeasonStats
    | PlayerCareerStats;
}

export interface PlayerContract {
  contractDetailId: number;
  annualSalary: number;
  averageAnnualValue: number;
  clauses: ContractClause[];
  dailyCapHit: number;
  minorSalary: number;
  performanceBonus: number;
  projectedCapHit: number;
  seasonInfo: PlayerSeasonInfo;
  signingBonus: number;
  [key: string]: number | string | ContractClause[] | PlayerSeasonInfo;
}

export interface PlayerSeasonInfo {
  seasonId: string;
  seasonYearEnd: number;
  seasonYearStart: number;
}

export interface PlayerDraftInfo {
  draftYear: number;
  draftedBy: TeamInfo;
  pickOrder: number;
  round: number;
}

export interface PlayerAgentInfo {
  agencyName: string;
  email: string;
  fullName: string;
  phoneNumber: string;
}

export interface PlayerContractExpiration {
  expiryStatus: ContractExpiryStatus;
  accruedSeasons: number;
  remainingTerm: number;
}

export enum PlayerPosition {
  LeftWing = 1,
  RightWing = 2,
  Center = 3,
  LeftDefense = 4,
  RightDefense = 5,
  Goalie = 6,
  Scratched = 7,
  IR = 8,
  LTIR = 9,
}

export enum PlayerShotHand {
  Left = 1,
  Right = 2,
}

export interface PlayerInfo {
  playerId: number;
  age: number;
  firstName: string;
  lastName: string;
  primaryPosition: PlayerPosition;
  shotHand: PlayerShotHand;
  qoValue: number;
  arbRights: boolean;
  yearsUntilUfa: number;
  [key: string]: number | string | PlayerShotHand | PlayerPosition | boolean;
}

export interface PlayerSearch {
  currentTeam: TeamInfo;
  playerInfo: PlayerInfo;
}

export interface PlayerListItem {
  teamInfo: TeamInfo;
  playerId: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface PlayerStats {
  playerId: number;
  stats: PlayerSeasonStats[];
}

export interface PlayerSeasonStats {
  playerId: string;
  season: PlayerSeasonInfo;
  teamName: string;
  leagueName: string;
  leagueFullName: string;
  leagueType: string;
  regularSeasonStats: PlayerSeasonStatLine;
  postSeasonStats: PlayerSeasonStatLine;
  [key: string]: string | PlayerSeasonInfo | PlayerSeasonStatLine;
}

export interface PlayerSeasonStatLine {
  gamesPlayed: number;
  goals: number;
  assists: number;
  points: number;
  penaltyInfractionMinutes: number;
  plusMinus: number;
  pointsPerGame: number;
  savePercentage: number;
  goalsAgainstAverage: number;
  [key: string]: number;
}

export interface PlayerLineupInfo {
  position: PlayerPosition;
  line: number;
}

export interface PlayerCurrentSeasonStats {
  playerId: number;
  season: PlayerSeasonInfo;
  regularSeasonStats: PlayerSeasonStatLine;
  postSeasonStats: PlayerSeasonStatLine;
}

export interface PlayerCareerStats {
  playerId: number;
  regularSeasonStats: PlayerSeasonStatLine;
  postSeasonStats: PlayerSeasonStatLine;
  [key: string]: number | PlayerSeasonStatLine;
}

export interface PlayerUnsigned {
  playerId: number;
  firstName: string;
  lastName: string;
  primaryPosition: PlayerPosition;
  shotHand: PlayerShotHand;
}

export interface PlayerTradeComparables {
  trades: TeamTrade[];
}
