/* eslint-disable max-len */
import { Player } from 'types/player';
import { RootState } from 'store';
import {
  Team,
  TeamDeadCap,
  TeamDraftPick,
  TeamDraftPicksByYear,
  TeamDraftPicksByYearByRound,
  TeamRosterOutlookByPosition,
  TeamSummary,
  TeamTrade,
  TeamsByConferenceByDivision,
} from 'types/teams';

export const selectTeams = (state: RootState): Team[] | null => state.teams.teams;

export const selectTeamsSummary = (state: RootState): TeamSummary[] | null => state.teams.teamsSummary;

export const selectTeamsIsLoading = (state: RootState): boolean => state.teams.isLoading;

export const selectTeamsSummaryIsLoading = (state: RootState): boolean => state.teams.isTeamSummaryLoading;

export const selectTeamsIsDeadCapOutlookLoading = (state: RootState): boolean => state.teams.isDeadCapOutlookLoading;

export const selectTeamsIsOutlookLoading = (state: RootState): boolean => state.teams.isOutlookLoading;

export const selectTeamsIsSummaryLoading = (state: RootState): boolean => state.teams.isSummaryLoading;

export const selectTeamsIsProjectedSummaryLoading = (state: RootState): boolean => state.teams.isProjectedSummaryLoading;

export const selectTeamsIsDraftPicksLoading = (state: RootState): boolean => state.teams.isDraftPicksLoading;

export const selectTeamsByConferenceByDivision = (state: RootState): TeamsByConferenceByDivision | null => state.teams.teamsByConferenceByDivision;

export const selectTeam = (state: RootState): Team | null => state.teams.team;

export const selectTeamSummary = (state: RootState): TeamSummary | null => state.teams.teamSummary;

export const selectTeamProjectedSummary = (state: RootState): TeamSummary[] | null => state.teams.teamProjectedSummary;

export const selectTeamDraftPicks = (state: RootState): TeamDraftPick[] | null => state.teams.teamDraftPicks;

export const selectTeamDraftPicksByYear = (state: RootState): TeamDraftPicksByYear | null => state.teams.teamDraftPicksByYear;

export const selectTeamDraftPicksByYearByRound = (state: RootState): TeamDraftPicksByYearByRound | null => state.teams.teamDraftPicksByYearByRound;

export const selectTeamDeadCapOutlook = (state: RootState): TeamDeadCap[] | null => state.teams.teamDeadCapOutlook;

export const selectTeamActiveActiveContractYears = (state: RootState): number[] => state.teams.teamActiveContractYears;

export const selectTeamActiveRosterOutlook = (state: RootState): Player[] | null => state.teams.teamActiveRosterOutlook;

export const selectTeamActiveRosterOutlookByPosition = (state: RootState): TeamRosterOutlookByPosition | null => state.teams.teamActiveRosterOutlookByPosition;

export const selectTeamInactiveActiveContractYears = (state: RootState): number[] => state.teams.teamInactiveContractYears;

export const selectTeamInactiveRosterOutlook = (state: RootState): Player[] | null => state.teams.teamInactiveRosterOutlook;

export const selectTeamInactiveRosterOutlookByPosition = (state: RootState): TeamRosterOutlookByPosition | null => state.teams.teamInactiveRosterOutlookByPosition;

export const selectTeamContractYear = (state: RootState): number | null => state.teams.teamContractYear;

export const selectTeamContractYears = (state: RootState): number[] => state.teams.teamContractYears;

export const selectTeamRosterStatFilter = (state: RootState): string => state.teams.teamRosterStatFilter;

export const selectTrades = (state: RootState): TeamTrade[] | null => state.teams.trades;

export const selectTradesIsLoading = (state: RootState): boolean => state.teams.isTradesLoading;
