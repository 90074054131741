/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export const HELP_CENTER_VIDEOS = [
  {
    description: "Searching for a specific player's profile",
    title: 'Player Search Bar',
    url: 'https://player.vimeo.com/video/770565580?h=51d113902f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
  {
    description: 'Removing comparable players',
    title: 'Removing Comps',
    url: 'https://player.vimeo.com/video/770565441?h=60e7fad30f',
  },
  {
    description: 'Adding comparable players & showing 25 comps',
    title: 'Adding and Expanding Comps',
    url: 'https://player.vimeo.com/video/770565401?h=4f949f277e',
  },
  {
    description: 'Dragging and dropping specific players',
    title: 'Changing Order of Comps',
    url: 'https://player.vimeo.com/video/770565426?h=3166a84d16',
  },
  {
    description: 'Selecting CTC stats across comparable players',
    title: 'Changing Comp Stats',
    url: 'https://player.vimeo.com/video/770565462?h=bbccc49e09',
  },
  {
    description: '',
    title: 'Open Advanced Search',
    url: 'https://player.vimeo.com/video/770565553?h=c66a414082',
  },
  {
    description: 'Searching for a group of players based on season, age, AAV, contract length, expiration season',
    title: 'Advanced Search with Filters',
    url: 'https://player.vimeo.com/video/770565352?h=d5eb2a326b',
  },
  {
    description:
      'Add and remove specific fields to show in advanced search (cap hit, signing team, platform year, etc.)',
    title: 'Advanced Search Display Fields',
    url: 'https://player.vimeo.com/video/770565344?h=bb44a37dd7',
  },
  {
    description: "Create a scenario that shows a player's potential future contract",
    title: 'Enable Scenario Mode',
    url: 'https://player.vimeo.com/video/770565507?h=de7898b962',
  },
  {
    description: 'Enter years and dollar values for the contract scenario',
    title: 'Entering Contract Details',
    url: 'https://player.vimeo.com/video/770565531?h=9da4440d3c',
  },
  {
    description: 'Toggle in and out of scenario modes',
    title: 'Disable Scenario Mode',
    url: 'https://player.vimeo.com/video/770565486?h=268e9387f7',
  },
  {
    description:
      'Seeing how new contract impacts roster and capital outlook across multiple seasons, adding and removing players from the roster',
    title: 'Compare Rosters in Scenario Mode',
    url: 'https://player.vimeo.com/video/770565375?h=9cc82e33f8',
  },
  {
    description:
      "Using the 'share' feature to send a custom player comp set, trade, advanced search, or roster comparison",
    title: 'Sharing Links',
    url: 'https://player.vimeo.com/video/770565758?h=c9e0b1897b',
  },
  {
    description: 'Adding comparable player contracts and saving for future use and visibility',
    title: 'Saving Comps',
    url: 'https://player.vimeo.com/video/770565662?h=6c4742ed19',
  },
  {
    description: 'Resetting previously customized player comps to default ',
    title: 'Resetting Comps',
    url: 'https://player.vimeo.com/video/770565616?h=faca37f97e',
  },
  {
    description: 'Using the Contact Us feature in the platform for help or feedback submission',
    title: 'Direct Messaging',
    url: 'https://player.vimeo.com/video/770565471?h=82c7b2c601',
  },
];
