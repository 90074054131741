import makeStyles from '@mui/styles/makeStyles';
import ordinal from 'ordinal';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import { Container, Item, StanleySelect } from '@project-stanley/cap-management-components';

import { TeamDraftPick } from 'types/teams';
import { TradeDraft } from 'types/trade';

interface TradeSimulatorDraftAssetItemProps {
  draftPicks: TeamDraftPick[];
  draftAsset: TradeDraft;
  draftAssetsSelected: TradeDraft[];
  isPrinting: boolean;
  onChange: (field: string, value: number | boolean) => void;
}

function TradeSimulatorDraftAssetItem({
  draftPicks,
  draftAsset,
  draftAssetsSelected,
  isPrinting,
  onChange,
}: TradeSimulatorDraftAssetItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container alignItems="center">
      <Item xs={isPrinting ? 4 : 12} sm={4}>
        Draft Selection
      </Item>
      <Item xs={isPrinting ? 6 : 12} sm={6}>
        <StanleySelect
          displayEmpty
          emptyText="Selection"
          fullWidth
          options={draftPicks.map((draftPick) => ({
            disabled:
              draftAssetsSelected.some(
                (draftAssetSelected) => draftAssetSelected.draftPickId === draftPick.draftPickId,
              ) && draftAsset.draftPickId !== draftPick.draftPickId,
            label: `${ordinal(draftPick.round)} Round - ${draftPick.year}`,
            value: draftPick.draftPickId,
          }))}
          selectClasses={{ select: classes.select }}
          value={draftAsset.draftPickId}
          onChange={({ target }: SelectChangeEvent<unknown>) => onChange('draftPickId', target.value as number)}
        />
      </Item>
      <Container item xs={isPrinting ? 2 : 12} sm={2} justifyContent="center">
        <Checkbox
          checked={draftAsset.isConditional}
          color="primary"
          onChange={({ target }) => onChange('isConditional', target.checked)}
        />
      </Container>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: `${theme.spacing(0.75)} !important`,
    paddingTop: `${theme.spacing(0.75)} !important`,
  },
}));

export default TradeSimulatorDraftAssetItem;
