// eslint-disable-next-line import/prefer-default-export
export const numericSort = (a: number | string, b: number | string): number => {
  if (typeof a === 'string' || typeof b === 'string') return 0;

  return a - b;
};

export const timeSort = (a: string | number, b: string | number): number => {
  if (typeof a !== 'string' || typeof b !== 'string') return 0;

  return a.localeCompare(b);
};
