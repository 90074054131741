import {
  Autocomplete,
  Box,
  InputAdornment,
  InputProps,
  MenuItem,
  Popper,
  SxProps,
  TextField,
  Theme,
  autocompleteClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Children, ComponentType, HTMLAttributes, forwardRef, useCallback, useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';
import { Virtuoso } from 'react-virtuoso';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { ICONS } from 'utilities/icons';
import { TeamInfo } from 'types/teams';
import { selectPlayers } from 'modules/advancedSearch/search.selectors';

const StyledStanleySVGIcon = styled(StanleySVGIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.paper}`]: {
    padding: '0.5rem 0',
  },
});

const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
`;

export interface StanleyPlayerSearchOption {
  currentTeam?: TeamInfo | null;
  label: string;
  value: string;
}

interface StanleyPlayerSearchProps {
  clearOnSelect?: boolean;
  disabled?: boolean;
  TextFieldInputProps?: InputProps;
  sx?: SxProps<Theme>;
  onOptionSelected: (selectedOption: StanleyPlayerSearchOption | null) => void;
}

const ListboxComponent = forwardRef<HTMLDivElement>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...rest } = props;
  const itemData = Children.toArray(children);

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemData.length * itemSize;
  };

  return (
    <div ref={ref}>
      <Virtuoso style={{ height: `${getHeight()}px` }} data={itemData} {...rest} itemContent={(index, data) => data} />
    </div>
  );
});

function StanleyPlayerSearch({
  clearOnSelect = true,
  disabled,
  TextFieldInputProps,
  sx = { maxWidth: '18rem', width: '100%' },
  onOptionSelected,
}: StanleyPlayerSearchProps): JSX.Element {
  const players = useSelector(selectPlayers);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<StanleyPlayerSearchOption[]>([]);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (players) {
      setOptions(
        players
          .map((player) => ({
            currentTeam: player.teamInfo,
            value: player.playerId.toString(),
            label: `${player.firstName} ${player.lastName}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
  }, [players]);

  const handleOptionSelected = useCallback(
    (_, newValue: StanleyPlayerSearchOption | null) => {
      setOpen(false);

      if (!clearOnSelect) {
        setSearchTerm(newValue?.label || '');
      } else {
        setSearchTerm('');
      }

      onOptionSelected(
        isNil(newValue)
          ? newValue
          : {
              label: newValue.label,
              value: newValue.value,
            },
      );
    },
    [clearOnSelect, onOptionSelected],
  );

  return (
    <Autocomplete
      sx={{
        alignItems: 'center',
        display: 'flex',
        ...sx,
      }}
      disabled={disabled}
      clearOnBlur={false}
      disableListWrap
      inputValue={searchTerm}
      ListboxComponent={ListboxComponent as ComponentType<HTMLAttributes<HTMLElement>>}
      PopperComponent={StyledPopper}
      open={open}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="false"
          inputProps={{
            ...params.inputProps,
            style: {
              padding: '0 0 0 2.5rem',
            },
          }}
          InputProps={{
            ...params.InputProps,
            ...TextFieldInputProps,
            style: { paddingBottom: '0.4375rem', paddingRight: '0.5625rem', paddingTop: '0.4375rem' },
            startAdornment: (
              <StyledInputAdornment position="start">
                <Search />
              </StyledInputAdornment>
            ),
            endAdornment: null,
          }}
          placeholder="Search Players..."
          variant="outlined"
        />
      )}
      renderOption={(_, option) => (
        <MenuItem key={`${option.label}-${option.value}`} onClick={() => handleOptionSelected(_, option)}>
          <Box display="flex" alignItems="center">
            {option.currentTeam && (
              <StyledStanleySVGIcon height="1.5rem" imageSrc={ICONS[option.currentTeam.abrvName]} width="1.5rem" />
            )}
            {option.label}
          </Box>
        </MenuItem>
      )}
      onClose={() => setOpen(false)}
      onInputChange={(_, value: string) => {
        setSearchTerm(value);
      }}
      onOpen={() => setOpen(true)}
    />
  );
}

export default StanleyPlayerSearch;
