/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import MaterialTable from '@material-table/core';
import { Box, IconButton, SelectChangeEvent, Tooltip, Typography, useTheme } from '@mui/material';
import { Close, Delete, Edit, Search } from '@mui/icons-material';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  StanleyConfirmDialog,
  StanleyPageActions,
  StanleySelect,
  StanleyTransparentButton,
  useWidth,
} from '@project-stanley/cap-management-components';
import { isBefore } from 'date-fns';
import { useSelector } from 'react-redux';

import ComparableContractsDialog, {
  CompSetActionTypes,
} from 'modules/players/playerComparableContracts/comparableContractsDialog.component';
import PlayerDisplayFieldsDialog from 'components/playerDataFields/playerDataFieldsDialog.component';
import PlayerSearchCriteriaDialog from 'modules/advancedSearch/playerSearchCriteriaDialog.component';
import StanleyPlayerSearch, { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useQueryParams from 'hooks/useQueryParams';
import useShareMenuItem from 'hooks/useShareMenuItem';
import { BREAK_POINTS } from 'hooks/useWidth';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { Filters, PlayerSearchResult } from 'types/playerSearch';
import { FirestoreError } from 'firebase/firestore';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import {
  INITIAL_FILTERS,
  mapFiltersToQueryParams,
  mapQueryParamsToFilters,
  prepareSearchPayload,
} from 'utilities/playerSearch';
import { PagePrintAction } from 'utilities/pageActions';
import { ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { deleteFirestoreData } from 'utilities/firebase';
import { isNil } from 'lodash';
import { searchAdvanced } from 'modules/advancedSearch/search.slice';
import { selectBlockNavigation } from 'modules/layout/layout.selectors';
import { selectComparableContractsLists } from 'modules/players/playerComparableContracts/comparableContracts.selectors';
import { selectPlayerSearchCriteria } from 'modules/user/user.selectors';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';
import { selectSearchIsLoadingAdvancedSearch } from 'modules/advancedSearch/search.selectors';
import { selectSeason } from 'modules/season/season.selectors';
import { setBlockNavigation, showToast } from 'modules/layout/layout.slice';
import { setCompLists } from 'modules/players/playerComparableContracts/comparableContracts.slice';
import { useAppDispatch } from 'store';

interface PlayerSearchToolbarProps {
  tableRef: MutableRefObject<MaterialTable<any> | undefined>;
  dataFields: (number | string)[];
  paperRef?: MutableRefObject<HTMLDivElement | null>;
  selectedRows: (PlayerSearchResult & { id: number })[];
  selectedCompSet: string;

  onDataFieldsChange: (dataFields: (number | string)[]) => void;
  onOptionSelected: (selectedOption: StanleyPlayerSearchOption | null, seasonYear: string) => void;
  onSaveCompSet: (value: string, actionType: CompSetActionTypes, seasonYear: string) => void;
  onSearch: React.Dispatch<React.SetStateAction<PlayerSearchResult[]>>;
  onSelectCompSet: (value: string, seasonYear: string) => void;
  onRemovePlayers: () => void;
}

function PlayerSearchToolbar({
  tableRef,
  dataFields,
  paperRef,
  selectedRows,
  selectedCompSet,
  onDataFieldsChange,
  onOptionSelected,
  onSaveCompSet,
  onSearch,
  onSelectCompSet,
  onRemovePlayers,
}: PlayerSearchToolbarProps) {
  const copyToClipboard = useCopyToClipboard()[1];
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const width = useWidth();
  const queryParams = useQueryParams();

  const blockNavigation = useSelector(selectBlockNavigation);
  const scenario = useSelector(selectScenario);
  const season = useSelector(selectSeason);
  const playerSearchCriteria = useSelector(selectPlayerSearchCriteria);
  const isLoading = useSelector(selectSearchIsLoadingAdvancedSearch);
  const compLists = useSelector(selectComparableContractsLists);

  const [filters, setFilters] = useState<Filters>({
    ...INITIAL_FILTERS,
    playerStatsSearchCriteria: { ...INITIAL_FILTERS.playerStatsSearchCriteria, seasonYear: season.seasonId },
  });
  const [searchCriteriaOpen, setSearchCriteriaOpen] = useState(false);
  const [dataFieldsOpen, setDisplayFieldsOpen] = useState(false);
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [isSavingCompSet, setIsSavingCompSet] = useState(false);
  const [listToDelete, setListToDelete] = useState<string | null>(null);

  const handleSearch = useCallback(
    async (newFilters: Filters, initialSearch = false) => {
      if (selectedCompSet) onSelectCompSet('', newFilters.playerStatsSearchCriteria?.seasonYear || season.seasonId);

      const payload = prepareSearchPayload({ filters: newFilters, scenario });

      const response = await dispatch(searchAdvanced(payload));

      if (response.type === searchAdvanced.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Unable to search. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      onSearch((response.payload as { matchingPlayerList: PlayerSearchResult[] }).matchingPlayerList);

      if (!initialSearch) {
        dispatch(
          setBlockNavigation({
            ...blockNavigation,
            shouldBlockNavigation: true,
            actions: [
              ...blockNavigation.actions,
              {
                storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_CRITERIA,
                data: newFilters,
              },
            ],
          }),
        );
      }
    },
    [dispatch, blockNavigation, scenario, season.seasonId, selectedCompSet, onSearch, onSelectCompSet],
  );

  useEffect(() => {
    const newFilters: Filters = {
      ...INITIAL_FILTERS,
      playerStatsSearchCriteria: { ...INITIAL_FILTERS.playerStatsSearchCriteria, seasonYear: season.seasonId },
    };

    if (queryParams.toString() !== '') {
      const queryParamFilters = mapQueryParamsToFilters(queryParams);
      setFilters(queryParamFilters);
      handleSearch(queryParamFilters, true);
      return;
    }

    if (playerSearchCriteria) {
      setFilters(playerSearchCriteria);
      handleSearch(playerSearchCriteria, true);
      return;
    }

    if (newFilters.playerStatsSearchCriteria) {
      if (isBefore(new Date(), new Date(season.seasonStartDate))) {
        newFilters.playerStatsSearchCriteria.seasonYear = `${season.seasonYearStart - 1}-${season.seasonYearEnd - 1}`;
      } else {
        newFilters.playerStatsSearchCriteria.seasonYear = season.seasonId;
      }
    }

    setFilters(newFilters);
    handleSearch(newFilters, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSearchCriteria = useCallback(() => setSearchCriteriaOpen(!searchCriteriaOpen), [searchCriteriaOpen]);

  const toggleDataFields = useCallback(() => setDisplayFieldsOpen(!dataFieldsOpen), [dataFieldsOpen]);

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(`${window.location.origin.toString()}${ROUTES.SEARCH}?${mapFiltersToQueryParams(filters)}`);

    logGAEvent({
      category: GA_CATEGORIES.ADVANCED_SEARCH,
      action: GA_ACTIONS.SHARE_SEARCH,
    });

    dispatch(
      showToast({
        toastMessage: 'Copied Successfully to Clipboard',
        toastType: ToastTypes.SUCCESS,
      }),
    );
  }, [dispatch, filters, copyToClipboard]);

  const toggleSaveAs = useCallback(() => setSaveAsOpen(!saveAsOpen), [saveAsOpen]);

  const handleSaveCompSet = useCallback(
    (value: string, actionType: CompSetActionTypes) => {
      setIsSavingCompSet(true);

      onSaveCompSet(value, actionType, filters.playerStatsSearchCriteria?.seasonYear || season.seasonId);

      setIsSavingCompSet(false);
      toggleSaveAs();
    },
    [filters, season.seasonId, onSaveCompSet, toggleSaveAs],
  );

  const handleDeleteSavedCompSet = useCallback(async () => {
    if (listToDelete) {
      setIsSavingCompSet(true);

      try {
        await deleteFirestoreData({ path: `compLists/${listToDelete}` });

        const newCompLists = compLists.filter((item) => item.id !== listToDelete);

        dispatch(setCompLists(newCompLists));
      } catch (error) {
        console.log('FIRESTORE ERROR: ', error as FirestoreError);
      }

      setListToDelete(null);
      setIsSavingCompSet(false);
    }
  }, [dispatch, compLists, listToDelete]);

  const shareMenuItem = useShareMenuItem({ disableOnScenario: false, onClick: handleCopyToClipboard });

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS || width === BREAK_POINTS.SM, [width]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1.5}>
        <Typography variant="h6" fontWeight={400}>
          Player Contract Search
        </Typography>
        {paperRef && (
          <StanleyPageActions printRef={paperRef} actions={[shareMenuItem, PagePrintAction]} tooltipTitle="Actions" />
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
        <Box display="flex">
          {selectedRows.length > 0 && (
            <Tooltip title={`Remove ${selectedRows.length} players(s)`}>
              <IconButton
                color="primary"
                sx={{
                  padding: theme.spacing(0.75),
                  marginLeft: theme.spacing(0.5),
                  marginRight: theme.spacing(1),
                }}
                onClick={() => {
                  onRemovePlayers();

                  const { currentPage, pageSize } = (tableRef?.current as any)?.state;
                  selectedRows.forEach((row: PlayerSearchResult & { id: number }) => {
                    (tableRef?.current as any)?.onRowSelected(
                      { target: { clicked: false } },
                      [row.id + pageSize * currentPage],
                      row,
                    );
                  });
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <StanleyPlayerSearch
            sx={{ width: '16rem' }}
            TextFieldInputProps={{ sx: { width: '100%', maxWidth: '16rem' } }}
            disabled={isLoading}
            onOptionSelected={(selectedOption) =>
              onOptionSelected(selectedOption, filters.playerStatsSearchCriteria?.seasonYear || season.seasonId)
            }
          />
          {isSmallScreen ? (
            <Tooltip title="Search Criteria" sx={{ marginLeft: 1 }}>
              <IconButton size="small" onClick={toggleSearchCriteria}>
                <Search />
              </IconButton>
            </Tooltip>
          ) : (
            <StanleyTransparentButton
              sx={{ marginLeft: 1, textTransform: 'none', whiteSpace: 'nowrap' }}
              onClick={toggleSearchCriteria}
            >
              Search Criteria
            </StanleyTransparentButton>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {isSmallScreen ? (
            <Tooltip title="Edit Table">
              <IconButton size="small" onClick={toggleSearchCriteria}>
                <Edit />
              </IconButton>
            </Tooltip>
          ) : (
            <StanleyTransparentButton
              sx={{ marginRight: 1, textTransform: 'none', whiteSpace: 'nowrap' }}
              onClick={toggleDataFields}
            >
              Edit Table
            </StanleyTransparentButton>
          )}
          <StanleyTransparentButton
            disabled={isLoading}
            sx={{ marginRight: 1, textTransform: 'none', whiteSpace: 'nowrap' }}
            onClick={toggleSaveAs}
          >
            Save As
          </StanleyTransparentButton>
          {compLists.length > 0 && (
            <StanleySelect
              disabled={isLoading}
              displayEmpty
              emptyText="Select Existing List"
              fullWidth
              options={compLists.map((compListItem) => ({
                label: compListItem.name,
                value: compListItem.id,
              }))}
              renderOption={(option) => (
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1">{option.label}</Typography>
                  {option.value !== 'default' && option.value !== selectedCompSet && (
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();

                        setListToDelete(option.value as string);
                      }}
                    >
                      <Close color="primary" />
                    </IconButton>
                  )}
                </Box>
              )}
              renderValue={(value) => {
                if (!value) return 'Select Existing List';
                if (value === 'default') return <Typography variant="body1">Default</Typography>;

                const selectedCompList = compLists.find((item) => item.id === value);

                return <Typography variant="body1">{selectedCompList?.name}</Typography>;
              }}
              sx={{
                width: '16rem',
              }}
              value={selectedCompSet}
              onChange={({ target }: SelectChangeEvent<unknown>) =>
                onSelectCompSet(
                  target.value as string,
                  filters.playerStatsSearchCriteria?.seasonYear || season.seasonId,
                )
              }
            />
          )}
        </Box>
      </Box>
      <PlayerSearchCriteriaDialog
        isLoading={isLoading}
        filters={filters}
        open={searchCriteriaOpen}
        onChange={setFilters}
        onClose={toggleSearchCriteria}
        onSearch={handleSearch}
      />
      <PlayerDisplayFieldsDialog
        dataFields={dataFields}
        open={dataFieldsOpen}
        onClose={toggleDataFields}
        onDataFieldsChange={onDataFieldsChange}
      />
      <ComparableContractsDialog
        isLoading={isSavingCompSet}
        open={saveAsOpen}
        onClose={toggleSaveAs}
        onSubmit={handleSaveCompSet}
      />
      <StanleyConfirmDialog
        cancelText="Cancel"
        confirmText="Delete"
        contentText="Are you sure you want to delete this list?"
        isLoading={isSavingCompSet}
        loadingText="Deleting..."
        open={!isNil(listToDelete)}
        title="Delete List?"
        onClose={() => setListToDelete(null)}
        onConfirm={handleDeleteSavedCompSet}
      />
    </>
  );
}

export default PlayerSearchToolbar;
