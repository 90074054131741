import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Item, StanleyDollar } from '@project-stanley/cap-management-components';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import { CONTRACT_CLAUSE_MAP, CONTRACT_EXPIRY_STATUS_MAP } from 'utilities/contract';
import { ContractExpiryStatus, ContractStatus } from 'types/contract';
import { PLAYER_POSITION_ABBR_MAP, convertToFeetInches, convertToLBS, getPositionAbbrList } from 'utilities/player';
import { Player } from 'types/player';
import { ROSTER_CONTRACT_FILTER_KEYS, ROSTER_PLAYER_FILTER_KEYS } from 'utilities/roster';
import { ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { getProjectedTeamSummary, getTeamRosterOutlook, getTeamSummary } from 'modules/teams/teams.slice';
import { scenarioMovePlayer } from 'modules/scenarios/scenarios.slice';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeamContractYear, selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

export interface TeamParamTypes {
  teamId: string;
  [key: string]: string;
}
interface RosterPositionListItemProps {
  player: Player;
}

function RosterPositionListItem({ player }: RosterPositionListItemProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { teamId } = useParams<TeamParamTypes>();
  const { pathname } = useLocation();

  const contractYear = useSelector(selectTeamContractYear);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const scenario = useSelector(selectScenario);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  const handleMovePlayer = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      let response;

      if (player.status === ContractStatus.ACTIVE) {
        response = await dispatch(
          scenarioMovePlayer({ playerId: player.playerId, newStatus: ContractStatus.IN_ACTIVE }),
        );
      } else {
        response = await dispatch(scenarioMovePlayer({ playerId: player.playerId, newStatus: ContractStatus.ACTIVE }));
      }

      setAnchorEl(null);

      if (response.type === scenarioMovePlayer.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Unable to move player',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      dispatch(getTeamRosterOutlook(teamId as string));
      dispatch(getTeamSummary(teamId as string));
      dispatch(getProjectedTeamSummary(teamId as string));
    },
    [dispatch, player, teamId],
  );

  const renderedPlayerFilterValue = useMemo(() => {
    switch (rosterStatFilter) {
      case ROSTER_PLAYER_FILTER_KEYS.TOTAL_NHL_GAMES:
        return (
          (player.careerStats &&
            player.careerStats.regularSeasonStats &&
            player.careerStats.regularSeasonStats.gamesPlayed) ||
          '--'
        );

      case ROSTER_PLAYER_FILTER_KEYS.GAMES_PLAYED:
      case ROSTER_PLAYER_FILTER_KEYS.GOALS:
      case ROSTER_PLAYER_FILTER_KEYS.ASSISTS:
      case ROSTER_PLAYER_FILTER_KEYS.PPG:
        return (
          (player.currentSeasonStats &&
            player.currentSeasonStats.regularSeasonStats &&
            player.currentSeasonStats.regularSeasonStats[rosterStatFilter]) ||
          '--'
        );

      case ROSTER_PLAYER_FILTER_KEYS.POINTS: {
        if (
          !player.currentSeasonStats ||
          (player.currentSeasonStats && !player.currentSeasonStats.regularSeasonStats)
        ) {
          return '--';
        }

        return (
          player.currentSeasonStats.regularSeasonStats.goals + player.currentSeasonStats.regularSeasonStats.assists
        );
      }

      case ROSTER_PLAYER_FILTER_KEYS.HEIGHT: {
        if (!player.heightCm) return '--';

        return `${convertToFeetInches(player.heightCm)} | ${player.heightCm.toFixed(1)}cm`;
      }

      case ROSTER_PLAYER_FILTER_KEYS.WEIGHT: {
        if (!player.weightKg) return '--';

        return `${convertToLBS(player.weightKg)} | ${player.weightKg.toFixed(1)}kg`;
      }

      default: {
        if (!contractYear && (!player.currentContractDetail || !player.currentContractDetail[rosterStatFilter])) {
          return '--';
        }

        let amount = player.currentContractDetail ? (player.currentContractDetail[rosterStatFilter] as number) : 0;

        if (contractYear) {
          const playerContract = player.contractDetails?.find(
            (contract) => contract?.seasonInfo.seasonYearStart === contractYear,
          );

          if (!playerContract) return '--';

          amount = playerContract[rosterStatFilter] as number;
        }

        if (rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP) {
          return `${amount.toFixed(2)} %`;
        }

        return <StanleyDollar amount={amount} />;
      }
    }
  }, [contractYear, rosterStatFilter, player]);

  const lastYearOfContract: number = useMemo(() => {
    if (!curSeasonStartYear) return 0;

    return player.contractDetails.reduce((contractEndYear, curContractYear) => {
      if (!curContractYear) return contractEndYear;

      return curContractYear.seasonInfo.seasonYearStart > contractEndYear
        ? curContractYear.seasonInfo.seasonYearStart
        : contractEndYear;
    }, curSeasonStartYear);
  }, [curSeasonStartYear, player]);

  const hasMultipleSideItems = useMemo(
    (): boolean =>
      player.expiryStatus &&
      player.currentContractDetail &&
      !isEmpty(player.currentContractDetail.clauses) &&
      contractYear !== lastYearOfContract,
    [contractYear, lastYearOfContract, player],
  );

  const hasContractClauses = useMemo(
    (): boolean =>
      player.currentContractDetail &&
      !isEmpty(player.currentContractDetail.clauses) &&
      contractYear !== lastYearOfContract,
    [contractYear, lastYearOfContract, player],
  );

  return (
    <Link className={classes.link} to={`${ROUTES.PLAYERS}/${player.playerId}`}>
      <Container className={classes.positionContainer}>
        <Item className={classes.sideItem} xs={3}>
          <Typography noWrap variant="caption">
            {isEmpty(player.secondaryPositions)
              ? PLAYER_POSITION_ABBR_MAP[player.primaryPosition]
              : getPositionAbbrList(player.primaryPosition, player.secondaryPositions)}
          </Typography>
        </Item>
        <Item xs={6}>
          <Typography className={classes.centerText} noWrap variant="subtitle1">
            {player.firstName}
            &nbsp;
            {player.lastName}
          </Typography>
          <Typography className={classes.centerText} variant="subtitle1">
            {renderedPlayerFilterValue}
          </Typography>
        </Item>
        {isNil(scenario) || pathname === ROUTES.ROSTER_COMPARISON ? (
          <Item className={classNames(classes.sideItem, { [classes.multipleSideItems]: hasMultipleSideItems })} xs={3}>
            {hasContractClauses && (
              <Typography className={classes.clauses} align="center" noWrap variant="caption">
                {player.currentContractDetail.clauses.map((clause): string => CONTRACT_CLAUSE_MAP[clause]).join(', ')}
              </Typography>
            )}
            {player.expiryStatus && (
              <Typography
                noWrap
                variant="caption"
                className={classNames(
                  contractYear === lastYearOfContract &&
                    player.expiryStatus === ContractExpiryStatus.UNRESTRICTED_FREE_AGENT &&
                    classes.lastYearExpiryUFA,
                  contractYear === lastYearOfContract &&
                    player.expiryStatus === ContractExpiryStatus.RESTRICTED_FREE_AGENT &&
                    classes.lastYearExpiryRFA,
                )}
              >
                {CONTRACT_EXPIRY_STATUS_MAP[player.expiryStatus]}
              </Typography>
            )}
            {player.unsigned && (
              <Typography noWrap variant="caption">
                RIGHTS
              </Typography>
            )}
          </Item>
        ) : (
          <Item className={classes.sideItem} xs={3}>
            <IconButton classes={{ root: classes.iconButton }} color="primary" onClick={handleOpenMenu} size="large">
              <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
              <MenuItem onClick={handleMovePlayer}>
                {player.status === ContractStatus.ACTIVE ? 'Send to Minors' : 'Send To NHL'}
              </MenuItem>
            </Menu>
          </Item>
        )}
      </Container>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  positionContainer: {
    background: Colors.LIGHT_GRAY,
    borderRadius: theme.spacing(1.25),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  sideItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  multipleSideItems: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centerText: {
    lineHeight: 1.25,
    textAlign: 'center',
  },
  clauses: {
    width: '100%',
  },
  iconButton: {
    padding: theme.spacing(0.0375),
  },
  lastYearExpiry: {
    padding: theme.spacing(1),
  },
  lastYearExpiryUFA: {
    backgroundColor: `${Colors.ERROR}22`,
    borderRadius: theme.spacing(1.25),
    color: Colors.ERROR,
    padding: '0.5rem 1rem',
  },
  lastYearExpiryRFA: {
    backgroundColor: `${Colors.BLUE}22`,
    borderRadius: theme.spacing(1.25),
    color: Colors.BLUE,
    padding: '0.5rem 1rem',
  },
}));

export default RosterPositionListItem;
