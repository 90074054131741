import { StanleyDollar } from '@project-stanley/cap-management-components';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TradeTableSummaryProps {
  annualCapHitEffect: number;
  totalCapHit: number;
}

function TradeTableSummary({ annualCapHitEffect, totalCapHit }: TradeTableSummaryProps): JSX.Element {
  const classes = useStyles();

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography variant="body2">Total</Typography>
        </TableCell>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell}>
          <Typography variant="body2">
            <StanleyDollar amount={totalCapHit} />
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography variant="body2">Annual Effect</Typography>
        </TableCell>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell}>
          <Typography variant="body2">
            <StanleyDollar amount={annualCapHitEffect} showColor />
          </Typography>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
    color: 'black',
    padding: theme.spacing(1.25),
  },
}));

export default TradeTableSummary;
