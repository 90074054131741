import { Box, Checkbox, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import ScenarioDialog, { ScenarioActionTypes } from 'modules/scenarios/scenarioDialog.component';
import useQueryParams from 'hooks/useQueryParams';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { ROUTES, SCENARIO_ID } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { createScenario, getScenario, getScenarios, resetScenarios } from 'modules/scenarios/scenarios.slice';
import { getPlayer, getPlayerContracts } from 'modules/players/players.slice';
import {
  getProjectedTeamSummary,
  getTeam,
  getTeamDeadCapOutlook,
  getTeamDraftPicks,
  getTeamRosterOutlook,
  getTeamSummary,
  getTeams,
  getTeamsSummary,
} from 'modules/teams/teams.slice';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: 'fit-content',
  margin: `auto ${theme.spacing(1)}`,
  color: Colors.BRAND_GOLD,
}));

function ScenarioToggle(): JSX.Element {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const queryParams = useQueryParams();

  const isPlayerPage = useMatch(ROUTES.PLAYER);
  const isTeamPage = useMatch(ROUTES.TEAM);
  const isTeamsPage = useMatch(ROUTES.TEAMS);

  const scenario = useSelector(selectScenario);

  const [createScenarioDialogOpen, setCreateScenarioDialogOpen] = useState(false);

  useEffect(
    () => () => {
      dispatch(resetScenarios());
    },
    [dispatch],
  );

  const toggleScenarioDialog = useCallback(
    () => setCreateScenarioDialogOpen(!createScenarioDialogOpen),
    [createScenarioDialogOpen, setCreateScenarioDialogOpen],
  );

  const toggleScenarioMode = useCallback(
    async (scenarioId?: string) => {
      if (scenario) {
        queryParams.delete(SCENARIO_ID);

        dispatch(resetScenarios());
      } else if (scenarioId) {
        await dispatch(getScenario(scenarioId));
      }

      if (isTeamPage) {
        const { teamId } = isTeamPage.params as { teamId: string };

        dispatch(getTeam(teamId));
        dispatch(getTeamDeadCapOutlook(teamId));
        dispatch(getTeamDraftPicks(teamId));
        dispatch(getTeamSummary(teamId));
        dispatch(getTeamRosterOutlook(teamId));
        dispatch(getProjectedTeamSummary(teamId));
        return;
      }

      if (isTeamsPage) {
        dispatch(getTeams());
        dispatch(getTeamsSummary());
        return;
      }

      if (isPlayerPage) {
        const { playerId } = isPlayerPage.params as { playerId: string };

        dispatch(getPlayer(playerId));
        dispatch(getTeams());
        dispatch(getPlayerContracts(playerId));
        return;
      }

      dispatch(getTeams());
    },
    [dispatch, isPlayerPage, isTeamPage, isTeamsPage, queryParams, scenario],
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (scenario: number | string, scenarioActionType: ScenarioActionTypes) => {
      (async () => {
        if (scenarioActionType === ScenarioActionTypes.SELECT) {
          logGAEvent({
            category: GA_CATEGORIES.SCENARIOS,
            action: GA_ACTIONS.SCENARIO_SELECT,
            value: scenario as number,
          });

          toggleScenarioMode(scenario as string);
          toggleScenarioDialog();
          return;
        }

        const response = await dispatch(createScenario(scenario as string));

        if (response.type === createScenario.rejected.toString()) {
          dispatch(
            showToast({ toastMessage: 'There was an error creating the scenario', toastType: ToastTypes.ERROR }),
          );
        }

        dispatch(getScenarios());

        logGAEvent({
          category: GA_CATEGORIES.SCENARIOS,
          action: GA_ACTIONS.SCENARIO_CREATE,
          value: response.payload as number,
        });

        toggleScenarioMode(response.payload as string);
        toggleScenarioDialog();
      })();
    },
    [dispatch, toggleScenarioDialog, toggleScenarioMode],
  );

  return (
    <>
      <Box display="flex" alignItems="center">
        {scenario && (
          <Typography color="primary" noWrap variant="h6">
            {scenario.name}
          </Typography>
        )}
        <Tooltip title="Toggle Scenario Mode">
          <StyledCheckbox
            sx={{
              '&.Mui-checked': {
                color: theme.palette.error.main,
              },
            }}
            checked={Boolean(scenario)}
            checkedIcon={<RadioButtonChecked fontSize="small" />}
            icon={<RadioButtonUnchecked fontSize="small" />}
            onChange={scenario ? () => toggleScenarioMode() : toggleScenarioDialog}
          />
        </Tooltip>
      </Box>
      <ScenarioDialog open={createScenarioDialogOpen} onClose={toggleScenarioDialog} onSubmit={handleSubmit} />
    </>
  );
}

export default ScenarioToggle;
