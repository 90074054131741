import { RootState } from 'store/index';
import { Season } from 'types/season';

export const selectDaysIntoSeason = (state: RootState): number => state.season.season.numDaysIntoSeason;

export const selectDaysInSeason = (state: RootState): number => state.season.season.totalNumDaysInSeason;

export const selectSeasonStartDay = (state: RootState): string => state.season.season.seasonStartDate;

export const selectSeasonEndDay = (state: RootState): string => state.season.season.seasonEndDate;

export const selectSeasonStartYear = (state: RootState): number => state.season.season.seasonYearStart;

export const selectSeasonId = (state: RootState): string => state.season.season.seasonId;

export const selectSeason = (state: RootState): Season => state.season.season;

export const selectSeasons = (state: RootState): string[] => state.season.seasons;
