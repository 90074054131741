import { Box, IconButton, styled, useTheme } from '@mui/material';
import { StanleyDetailsPanel, StanleySVGIcon } from '@project-stanley/cap-management-components';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import PlayerDraftStatusPanelItem from 'modules/players/playerDraftStatus/playerDraftStatusPanelItem.component';
import { ICONS } from 'utilities/icons';
import { PlayerDraftInfo } from 'types/player';
import { ROUTES } from 'utilities/routes';

interface PlayerDraftStatusPanelProps {
  playerDraftStatus: PlayerDraftInfo;
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));

const StyledStanleyDetailsPanel = styled(StanleyDetailsPanel)(({ theme }) => ({
  height: 'calc(100% - 32px)',
  marginLeft: theme.spacing(0.25),
  marginRight: theme.spacing(0.25),
}));

function PlayerDraftStatusPanel({
  playerDraftStatus: { draftYear, draftedBy, pickOrder, round },
}: PlayerDraftStatusPanelProps): JSX.Element {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleTeamClick = useCallback(() => {
    navigate(`${ROUTES.TEAMS}/${draftedBy.teamId}`);
  }, [navigate, draftedBy]);

  return (
    <StyledStanleyDetailsPanel title="NHL Draft Status">
      <Box marginTop={theme.spacing(2.5)}>
        <PlayerDraftStatusPanelItem label="Draft Year:" value={!isNil(draftYear) ? draftYear : 'N/A'} />
        <PlayerDraftStatusPanelItem label="Overall:" value={!isNil(pickOrder) ? pickOrder : 'N/A'} />
        <PlayerDraftStatusPanelItem label="Round:" value={!isNil(round) ? round : 'N/A'} />
        <PlayerDraftStatusPanelItem label="Drafted Team:" value={!isNil(draftedBy) ? draftedBy.abrvName : 'N/A'}>
          {draftedBy && (
            <StyledIconButton onClick={handleTeamClick} size="large">
              <StanleySVGIcon height="1.5rem" imageSrc={ICONS[draftedBy.abrvName]} width="1.5rem" />
            </StyledIconButton>
          )}
        </PlayerDraftStatusPanelItem>
      </Box>
    </StyledStanleyDetailsPanel>
  );
}

export default PlayerDraftStatusPanel;
