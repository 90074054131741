import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'middleware/api';
import { Season } from 'types/season';

interface SeasonState {
  isLoading: boolean;
  season: Season;
  seasons: string[];
}

const INITIAL_SEASON_STATE: Season = {
  seasonId: 'string',
  seasonYearStart: 0,
  seasonYearEnd: 0,
  seasonStartDate: 'string',
  seasonEndDate: 'string',
  totalNumDaysInSeason: 0,
  numDaysIntoSeason: 0,
};

const initialState: SeasonState = {
  isLoading: false,
  season: INITIAL_SEASON_STATE,
  seasons: [],
};

export const getSeason = createAsyncThunk('season/getSeason', async () => {
  const response = (await api('seasons/current', { method: 'GET' }, true)) as Season;

  return response;
});

export const getSeasons = createAsyncThunk('season/getSeasons', async () => {
  const response = (await api('seasons', { method: 'GET' }, true)) as string[];

  return response;
});

export const seasonSlice = createSlice({
  name: 'scoreboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSeason.pending, (state) => {
        state.isLoading = true;
        state.season = INITIAL_SEASON_STATE;
      })
      .addCase(getSeason.fulfilled, (state, action: { payload: Season }) => {
        state.isLoading = false;
        state.season = action.payload;
      })
      .addCase(getSeason.rejected, (state) => {
        state.isLoading = false;
        state.season = INITIAL_SEASON_STATE;
      })
      .addCase(getSeasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSeasons.fulfilled, (state, action: { payload: string[] }) => {
        state.isLoading = false;
        state.seasons = action.payload;
      })
      .addCase(getSeasons.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default seasonSlice.reducer;
