import { ChangeEvent, useCallback } from 'react';
import { Container, Item } from '@project-stanley/cap-management-components';

import MinMaxFilter from 'modules/advancedSearch/minMaxFilter.component';
import { Box, SelectChangeEvent } from '@mui/material';
import { Filters } from 'types/playerSearch';
import { PlayerPosition } from 'types/player';

interface PlayerSearchContractMixMaxDetailsProps {
  filters: Filters;
  onChange: React.Dispatch<React.SetStateAction<Filters>>;
}

function PlayerSearchContractMixMaxDetails({ filters, onChange }: PlayerSearchContractMixMaxDetailsProps) {
  const handleBaseFiltersChange = useCallback(
    (filterKey: string) =>
      ({ target }: SelectChangeEvent<unknown>) =>
        onChange((curFilters) => ({
          ...curFilters,
          [filterKey]: target.value as number | PlayerPosition[],
        })),
    [onChange],
  );

  const handleContractDollarMinMaxChange = useCallback(
    (filterKey: string) => (value: number | string) => {
      onChange((curFilters) => ({
        ...curFilters,
        contractSearchCriteria: {
          ...curFilters.contractSearchCriteria,
          [filterKey]: value,
        },
      }));
    },
    [onChange],
  );

  const handleContractMinMaxChange = useCallback(
    (filterKey: string) =>
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        onChange((curFilters) => ({
          ...curFilters,
          contractSearchCriteria: {
            ...curFilters.contractSearchCriteria,
            [filterKey]: value,
          },
        }));
      },
    [onChange],
  );

  const handleClearBaseFilterItem = useCallback(
    (filterKey: string) => () =>
      onChange((curFilters) => ({
        ...curFilters,
        [filterKey]: '',
      })),
    [onChange],
  );

  const handleClearContractItem = useCallback(
    (filterKey: string) => () => {
      onChange((curFilters) => ({
        ...curFilters,
        contractSearchCriteria: {
          ...curFilters.contractSearchCriteria,
          [filterKey]: '',
        },
      }));
    },
    [onChange],
  );

  return (
    <Box flex={1} padding="1rem" sx={{ overflowY: 'scroll' }}>
      <Container spacing={4}>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            isDollarFilter
            maxValue={filters.contractSearchCriteria?.maxAverageAnnualValue || ''}
            minValue={filters.contractSearchCriteria?.minAverageAnnualValue || ''}
            title="Contract AAV"
            onChangeDollarMin={handleContractDollarMinMaxChange('minAverageAnnualValue')}
            onChangeDollarMax={handleContractDollarMinMaxChange('maxAverageAnnualValue')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.contractSearchCriteria?.maxSigningAge || ''}
            minValue={filters.contractSearchCriteria?.minSigningAge || ''}
            placeholders={{
              maxPlaceholder: '45',
              minPlaceholder: '18',
            }}
            title="Signing Age"
            onChangeMin={handleContractMinMaxChange('minSigningAge')}
            onChangeMax={handleContractMinMaxChange('maxSigningAge')}
            onClearMin={handleClearContractItem('minSigningAge')}
            onClearMax={handleClearContractItem('maxSigningAge')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.maxAge || ''}
            minValue={filters.minAge || ''}
            placeholders={{
              maxPlaceholder: '45',
              minPlaceholder: '18',
            }}
            title="Current Age"
            onChangeMin={handleBaseFiltersChange('minAge')}
            onChangeMax={handleBaseFiltersChange('maxAge')}
            onClearMin={handleClearBaseFilterItem('minAge')}
            onClearMax={handleClearBaseFilterItem('maxAge')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.contractSearchCriteria?.maxContractLength || ''}
            minValue={filters.contractSearchCriteria?.minContractLength || ''}
            placeholders={{
              maxPlaceholder: '8',
              minPlaceholder: '1',
            }}
            title="Contract Length"
            onChangeMin={handleContractMinMaxChange('minContractLength')}
            onChangeMax={handleContractMinMaxChange('maxContractLength')}
            onClearMin={handleClearContractItem('minContractLength')}
            onClearMax={handleClearContractItem('maxContractLength')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            isDollarFilter
            maxValue={filters.contractSearchCriteria?.maxContractValue || ''}
            minValue={filters.contractSearchCriteria?.minContractValue || ''}
            title="Contract Value"
            onChangeDollarMin={handleContractDollarMinMaxChange('minContractValue')}
            onChangeDollarMax={handleContractDollarMinMaxChange('maxContractValue')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            isDollarFilter
            maxValue={filters.contractSearchCriteria?.maxCapHit || ''}
            minValue={filters.contractSearchCriteria?.minCapHit || ''}
            title="Cap Hit"
            onChangeDollarMin={handleContractDollarMinMaxChange('minCapHit')}
            onChangeDollarMax={handleContractDollarMinMaxChange('maxCapHit')}
          />
        </Item>
      </Container>
    </Box>
  );
}

export default PlayerSearchContractMixMaxDetails;
