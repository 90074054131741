import { RootState } from 'store/index';

import { BlockNavigation, ShowToast } from 'modules/layout/layout.slice';
import { NavItem } from 'types/drawer';

export const selectShowToast = (state: RootState): boolean => state.layout.showToast;

export const selectToast = (state: RootState): ShowToast => {
  const { toastLoading, toastMessage, toastType } = state.layout;

  return { toastLoading, toastMessage, toastType };
};

export const selectInitialRoute = (state: RootState): string | null => state.layout.initialRoute;

export const selectIsPrinting = (state: RootState): boolean => state.print.isPrinting;

export const selectShowSubNav = (state: RootState): boolean => !!state.layout.subNavItems;

export const selectSubNavItems = (state: RootState): NavItem[] => state.layout.subNavItems || [];

export const selectBlockNavigation = (state: RootState): BlockNavigation => state.layout.blockNavigation;
