import makeStyles from '@mui/styles/makeStyles';
import { Box, TextField, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Container, Item, StanleyTeamSelect } from '@project-stanley/cap-management-components';
import { useSelector } from 'react-redux';

import TradeSimulatorDraftAssetItem from 'modules/tradeSimulator/tradeAssetDraftAssetItem';
import TradeSimulatorDraftAssetsHeader from 'modules/tradeSimulator/tradeSimulatorDraftAssetsHeader';
import TradeSimulatorPlayerAssetItem from 'modules/tradeSimulator/tradeSimulatorPlayerAssetItem';
import TradeSimulatorPlayerAssetsHeader from 'modules/tradeSimulator/tradeSimulatorPlayerAssetsHeader';
import TradeSimulatorTeamSummary from 'modules/tradeSimulator/tradeSimulatorTeamSummary.component';
import usePrevious from 'hooks/usePrevious';
import { Player } from 'types/player';
import { TeamDraftPick, TeamRosterOutlook, TeamSummary } from 'types/teams';
import { TradeTeam } from 'types/trade';
import { getTeamDraftPicks, getTeamRosterOutlook } from 'modules/teams/teams.slice';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectTeams } from 'modules/teams/teams.selectors';
import { useAppDispatch } from 'store';

interface TradeSimulatorTeamFormProps {
  otherTeamId: number;
  teamSummaryCapAdj: TeamSummary | null;
  teamSummaryPreview: TeamSummary | null;
  tradeDetails: TradeTeam;

  onAddDraft: () => void;
  onAddPlayer: () => void;
  onDetailsChange: (value: string) => void;
  onDraftAssetChange: (field: string, value: number | boolean, index: number) => void;
  onPlayerAssetChange: (field: string, value: number | boolean, index: number) => void;
  onRemoveDraft: () => void;
  onRemovePlayer: () => void;
  onTeamChange: (teamId: number | number[]) => void;
}

function TradeSimulatorTeamForm({
  otherTeamId,
  teamSummaryCapAdj,
  teamSummaryPreview,
  tradeDetails,
  onAddDraft,
  onAddPlayer,
  onDetailsChange,
  onDraftAssetChange,
  onPlayerAssetChange,
  onRemoveDraft,
  onRemovePlayer,
  onTeamChange,
}: TradeSimulatorTeamFormProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const teams = useSelector(selectTeams);
  const isPrinting = useSelector(selectIsPrinting);

  const teamId = usePrevious(tradeDetails.teamId) as number;

  const [players, setPlayers] = useState<Player[]>([]);
  const [draftPicks, setDraftPicks] = useState<TeamDraftPick[]>([]);

  useEffect(() => {
    if (teamId !== tradeDetails.teamId) {
      (async () => {
        const draftPicksResponse = await dispatch(getTeamDraftPicks(tradeDetails.teamId.toString()));
        const playersResponse = await dispatch(getTeamRosterOutlook(tradeDetails.teamId.toString()));

        setDraftPicks(draftPicksResponse.payload as TeamDraftPick[]);
        setPlayers((playersResponse.payload as { response: TeamRosterOutlook }).response.players);
      })();
    }
  }, [dispatch, teamId, tradeDetails.teamId]);

  const handleDetailsChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onDetailsChange(value);

  return (
    <>
      <Box paddingBottom={theme.spacing(2)}>
        <StanleyTeamSelect
          closeOnSelect
          selectClasses={{ select: classes.select }}
          teamId={tradeDetails.teamId}
          teams={teams?.filter((team) => team.teamId !== otherTeamId) || []}
          onTeamChange={onTeamChange}
        />
      </Box>
      <Box paddingBottom={theme.spacing(1)}>
        <TradeSimulatorPlayerAssetsHeader
          isPrinting={isPrinting}
          playerAssets={tradeDetails.playerAssets}
          onAddPlayer={onAddPlayer}
          onRemovePlayer={onRemovePlayer}
        />
      </Box>
      {tradeDetails.playerAssets.map((playerAsset, index) => (
        <Box key={`player-asset-${index}`} paddingBottom={theme.spacing(1)}>
          <TradeSimulatorPlayerAssetItem
            isPrinting={isPrinting}
            players={players}
            playerAsset={playerAsset}
            playerAssetsSelected={tradeDetails.playerAssets}
            onChange={(field: string, value: number | boolean) => onPlayerAssetChange(field, value, index)}
          />
        </Box>
      ))}
      <Box paddingY={theme.spacing(1)}>
        <TradeSimulatorDraftAssetsHeader
          isPrinting={isPrinting}
          draftAssets={tradeDetails.draftAssets}
          onAddDraft={onAddDraft}
          onRemoveDraft={onRemoveDraft}
        />
      </Box>
      <Container>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          {tradeDetails.draftAssets.map((draftAsset, index) => (
            <Box key={`draft-asset-${index}`} paddingBottom={theme.spacing(1)}>
              <TradeSimulatorDraftAssetItem
                isPrinting={isPrinting}
                draftPicks={draftPicks}
                draftAsset={draftAsset}
                draftAssetsSelected={tradeDetails.draftAssets}
                onChange={(field: string, value: number | boolean) => onDraftAssetChange(field, value, index)}
              />
            </Box>
          ))}
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6} className={classes.details}>
          <TextField
            className={classes.textField}
            fullWidth
            InputProps={{
              classes: { root: classes.inputRoot },
            }}
            multiline
            placeholder="Insert Transaction Details..."
            variant="outlined"
            onChange={handleDetailsChange}
          />
        </Item>
      </Container>
      <Container spacing={2}>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummary
            isCapAdjustment
            isPrinting={isPrinting}
            teamSummaryPreview={teamSummaryCapAdj}
            title="Capital Adjustments (+/-)"
          />
        </Item>
        <Item xs={isPrinting ? 6 : 12} sm={6}>
          <TradeSimulatorTeamSummary
            isPrinting={isPrinting}
            teamSummaryPreview={teamSummaryPreview}
            title="Adjusted Captial Structure"
          />
        </Item>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: `${theme.spacing(0.75)} !important`,
    paddingTop: `${theme.spacing(0.75)} !important`,
  },
  formAction: {
    marginLeft: theme.spacing(2),
  },
  addiTitle: {
    paddingBottom: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    flex: 1,
  },
  inputRoot: {
    height: '100%',
  },
}));

export default TradeSimulatorTeamForm;
