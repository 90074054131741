import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SavedCompListItem } from 'types/comparableContracts';

export interface ComparableContractsState {
  compLists: SavedCompListItem[];
}

const initialState: ComparableContractsState = {
  compLists: [],
};

export const comparableContractsSlice = createSlice({
  name: 'comparableContracts',
  initialState,
  reducers: {
    setCompLists(state, action: PayloadAction<SavedCompListItem[]>) {
      state.compLists = action.payload;
    },
    addCompListItem(state, action: PayloadAction<SavedCompListItem>) {
      state.compLists = [action.payload, ...state.compLists];
    },
    updateCompListItem(state, action: PayloadAction<SavedCompListItem>) {
      const compListItemIndex = state.compLists.findIndex((compListItem) => compListItem.id === action.payload.id);

      if (compListItemIndex !== -1) {
        const newCompLists = [...state.compLists];

        newCompLists[compListItemIndex].playerIds = action.payload.playerIds;
        state.compLists = newCompLists;
      }
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getUser.pending, (state) => {
  //       state.isLoading = true;
  //     })
  //     .addCase(getUser.fulfilled, (state, { payload }) => {
  //       state.isLoading = false;
  //       state.user = payload;
  //     })
  //     .addCase(getUser.rejected, (state) => {
  //       state.isLoading = false;
  //     });
  // },
});

export const { setCompLists, addCompListItem, updateCompListItem } = comparableContractsSlice.actions;

export default comparableContractsSlice.reducer;
