import { TeamInfo } from 'types/teams';

export interface ReportPlayerInfo {
  playerId: number;
  firstName: string;
  lastName: string;
  primaryPosition: number;
  shotHand: number;
  age: number;
  qoValue: number;
  arbRights: boolean;
  yearsUntilUfa: number;
}

interface ReportSeasonInfo {
  seasonId: number;
  startYear: string;
  endYear: string;
  startDate: string;
  endDate: string;
  seasonDisplayName: string;
  seasonType: string;
}

export interface Report {
  createdAt: string;
  description: string;
  id: string;
  imageUrl: string;
  filename: string;
  playerInfo?: ReportPlayerInfo;
  preSignedUrl: string;
  reportId: string;
  reportType: number;
  s3Etag: string;
  seasonId: number;
  seasonInfo: ReportSeasonInfo;
  tags: string[];
  teamInfo?: TeamInfo;
  title: string;
  updatedAt: string;
  visible: boolean;
}

export enum ReportType {
  'Player' = 0,
  'Team' = 1,
  'League' = 2,
}
