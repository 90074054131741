/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import MaterialTable from '@material-table/core';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { cloneDeep, eachRight, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ComparableContractsTable, {
  DragState,
} from 'modules/players/playerComparableContracts/comparableContractsTable.component';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { INITIAL_STAT_SELECTION, generateComparableContractSeasonStatFilters } from 'utilities/comparableContracts';
import { PlayerComparableContract } from 'types/comparableContracts';
import { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { ToastTypes } from 'types/layout';
import {
  getPlayerComparableContract,
  getPlayerComparableContractSolo,
  getPlayerComparableContracts,
  setPlayerContractComparables,
} from 'modules/players/players.slice';
import { getPlayerFullname } from 'utilities/player';
import { selectBlockNavigation } from 'modules/layout/layout.selectors';
import { selectComparableContractsLists } from 'modules/players/playerComparableContracts/comparableContracts.selectors';
import {
  selectPlayer,
  selectPlayerComparableContracts,
  selectPlayerComparableContractsSubject,
  selectPlayerIsLoadingComparableContracts,
} from 'modules/players/players.selectors';
import { selectPlayerCompSets } from 'modules/user/user.selectors';
import { setBlockNavigation, showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

function PlayerComparableContracts(): JSX.Element {
  const tableRef = useRef<MaterialTable<any>>();

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { playerId } = useParams();

  const blockNavigation = useSelector(selectBlockNavigation);
  const playerComparableContracts = useSelector(selectPlayerComparableContracts);
  const playerComparableContractsSubject = useSelector(selectPlayerComparableContractsSubject);
  const player = useSelector(selectPlayer);
  const playerCompSets = useSelector(selectPlayerCompSets);
  const savedCompLists = useSelector(selectComparableContractsLists);
  const isLoadingDefaultComps = useSelector(selectPlayerIsLoadingComparableContracts);

  const [selectedStatSeason, setSelectedStatSeason] = useState(INITIAL_STAT_SELECTION);
  const [compSets, setCompSets] = useState(playerCompSets || {});
  const [selectedCompSet, setSelectedCompSet] = useState('');
  const [isLoadingCompSet, setIsLoadingCompSet] = useState(false);

  useEffect(() => {
    if (playerCompSets) setCompSets(playerCompSets);
  }, [playerCompSets]);

  const requestIndividualComps = useCallback(
    async (value: string) => {
      const savedCompList = savedCompLists.find((compListItem) => compListItem.id === value);

      if (savedCompList) {
        setIsLoadingCompSet(true);

        const fullCompSet: PlayerComparableContract[] = await Promise.all(
          savedCompList.playerIds.map(async (id) => {
            const response = await dispatch(getPlayerComparableContractSolo(id.toString()));

            return response.payload as PlayerComparableContract;
          }),
        );

        setIsLoadingCompSet(false);
        dispatch(setPlayerContractComparables(fullCompSet.filter((compSet) => compSet !== undefined)));
      }
    },
    [dispatch, savedCompLists],
  );

  const handleSaveCompSet = useCallback(
    (playerCompSet: PlayerComparableContract[]) => {
      let compSetToSave = compSets as Record<string, unknown>;

      if (playerCompSet) {
        const compPlayerIds = playerCompSet.reduce((acc, contractComp) => {
          acc.push(contractComp.playerInfo.playerId.toString());

          return acc;
        }, [] as string[]);

        if (playerComparableContractsSubject) {
          compSetToSave = {
            ...compSetToSave,
            [playerComparableContractsSubject?.playerInfo.playerId]: compPlayerIds.join(','),
          };
        }
      }

      dispatch(
        setBlockNavigation({
          ...blockNavigation,
          shouldBlockNavigation: true,
          actions: [
            ...blockNavigation.actions,
            {
              storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS,
              data: compSetToSave,
            },
          ],
        }),
      );
    },
    [dispatch, blockNavigation, compSets, playerComparableContractsSubject],
  );

  const handlePlayerOptionSelected = useCallback(
    async (optionSelected: StanleyPlayerSearchOption | null) => {
      if (optionSelected) {
        const { label, value } = optionSelected;

        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.PLAYER_PROFILE_ADD_CONTRACT_COMPARABLE,
          label,
          value: Number(value),
        });

        const response = await dispatch(getPlayerComparableContract(value));

        if (response.type === getPlayerComparableContract.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Unable to get player contract',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }

        if (playerComparableContracts) {
          handleSaveCompSet([response.payload, ...playerComparableContracts] as PlayerComparableContract[]);
        }
      }
    },
    [dispatch, playerComparableContracts, handleSaveCompSet],
  );

  const handleRemoveComparableContracts = useCallback(
    (contractsToRemove: (PlayerComparableContract & { id: number })[]) => {
      const newComparableContracts = cloneDeep(playerComparableContracts);

      eachRight(contractsToRemove, (comparableContract) => newComparableContracts?.splice(comparableContract.id, 1));

      (tableRef?.current as any)?.onAllSelected(false);

      dispatch(setPlayerContractComparables(newComparableContracts));

      if (newComparableContracts) handleSaveCompSet(newComparableContracts);

      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: GA_ACTIONS.PLAYER_PROFILE_REMOVE_CONTRACT_COMPARABLE,
        label: player ? getPlayerFullname(player) : '',
        value: player?.playerId,
      });
    },
    [dispatch, player, playerComparableContracts, handleSaveCompSet],
  );

  const seasonStatFilters = useMemo(
    () => generateComparableContractSeasonStatFilters(playerComparableContracts),
    [playerComparableContracts],
  );

  const handleMoveContract = useCallback(
    (dragState: DragState) => {
      if (!playerComparableContracts) return;

      const newComparableContracts = cloneDeep(playerComparableContracts);
      const contract = newComparableContracts.splice(dragState.rowId, 1);

      if (!contract) return;

      newComparableContracts?.splice(dragState.dropIndex, 0, contract[0]);

      dispatch(setPlayerContractComparables(newComparableContracts));
      handleSaveCompSet(newComparableContracts);

      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: GA_ACTIONS.PLAYER_PROFILE_MOVE_CONTRACT_COMPARABLE,
        label: player ? getPlayerFullname(player) : '',
        value: player?.playerId,
      });
    },
    [dispatch, player, playerComparableContracts, handleSaveCompSet],
  );

  const handleSelectedStatSeasonChange = useCallback(
    (value: string) => {
      if (value === selectedStatSeason) return;

      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: GA_ACTIONS.CONTRACT_COMP_SEASON_FILTER_CHANGE,
        label: player ? getPlayerFullname(player) : '',
        value: selectedStatSeason,
      });

      setSelectedStatSeason(value);
    },
    [player, selectedStatSeason],
  );

  const handleSelectedListChange = useCallback(
    (value: string) => {
      if (value === selectedCompSet) return;

      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: GA_ACTIONS.CONTRACT_COMP_LIST_CHANGE,
        label: player ? getPlayerFullname(player) : '',
        value,
      });

      if (value === 'default') {
        if (playerId) dispatch(getPlayerComparableContracts(playerId));

        setSelectedCompSet(value);
        return;
      }

      if (value !== '') requestIndividualComps(value);

      setSelectedCompSet(value);

      const selectedCompList = savedCompLists.find((item) => item.id === value);

      if (selectedCompList && playerComparableContractsSubject) {
        dispatch(
          setBlockNavigation({
            ...blockNavigation,
            shouldBlockNavigation: true,
            actions: [
              ...blockNavigation.actions,
              {
                storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS,
                data: {
                  ...compSets,
                  [playerComparableContractsSubject?.playerInfo.playerId]: selectedCompList.playerIds,
                },
              },
            ],
          }),
        );
      }
    },
    [
      dispatch,
      blockNavigation,
      compSets,
      player,
      playerId,
      savedCompLists,
      playerComparableContractsSubject,
      selectedCompSet,
      requestIndividualComps,
    ],
  );

  if (isNil(playerComparableContracts) || isNil(playerComparableContractsSubject)) {
    return (
      <Box margin={theme.spacing(4)} marginBottom={theme.spacing(4)} textAlign="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <ComparableContractsTable
      tableRef={tableRef}
      comparableContractsSubject={playerComparableContractsSubject}
      comparableContracts={playerComparableContracts || []}
      isLoading={isLoadingCompSet || isLoadingDefaultComps}
      selectedSeason={selectedStatSeason}
      selectedCompSet={selectedCompSet}
      seasonStatFilters={seasonStatFilters}
      onMoveContract={handleMoveContract}
      onOptionSelected={handlePlayerOptionSelected}
      onRemoveContracts={handleRemoveComparableContracts}
      onSeasonChange={handleSelectedStatSeasonChange}
      onSelectCompSet={handleSelectedListChange}
    />
  );
}

export default PlayerComparableContracts;
