import { Player } from 'types/player';
import { TeamInfo } from 'types/teams';

export interface PlayerContract {
  contractId: number;
  playerId: number;
  contractDetails: PlayerContractDetails[];
  contractLength: number;
  contractType: ContractType;
  description: string;
  expiryStatus: ContractExpiryStatus;
  isExtension: boolean;
  playerInfo?: Player;
  signingDate: Date;
  signingTeam: TeamInfo;
  totalValue: number;
  arbRights: boolean;
  qoValue: number;
  professionalSeasons: number;
  nhlseaons: number;
}

export interface PlayerContractDetails {
  contractDetailId: string;
  contractId: string;
  playerId: string;
  averageAnnualValue: number;
  baseSalary: number;
  capHit: number;
  capHitPercentage: number;
  clauses: ContractClause[];
  minorsSalary: number;
  performanceBonus: number;
  seasonInfo: ContractSeasonInfo;
  signingBonus: number;
  status: ContractStatus;
  totalSalary: number;
}

export interface ContractSeasonInfo {
  seasonYearStart: number;
  seasonYearEnd: number;
}

export enum ContractType {
  STANDARD = 1,
  ENTRY_LEVEL_CONTRACT = 2,
}

export enum ContractExpiryStatus {
  RESTRICTED_FREE_AGENT = 1,
  UNRESTRICTED_FREE_AGENT = 2,
}

export enum ContractClause {
  NO_TRADE = 1,
  MODIFIED_NO_TRADE = 2,
  NO_MOVEMENT = 3,
}

export enum ContractStatus {
  ACTIVE = 1,
  IN_ACTIVE = 2,
  IR = 3,
  LTIR = 4,
}

export interface PlayerContractSimulationPreview {
  playerId: number;
  signingTeamId: number;
  contractType: number;
  expiryStatus: number;
  contractDetails: PlayerContractSimulationPreviewDetails[];
}

export interface PlayerContractSimulationPreviewDetails {
  baseSalary: number;
  hasNoMovementClause: boolean;
  hasNoTradeClause: boolean;
  minorsSalary: number;
  performanceBonus: number;
  season: string;
  signingBonus: number;
  [key: string]: boolean | number | string;
}

export interface PlayerContractSimulation {
  contractDetails: PlayerContractSimulationDetails[];
  contractType: ContractType;
  signingTeamId: number;
  totalValue: number;
}
export interface PlayerContractSimulationDetails {
  averageAnnualValue: number;
  baseSalary: number;
  capHit: number;
  capHitPercentage: number;
  minorsSalary: number;
  hasNoMovementClause: boolean;
  hasNoTradeClause: boolean;
  performanceBonus: number;
  season: string;
  signingBonus: number;
  totalSalary: number;
}
