/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'middleware/api';
import { Filters, PlayerSearchResult } from 'types/playerSearch';
import { PlayerListItem } from 'types/player';

export interface PlayersState {
  isLoading: boolean;
  isLoadingAdvancedSearch: boolean;
  players: PlayerListItem[] | null;
}

const initialState: PlayersState = {
  isLoading: false,
  isLoadingAdvancedSearch: false,
  players: null,
};

export const getPlayers = createAsyncThunk(
  'search/getPlayers',
  async () => (await api('v2/players', { method: 'GET' }, true)) as PlayerListItem[],
);

export const searchAdvanced = createAsyncThunk(
  'search/searchAdvanced',
  async (searchPayload: Filters) =>
    (await api('players/advancedsearch', { method: 'POST', body: searchPayload }, true)) as {
      matchingPlayerList: PlayerSearchResult[];
    },
);

export const getAdvancedPlayerData = createAsyncThunk(
  'search/getAdvancedPlayerData',
  async ({ playerIds, seasonYear }: { playerIds: string; seasonYear: string }) =>
    (await api(`players/advancedsearch?playerIds=${playerIds}&seasonYear=${seasonYear}`, { method: 'GET' }, true)) as {
      matchingPlayerList: PlayerSearchResult[];
    },
);

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlayers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlayers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.players = payload;
      })
      .addCase(getPlayers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchAdvanced.pending, (state) => {
        state.isLoadingAdvancedSearch = true;
      })
      .addCase(searchAdvanced.fulfilled, (state) => {
        state.isLoadingAdvancedSearch = false;
      })
      .addCase(searchAdvanced.rejected, (state) => {
        state.isLoadingAdvancedSearch = false;
      })
      .addCase(getAdvancedPlayerData.pending, (state) => {
        state.isLoadingAdvancedSearch = true;
      })
      .addCase(getAdvancedPlayerData.fulfilled, (state) => {
        state.isLoadingAdvancedSearch = false;
      })
      .addCase(getAdvancedPlayerData.rejected, (state) => {
        state.isLoadingAdvancedSearch = false;
      });
  },
});

export default searchSlice.reducer;
