import { Box, CircularProgress } from '@mui/material';
import { Container } from '@project-stanley/cap-management-components';
import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import Trades from 'components/trades/trades.component';
import usePrevious from 'hooks/usePrevious';
import { PlayerTradeComparables } from 'types/player';
import { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { TeamTrade } from 'types/teams';
import { ToastTypes } from 'types/layout';
import { getTrades } from 'modules/teams/teams.slice';
import { makeStyles } from '@mui/styles';
import { selectPlayerComparableTrades } from 'modules/players/players.selectors';
import { selectTradesIsLoading } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

function PlayerComparableTrades() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isTradesLoading = useSelector(selectTradesIsLoading);
  const trades = useSelector(selectPlayerComparableTrades);

  const [tradeComps, setTradeComps] = useState(trades);

  const prevTrades = usePrevious(trades) as TeamTrade[] | null;

  useEffect(() => {
    if (prevTrades === null && !isNil(trades)) setTradeComps(trades);
  }, [prevTrades, trades]);

  const handleSearchOptionSelected = useCallback(
    async (optionSelected?: StanleyPlayerSearchOption | null) => {
      if (optionSelected) {
        const searchedTrades = (await dispatch(getTrades({ playerIds: [Number(optionSelected.value)] }))) as {
          type: string;
          payload: PlayerTradeComparables;
        };

        if (searchedTrades.type === getTrades.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Unable to find trades. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }
        if (searchedTrades.payload.trades.length === 0) {
          dispatch(
            showToast({
              toastMessage: 'No trades with this player found. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }

        setTradeComps((prevTradeComps) => {
          if (prevTradeComps) return [...searchedTrades.payload.trades, ...prevTradeComps];

          return searchedTrades.payload.trades;
        });
      }
    },
    [dispatch],
  );

  const handleRemoveTrades = useCallback((newTrades: TeamTrade[]) => setTradeComps(newTrades), []);

  if (!tradeComps) {
    return (
      <Container className={classes.container}>
        <CircularProgress color="primary" />
      </Container>
    );
  }

  return (
    <Box position="relative">
      {isTradesLoading && (
        <Box className={classes.overlay}>
          <CircularProgress color="primary" />
        </Box>
      )}
      <Trades
        showRemove
        trades={tradeComps}
        onRemoveTrades={handleRemoveTrades}
        onSearchOptionSelected={handleSearchOptionSelected}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: `${Colors.BRAND_BLACK}25`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 100,
  },
}));

export default PlayerComparableTrades;
