import { StanleyTransparentButton } from '@project-stanley/cap-management-components';
import { styled } from '@mui/material';
import { useLocation, useMatch, useResolvedPath } from 'react-router-dom';

import Colors from 'styles/colors';
import { NavItem } from 'types/drawer';
import { ROUTES } from 'utilities/routes';

interface HeaderLinkProps {
  isSubLink?: boolean;
  link: NavItem;
  onClick: () => void;
}

const StyledStanleyTransparentButton = styled(StanleyTransparentButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active }) => ({
  boxShadow: active ? `inset 0 -2px 0 0 ${Colors.BRAND_GOLD}` : 'none',
  borderRadius: 0,
  color: active ? Colors.BRAND_GOLD : Colors.BRAND_BLACK,
  textTransform: 'none',
  fontWeight: 'unset',
  '&:hover': {
    color: Colors.BRAND_GOLD,
  },
}));

function HeaderLink({ isSubLink = false, link, onClick }: HeaderLinkProps): JSX.Element {
  const location = useLocation();

  const resolved = useResolvedPath(link.path || ROUTES.TEAMS);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <StyledStanleyTransparentButton
      key={link.text}
      active={Boolean(match) || Boolean(!isSubLink && link.path && location.pathname.includes(link.path))}
      onClick={onClick}
    >
      {link.text}
    </StyledStanleyTransparentButton>
  );
}

export default HeaderLink;
