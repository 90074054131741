import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { StanleyTeamSelect } from '@project-stanley/cap-management-components';

import { Team } from 'types/teams';

interface RosterComparisonRosterFiltersProps {
  teamId: number;
  teams: Team[];
  onTeamChange: (teamId: number | number[]) => void;
}

function RosterComparisonRosterFilters({
  teamId,
  teams,
  onTeamChange,
}: RosterComparisonRosterFiltersProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box display="flex">
      <StanleyTeamSelect
        selectClasses={{ select: classes.teamSelect }}
        teamId={teamId}
        teams={teams}
        onTeamChange={onTeamChange}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  teamSelect: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: `${theme.spacing(0.3125)} !important`,
    paddingTop: `${theme.spacing(0.3125)} !important`,
  },
}));

export default RosterComparisonRosterFilters;
