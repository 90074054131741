import { Action, AnyAction, Reducer, ThunkAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { PrintState, printReducer } from '@project-stanley/cap-management-components';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from 'modules/auth/auth.slice';
import comparableContractsReducer from 'modules/players/playerComparableContracts/comparableContracts.slice';
import contractsReducer from 'modules/contracts/contracts.slice';
import layoutReducer from 'modules/layout/layout.slice';
import playersReducer from 'modules/players/players.slice';
import recentTransactionsReducer from 'modules/teams/recentTransactions/recentTransactions.slice';
import researchReducer from 'modules/research/research.slice';
import rosterComparisonReducer from 'modules/rosterComparison/rosterComparison.slice';
import scenariosReducer from 'modules/scenarios/scenarios.slice';
import scoresReducer from 'modules/scoreBoard/scoreboard.slice';
import searchReducer from 'modules/advancedSearch/search.slice';
import seasonReducer from 'modules/season/season.slice';
import teamsReducer from 'modules/teams/teams.slice';
import userReducer from 'modules/user/user.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    players: playersReducer,
    print: printReducer as Reducer<PrintState, AnyAction>,
    recentTransactions: recentTransactionsReducer,
    research: researchReducer,
    rosterComparison: rosterComparisonReducer,
    scenarios: scenariosReducer,
    scores: scoresReducer,
    season: seasonReducer,
    teams: teamsReducer,
    user: userReducer,
    comparableContracts: comparableContractsReducer,
    contracts: contractsReducer,
    search: searchReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): ThunkDispatch<RootState, null | undefined, Action<string>> =>
  useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
