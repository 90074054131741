import { PlayerInfo } from 'types/player';
import { TeamInfo } from 'types/teams';

export interface RecentSigning {
  averageAnnualValue: number;
  capHit: number;
  contractLength: number;
  playerInfo: PlayerInfo;
  signingDate: string;
  signingTeam: TeamInfo;
}

export interface RecentRecall {
  id: string;
  abbreviation: string;
  playerName: string;
  playerId: string;
  type: string;
}

export interface RecentInjury {
  id: string;
  playerInfo: PlayerInfo;
  teamInfo: TeamInfo;
  date: string;
  transaction: InjuryTransactionType;
  type: InjuryType;
}

export interface RecentTransaction {
  id: string;
  playerInfo: PlayerInfo;
  teamInfo: TeamInfo;
  date: string;
  type: TransactionType;
}

export enum TransactionType {
  Recall = 0,
  Reassign = 1,
  Loan = 2,
  'Conditioning Loan' = 3,
  Junior = 4,
}

export enum InjuryTransactionType {
  'Injured Reserve' = 0,
  'Long-Term IR' = 1,
  SOIR = 2,
  LTICL = 3,
}

export enum InjuryType {
  Deactivated = 0,
  Activated = 1,
}
