import { Alert, CircularProgress, Snackbar, Typography, styled } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ToastTypes } from 'types/layout';
import { clearToast } from 'modules/layout/layout.slice';
import { selectShowToast, selectToast } from 'modules/layout/layout.selectors';
import { useAppDispatch } from 'store';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledAlert = styled(Alert, {
  shouldForwardProp: (prop: string) => prop !== 'toastType',
})<{ toastType: string | null }>(({ toastType, theme }) => {
  const defaultStyles = {
    alignItems: 'center',
    boxShadow: theme.shadows[10],
  };

  switch (toastType) {
    case ToastTypes.BRAND_GOLD:
      return { ...defaultStyles, backgroundColor: theme.palette.primary.main };

    case ToastTypes.ERROR:
      return { ...defaultStyles, backgroundColor: theme.palette.error.main };

    default:
      return { ...defaultStyles, backgroundColor: theme.palette.common.white };
  }
});

function ToastMessage(): JSX.Element {
  const dispatch = useAppDispatch();

  const showToast = useSelector(selectShowToast);
  const toast = useSelector(selectToast);

  const handleClose = useCallback(() => {
    dispatch(clearToast());
  }, [dispatch]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={5000}
      open={showToast}
      onClose={handleClose}
    >
      <StyledAlert
        icon={toast.toastLoading ? <StyledCircularProgress size="1.5rem" /> : null}
        sx={{
          width: '100%',
          '& .MuiAlert-action': {
            color: toast.toastType === ToastTypes.SUCCESS ? 'black' : 'white',
            paddingTop: 0,
          },
          '& .MuiAlert-icon': {
            color: toast.toastType === ToastTypes.SUCCESS ? 'green' : 'white',
          },
        }}
        severity={toast.toastType === ToastTypes.SUCCESS ? 'success' : 'error'}
        toastType={toast.toastType}
        onClose={handleClose}
      >
        <Typography color={toast.toastType === ToastTypes.SUCCESS ? 'black' : 'white'} fontSize="1rem">
          {toast.toastMessage}
        </Typography>
      </StyledAlert>
    </Snackbar>
  );
}

export default ToastMessage;
