/* eslint-disable react/no-unstable-nested-components */
import { Box } from '@mui/material';
import { MTableBody } from '@material-table/core';
import { StanleyDollar, StanleyTable } from '@project-stanley/cap-management-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RosterDeadCapTableSummary from 'modules/teams/rosterView/rosterDeadCapTableSummary.component';
import { ROSTER_CONTRACT_FILTER_KEYS } from 'utilities/roster';
import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { TeamDeadCap, TeamDeadCapType } from 'types/teams';
import { formatYearRange } from 'utilities/date';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';

interface RosterDeadCapTableProps {
  deadCapOutlook: TeamDeadCap[] | null;
}

function RosterDeadCapTable({ deadCapOutlook }: RosterDeadCapTableProps): JSX.Element {
  const navigate = useNavigate();

  const curSeasonStartYear = useSelector(selectSeasonStartYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);

  const renderDeadCapColumn = useCallback(
    (rowData: TeamDeadCap, yearNumber: number) => {
      if (!curSeasonStartYear) return <Box>--</Box>;

      const curSeasonId = `${curSeasonStartYear + yearNumber}-${curSeasonStartYear + yearNumber + 1}`;
      const curSeasonBuyout = rowData.details.find((buyoutDetails) => buyoutDetails.seasonId === curSeasonId);

      if (!curSeasonBuyout || !curSeasonBuyout[rosterStatFilter]) return '--';

      if (rosterStatFilter === ROSTER_CONTRACT_FILTER_KEYS.PER_OF_PROJ_CAP) {
        return (
          <Box>
            {(curSeasonBuyout[rosterStatFilter] as number).toFixed(2)}
            &nbsp;%
          </Box>
        );
      }

      return <StanleyDollar amount={curSeasonBuyout[rosterStatFilter] as number} />;
    },
    [rosterStatFilter, curSeasonStartYear],
  );

  const columns = [
    {
      title: 'Projected Dead Cap',
      cellStyle: { minWidth: 200 },
      hasSortCellStyle: false,
      customSort: (rowA: TeamDeadCap, rowB: TeamDeadCap) =>
        `${rowA.lastName}, ${rowA.firstName}`.localeCompare(`${rowB.lastName}, ${rowB.firstName}`),
      render: ({ firstName, lastName }: TeamDeadCap) => `${lastName}, ${firstName}`,
    },
    {
      title: 'Type',
      cellStyle: { minWidth: 185 },
      hasSortCellStyle: false,
      render: ({ deadCapType }: TeamDeadCap) => TeamDeadCapType[deadCapType],
    },
    {
      cellStyle: { minWidth: 75 },
      sorting: false,
    },
    {
      cellStyle: { minWidth: 75 },
      sorting: false,
    },
    {
      title: formatYearRange(curSeasonStartYear),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 0),
    },
    {
      title: formatYearRange(curSeasonStartYear, 1),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 1),
    },
    {
      title: formatYearRange(curSeasonStartYear, 2),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 2),
    },
    {
      title: formatYearRange(curSeasonStartYear, 3),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 3),
    },
    {
      title: formatYearRange(curSeasonStartYear, 4),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 4),
    },
    {
      title: formatYearRange(curSeasonStartYear, 5),
      cellStyle: { minWidth: 100 },
      render: (rowData: TeamDeadCap) => renderDeadCapColumn(rowData, 5),
    },
  ];

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: TeamDeadCap) => {
      if (rowData && rowData.playerId) {
        navigate(`${ROUTES.PLAYERS}/${rowData.playerId}`);
      }
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            <RosterDeadCapTableSummary players={deadCapOutlook || []} rosterStatFilter={rosterStatFilter} />
          </>
        ),
      }}
      data={deadCapOutlook || []}
      onRowClick={handleRowClick}
    />
  );
}

export default RosterDeadCapTable;
