import { AuthError, signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Typography, styled } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignInForm from 'modules/auth/signInForm.component';
import { ROUTES } from 'utilities/routes';
import { SignInUser } from 'types/auth';
import { ToastTypes } from 'types/layout';
import { auth } from 'utilities/firebase';
import { selectUser } from 'modules/auth/auth.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '30rem',
  padding: theme.spacing(2),
  textAlign: 'center',
  width: '100%',
}));

function SignIn(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [isSigningIn, setIsSigningIn] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(ROUTES.LOADING);
    }
  }, [navigate, user]);

  useEffect(() => {
    const zendeskEl = document.querySelector('#launcher');

    zendeskEl?.setAttribute('class', 'hide-zendesk');
  }, []);

  const handleSignIn = useCallback(
    async ({ email, password }: SignInUser) => {
      setIsSigningIn(true);

      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (err) {
        dispatch(
          showToast({
            toastMessage: (err as AuthError).message,
            toastType: ToastTypes.ERROR,
          }),
        );
      }

      setIsSigningIn(false);
    },
    [dispatch, setIsSigningIn],
  );

  return (
    <Box alignItems="center" display="flex" justifyContent="center" width="100%">
      <StyledBox>
        <Container direction="column" spacing={3}>
          <Item>
            <Typography variant="h6">Project Stanley</Typography>
          </Item>
          <Item>
            <SignInForm isLoading={isSigningIn} onSubmit={handleSignIn} />
          </Item>
        </Container>
      </StyledBox>
    </Box>
  );
}

export default SignIn;
