/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';

export const formatYearRange = (curYear: number, yearsToAdd = 0): string => {
  const startDate = new Date(curYear + yearsToAdd, 1, 1);
  const endDate = new Date(curYear + yearsToAdd + 1, 1, 1);

  const formattedStartYear = format(startDate, 'yyyy');
  const formattedEndYear = format(endDate, 'yy');

  return `${formattedStartYear}-${formattedEndYear}`;
};
