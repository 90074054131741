import { Box } from '@mui/material';
import { isEmpty } from 'lodash';

import { PLAYER_POSITIONS, PLAYER_POSITION_ABBR_MAP } from 'utilities/player';
import { Player, PlayerPosition } from 'types/player';
import { isForwardPosition } from 'utilities/roster';

interface RosterPositionColumnProps {
  player: Player;
}

function RosterPositionColumn({
  player: { primaryPosition, secondaryPositions },
}: RosterPositionColumnProps): JSX.Element {
  if (PlayerPosition[primaryPosition] === PLAYER_POSITIONS.G) return <Box>Goalie&nbsp;(G)</Box>;

  const positionGroup = 'Forward';
  const positionPrimary = PLAYER_POSITION_ABBR_MAP[primaryPosition];
  let positionSecondary = secondaryPositions ? PLAYER_POSITION_ABBR_MAP[secondaryPositions[0]] : '';

  if (isForwardPosition(PlayerPosition[primaryPosition])) {
    if (isEmpty(secondaryPositions)) {
      return <Box>{`${positionGroup} (${positionPrimary})`}</Box>;
    }

    switch (PlayerPosition[primaryPosition]) {
      case PlayerPosition[1]:
      case PlayerPosition[2]: {
        if (secondaryPositions.includes(PlayerPosition.Center)) {
          positionSecondary = PLAYER_POSITION_ABBR_MAP[secondaryPositions[3]];
        } else {
          return <Box>{`${positionGroup} (W)`}</Box>;
        }

        return <Box>{`${positionGroup} (${positionPrimary}/${positionSecondary})`}</Box>;
      }

      default: {
        positionSecondary = secondaryPositions.length === 1 ? PLAYER_POSITION_ABBR_MAP[secondaryPositions[0]] : 'W';
        return <Box>{`${positionGroup} (${positionPrimary}/${positionSecondary})`}</Box>;
      }
    }
  }

  return isEmpty(secondaryPositions) ? (
    <Box>{`Defense (${PLAYER_POSITION_ABBR_MAP[primaryPosition]})`}</Box>
  ) : (
    <Box>
      {`Defense (${PLAYER_POSITION_ABBR_MAP[primaryPosition]}/${PLAYER_POSITION_ABBR_MAP[secondaryPositions[0]]})`}
    </Box>
  );
}

export default RosterPositionColumn;
