import { Box, Typography, styled, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

// eslint-disable-next-line max-len
import PlayerContractSimulator from 'modules/players/playerContracts/playerContractSimulator/playerContractSimulator.component';
import PlayerContractSummary from 'modules/players/playerContracts/playerContractSummary.component';
import PlayerContractTable from 'modules/players/playerContracts/playerContractTable.component';
import { CONTRACT_TYPE_MAP } from 'utilities/contract';
import { PlayerContract } from 'types/contract';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectPlayer } from 'modules/players/players.selectors';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
}));

interface PlayerContractsProps {
  contracts: PlayerContract[];
}

function PlayerContracts({ contracts }: PlayerContractsProps): JSX.Element {
  const theme = useTheme();

  const isPrinting = useSelector(selectIsPrinting);
  const player = useSelector(selectPlayer);
  const scenario = useSelector(selectScenario);

  return (
    <>
      {scenario && !isPrinting && player && <PlayerContractSimulator player={player} playerContracts={contracts} />}
      {[...contracts].reverse().map(
        (contract, index): JSX.Element => (
          <Box key={contract.contractId} marginBottom={theme.spacing(4)}>
            <StyledTypography variant="subtitle2">
              Deal&nbsp;#
              {contracts.length - index}
              :&nbsp;
              {CONTRACT_TYPE_MAP[contract.contractType]}
            </StyledTypography>
            <PlayerContractSummary contract={contract} />
            <PlayerContractTable contract={contract.contractDetails} />
          </Box>
        ),
      )}
    </>
  );
}

export default PlayerContracts;
