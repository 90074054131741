/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-restricted-syntax */
import clearCache from 'clear-cache';
import { Component, ReactNode } from 'react';

import semverGreaterThan from 'utilities/semVer';

import packageJson from '../../../package.json';

(global as any).appVersion = packageJson.version;

export interface CacheBusterState {
  loading: boolean;
  isLatestVersion: boolean;
  refreshCacheAndReload: () => void;
}

interface CacheBusterProps {
  children: (props: CacheBusterState) => ReactNode;
}

export default class CacheBuster extends Component<CacheBusterProps, CacheBusterState> {
  constructor(props: CacheBusterProps) {
    super(props);

    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        clearCache(true);
      },
    };
  }

  componentDidMount() {
    fetch(`/meta.json?dt=${Date.now()}`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version as string;
        const currentVersion = (global as any).appVersion as string;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch((e) => {
        console.log('Failed fetch of meta.json', e);
        this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;

    return children({ loading, isLatestVersion, refreshCacheAndReload }) as JSX.Element;
  }
}
