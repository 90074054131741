import { ChangeEvent, useCallback } from 'react';
import { Container, Item } from '@project-stanley/cap-management-components';

import MinMaxFilter from 'modules/advancedSearch/minMaxFilter.component';
import { Box } from '@mui/material';
import { Filters } from 'types/playerSearch';

interface PlayerSearchMixMaxStatsProps {
  filters: Filters;
  onChange: React.Dispatch<React.SetStateAction<Filters>>;
}

function PlayerSearchMixMaxStats({ filters, onChange }: PlayerSearchMixMaxStatsProps) {
  const handleStatFiltersChange = useCallback(
    (filterKey: string) =>
      ({ target }: ChangeEvent<{ value: unknown }>) => {
        let newValue = target.value as string;

        if (filterKey !== 'timeOnIcePerGameMin' && filterKey !== 'timeOnIcePerGameMax') {
          newValue = (target.value as string).replace(/[^0-9.]/g, '');
        }

        onChange((curFilters) => ({
          ...curFilters,
          playerStatsSearchCriteria: {
            ...curFilters.playerStatsSearchCriteria,
            [filterKey]: newValue,
          },
        }));
      },
    [onChange],
  );

  const handleClearStatFilter = useCallback(
    (filterKey: string) => () => {
      onChange((curFilters) => ({
        ...curFilters,
        playerStatsSearchCriteria: {
          ...curFilters.playerStatsSearchCriteria,
          [filterKey]: '',
        },
      }));
    },
    [onChange],
  );

  return (
    <Box flex={1} padding="1rem" sx={{ overflowY: 'scroll' }}>
      <Container spacing={4}>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.maxGamesPlayed || ''}
            minValue={filters.playerStatsSearchCriteria?.minGamesPlayed || ''}
            placeholders={{
              maxPlaceholder: '100',
              minPlaceholder: '1',
            }}
            title="Games Played"
            onChangeMin={handleStatFiltersChange('minGamesPlayed')}
            onChangeMax={handleStatFiltersChange('maxGamesPlayed')}
            onClearMax={handleClearStatFilter('maxGamesPlayed')}
            onClearMin={handleClearStatFilter('minGamesPlayed')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.maxPoints || ''}
            minValue={filters.playerStatsSearchCriteria?.minPoints || ''}
            placeholders={{
              maxPlaceholder: '100',
              minPlaceholder: '1',
            }}
            title="Points"
            onChangeMin={handleStatFiltersChange('minPoints')}
            onChangeMax={handleStatFiltersChange('maxPoints')}
            onClearMax={handleClearStatFilter('maxPoints')}
            onClearMin={handleClearStatFilter('minPoints')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.pointsPerGameMax || ''}
            minValue={filters.playerStatsSearchCriteria?.pointsPerGameMin || ''}
            placeholders={{
              maxPlaceholder: '1.00',
              minPlaceholder: '0.10',
            }}
            title="Points Per Game (P/PG)"
            onChangeMin={handleStatFiltersChange('pointsPerGameMin')}
            onChangeMax={handleStatFiltersChange('pointsPerGameMax')}
            onClearMax={handleClearStatFilter('pointsPerGameMax')}
            onClearMin={handleClearStatFilter('pointsPerGameMin')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            isTOIFilter
            maxValue={filters.playerStatsSearchCriteria?.timeOnIcePerGameMax || ''}
            minValue={filters.playerStatsSearchCriteria?.timeOnIcePerGameMin || ''}
            placeholders={{
              maxPlaceholder: 'MM:SS',
              minPlaceholder: 'MM:SS',
            }}
            title="ATOI"
            onChangeMin={handleStatFiltersChange('timeOnIcePerGameMin')}
            onChangeMax={handleStatFiltersChange('timeOnIcePerGameMax')}
            onClearMax={handleClearStatFilter('timeOnIcePerGameMax')}
            onClearMin={handleClearStatFilter('timeOnIcePerGameMin')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.maxGoals || ''}
            minValue={filters.playerStatsSearchCriteria?.minGoals || ''}
            placeholders={{
              maxPlaceholder: '100',
              minPlaceholder: '1',
            }}
            title="Goals"
            onChangeMin={handleStatFiltersChange('minGoals')}
            onChangeMax={handleStatFiltersChange('maxGoals')}
            onClearMax={handleClearStatFilter('maxGoals')}
            onClearMin={handleClearStatFilter('minGoals')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.goalsPerGameMax || ''}
            minValue={filters.playerStatsSearchCriteria?.goalsPerGameMin || ''}
            placeholders={{
              maxPlaceholder: '1.00',
              minPlaceholder: '0.10',
            }}
            title="Goals Per Game (G/PG)"
            onChangeMin={handleStatFiltersChange('goalsPerGameMin')}
            onChangeMax={handleStatFiltersChange('goalsPerGameMax')}
            onClearMax={handleClearStatFilter('goalsPerGameMax')}
            onClearMin={handleClearStatFilter('goalsPerGameMin')}
          />
        </Item>
        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.maxAssists || ''}
            minValue={filters.playerStatsSearchCriteria?.minAssists || ''}
            placeholders={{
              maxPlaceholder: '100',
              minPlaceholder: '1',
            }}
            title="Assists"
            onChangeMin={handleStatFiltersChange('minAssists')}
            onChangeMax={handleStatFiltersChange('maxAssists')}
            onClearMax={handleClearStatFilter('maxAssists')}
            onClearMin={handleClearStatFilter('minAssists')}
          />
        </Item>

        <Item xs={12} sm={6}>
          <MinMaxFilter
            maxValue={filters.playerStatsSearchCriteria?.assistsPerGameMax || ''}
            minValue={filters.playerStatsSearchCriteria?.assistsPerGameMin || ''}
            placeholders={{
              maxPlaceholder: '1.00',
              minPlaceholder: '0.10',
            }}
            title="Assists Per Game (A/PG)"
            onChangeMin={handleStatFiltersChange('assistsPerGameMin')}
            onChangeMax={handleStatFiltersChange('assistsPerGameMax')}
            onClearMax={handleClearStatFilter('assistsPerGameMax')}
            onClearMin={handleClearStatFilter('assistsPerGameMin')}
          />
        </Item>
      </Container>
    </Box>
  );
}

export default PlayerSearchMixMaxStats;
