/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';

import { CAP_SUMMARY_COLUMN_KEY_MAP, CAP_SUMMARY_COLUMN_MAP } from 'utilities/teams';
import { CONTRACT_EXPIRY_STATUS_MAP, CONTRACT_STATUS_MAP } from 'utilities/contract';
import { PLAYER_POSITION_ABBR_MAP, PLAYER_POSITION_MAP, getFullName, getPlayerFullname } from 'utilities/player';
import { Player } from 'types/player';
import { PlayerComparableContract } from 'types/comparableContracts';
import { TeamSummary } from 'types/teams';
import { formatTOI } from 'utilities/comparableContracts';

export const generateEmptySpaces = (numSpaces: number) => new Array(numSpaces).join(' ');

/* eslint-disable max-len */
export const CONTRACT_COMPARABLES_CSV_HEADER = {
  '': 'Player',
  ' ': 'Signing Team',
  '  ': 'Position',
  '   ': 'Platform Year',
  '    ': 'Signing Date',
  '     ': 'Signing Age',
  '      ': 'Length',
  '       ': 'Signed As',
  '        ': 'Arb. Rights',
  '         ': 'AAV',
  '          ': 'QO',
  Career: 'GP',
  'Career ': 'G',
  'Career  ': 'A',
  'Career   ': 'PTS',
  'Career    ': 'P/PG',
  'Career     ': 'G/PG',
  'Career      ': 'A/PG',
  'Career       ': 'ATOI',
  'Career-Contract': 'GP',
  'Career-Contract ': 'G',
  'Career-Contract  ': 'A',
  'Career-Contract   ': 'PTS',
  'Career-Contract    ': 'P/PG',
  'Career-Contract     ': 'G/PG',
  'Career-Contract      ': 'A/PG',
  'Career-Contract       ': 'ATOI',
  PY: 'GP',
  'PY ': 'G',
  'PY  ': 'A',
  'PY   ': 'PTS',
  'PY    ': 'P/PG',
  'PY     ': 'G/PG',
  'PY      ': 'A/PG',
  'PY       ': 'ATOI',
  'PY-1': 'GP',
  'PY-1 ': 'G',
  'PY-1  ': 'A',
  'PY-1   ': 'PTS',
  'PY-1    ': 'P/PG',
  'PY-1     ': 'G/PG',
  'PY-1      ': 'A/PG',
  'PY-1       ': 'ATOI',
  'PY-2': 'GP',
  'PY-2 ': 'G',
  'PY-2  ': 'A',
  'PY-2   ': 'PTS',
  'PY-2    ': 'P/PG',
  'PY-2     ': 'G/PG',
  'PY-2      ': 'A/PG',
  'PY-2       ': 'ATOI',
};

export const generateContractComparablesCSVHeader = (seasonYear: number) => {
  let csvHeader = CONTRACT_COMPARABLES_CSV_HEADER;

  for (let i = 0; i < 6; i++) {
    const season = `${seasonYear - i}-${seasonYear - i + 1}`;

    csvHeader = {
      ...csvHeader,
      [`${season}${generateEmptySpaces(1)}`]: 'GP',
      [`${season}${generateEmptySpaces(2)}`]: 'G',
      [`${season}${generateEmptySpaces(3)}`]: 'A',
      [`${season}${generateEmptySpaces(4)}`]: 'PTS',
      [`${season}${generateEmptySpaces(5)}`]: 'P/PG',
      [`${season}${generateEmptySpaces(6)}`]: 'G/PG',
      [`${season}${generateEmptySpaces(7)}`]: 'A/PG',
      [`${season}${generateEmptySpaces(8)}`]: 'ATOI',
    };
  }

  return csvHeader;
};

export const mapPlayersForCSV = (
  roster: Player[],
  seasonStartYear: number,
  rosterStatKey: string,
): { [key: string]: string | number }[] =>
  roster.map((player) => ({
    name: getPlayerFullname(player),
    age: player.age,
    primaryPosition: `${PLAYER_POSITION_MAP[player.primaryPosition]} (${
      PLAYER_POSITION_ABBR_MAP[player.primaryPosition]
    })`,
    status: CONTRACT_STATUS_MAP[player.status],
    expiryStatus: CONTRACT_EXPIRY_STATUS_MAP[player.expiryStatus],
    [`${seasonStartYear}-${seasonStartYear + 1}`]: player.contractDetails[0]
      ? (player.contractDetails[0][rosterStatKey] as string | number)
      : '--',
    [`${seasonStartYear + 1}-${seasonStartYear + 2}`]: player.contractDetails[1]
      ? (player.contractDetails[1][rosterStatKey] as string | number)
      : '--',
    [`${seasonStartYear + 2}-${seasonStartYear + 3}`]: player.contractDetails[2]
      ? (player.contractDetails[2][rosterStatKey] as string | number)
      : '--',
    [`${seasonStartYear + 3}-${seasonStartYear + 4}`]: player.contractDetails[3]
      ? (player.contractDetails[3][rosterStatKey] as string | number)
      : '--',
    [`${seasonStartYear + 4}-${seasonStartYear + 5}`]: player.contractDetails[4]
      ? (player.contractDetails[4][rosterStatKey] as string | number)
      : '--',
    [`${seasonStartYear + 5}-${seasonStartYear + 6}`]: player.contractDetails[5]
      ? (player.contractDetails[5][rosterStatKey] as string | number)
      : '--',
  }));

export const generateYearColumns = (startYear: number) => {
  const columns: { label: string; value: string }[] = [];

  for (let i = 0; i < 6; i++) {
    const season = `${startYear + i}-${startYear + i + 1}`;

    columns.push({ label: season, value: season });
  }

  return columns;
};

export const generateProjectionDataContent = (
  projectedTeamSummary: TeamSummary[],
  seasonYear: number,
): { [key: string]: string | number }[] =>
  Object.keys(CAP_SUMMARY_COLUMN_MAP).map((key) => {
    const obj: { [key: string]: string | number } = { label: CAP_SUMMARY_COLUMN_MAP[Number(key)] };
    const projectionKey = CAP_SUMMARY_COLUMN_KEY_MAP[Number(key)];

    for (let i = 0; i < 6; i++) {
      const season = `${seasonYear + i}-${seasonYear + i + 1}`;

      obj[season] = projectedTeamSummary[i][projectionKey] as string | number;
    }

    return obj;
  });

export const mapComparableContractForCSV = ({
  comparableContract: {
    careerStats,
    playerInfo,
    contractInfo,
    careerToContractStats,
    platformYearStats,
    platformYearMinusOneStatsCumulative,
    platformYearMinusTwoStatsCumulative,
    seasonStats,
  },
  currentSeasonStartYear,
}: {
  comparableContract: PlayerComparableContract;
  currentSeasonStartYear: number;
}) => {
  const csvJSON: {
    [key: string]: string | number;
  } = {};

  csvJSON[''] = getFullName(playerInfo);
  csvJSON[' '] = contractInfo.signingTeam.abrvName;
  csvJSON['  '] = PLAYER_POSITION_ABBR_MAP[playerInfo.primaryPosition];
  csvJSON['   '] = `${platformYearStats.season.seasonYearStart
    .toString()
    .slice(2)}-${platformYearStats.season.seasonYearEnd.toString().slice(2)}`;
  csvJSON['    '] = format(new Date(contractInfo.signingDate), 'MM/dd/yyyy');
  csvJSON['     '] = contractInfo.signingAge;
  csvJSON['      '] = contractInfo.contractLength;
  csvJSON['       '] = CONTRACT_EXPIRY_STATUS_MAP[contractInfo.signingStatus];
  csvJSON['        '] = playerInfo.arbRights ? 'YES' : 'NO';
  csvJSON['         '] = `$${contractInfo.averageAnnualValue}`;
  csvJSON['          '] = playerInfo.qoValue ? `$${playerInfo.qoValue}` : 'NR';

  // Career Stats
  csvJSON.Career = careerStats.regularSeasonStats.gamesPlayed;
  csvJSON['Career '] = careerStats.regularSeasonStats.goals;
  csvJSON['Career  '] = careerStats.regularSeasonStats.assists;
  csvJSON['Career   '] = careerStats.regularSeasonStats.points;
  csvJSON['Career    '] = careerStats.regularSeasonStats.pointsPerGame.toFixed(2);
  csvJSON['Career     '] = Number(
    (careerStats.regularSeasonStats.goals / careerStats.regularSeasonStats.gamesPlayed).toFixed(2),
  );
  csvJSON['Career      '] = Number(
    (careerStats.regularSeasonStats.assists / careerStats.regularSeasonStats.gamesPlayed).toFixed(2),
  );
  csvJSON['Career       '] = careerStats.regularSeasonStats.timeOnIceTotal || 0;

  const curSeasonIndex = seasonStats.findIndex((season) => season.season.seasonYearStart === currentSeasonStartYear);

  if (curSeasonIndex !== -1) {
    const curSeasonStats = seasonStats[curSeasonIndex];

    // Current Season Stats
    csvJSON.__20 = curSeasonStats.regularSeasonStats.gamesPlayed;
    csvJSON.__21 = curSeasonStats.regularSeasonStats.goals;
    csvJSON.__22 = curSeasonStats.regularSeasonStats.assists;
    csvJSON.__23 = curSeasonStats.regularSeasonStats.points;
    csvJSON.__24 = curSeasonStats.regularSeasonStats.pointsPerGame.toFixed(2);
    csvJSON.__25 = curSeasonStats.regularSeasonStats.goalsPerGame.toFixed(2);
    csvJSON.__26 = curSeasonStats.regularSeasonStats.assistsPerGame.toFixed(2);
    csvJSON.__27 = formatTOI(curSeasonStats.regularSeasonStats.averageTimeOnIceTotal);
  }

  // Career-to-Contract Stats
  csvJSON['Career-Contract'] = careerToContractStats.regularSeasonStats?.gamesPlayed;
  csvJSON['Career-Contract '] = careerToContractStats.regularSeasonStats?.goals;
  csvJSON['Career-Contract  '] = careerToContractStats.regularSeasonStats?.assists;
  csvJSON['Career-Contract   '] = careerToContractStats.regularSeasonStats?.points;
  csvJSON['Career-Contract    '] = careerToContractStats.regularSeasonStats?.pointsPerGame.toFixed(2);
  csvJSON['Career-Contract     '] = careerToContractStats.regularSeasonStats?.goalsPerGame.toFixed(2);
  csvJSON['Career-Contract      '] = careerToContractStats.regularSeasonStats?.assistsPerGame.toFixed(2);
  csvJSON['Career-Contract       '] = formatTOI(careerToContractStats.regularSeasonStats?.averageTimeOnIceTotal);

  // Platform Year Stats
  csvJSON.PY = platformYearStats.regularSeasonStats.gamesPlayed;
  csvJSON['PY '] = platformYearStats.regularSeasonStats.goals;
  csvJSON['PY  '] = platformYearStats.regularSeasonStats.assists;
  csvJSON['PY   '] = platformYearStats.regularSeasonStats.points;
  csvJSON['PY    '] = platformYearStats.regularSeasonStats.pointsPerGame.toFixed(2);
  csvJSON['PY     '] = platformYearStats.regularSeasonStats.goalsPerGame.toFixed(2);
  csvJSON['PY      '] = platformYearStats.regularSeasonStats.assistsPerGame.toFixed(2);
  csvJSON['PY       '] = formatTOI(platformYearStats.regularSeasonStats.averageTimeOnIceTotal);

  // Platform Year - 1 Stats
  csvJSON['PY-1'] = platformYearMinusOneStatsCumulative.regularSeasonStats.gamesPlayed;
  csvJSON['PY-1 '] = platformYearMinusOneStatsCumulative.regularSeasonStats.goals;
  csvJSON['PY-1  '] = platformYearMinusOneStatsCumulative.regularSeasonStats.assists;
  csvJSON['PY-1   '] = platformYearMinusOneStatsCumulative.regularSeasonStats.points;
  csvJSON['PY-1    '] = platformYearMinusOneStatsCumulative.regularSeasonStats.pointsPerGame.toFixed(2);
  csvJSON['PY-1     '] = platformYearMinusOneStatsCumulative.regularSeasonStats.goalsPerGame.toFixed(2);
  csvJSON['PY-1      '] = platformYearMinusOneStatsCumulative.regularSeasonStats.assistsPerGame.toFixed(2);
  csvJSON['PY-1       '] = formatTOI(platformYearMinusOneStatsCumulative.regularSeasonStats.averageTimeOnIceTotal);

  // Platform Year - 2 Stats
  csvJSON['PY-2'] = platformYearMinusTwoStatsCumulative.regularSeasonStats.gamesPlayed;
  csvJSON['PY-2 '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.goals;
  csvJSON['PY-2  '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.assists;
  csvJSON['PY-2   '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.points;
  csvJSON['PY-2    '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.pointsPerGame.toFixed(2);
  csvJSON['PY-2     '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.goalsPerGame.toFixed(2);
  csvJSON['PY-2      '] = platformYearMinusTwoStatsCumulative.regularSeasonStats.assistsPerGame.toFixed(2);
  csvJSON['PY-2       '] = formatTOI(platformYearMinusTwoStatsCumulative.regularSeasonStats.averageTimeOnIceTotal);

  // Remaining Stats
  const sortedSeasonStats = [...seasonStats];

  sortedSeasonStats.sort((a, b) => b.season.seasonYearStart - a.season.seasonYearStart);

  sortedSeasonStats.forEach((sortedSeason) => {
    const season = `${sortedSeason.season.seasonYearStart}-${sortedSeason.season.seasonYearEnd}`;

    csvJSON[`${season}${generateEmptySpaces(1)}`] = sortedSeason.regularSeasonStats.gamesPlayed;
    csvJSON[`${season}${generateEmptySpaces(2)}`] = sortedSeason.regularSeasonStats.goals;
    csvJSON[`${season}${generateEmptySpaces(3)}`] = sortedSeason.regularSeasonStats.assists;
    csvJSON[`${season}${generateEmptySpaces(4)}`] = sortedSeason.regularSeasonStats.points;
    csvJSON[`${season}${generateEmptySpaces(5)}`] = sortedSeason.regularSeasonStats.pointsPerGame.toFixed(2);
    csvJSON[`${season}${generateEmptySpaces(6)}`] = sortedSeason.regularSeasonStats.goalsPerGame.toFixed(2);
    csvJSON[`${season}${generateEmptySpaces(7)}`] = sortedSeason.regularSeasonStats.assistsPerGame.toFixed(2);
    csvJSON[`${season}${generateEmptySpaces(8)}`] = formatTOI(sortedSeason.regularSeasonStats.averageTimeOnIceTotal);
  });

  return csvJSON;
};
