/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import MaterialTable from '@material-table/core';
import { Box, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PlayerContractSimulatorActions from 'modules/players/playerContracts/playerContractSimulator/playerContractSimulatorActions.component';
import PlayerContractSimulatorSummary from 'modules/players/playerContracts/playerContractSimulator/playerContractSimulatorSummary.component';
import PlayerContractSimulatorTable from 'modules/players/playerContracts/playerContractSimulator/playerContractSimulatorTable.component';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import {
  INITIAL_CONTRACT_SIMULATION,
  INITIAL_CONTRACT_SIMULATION_DETAILS,
  getContractSimulationStartYear,
  mapContractSimulationToPayload,
} from 'utilities/contract';
import { Player } from 'types/player';
import {
  PlayerContract,
  PlayerContractSimulation,
  PlayerContractSimulationDetails,
  PlayerContractSimulationPreview,
} from 'types/contract';
import { StanleyTeamSelect } from '@project-stanley/cap-management-components';
import { ToastTypes } from 'types/layout';
import { addScenarioContract, getScenarioContractPreview } from 'modules/scenarios/scenarios.slice';
import { getPlayerContracts } from 'modules/players/players.slice';
import { getPlayerFullname } from 'utilities/player';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeams } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

interface BulkEditChangedRow {
  newData: PlayerContractSimulationDetails;
}

interface PlayerContractSimulatorProps {
  player: Player;
  playerContracts: PlayerContract[];
}

function PlayerContractSimulator({ player, playerContracts }: PlayerContractSimulatorProps): JSX.Element {
  const tableRef = useRef<MaterialTable<any>>();

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const teams = useSelector(selectTeams);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);

  const [isOneWay, setIsOneWay] = useState(false);
  const [contract, setContract] = useState<PlayerContractSimulation>({
    ...INITIAL_CONTRACT_SIMULATION,
    contractDetails: [],
    signingTeamId: player.currentTeam?.teamId || 1,
  });

  useEffect(() => {
    if (tableRef.current !== undefined) (tableRef.current as any).dataManager.changeBulkEditOpen(true);
  }, [tableRef]);

  const [hasPreview, setHasPreview] = useState(false);

  useEffect(() => {
    if (curSeasonStartYear) {
      setContract((prevContract) => ({
        ...prevContract,
        contractDetails: [
          {
            ...INITIAL_CONTRACT_SIMULATION_DETAILS,
            season: playerContracts[playerContracts.length - 1]
              ? getContractSimulationStartYear(
                  playerContracts[playerContracts.length - 1].contractDetails,
                  curSeasonStartYear + 1,
                )
              : `${curSeasonStartYear} - ${curSeasonStartYear + 1}`,
          },
        ],
      }));
    }
  }, [curSeasonStartYear, playerContracts]);

  const handleAddContractYear = useCallback(() => {
    setHasPreview(false);

    const newContractEndYear = parseInt(
      contract.contractDetails[contract.contractDetails.length - 1].season.split('-')[1],
      10,
    );

    const contractDetails = [
      ...contract.contractDetails,
      {
        ...INITIAL_CONTRACT_SIMULATION_DETAILS,
        season: `${newContractEndYear} - ${newContractEndYear + 1}`,
      },
    ];

    setContract({ ...contract, contractDetails });
  }, [contract, setContract]);

  const handleRemoveContractYear = useCallback(() => {
    setHasPreview(false);

    const contractDetails = [...contract.contractDetails];
    contractDetails.pop();

    setContract({ ...contract, contractDetails });
  }, [contract, setContract]);

  const handleContractSimulationPreview = useCallback(() => {
    if (tableRef.current) {
      const { bulkEditChangedRows }: { bulkEditChangedRows: { [key: string]: BulkEditChangedRow } } = (
        tableRef.current as any
      ).dataManager;

      if (contract.contractDetails.length !== Object.keys(bulkEditChangedRows).length) {
        dispatch(
          showToast({
            toastMessage: 'Unable to preview contract details until all contract years have been edited.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      (async () => {
        const payload: PlayerContractSimulationPreview = {
          playerId: player.playerId,
          signingTeamId: contract.signingTeamId,
          contractType: 1,
          expiryStatus: 1,
          contractDetails: [],
        };

        Object.keys(bulkEditChangedRows).forEach((key) => {
          payload.contractDetails.push(mapContractSimulationToPayload(bulkEditChangedRows[key].newData, isOneWay));
        });

        const response = await dispatch(getScenarioContractPreview(payload));

        if (response.type === getScenarioContractPreview.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Previewing your contract failed. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }

        setContract(response.payload as PlayerContractSimulation);
        setHasPreview(true);
      })();
    }
  }, [dispatch, contract, isOneWay, player, tableRef]);

  const handleCompleteContractSimulation = useCallback(() => {
    if (tableRef.current) {
      const { bulkEditChangedRows }: { bulkEditChangedRows: { [key: string]: BulkEditChangedRow } } = (
        tableRef.current as any
      ).dataManager;

      (async () => {
        const payload: PlayerContractSimulationPreview = {
          playerId: player.playerId,
          signingTeamId: contract.signingTeamId,
          contractType: 1,
          expiryStatus: 1,
          contractDetails: [],
        };

        Object.keys(bulkEditChangedRows).forEach((key) => {
          payload.contractDetails.push(mapContractSimulationToPayload(bulkEditChangedRows[key].newData, isOneWay));
        });

        const response = await dispatch(addScenarioContract(payload));

        if (response.type === getScenarioContractPreview.rejected.toString()) {
          dispatch(
            showToast({
              toastMessage: 'Unable to save contract simulation. Please try again.',
              toastType: ToastTypes.ERROR,
            }),
          );
          return;
        }

        const signingTeam = teams && teams.find((team) => team.teamId === contract.signingTeamId);

        logGAEvent({
          category: GA_CATEGORIES.SCENARIOS,
          action: GA_ACTIONS.SCENARIO_CONTRACT_SIMULATED,
          label: `${getPlayerFullname(player)} - ${signingTeam ? signingTeam?.abrvName : ''}`,
        });

        dispatch(getPlayerContracts(player.playerId.toString()));
        setHasPreview(false);
        setContract({
          ...INITIAL_CONTRACT_SIMULATION,
          signingTeamId: player.currentTeam.teamId || 1,
        });
      })();
    }
  }, [dispatch, contract, isOneWay, player, teams]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box marginBottom={theme.spacing(2)}>
          <Box>
            <StanleyTeamSelect
              teamId={contract.signingTeamId || player?.currentTeam.teamId}
              teams={teams || []}
              onTeamChange={(signingTeamId: number | number[]) => {
                if (Array.isArray(signingTeamId)) return;

                setContract({ ...contract, signingTeamId });
              }}
            />
          </Box>
          <PlayerContractSimulatorSummary contract={contract} isOneWay={isOneWay} onOneWayChange={setIsOneWay} />
        </Box>
        <PlayerContractSimulatorActions
          contract={contract}
          disableComplete={!hasPreview}
          onAddContractYear={handleAddContractYear}
          onComplete={handleCompleteContractSimulation}
          onPreview={handleContractSimulationPreview}
          onRemoveContractYear={handleRemoveContractYear}
        />
      </Box>
      <Box marginBottom={theme.spacing(4)}>
        <PlayerContractSimulatorTable
          ref={tableRef}
          contract={contract.contractDetails}
          isOneWay={isOneWay}
          onDataChange={() => setHasPreview(false)}
        />
      </Box>
    </>
  );
}

export default PlayerContractSimulator;
