/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import MaterialTable, { MTableBody, MTableBodyRow } from '@material-table/core';
import makeStyles from '@mui/styles/makeStyles';
import { DocumentData, DocumentReference, FirestoreError } from 'firebase/firestore';
import { IconButton, TableBody } from '@mui/material';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  StanleyDollar,
  StanleySVGIcon,
  StanleyTable,
  StanleyTransparentButton,
  TextAlign,
} from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ComparableContractTableToolbar from 'modules/players/playerComparableContracts/comparableContractTableToolbar.component';
import ComparableContractsTableSummary from 'modules/players/playerComparableContracts/comparableContractsTableSummary.component';
import usePrevious from 'hooks/usePrevious';
import {
  CONTRACT_DATA_FIELD_VALUES,
  DECIMAL_DATA_FIELDS,
  DEFAULT_DATA_FIELD_OPTIONS,
  NEEDS_CALC_CAREER_DATA_FIELDS,
  NEEDS_CALC_DATA_FIELDS,
  PLATFORM_STAT_SELECTIONS,
  PLAYER_DATA_FIELD_VALUES,
  PLAYER_EV_STATS_DATA_FIELD_VALUES,
  PLAYER_PP_STATS_DATA_FIELD_VALUES,
  PLAYER_SH_STATS_DATA_FIELD_VALUES,
  PLAYER_STATS_DATA_FIELD_VALUES,
  PLAYER_TOTAL_STATS_DATA_FIELD_VALUES,
  TIME_DATA_FIELDS,
} from 'constants/comparableContracts';
import { CONTRACT_EXPIRY_STATUS_MAP, CONTRACT_TYPE_MAP } from 'utilities/contract';
import { CompSetActionTypes } from 'modules/players/playerComparableContracts/comparableContractsDialog.component';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { FirebaseData, FirebaseWriteType } from 'types/firebase';
import { ICONS } from 'utilities/icons';
import {
  INITIAL_STAT_SELECTION,
  formatTOI,
  generateComparableContractsSummary,
  getCalcStat,
  getContractSeasonStats,
} from 'utilities/comparableContracts';
import { PLAYER_POSITION_ABBR_MAP } from 'utilities/player';
import { PlayerComparableContract, SavedCompListItem } from 'types/comparableContracts';
import { ROUTES } from 'utilities/routes';
import { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { TableRowClick } from 'types/table';
import {
  addCompListItem,
  updateCompListItem,
} from 'modules/players/playerComparableContracts/comparableContracts.slice';
import { selectBlockNavigation, selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectContractComparablesDataFields } from 'modules/user/user.selectors';
import { setBlockNavigation } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

export interface DragState {
  rowId: number;
  dropIndex: number;
}

interface ComparableContractsTableProps {
  tableRef: MutableRefObject<MaterialTable<any> | undefined>;
  comparableContractsSubject: PlayerComparableContract;
  comparableContracts: (PlayerComparableContract & { isSubject?: boolean })[];
  isLoading: boolean;
  selectedCompSet: string;
  selectedSeason: string;
  seasonStatFilters: { label: string; value: string }[];
  onMoveContract: (dragState: DragState) => void;
  onOptionSelected: (selectedOption: StanleyPlayerSearchOption | null) => void;
  onRemoveContracts: (selectedContracts: (PlayerComparableContract & { id: number })[]) => void;
  onSeasonChange: (value: string) => void;
  onSelectCompSet: (value: string) => void;
}

const dragState: DragState = {
  rowId: -1,
  dropIndex: -1,
};

function ComparableContractsTable({
  tableRef,
  comparableContractsSubject,
  comparableContracts,
  isLoading,
  selectedSeason,
  selectedCompSet,
  seasonStatFilters,
  onMoveContract,
  onOptionSelected,
  onRemoveContracts,
  onSeasonChange,
  onSelectCompSet,
}: ComparableContractsTableProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isPrinting = useSelector(selectIsPrinting);
  const blockNavigation = useSelector(selectBlockNavigation);
  const contractComparablesDataFields = useSelector(selectContractComparablesDataFields);

  const [summaryColumnIndicies, setSummaryColumnIndicies] = useState<Record<string, number>>({});
  const [selectedRows, setSelectedRows] = useState<(PlayerComparableContract & { id: number })[]>([]);
  const [compData, setCompData] = useState<PlayerComparableContract[]>([]);
  const [dataFields, setDataFields] = useState<(string | number)[]>(
    contractComparablesDataFields || DEFAULT_DATA_FIELD_OPTIONS,
  );

  const prevIsPrinting = usePrevious(isPrinting);

  useEffect(() => {
    if (isPrinting && tableRef.current) {
      (tableRef.current as any).onRowsPerPageChange({ target: { value: comparableContracts?.length || 25 } });
    }

    if (!isPrinting && tableRef.current && prevIsPrinting) {
      (tableRef.current as any).onRowsPerPageChange({ target: { value: 5 } });
    }
  }, [comparableContracts, isPrinting, prevIsPrinting, tableRef]);

  useEffect(() => {
    if (comparableContracts?.length !== compData?.length) {
      if (comparableContracts && (tableRef.current as any).state.pageSize > comparableContracts?.length) {
        (tableRef.current as any).onRowsPerPageChange({ target: { value: comparableContracts?.length || 5 } });
      }
    }
  }, [comparableContracts, compData, tableRef]);

  useEffect(() => {
    setCompData(comparableContracts);
  }, [comparableContracts]);

  const handleDataFieldsChange = useCallback(
    (newDataFields: (string | number)[]) => {
      if (isEqual(newDataFields, dataFields)) return;

      setDataFields(newDataFields);

      dispatch(
        setBlockNavigation({
          ...blockNavigation,
          shouldBlockNavigation: true,
          actions: [
            ...blockNavigation.actions,
            {
              storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS_DATA_FIELDS,
              data: newDataFields,
            },
          ],
        }),
      );
    },
    [dispatch, dataFields, blockNavigation],
  );

  const renderPlayerName = useCallback(
    ({ playerInfo: { firstName, lastName, playerId } }: PlayerComparableContract) => (
      <StanleyTransparentButton
        classes={{
          root: classes.playerButtonRoot,
        }}
        color="secondary"
        onClick={() => navigate(`${ROUTES.PLAYERS}/${playerId}`)}
      >
        <>
          {lastName}
          ,&nbsp;
          {firstName}
        </>
      </StanleyTransparentButton>
    ),
    [classes, navigate],
  );

  const renderTableStatColumn = useCallback(
    (statKey: string) => (playerStats: PlayerComparableContract) => {
      const isDecimalKey = Boolean(DECIMAL_DATA_FIELDS[statKey]);
      const isTOIKey = Boolean(TIME_DATA_FIELDS[statKey]);
      const isNeedsCalc =
        Boolean(NEEDS_CALC_DATA_FIELDS[statKey]) ||
        Boolean(selectedSeason === INITIAL_STAT_SELECTION && NEEDS_CALC_CAREER_DATA_FIELDS[statKey]);

      const curSeasonStats = getContractSeasonStats({ playerStats, selectedSeason });

      if (!curSeasonStats) return '--';
      if (isNeedsCalc) {
        const calcStat = getCalcStat(curSeasonStats, statKey);

        return typeof calcStat === 'string' ? calcStat : calcStat.toFixed(2);
      }

      const stat = curSeasonStats[statKey];
      const isString = typeof stat === 'string';

      if ((!stat && stat !== 0) || typeof stat === 'object') return '--';
      if (isTOIKey && isString) return formatTOI(stat);

      return !isDecimalKey || isString ? stat : stat.toFixed(2);
    },
    [selectedSeason],
  );

  const sortTableContractColumn = useCallback(
    (contractKey: string) => (rowA: PlayerComparableContract, rowB: PlayerComparableContract) =>
      (rowA.contractInfo[contractKey] as number) - (rowB.contractInfo[contractKey] as number),
    [],
  );

  const sortTablePlayerInfoColumn = useCallback(
    (playerKey: string) => (rowA: PlayerComparableContract, rowB: PlayerComparableContract) =>
      (rowA.playerInfo[playerKey] as number) - (rowB.playerInfo[playerKey] as number),
    [],
  );

  const columns = useMemo(() => {
    const columnIndices: Record<string, number> = {};

    const newColumns: Record<string, any>[] = [
      {
        title: 'Player',
        cellStyle: { minWidth: 165, width: 165 },
        hasSortCellStyle: false,
        headerStyle: { minWidth: 165, width: 165 },
        sorting: false,
        width: 185,
        customSort: (rowA: PlayerComparableContract, rowB: PlayerComparableContract) =>
          `${rowA.playerInfo.lastName}, ${rowA.playerInfo.firstName}`.localeCompare(
            `${rowB.playerInfo.lastName}, ${rowB.playerInfo.firstName}`,
          ),
        render: renderPlayerName,
      },
    ];

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_POSITION)) {
      newColumns.push({
        title: 'Position',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        sorting: false,
        render: ({ playerInfo: { primaryPosition } }: PlayerComparableContract) =>
          PLAYER_POSITION_ABBR_MAP[primaryPosition],
      });
      columnIndices[PLAYER_DATA_FIELD_VALUES.DATA_FIELD_POSITION] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_AGE)) {
      newColumns.push({
        title: 'Current Age',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('currentAge'),
        render: ({ playerInfo }: PlayerComparableContract) => (playerInfo ? playerInfo.age : '--'),
      });
      columnIndices[PLAYER_DATA_FIELD_VALUES.DATA_FIELD_CURRENT_AGE] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_TEAM)) {
      newColumns.push({
        title: 'Signing Team',
        cellStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        headerStyle: { minWidth: 100, width: 100, textAlign: TextAlign.center },
        sorting: false,
        render: ({ contractInfo }: PlayerComparableContract) => {
          if (!contractInfo) return '--';

          return (
            <IconButton
              className={classes.iconButton}
              onClick={(event: TableRowClick) => {
                event?.stopPropagation();

                navigate(`${ROUTES.TEAMS}/${contractInfo.signingTeam.teamId}`);
              }}
              size="large"
            >
              <StanleySVGIcon height="1.75rem" imageSrc={ICONS[contractInfo.signingTeam.abrvName]} width="1.75rem" />
            </IconButton>
          );
        },
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_TEAM] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_DATE)) {
      newColumns.push({
        title: 'Signing Date',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        sorting: false,
        render: ({ contractInfo }: PlayerComparableContract) => {
          if (!contractInfo) return '--';

          return format(new Date(contractInfo.signingDate), 'MM/dd/yyyy');
        },
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_DATE] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_AGE)) {
      newColumns.push({
        title: 'Signing Age',
        cellStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        headerStyle: { minWidth: 105, width: 105, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('signingAge'),
        render: ({ contractInfo }: PlayerComparableContract) => (contractInfo ? contractInfo.signingAge : '--'),
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_AGE] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_LENGTH)) {
      newColumns.push({
        title: 'Length',
        cellStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        headerStyle: { minWidth: 60, width: 60, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('contractLength'),
        render: ({ contractInfo }: PlayerComparableContract) => (contractInfo ? contractInfo.contractLength : '--'),
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_LENGTH] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_TYPE)) {
      newColumns.push({
        title: 'Type',
        cellStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        headerStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('contractType'),
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo ? CONTRACT_TYPE_MAP[contractInfo.contractType] : '--',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CONTRACT_TYPE] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_STATUS)) {
      newColumns.push({
        title: 'Signed As',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('signingStatus'),
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo && contractInfo.signingStatus !== null
            ? CONTRACT_EXPIRY_STATUS_MAP[contractInfo.signingStatus]
            : 'ELC',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_SIGNING_STATUS] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_EXPIRATION_STATUS)) {
      newColumns.push({
        title: 'Expiry',
        cellStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
        headerStyle: { minWidth: 65, width: 65, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('expiryStatus'),
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo ? CONTRACT_EXPIRY_STATUS_MAP[contractInfo.expiryStatus] : '--',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_EXPIRATION_STATUS] = newColumns.length;
    }

    if (
      Object.values(PLATFORM_STAT_SELECTIONS).includes(selectedSeason) &&
      dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_PLATFORM_YEAR)
    ) {
      newColumns.push({
        title: 'Platform Year',
        cellStyle: { minWidth: 90, width: 90 },
        headerStyle: { minWidth: 90, width: 90 },
        sorting: false,
        render: ({ platformYearStats }: PlayerComparableContract) => {
          if (!platformYearStats) return '--';

          const { seasonYearStart, seasonYearEnd } = platformYearStats.season;

          return `${seasonYearStart} - ${seasonYearEnd}`;
        },
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_PLATFORM_YEAR] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_ARB_ELIGIBILITY)) {
      newColumns.push({
        title: 'Arb. Eligible',
        cellStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        headerStyle: { minWidth: 125, width: 125, textAlign: TextAlign.center },
        render: ({ playerInfo }: PlayerComparableContract) => (playerInfo.arbRights ? 'YES' : 'NO'),
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_ARB_ELIGIBILITY] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_AAV)) {
      newColumns.push({
        title: 'AAV',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTableContractColumn('averageAnnualValue'),
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo ? <StanleyDollar amount={contractInfo.averageAnnualValue} /> : '--',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_AAV] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_QO_VALUE)) {
      newColumns.push({
        title: 'QO',
        cellStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        headerStyle: { minWidth: 90, width: 90, textAlign: TextAlign.center },
        customSort: sortTablePlayerInfoColumn('qoValue'),
        render: ({ playerInfo }: PlayerComparableContract) =>
          playerInfo && playerInfo.qoValue ? <StanleyDollar amount={playerInfo.qoValue} /> : 'NR',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_QO_VALUE] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED)) {
      newColumns.push({
        title: 'Total - GP',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GAMES_PLAYED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS)) {
      newColumns.push({
        title: 'Total - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS)) {
      newColumns.push({
        title: 'Total - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR)) {
      newColumns.push({
        title: 'Total - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(
          selectedSeason === INITIAL_STAT_SELECTION
            ? PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS
            : PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR,
        ),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_SR] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME)) {
      newColumns.push({
        title: 'Total - G/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - A/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME)) {
      newColumns.push({
        title: 'Total - P/PG',
        cellStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        headerStyle: { minWidth: 85, width: 85, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.POINTS_PER_GAME] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL)) {
      newColumns.push({
        title: 'Total - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_TOTAL] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS)) {
      newColumns.push({
        title: 'Total - TOI Shifts',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.TIME_ON_ICE_SHIFTS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS)) {
      newColumns.push({
        title: 'Total - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT)) {
      newColumns.push({
        title: 'Total - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON)) {
      newColumns.push({
        title: 'Total - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.FACEOFFS_WON] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST)) {
      newColumns.push({
        title: 'Total - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST),
      });
      columnIndices[PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT)) {
      newColumns.push({
        title: 'Total - Shooting(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOOTING_PCT] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS)) {
      newColumns.push({
        title: 'Total - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.SHOTS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES)) {
      newColumns.push({
        title: 'Total - PIMS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.PENALTY_INFRACTION_MINUTES] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS)) {
      newColumns.push({
        title: 'Total - Hits',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS),
      });
      columnIndices[PLAYER_TOTAL_STATS_DATA_FIELD_VALUES.HITS] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH)) {
      newColumns.push({
        title: 'EV - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH),
      });
      columnIndices[PLAYER_EV_STATS_DATA_FIELD_VALUES.SHOTS_EVEN_STRENGTH] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY)) {
      newColumns.push({
        title: 'PP - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY)) {
      newColumns.push({
        title: 'PP - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY)) {
      newColumns.push({
        title: 'PP - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_POWER_PLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY)) {
      newColumns.push({
        title: 'PP - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY),
      });
      columnIndices[PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY)) {
      newColumns.push({
        title: 'PP - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY),
      });
      columnIndices[PLAYER_PP_STATS_DATA_FIELD_VALUES.SHOTS_POWERPLAY] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - PTS',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - G/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.GOALS_PER_GAME_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - A/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.ASSISTS_PER_GAME_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - P/PG',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.POINTS_SR_PER_GAME_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - ATOI',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.AVERAGE_TIME_ON_ICE_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FOs',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Win(%)',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WIN_PCT_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Wins',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.FACEOFFS_WON_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - FO Losses',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED),
      });
      columnIndices[PLAYER_STATS_DATA_FIELD_VALUES.FACEOFFS_LOST_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED)) {
      newColumns.push({
        title: 'SH - Shots',
        cellStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        headerStyle: { minWidth: 75, width: 75, textAlign: TextAlign.center },
        sorting: false,
        render: renderTableStatColumn(PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED),
      });
      columnIndices[PLAYER_SH_STATS_DATA_FIELD_VALUES.SHOTS_SHORTHANDED] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT)) {
      newColumns.push({
        title: 'Cap Hit',
        cellStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        headerStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        sorting: false,
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo ? <StanleyDollar amount={contractInfo.capHit} /> : '--',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT] = newColumns.length;
    }

    if (dataFields.includes(CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT_PER)) {
      newColumns.push({
        title: 'Cap Hit(%)',
        cellStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        headerStyle: { minWidth: 70, width: 70, textAlign: TextAlign.center },
        sorting: false,
        render: ({ contractInfo }: PlayerComparableContract) =>
          contractInfo ? `${(contractInfo.capHitPercentage * 100).toFixed(2)}%` : '--',
      });
      columnIndices[CONTRACT_DATA_FIELD_VALUES.DATA_FIELD_CAP_HIT_PER] = newColumns.length;
    }

    setSummaryColumnIndicies(columnIndices);

    return newColumns;
  }, [
    classes,
    navigate,
    dataFields,
    selectedSeason,
    renderPlayerName,
    renderTableStatColumn,
    sortTableContractColumn,
    sortTablePlayerInfoColumn,
  ]);

  const handleSaveCompSet = useCallback(
    async (nameOrId: string, type: CompSetActionTypes) => {
      try {
        const data: FirebaseData = {
          playerIds: compData.reduce((acc, comp) => {
            acc.push(comp.playerInfo.playerId);

            return acc;
          }, [] as number[]),
        };

        if (type === CompSetActionTypes.CREATE) {
          data.name = nameOrId;
        }

        const response = (await writeFirestoreDataByUid({
          path: type === CompSetActionTypes.CREATE ? 'compLists' : `compLists/${nameOrId}`,
          data,
          byUid: type !== CompSetActionTypes.UPDATE,
          type: type === CompSetActionTypes.CREATE ? FirebaseWriteType.ADD : FirebaseWriteType.SET,
          addOwner: true,
        })) as DocumentReference<DocumentData>;

        if (type === CompSetActionTypes.CREATE) {
          dispatch(addCompListItem({ ...data, id: response.id } as SavedCompListItem));

          onSelectCompSet(response.id);
        } else {
          dispatch(updateCompListItem({ ...data, id: nameOrId } as SavedCompListItem));

          onSelectCompSet(nameOrId);
        }
      } catch (error) {
        console.log('FIRESTORE ERROR: ', error as FirestoreError);
      }
    },
    [dispatch, compData, onSelectCompSet],
  );

  return (
    <>
      <ComparableContractTableToolbar
        tableRef={tableRef}
        dataFields={dataFields}
        isLoading={isLoading}
        selectedCompSet={selectedCompSet}
        selectedRows={selectedRows}
        selectedSeason={selectedSeason}
        seasonStatFilters={seasonStatFilters}
        onDataFieldsChange={handleDataFieldsChange}
        onOptionSelected={onOptionSelected}
        onRemoveContracts={onRemoveContracts}
        onSeasonChange={onSeasonChange}
        onSelectCompSet={onSelectCompSet}
        onSaveCompSet={handleSaveCompSet}
      />
      <StanleyTable
        ref={tableRef}
        actions={[
          {
            icon: () => <div />,
            text: 'delete',
            tooltip: 'Remove Comparable Contract',
            onClick: () => {},
          },
        ]}
        columns={columns}
        components={{
          Body: ({ renderData, ...props }: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const comparableContractsSummary = generateComparableContractsSummary(renderData || [], selectedSeason);

            return (
              <>
                {comparableContractsSubject && (
                  <TableBody>
                    <MTableBodyRow
                      actions={props.actions}
                      columns={props.columns}
                      components={props.components}
                      data={{ ...comparableContractsSubject, tableData: { id: -1 }, isSubject: true }}
                      icons={props.icons}
                      options={{
                        ...props.options,
                        selectionProps: { style: { visibility: 'hidden' } },
                      }}
                      getFieldValue={props.getFieldValue}
                      onToggleDetailPanel={props.onToggleDetailPanel}
                    />
                  </TableBody>
                )}
                <MTableBody renderData={renderData} {...props} />
                <ComparableContractsTableSummary
                  columnIndicies={summaryColumnIndicies}
                  comparableContractsSummary={comparableContractsSummary || []}
                />
              </>
            );
          },
        }}
        data={compData || []}
        options={{
          fixedColumns: {
            left: 1,
          },
          paging: (compData || []).length > 5,
          pageSize: 5,
          pageSizeOptions: [5, 10, 25],
          selection: true,
        }}
        isLoading={isLoading}
        showSelectAll
        onSelectionChange={(data) => setSelectedRows(data)}
        onRowDragEnd={() => {
          onMoveContract(dragState);

          dragState.rowId = -1;
          dragState.dropIndex = -1;
        }}
        onRowDragEnter={(rowId: number) => {
          if (rowId !== dragState.rowId) {
            dragState.dropIndex = rowId === 0 ? 1 : rowId;
          }
        }}
        onRowDragStart={(rowId: number) => {
          dragState.rowId = rowId;
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.25),
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  playerButtonRoot: {
    fontWeight: 400,
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
}));

export default ComparableContractsTable;
