/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeEvent, ReactElement, RefCallback, forwardRef } from 'react';
import { Clear } from '@mui/icons-material';
import { IMask, IMaskInput } from 'react-imask';
import { IconButton, TextField, useTheme } from '@mui/material';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<ReactElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="mm:ss"
      autofix={false}
      overwrite
      lazy
      prepare={(value: string) => value.toUpperCase()}
      blocks={{
        mm: {
          mask: IMask.MaskedRange,
          placeholderChar: 'm',
          from: 0,
          to: 59,
          maxLength: 2,
          autofix: true,
        },
        ss: {
          mask: IMask.MaskedRange,
          placeholderChar: 's',
          from: 0,
          to: 59,
          maxLength: 2,
          autofix: true,
        },
      }}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
    />
  );
});

interface StanleyTOIInputProps {
  disabled: boolean;
  placeholder: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export default function StanleyTOIInput({ disabled, placeholder, value, onChange, onClear }: StanleyTOIInputProps) {
  const theme = useTheme();

  return (
    <TextField
      disabled={disabled}
      fullWidth
      inputProps={{
        sx: {
          paddingBottom: theme.spacing(0.875),
          paddingLeft: theme.spacing(1.5),
          paddingTop: theme.spacing(0.875),
        },
      }}
      InputProps={{
        inputComponent: TextMaskCustom as any,
        endAdornment: (
          <IconButton sx={{ visibility: value ? 'visible' : 'hidden' }} size="small" onClick={onClear}>
            <Clear fontSize="small" />
          </IconButton>
        ),
      }}
      placeholder={placeholder}
      sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0.375rem' } }}
      value={value}
      variant="outlined"
      onChange={onChange}
    />
  );
}
