import { Tab, Tabs } from '@mui/material';
import { useMemo } from 'react';

import PlayerSearchContractMixMaxDetails from 'modules/advancedSearch/playerSearchContractMixMaxDetails.component';
import PlayerSearchMixMaxStats from 'modules/advancedSearch/playerSearchMixMaxStats.component';
import { Filters } from 'types/playerSearch';

interface PlayerSearchMixMaxPanelProps {
  filters: Filters;
  selectedTabIndex: number;
  onFiltersChange: React.Dispatch<React.SetStateAction<Filters>>;
  onTabChange: (tabIndex: number) => void;
}

function PlayerSearchMixMaxPanel({
  filters,
  selectedTabIndex,
  onFiltersChange,
  onTabChange,
}: PlayerSearchMixMaxPanelProps) {
  const renderedTab = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return <PlayerSearchContractMixMaxDetails filters={filters} onChange={onFiltersChange} />;

      default:
        return <PlayerSearchMixMaxStats filters={filters} onChange={onFiltersChange} />;
    }
  }, [filters, selectedTabIndex, onFiltersChange]);

  return (
    <>
      <Tabs
        indicatorColor="primary"
        scrollButtons={false}
        textColor="primary"
        value={selectedTabIndex}
        variant="scrollable"
        onChange={(e, tabIndex) => onTabChange(tabIndex as number)}
      >
        <Tab label="Player & Contract Details" sx={{ textTransform: 'none' }} />
        <Tab label="Performance Stats" sx={{ textTransform: 'none' }} />
      </Tabs>
      {renderedTab}
    </>
  );
}

export default PlayerSearchMixMaxPanel;
