import { cloneDeep } from 'lodash';

import { PLAYER_ASSETS_FIELDS } from 'modules/tradeSimulator/tradeSimulatorPlayerAssetItem';
import { TradeDraft, TradePlayer, TradeTeam } from 'types/trade';

export const INITIAL_PLAYER_ASSET: TradePlayer = {
  playerId: null,
  retentionDetails: {
    percentRetained: 0,
    amountRetained: 0,
  },
  waiveNmc: false,
};

export const INITIAL_DRAFT_ASSET: TradeDraft = {
  draftPickId: null,
  isConditional: false,
};

export const INITIAL_TRADE_TEAM: TradeTeam = {
  teamId: 1,
  details: '',
  draftAssets: [INITIAL_DRAFT_ASSET],
  playerAssets: [INITIAL_PLAYER_ASSET],
};

export const updateTradePlayerAssets = (
  playerAssets: TradePlayer[],
  field: string,
  value: number | boolean,
  index: number,
): TradePlayer[] => {
  const playerAsset = cloneDeep(playerAssets[index]);
  const updatedPlayerAssets = cloneDeep(playerAssets);

  switch (field) {
    case PLAYER_ASSETS_FIELDS.RETAINED: {
      if (value) {
        playerAsset.retentionDetails.percentRetained = 10;
      } else {
        playerAsset.retentionDetails.percentRetained = 0;
      }
      break;
    }

    case PLAYER_ASSETS_FIELDS.PERCENT_RETAINED:
      playerAsset.retentionDetails.percentRetained = Number(value);
      break;

    default:
      playerAsset[field] = value;
      break;
  }

  updatedPlayerAssets[index] = playerAsset;

  return updatedPlayerAssets;
};
