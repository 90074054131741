/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { cloneDeep } from 'lodash';

import {
  CAREER_TO_CONTRACT_STAT_SELECTION,
  NEEDS_CALC_CAREER_DATA_FIELDS,
  NEEDS_CALC_CAREER_DATA_FIELDS_DETAILS,
  NEEDS_CALC_DATA_FIELDS,
  NEEDS_CALC_DATA_FIELDS_DETAILS,
  PLATFORM_STAT_SELECTIONS,
  STATS,
} from 'constants/comparableContracts';
import {
  ComparableContractsTableSummaryItem,
  ComparableSeasonStatLine,
  CompararableContractSeasonStats,
  GetContractSeasonStatsArgs,
  PlayerComparableContract,
} from 'types/comparableContracts';
import { numericSort, timeSort } from 'utilities/sorting';

export const INITIAL_STAT_SELECTION = 'Career Stats';

export const getContractSeasonStats = ({
  playerStats: {
    careerStats: { regularSeasonStats },
    careerToContractStats,
    seasonStats,
    ...rest
  },
  selectedSeason,
}: GetContractSeasonStatsArgs): ComparableSeasonStatLine | null => {
  const curSeasonStats: ComparableSeasonStatLine = regularSeasonStats;

  if (selectedSeason === 'Career Stats') return curSeasonStats;

  if (Object.values(PLATFORM_STAT_SELECTIONS).includes(selectedSeason)) {
    return (rest[selectedSeason] as CompararableContractSeasonStats).regularSeasonStats;
  }

  if (
    selectedSeason === CAREER_TO_CONTRACT_STAT_SELECTION &&
    careerToContractStats &&
    careerToContractStats.regularSeasonStats
  ) {
    return careerToContractStats.regularSeasonStats;
  }

  const season = seasonStats.find((curSeason) => curSeason.season.seasonId === selectedSeason);

  if (season) {
    return season.regularSeasonStats;
  }

  return null;
};

const reduceStatValues = (
  contractComparables: PlayerComparableContract[],
  selectedSeason: string,
  statKey: string,
): (number | string)[] => {
  const isNeedsCalc =
    Boolean(NEEDS_CALC_DATA_FIELDS[statKey]) ||
    Boolean(selectedSeason === INITIAL_STAT_SELECTION && NEEDS_CALC_CAREER_DATA_FIELDS[statKey]);

  return contractComparables.reduce((acc, curContract) => {
    const curSeasonStats = getContractSeasonStats({ playerStats: curContract, selectedSeason });

    if (!curSeasonStats) return acc;
    if (isNeedsCalc) {
      const calcStat = getCalcStat(curSeasonStats, statKey);

      if (typeof calcStat === 'number') acc.push(calcStat);

      return acc;
    }

    if (typeof curSeasonStats[statKey] === 'object') return acc;

    acc.push((curSeasonStats[statKey] as string | number) || 0);

    return acc;
  }, [] as (number | string)[]);
};

export const generateComparableContractSeasonStatFilters = (
  playerComparableContracts: PlayerComparableContract[] | null,
) => {
  const filters: { label: string; value: string }[] = [
    { label: INITIAL_STAT_SELECTION, value: INITIAL_STAT_SELECTION },
    { label: 'CTC', value: CAREER_TO_CONTRACT_STAT_SELECTION },
    { label: 'PY', value: PLATFORM_STAT_SELECTIONS.PLATFORM_YEAR },
    { label: 'PY - 1', value: PLATFORM_STAT_SELECTIONS.PLATFORM_YEAR_MINUS_ONE },
    { label: 'PY - 2', value: PLATFORM_STAT_SELECTIONS.PLATFORM_YEAR_MINUS_TWO },
  ];

  if (playerComparableContracts && playerComparableContracts.length) {
    const contracts = cloneDeep(playerComparableContracts);

    contracts[0].seasonStats
      .sort((seasonStatA, seasonStatB) => seasonStatB.season.seasonYearStart - seasonStatA.season.seasonYearStart)
      .forEach(({ season: { seasonId, seasonYearStart, seasonYearEnd } }) => {
        filters.push({ label: `${seasonYearStart} - ${seasonYearEnd}`, value: seasonId });
      });
  }

  return filters;
};

export const generateComparableContractsSummary = (
  contractComparables: PlayerComparableContract[],
  selectedSeason: string,
): ComparableContractsTableSummaryItem[] => {
  const summary: ComparableContractsTableSummaryItem[] = [];

  const assists = reduceStatValues(contractComparables, selectedSeason, STATS.ASSISTS).sort();
  const averageAnnualValue = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.contractInfo) return acc;
      acc.push(curContract.contractInfo.averageAnnualValue);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const qoValue = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.playerInfo || !curContract.playerInfo.qoValue) return acc;
      acc.push(curContract.playerInfo.qoValue);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const contractLength = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.contractInfo) return acc;
      acc.push(curContract.contractInfo.contractLength);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const gamesPlayed = reduceStatValues(contractComparables, selectedSeason, STATS.GAMES_PLAYED).sort(numericSort);
  const goals = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS).sort(numericSort);
  const points = reduceStatValues(contractComparables, selectedSeason, STATS.POINTS).sort(numericSort);
  const pointsPerGame = reduceStatValues(contractComparables, selectedSeason, STATS.POINTS_PER_GAME).sort(numericSort);
  const signingAge = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.contractInfo) return acc;
      acc.push(curContract.contractInfo.signingAge);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const capHitPercentage = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.contractInfo) return acc;
      acc.push(curContract.contractInfo.capHitPercentage * 100);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const currentAge = contractComparables
    .reduce((acc, curContract) => {
      if (!curContract.playerInfo) return acc;
      acc.push(curContract.playerInfo.age);
      return acc;
    }, [] as number[])
    .sort(numericSort);
  const assistsEvenStrength = reduceStatValues(contractComparables, selectedSeason, STATS.ASSISTS_EVEN_STRENGTH).sort(
    numericSort,
  );
  const assistsPerGame = reduceStatValues(contractComparables, selectedSeason, STATS.ASSISTS_PER_GAME).sort(
    numericSort,
  );
  const assistsPerGameEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.ASSISTS_PER_GAME_EVEN_STRENGTH,
  ).sort(numericSort);
  const assistsPerGamePowerplay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.ASSISTS_PER_GAME_POWERPLAY,
  ).sort(numericSort);
  const assistsPerGameShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.ASSISTS_PER_GAME_SHORTHANDED,
  ).sort(numericSort);
  const assistsPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.ASSISTS_POWERPLAY).sort(
    numericSort,
  );
  const assistsShorthanded = reduceStatValues(contractComparables, selectedSeason, STATS.ASSISTS_SHORTHANDED).sort(
    numericSort,
  );
  const blockedAtt = reduceStatValues(contractComparables, selectedSeason, STATS.BLOCKED_ATT).sort(numericSort);
  const blockedShots = reduceStatValues(contractComparables, selectedSeason, STATS.BLOCKED_SHOTS).sort(numericSort);
  const faceoffs = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS).sort(numericSort);
  const faceoffsEvenStrength = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_EVEN_STRENGTH).sort(
    numericSort,
  );
  const faceoffsLost = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_LOST).sort(numericSort);
  const faceoffsLostEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_LOST_EVEN_STRENGTH,
  ).sort(numericSort);
  const faceoffsLostPowerplay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_LOST_POWERPLAY,
  ).sort(numericSort);
  const faceoffsLostShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_LOST_SHORTHANDED,
  ).sort(numericSort);
  const faceoffsPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_POWERPLAY).sort(
    numericSort,
  );
  const faceoffsShorthanded = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_SHORTHANDED).sort(
    numericSort,
  );
  const faceoffsWinPct = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_WIN_PCT).sort(
    numericSort,
  );
  const faceoffsWinPctEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_WIN_PCT_EVEN_STRENGTH,
  ).sort(numericSort);
  const faceoffsWinPctPowerplay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_WIN_PCT_POWERPLAY,
  ).sort(numericSort);
  const faceoffsWinPctShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_WIN_PCT_SHORTHANDED,
  ).sort(numericSort);
  const faceoffsWon = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_WON).sort(numericSort);
  const faceoffsWonEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_WON_EVEN_STRENGTH,
  ).sort(numericSort);
  const faceoffsWonPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.FACEOFFS_WON_POWERPLAY).sort(
    numericSort,
  );
  const faceoffsWonShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.FACEOFFS_WON_SHORTHANDED,
  ).sort(numericSort);
  const giveaways = reduceStatValues(contractComparables, selectedSeason, STATS.GIVEAWAYS).sort(numericSort);
  const goalsAgainstAverage = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS_AGAINST_AVERAGE).sort(
    numericSort,
  );
  const goalsEvenStrength = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS_EVEN_STRENGTH).sort(
    numericSort,
  );
  const goalsPerGame = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS_PER_GAME).sort(numericSort);
  const goalsPerGameEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.GOALS_PER_GAME_EVEN_STRENGTH,
  ).sort(numericSort);
  const goalsPerGamePowerplay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.GOALS_PER_GAME_POWERPLAY,
  ).sort(numericSort);
  const goalsPerGameShortHanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.GOALS_PER_GAME_SHORTHANDED,
  ).sort(numericSort);
  const goalsPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS_POWERPLAY).sort(numericSort);
  const goalsShorthanded = reduceStatValues(contractComparables, selectedSeason, STATS.GOALS_SHORTHANDED).sort(
    numericSort,
  );
  const hits = reduceStatValues(contractComparables, selectedSeason, STATS.HITS).sort(numericSort);
  const missedShots = reduceStatValues(contractComparables, selectedSeason, STATS.MISSED_SHOTS).sort(numericSort);
  const missedShotsEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.MISSED_SHOTS_EVEN_STRENGTH,
  ).sort(numericSort);
  const missedShotsPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.MISSED_SHOTS_POWERPLAY).sort(
    numericSort,
  );
  const missedShotsShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.MISSED_SHOTS_SHORTHANDED,
  ).sort(numericSort);
  const penalties = reduceStatValues(contractComparables, selectedSeason, STATS.PENALTIES).sort(numericSort);
  const penaltyGoals = reduceStatValues(contractComparables, selectedSeason, STATS.PENALTY_GOALS).sort(numericSort);
  const penaltyInfractionMinutes = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.PENALTY_INFRACTION_MINUTES,
  ).sort(numericSort);
  const penaltyMinutes = reduceStatValues(contractComparables, selectedSeason, STATS.PENALTY_MINUTES).sort(numericSort);
  const penaltyMissedShots = reduceStatValues(contractComparables, selectedSeason, STATS.PENALTY_MISSED_SHOTS).sort(
    numericSort,
  );
  const penaltyShots = reduceStatValues(contractComparables, selectedSeason, STATS.PENALTY_SHOTS).sort(numericSort);
  const plusMinus = reduceStatValues(contractComparables, selectedSeason, STATS.PLUS_MINUS).sort(numericSort);
  const plusMinusSR = reduceStatValues(contractComparables, selectedSeason, STATS.PLUS_MINUS_SR).sort(numericSort);
  const pointsSR = reduceStatValues(contractComparables, selectedSeason, STATS.POINTS_SR).sort(numericSort);
  const pointsSREvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.POINTS_SR_EVEN_STRENGTH,
  ).sort(numericSort);
  const pointsSRPerGameEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.POINTS_SR_PER_GAME_EVEN_STRENGTH,
  ).sort(numericSort);
  const pointsSRPerGamePowerPlay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.POINTS_SR_PER_GAME_POWERPLAY,
  ).sort(numericSort);
  const pointsSRPerGameShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.POINTS_SR_PER_GAME_SHORTHANDED,
  ).sort(numericSort);
  const pointsSRPowerPlay = reduceStatValues(contractComparables, selectedSeason, STATS.POINTS_SR_POWERPLAY).sort(
    numericSort,
  );
  const pointsSRShorthanded = reduceStatValues(contractComparables, selectedSeason, STATS.POINTS_SR_SHORTHANDED).sort(
    numericSort,
  );
  const powerPlayGoals = reduceStatValues(contractComparables, selectedSeason, STATS.POWER_PLAY_GOALS).sort(
    numericSort,
  );
  const savePercentage = reduceStatValues(contractComparables, selectedSeason, STATS.SAVE_PERCENTAGE).sort(numericSort);
  const shootingPct = reduceStatValues(contractComparables, selectedSeason, STATS.SHOOTING_PCT).sort(numericSort);
  const shootoutGoals = reduceStatValues(contractComparables, selectedSeason, STATS.SHOOTOUT_GOALS).sort(numericSort);
  const shootoutMissedShots = reduceStatValues(contractComparables, selectedSeason, STATS.SHOOTOUT_MISSED_SHOTS).sort(
    numericSort,
  );
  const shootoutShots = reduceStatValues(contractComparables, selectedSeason, STATS.SHOOTOUT_SHOTS).sort(numericSort);
  const shots = reduceStatValues(contractComparables, selectedSeason, STATS.SHOTS).sort(numericSort);
  const shotsEvenStrength = reduceStatValues(contractComparables, selectedSeason, STATS.SHOTS_EVEN_STRENGTH).sort(
    numericSort,
  );
  const shotsPowerplay = reduceStatValues(contractComparables, selectedSeason, STATS.SHOTS_POWERPLAY).sort(numericSort);
  const shotsShorthanded = reduceStatValues(contractComparables, selectedSeason, STATS.SHOTS_SHORTHANDED).sort(
    numericSort,
  );
  const starterGames = reduceStatValues(contractComparables, selectedSeason, STATS.STARTER_GAMES).sort(numericSort);
  const takeaways = reduceStatValues(contractComparables, selectedSeason, STATS.TAKEAWAYS).sort(numericSort);
  const averageTimeOnIceTotal = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.AVERAGE_TIME_ON_ICE_TOTAL,
  ).sort(timeSort);
  const averageTimeOnIceEvenStrength = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.AVERAGE_TIME_ON_ICE_EVEN_STRENGTH,
  ).sort(timeSort);
  const averageTimeOnIcePowerPlay = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.AVERAGE_TIME_ON_ICE_POWER_PLAY,
  ).sort(timeSort);
  const averageTimeOnIceShorthanded = reduceStatValues(
    contractComparables,
    selectedSeason,
    STATS.AVERAGE_TIME_ON_ICE_SHORTHANDED,
  ).sort(timeSort);

  summary.push({
    assists: assists[assists.length - 1],
    assistsEvenStrength: assistsEvenStrength[assistsEvenStrength.length - 1],
    assistsPerGame: assistsPerGame[assistsPerGame.length - 1],
    assistsPerGameEvenStrength: assistsPerGameEvenStrength[assistsPerGameEvenStrength.length - 1],
    assistsPerGamePowerplay: assistsPerGamePowerplay[assistsPerGamePowerplay.length - 1],
    assistsPerGameShorthanded: assistsPerGameShorthanded[assistsPerGameShorthanded.length - 1],
    assistsPowerplay: assistsPowerplay[assistsPowerplay.length - 1],
    assistsShorthanded: assistsShorthanded[assistsShorthanded.length - 1],
    averageAnnualValue: averageAnnualValue[averageAnnualValue.length - 1],
    averageTimeOnIceTotal: averageTimeOnIceTotal[averageTimeOnIceTotal.length - 1],
    averageTimeOnIceEvenStrength: averageTimeOnIceEvenStrength[averageTimeOnIceEvenStrength.length - 1],
    averageTimeOnIcePowerPlay: averageTimeOnIcePowerPlay[averageTimeOnIcePowerPlay.length - 1],
    averageTimeOnIceShorthanded: averageTimeOnIceShorthanded[averageTimeOnIceShorthanded.length - 1],
    blockedAtt: blockedAtt[blockedAtt.length - 1],
    blockedShots: blockedShots[blockedShots.length - 1],
    capHitPercentage: capHitPercentage[averageAnnualValue.length - 1],
    contractLength: contractLength[contractLength.length - 1],
    currentAge: currentAge[currentAge.length - 1],
    faceoffs: faceoffs[faceoffs.length - 1],
    faceoffsEvenStrength: faceoffsEvenStrength[faceoffsEvenStrength.length - 1],
    faceoffsLost: faceoffsLost[faceoffsLost.length - 1],
    faceoffsLostEvenStrength: faceoffsLostEvenStrength[faceoffsLostEvenStrength.length - 1],
    faceoffsLostPowerplay: faceoffsLostPowerplay[faceoffsLostPowerplay.length - 1],
    faceoffsLostShorthanded: faceoffsLostShorthanded[faceoffsLostShorthanded.length - 1],
    faceoffsPowerplay: faceoffsPowerplay[faceoffsPowerplay.length - 1],
    faceoffsShorthanded: faceoffsShorthanded[faceoffsShorthanded.length - 1],
    faceoffsWinPct: faceoffsWinPct[faceoffsWinPct.length - 1],
    faceoffsWinPctEvenStrength: faceoffsWinPctEvenStrength[faceoffsWinPctEvenStrength.length - 1],
    faceoffsWinPctPowerplay: faceoffsWinPctPowerplay[faceoffsWinPctPowerplay.length - 1],
    faceoffsWinPctShorthanded: faceoffsWinPctShorthanded[faceoffsWinPctShorthanded.length - 1],
    faceoffsWon: faceoffsWon[faceoffsWon.length - 1],
    faceoffsWonEvenStrength: faceoffsWonEvenStrength[faceoffsWonEvenStrength.length - 1],
    faceoffsWonPowerplay: faceoffsWonPowerplay[faceoffsWonPowerplay.length - 1],
    faceoffsWonShorthanded: faceoffsWonShorthanded[faceoffsWonShorthanded.length - 1],
    gamesPlayed: gamesPlayed[gamesPlayed.length - 1],
    giveaways: giveaways[giveaways.length - 1],
    goals: goals[goals.length - 1],
    goalsAgainstAverage: goalsAgainstAverage[goalsAgainstAverage.length - 1],
    goalsEvenStrength: goalsEvenStrength[goalsEvenStrength.length - 1],
    goalsPerGame: goalsPerGame[goalsPerGame.length - 1],
    goalsPerGameEvenStrength: goalsPerGameEvenStrength[goalsPerGameEvenStrength.length - 1],
    goalsPerGamePowerplay: goalsPerGamePowerplay[goalsPerGamePowerplay.length - 1],
    goalsPerGameShortHanded: goalsPerGameShortHanded[goalsPerGameShortHanded.length - 1],
    goalsPowerplay: goalsPowerplay[goalsPowerplay.length - 1],
    goalsShorthanded: goalsShorthanded[goalsShorthanded.length - 1],
    hits: hits[hits.length - 1],
    missedShots: missedShots[missedShots.length - 1],
    missedShotsEvenStrength: missedShotsEvenStrength[missedShotsEvenStrength.length - 1],
    missedShotsPowerplay: missedShotsPowerplay[missedShotsPowerplay.length - 1],
    missedShotsShorthanded: missedShotsShorthanded[missedShotsShorthanded.length - 1],
    penalties: penalties[penalties.length - 1],
    penaltyGoals: penaltyGoals[penaltyGoals.length - 1],
    penaltyInfractionMinutes: penaltyInfractionMinutes[penaltyInfractionMinutes.length - 1],
    penaltyMinutes: penaltyMinutes[penaltyMinutes.length - 1],
    penaltyMissedShots: penaltyMissedShots[penaltyMissedShots.length - 1],
    penaltyShots: penaltyShots[penaltyShots.length - 1],
    plusMinus: plusMinus[plusMinus.length - 1],
    plusMinusSR: plusMinusSR[plusMinusSR.length - 1],
    pointsSR: points[points.length - 1],
    pointsSREvenStrength: pointsSREvenStrength[pointsSREvenStrength.length - 1],
    pointsPerGame: pointsPerGame[pointsPerGame.length - 1],
    pointsSRPerGameEvenStrength: pointsSRPerGameEvenStrength[pointsSRPerGameEvenStrength.length - 1],
    pointsSRPerGamePowerPlay: pointsSRPerGamePowerPlay[pointsSRPerGamePowerPlay.length - 1],
    pointsSRPerGameShorthanded: pointsSRPerGameShorthanded[pointsSRPerGameShorthanded.length - 1],
    pointsSRPowerPlay: pointsSRPowerPlay[pointsSRPowerPlay.length - 1],
    pointsSRShorthanded: pointsSRShorthanded[pointsSRShorthanded.length - 1],
    powerPlayGoals: powerPlayGoals[powerPlayGoals.length - 1],
    qoValue: qoValue[qoValue.length - 1],
    savePercentage: savePercentage[savePercentage.length - 1],
    shootingPct: shootingPct[shootingPct.length - 1],
    shootoutGoals: shootoutGoals[shootoutGoals.length - 1],
    shootoutMissedShots: shootoutMissedShots[shootoutMissedShots.length - 1],
    shootoutShots: shootoutShots[shootoutShots.length - 1],
    shots: shots[shots.length - 1],
    shotsEvenStrength: shotsEvenStrength[shotsEvenStrength.length - 1],
    shotsPowerplay: shotsPowerplay[shotsPowerplay.length - 1],
    shotsShorthanded: shotsShorthanded[shotsShorthanded.length - 1],
    signingAge: signingAge[signingAge.length - 1],
    starterGames: starterGames[starterGames.length - 1],
    takeaways: takeaways[takeaways.length - 1],
    title: 'Maximum',
  });

  summary.push({
    assists: getAvgValue(assists as number[]),
    assistsEvenStrength: getAvgValue(assistsEvenStrength as number[]),
    assistsPerGame: getAvgValue(assistsPerGame as number[]),
    assistsPerGameEvenStrength: getAvgValue(assistsPerGameEvenStrength as number[]),
    assistsPerGamePowerplay: getAvgValue(assistsPerGamePowerplay as number[]),
    assistsPerGameShorthanded: getAvgValue(assistsPerGameShorthanded as number[]),
    assistsPowerplay: getAvgValue(assistsPowerplay as number[]),
    assistsShorthanded: getAvgValue(assistsShorthanded as number[]),
    averageAnnualValue: getAvgValue(averageAnnualValue),
    averageTimeOnIceTotal: getAvgTime(averageTimeOnIceTotal as string[]),
    averageTimeOnIceEvenStrength: getAvgTime(averageTimeOnIceEvenStrength as string[]),
    averageTimeOnIcePowerPlay: getAvgTime(averageTimeOnIcePowerPlay as string[]),
    averageTimeOnIceShorthanded: getAvgTime(averageTimeOnIceShorthanded as string[]),
    blockedAtt: getAvgValue(blockedAtt as number[]),
    blockedShots: getAvgValue(blockedShots as number[]),
    capHitPercentage: getAvgValue(capHitPercentage),
    contractLength: getAvgValue(contractLength),
    currentAge: getAvgValue(currentAge),
    faceoffs: getAvgValue(faceoffs as number[]),
    faceoffsEvenStrength: getAvgValue(faceoffsEvenStrength as number[]),
    faceoffsLost: getAvgValue(faceoffsLost as number[]),
    faceoffsLostEvenStrength: getAvgValue(faceoffsLostEvenStrength as number[]),
    faceoffsLostPowerplay: getAvgValue(faceoffsLostPowerplay as number[]),
    faceoffsLostShorthanded: getAvgValue(faceoffsLostShorthanded as number[]),
    faceoffsPowerplay: getAvgValue(faceoffsPowerplay as number[]),
    faceoffsShorthanded: getAvgValue(faceoffsShorthanded as number[]),
    faceoffsWinPct: getAvgValue(faceoffsWinPct as number[]),
    faceoffsWinPctEvenStrength: getAvgValue(faceoffsWinPctEvenStrength as number[]),
    faceoffsWinPctPowerplay: getAvgValue(faceoffsWinPctPowerplay as number[]),
    faceoffsWinPctShorthanded: getAvgValue(faceoffsWinPctShorthanded as number[]),
    faceoffsWon: getAvgValue(faceoffsWon as number[]),
    faceoffsWonEvenStrength: getAvgValue(faceoffsWonEvenStrength as number[]),
    faceoffsWonPowerplay: getAvgValue(faceoffsWonPowerplay as number[]),
    faceoffsWonShorthanded: getAvgValue(faceoffsWonShorthanded as number[]),
    gamesPlayed: getAvgValue(gamesPlayed as number[]),
    giveaways: getAvgValue(giveaways as number[]),
    goals: getAvgValue(goals as number[]),
    goalsAgainstAverage: getAvgValue(goalsAgainstAverage as number[]),
    goalsEvenStrength: getAvgValue(goalsEvenStrength as number[]),
    goalsPerGame: getAvgValue(goalsPerGame as number[]),
    goalsPerGameEvenStrength: getAvgValue(goalsPerGameEvenStrength as number[]),
    goalsPerGamePowerplay: getAvgValue(goalsPerGamePowerplay as number[]),
    goalsPerGameShortHanded: getAvgValue(goalsPerGameShortHanded as number[]),
    goalsPowerplay: getAvgValue(goalsPowerplay as number[]),
    goalsShorthanded: getAvgValue(goalsShorthanded as number[]),
    hits: getAvgValue(hits as number[]),
    missedShots: getAvgValue(missedShots as number[]),
    missedShotsEvenStrength: getAvgValue(missedShotsEvenStrength as number[]),
    missedShotsPowerplay: getAvgValue(missedShotsPowerplay as number[]),
    missedShotsShorthanded: getAvgValue(missedShotsShorthanded as number[]),
    penalties: getAvgValue(penalties as number[]),
    penaltyGoals: getAvgValue(penaltyGoals as number[]),
    penaltyInfractionMinutes: getAvgValue(penaltyInfractionMinutes as number[]),
    penaltyMinutes: getAvgValue(penaltyMinutes as number[]),
    penaltyMissedShots: getAvgValue(penaltyMissedShots as number[]),
    penaltyShots: getAvgValue(penaltyShots as number[]),
    plusMinus: getAvgValue(plusMinus as number[]),
    plusMinusSR: getAvgValue(plusMinusSR as number[]),
    pointsSR: getAvgValue(pointsSR as number[]),
    pointsSREvenStrength: getAvgValue(pointsSREvenStrength as number[]),
    pointsPerGame: getAvgValue(pointsPerGame as number[]),
    pointsSRPerGameEvenStrength: getAvgValue(pointsSRPerGameEvenStrength as number[]),
    pointsSRPerGamePowerPlay: getAvgValue(pointsSRPerGamePowerPlay as number[]),
    pointsSRPerGameShorthanded: getAvgValue(pointsSRPerGameShorthanded as number[]),
    pointsSRPowerPlay: getAvgValue(pointsSRPowerPlay as number[]),
    pointsSRShorthanded: getAvgValue(pointsSRShorthanded as number[]),
    powerPlayGoals: getAvgValue(powerPlayGoals as number[]),
    qoValue: getAvgValue(qoValue),
    savePercentage: getAvgValue(savePercentage as number[]),
    shootingPct: getAvgValue(shootingPct as number[]),
    shootoutGoals: getAvgValue(shootoutGoals as number[]),
    shootoutMissedShots: getAvgValue(shootoutMissedShots as number[]),
    shootoutShots: getAvgValue(shootoutShots as number[]),
    shots: getAvgValue(shots as number[]),
    shotsEvenStrength: getAvgValue(shotsEvenStrength as number[]),
    shotsPowerplay: getAvgValue(shotsPowerplay as number[]),
    shotsShorthanded: getAvgValue(shotsShorthanded as number[]),
    signingAge: getAvgValue(signingAge),
    starterGames: getAvgValue(starterGames as number[]),
    takeaways: getAvgValue(takeaways as number[]),
    title: 'Average',
  });

  summary.push({
    assists: getMedianValue(assists as number[]),
    assistsEvenStrength: getMedianValue(assistsEvenStrength as number[]),
    assistsPerGame: getMedianValue(assistsPerGame as number[]),
    assistsPerGameEvenStrength: getMedianValue(assistsPerGameEvenStrength as number[]),
    assistsPerGamePowerplay: getMedianValue(assistsPerGamePowerplay as number[]),
    assistsPerGameShorthanded: getMedianValue(assistsPerGameShorthanded as number[]),
    assistsPowerplay: getMedianValue(assistsPowerplay as number[]),
    assistsShorthanded: getMedianValue(assistsShorthanded as number[]),
    averageAnnualValue: getMedianValue(averageAnnualValue),
    averageTimeOnIceTotal: getMedianTime(averageTimeOnIceTotal as string[]),
    averageTimeOnIceEvenStrength: getMedianTime(averageTimeOnIceEvenStrength as string[]),
    averageTimeOnIcePowerPlay: getMedianTime(averageTimeOnIcePowerPlay as string[]),
    averageTimeOnIceShorthanded: getMedianTime(averageTimeOnIceShorthanded as string[]),
    blockedAtt: getMedianValue(blockedAtt as number[]),
    blockedShots: getMedianValue(blockedShots as number[]),
    capHitPercentage: getMedianValue(capHitPercentage),
    contractLength: getMedianValue(contractLength),
    currentAge: getMedianValue(currentAge),
    faceoffs: getMedianValue(faceoffs as number[]),
    faceoffsEvenStrength: getMedianValue(faceoffsEvenStrength as number[]),
    faceoffsLost: getMedianValue(faceoffsLost as number[]),
    faceoffsLostEvenStrength: getMedianValue(faceoffsLostEvenStrength as number[]),
    faceoffsLostPowerplay: getMedianValue(faceoffsLostPowerplay as number[]),
    faceoffsLostShorthanded: getMedianValue(faceoffsLostShorthanded as number[]),
    faceoffsPowerplay: getMedianValue(faceoffsPowerplay as number[]),
    faceoffsShorthanded: getMedianValue(faceoffsShorthanded as number[]),
    faceoffsWinPct: getMedianValue(faceoffsWinPct as number[]),
    faceoffsWinPctEvenStrength: getMedianValue(faceoffsWinPctEvenStrength as number[]),
    faceoffsWinPctPowerplay: getMedianValue(faceoffsWinPctPowerplay as number[]),
    faceoffsWinPctShorthanded: getMedianValue(faceoffsWinPctShorthanded as number[]),
    faceoffsWon: getMedianValue(faceoffsWon as number[]),
    faceoffsWonEvenStrength: getMedianValue(faceoffsWonEvenStrength as number[]),
    faceoffsWonPowerplay: getMedianValue(faceoffsWonPowerplay as number[]),
    faceoffsWonShorthanded: getMedianValue(faceoffsWonShorthanded as number[]),
    gamesPlayed: getMedianValue(gamesPlayed as number[]),
    giveaways: getMedianValue(giveaways as number[]),
    goals: getMedianValue(goals as number[]),
    goalsAgainstAverage: getMedianValue(goalsAgainstAverage as number[]),
    goalsEvenStrength: getMedianValue(goalsEvenStrength as number[]),
    goalsPerGame: getMedianValue(goalsPerGame as number[]),
    goalsPerGameEvenStrength: getMedianValue(goalsPerGameEvenStrength as number[]),
    goalsPerGamePowerplay: getMedianValue(goalsPerGamePowerplay as number[]),
    goalsPerGameShortHanded: getMedianValue(goalsPerGameShortHanded as number[]),
    goalsPowerplay: getMedianValue(goalsPowerplay as number[]),
    goalsShorthanded: getMedianValue(goalsShorthanded as number[]),
    hits: getMedianValue(hits as number[]),
    missedShots: getMedianValue(missedShots as number[]),
    missedShotsEvenStrength: getMedianValue(missedShotsEvenStrength as number[]),
    missedShotsPowerplay: getMedianValue(missedShotsPowerplay as number[]),
    missedShotsShorthanded: getMedianValue(missedShotsShorthanded as number[]),
    penalties: getMedianValue(penalties as number[]),
    penaltyGoals: getMedianValue(penaltyGoals as number[]),
    penaltyInfractionMinutes: getMedianValue(penaltyInfractionMinutes as number[]),
    penaltyMinutes: getMedianValue(penaltyMinutes as number[]),
    penaltyMissedShots: getMedianValue(penaltyMissedShots as number[]),
    penaltyShots: getMedianValue(penaltyShots as number[]),
    plusMinus: getMedianValue(plusMinus as number[]),
    plusMinusSR: getMedianValue(plusMinusSR as number[]),
    pointsSR: getMedianValue(pointsSR as number[]),
    pointsSREvenStrength: getMedianValue(pointsSREvenStrength as number[]),
    pointsPerGame: getMedianValue(pointsPerGame as number[]),
    pointsSRPerGameEvenStrength: getMedianValue(pointsSRPerGameEvenStrength as number[]),
    pointsSRPerGamePowerPlay: getMedianValue(pointsSRPerGamePowerPlay as number[]),
    pointsSRPerGameShorthanded: getMedianValue(pointsSRPerGameShorthanded as number[]),
    pointsSRPowerPlay: getMedianValue(pointsSRPowerPlay as number[]),
    pointsSRShorthanded: getMedianValue(pointsSRShorthanded as number[]),
    powerPlayGoals: getMedianValue(powerPlayGoals as number[]),
    qoValue: getMedianValue(qoValue),
    savePercentage: getMedianValue(savePercentage as number[]),
    shootingPct: getMedianValue(shootingPct as number[]),
    shootoutGoals: getMedianValue(shootoutGoals as number[]),
    shootoutMissedShots: getMedianValue(shootoutMissedShots as number[]),
    shootoutShots: getMedianValue(shootoutShots as number[]),
    shots: getMedianValue(shots as number[]),
    shotsEvenStrength: getMedianValue(shotsEvenStrength as number[]),
    shotsPowerplay: getMedianValue(shotsPowerplay as number[]),
    shotsShorthanded: getMedianValue(shotsShorthanded as number[]),
    signingAge: getMedianValue(signingAge),
    starterGames: getMedianValue(starterGames as number[]),
    takeaways: getMedianValue(takeaways as number[]),
    title: 'Median',
  });

  summary.push({
    assists: assists[0],
    assistsEvenStrength: assistsEvenStrength[0],
    assistsPerGame: assistsPerGame[0],
    assistsPerGameEvenStrength: assistsPerGameEvenStrength[0],
    assistsPerGamePowerplay: assistsPerGamePowerplay[0],
    assistsPerGameShorthanded: assistsPerGameShorthanded[0],
    assistsPowerplay: assistsPowerplay[0],
    assistsShorthanded: assistsShorthanded[0],
    averageAnnualValue: averageAnnualValue[0],
    averageTimeOnIceTotal: averageTimeOnIceTotal[0],
    averageTimeOnIceEvenStrength: averageTimeOnIceEvenStrength[0],
    averageTimeOnIcePowerPlay: averageTimeOnIcePowerPlay[0],
    averageTimeOnIceShorthanded: averageTimeOnIceShorthanded[0],
    blockedAtt: blockedAtt[0],
    blockedShots: blockedShots[0],
    capHitPercentage: capHitPercentage[0],
    contractLength: contractLength[0],
    currentAge: currentAge[0],
    faceoffs: faceoffs[0],
    faceoffsEvenStrength: faceoffsEvenStrength[0],
    faceoffsLost: faceoffsLost[0],
    faceoffsLostEvenStrength: faceoffsLostEvenStrength[0],
    faceoffsLostPowerplay: faceoffsLostPowerplay[0],
    faceoffsLostShorthanded: faceoffsLostShorthanded[0],
    faceoffsPowerplay: faceoffsPowerplay[0],
    faceoffsShorthanded: faceoffsShorthanded[0],
    faceoffsWinPct: faceoffsWinPct[0],
    faceoffsWinPctEvenStrength: faceoffsWinPctEvenStrength[0],
    faceoffsWinPctPowerplay: faceoffsWinPctPowerplay[0],
    faceoffsWinPctShorthanded: faceoffsWinPctShorthanded[0],
    faceoffsWon: faceoffsWon[0],
    faceoffsWonEvenStrength: faceoffsWonEvenStrength[0],
    faceoffsWonPowerplay: faceoffsWonPowerplay[0],
    faceoffsWonShorthanded: faceoffsWonShorthanded[0],
    gamesPlayed: gamesPlayed[0],
    giveaways: giveaways[0],
    goals: goals[0],
    goalsAgainstAverage: goalsAgainstAverage[0],
    goalsEvenStrength: goalsEvenStrength[0],
    goalsPerGame: goalsPerGame[0],
    goalsPerGameEvenStrength: goalsPerGameEvenStrength[0],
    goalsPerGamePowerplay: goalsPerGamePowerplay[0],
    goalsPerGameShortHanded: goalsPerGameShortHanded[0],
    goalsPowerplay: goalsPowerplay[0],
    goalsShorthanded: goalsShorthanded[0],
    hits: hits[0],
    missedShots: missedShots[0],
    missedShotsEvenStrength: missedShotsEvenStrength[0],
    missedShotsPowerplay: missedShotsPowerplay[0],
    missedShotsShorthanded: missedShotsShorthanded[0],
    penalties: penalties[0],
    penaltyGoals: penaltyGoals[0],
    penaltyInfractionMinutes: penaltyInfractionMinutes[0],
    penaltyMinutes: penaltyMinutes[0],
    penaltyMissedShots: penaltyMissedShots[0],
    penaltyShots: penaltyShots[0],
    plusMinus: plusMinus[0],
    plusMinusSR: plusMinusSR[0],
    pointsSR: points[0],
    pointsSREvenStrength: pointsSREvenStrength[0],
    pointsPerGame: pointsPerGame[0],
    pointsSRPerGameEvenStrength: pointsSRPerGameEvenStrength[0],
    pointsSRPerGamePowerPlay: pointsSRPerGamePowerPlay[0],
    pointsSRPerGameShorthanded: pointsSRPerGameShorthanded[0],
    pointsSRPowerPlay: pointsSRPowerPlay[0],
    pointsSRShorthanded: pointsSRShorthanded[0],
    powerPlayGoals: powerPlayGoals[0],
    qoValue: qoValue[0],
    savePercentage: savePercentage[0],
    shootingPct: shootingPct[0],
    shootoutGoals: shootoutGoals[0],
    shootoutMissedShots: shootoutMissedShots[0],
    shootoutShots: shootoutShots[0],
    shots: shots[0],
    shotsEvenStrength: shotsEvenStrength[0],
    shotsPowerplay: shotsPowerplay[0],
    shotsShorthanded: shotsShorthanded[0],
    signingAge: signingAge[0],
    starterGames: starterGames[0],
    takeaways: takeaways[0],
    title: 'Minimum',
  });

  return summary;
};

const getMedianValue = (arr: number[]): number => {
  if (arr.length === 0) return 0;

  const mid = Math.ceil(arr.length / 2);

  return arr.length % 2 === 0 ? (arr[mid] + arr[mid - 1]) / 2 : arr[mid - 1];
};

const getAvgValue = (arr: number[]): string => {
  if (arr.length === 0) return (0).toFixed(1);

  // eslint-disable-next-line no-return-assign
  return (arr.reduce((sum, val) => (sum += val), 0) / arr.length).toFixed(1);
};

const reduceTimesToEpoch = (times: string[]): number[] =>
  times.reduce((acc, time) => {
    if (!time) return acc;

    const date = new Date();

    const value = time.split('.');
    if (value.length > 1) value.pop();
    const [hh, mm, ss] = value.join().split(':');

    date.setHours(Number(hh));
    date.setMinutes(Number(mm));
    date.setSeconds(Number(ss));

    acc.push(date.getTime());

    return acc;
  }, [] as number[]);

const getAvgTime = (arr: string[]): string => {
  if (arr.length === 0) return '--';

  const dateTimes = reduceTimesToEpoch(arr);

  // eslint-disable-next-line no-return-assign
  const avg = dateTimes.reduce((sum, val) => (sum += val), 0) / dateTimes.length;

  const avgDate = new Date(avg);
  const avgHours = avgDate.getHours();
  const avgMinutes = avgDate.getMinutes();
  const avgSeconds = avgDate.getSeconds();

  return `${avgHours || '00'}:${avgMinutes || '00'}:${avgSeconds || '00'}`;
};

const getMedianTime = (arr: string[]): string => {
  if (arr.length === 0) return '--';

  const dateTimes = reduceTimesToEpoch(arr);

  const mid = Math.ceil(arr.length / 2);
  const med = dateTimes.length % 2 === 0 ? (dateTimes[mid] + dateTimes[mid - 1]) / 2 : dateTimes[mid - 1];

  const avgDate = new Date(med);
  const avgHours = avgDate.getHours();
  const avgMinutes = avgDate.getMinutes();
  const avgSeconds = avgDate.getSeconds();

  return `${avgHours || '00'}:${avgMinutes || '00'}:${avgSeconds || '00'}`;
};

export const formatTOI = (time: string): string => {
  if (!time) return '';

  let value = time.split('.');
  if (value.length > 1) value.pop();
  value = value.join().split(':');

  if (value[0] === '00' || value[0] === '0') value.shift();

  return value.join(':');
};

export const getCalcStat = (seasonStats: ComparableSeasonStatLine, statKey: string): string | number => {
  const calcDetails = NEEDS_CALC_DATA_FIELDS_DETAILS[statKey] || NEEDS_CALC_CAREER_DATA_FIELDS_DETAILS[statKey];

  if (calcDetails) {
    const dividend = seasonStats[calcDetails.dividend] as number;
    const divisor = seasonStats[calcDetails.divisor] as number;

    return dividend && divisor ? dividend / divisor : '--';
  }

  return '--';
};
