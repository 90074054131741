import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box } from '@mui/material';
import { StanleyTable } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StanleyTableTeamIcon from 'components/table/stanleyTableTeamIcon.component';
import { InjuryTransactionType, InjuryType, RecentInjury } from 'types/transactions';
import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { getFullName } from 'utilities/player';
import { selectRecentInjuries } from 'modules/teams/recentTransactions/recentTransactions.selectors';

function RecentInjuries() {
  const navigate = useNavigate();

  const injuries = useSelector(selectRecentInjuries);

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        render: (injury: RecentInjury) => InjuryTransactionType[injury.transaction],
      },
      {
        title: 'Type',
        render: ({ type }: RecentInjury) => (
          <Box display="flex" alignItems="center">
            {InjuryType[type]}
            {type === InjuryType.Deactivated ? (
              <ArrowDownward sx={{ marginLeft: 2 }} color="error" />
            ) : (
              <ArrowUpward sx={{ marginLeft: 2 }} color="success" />
            )}
          </Box>
        ),
      },
      {
        title: 'Player',
        render: (injury: RecentInjury) => getFullName(injury.playerInfo),
      },
      {
        title: 'Team',
        sorting: false,
        render: ({ teamInfo }: RecentInjury) => <StanleyTableTeamIcon teamInfo={teamInfo} />,
      },
      {
        title: 'Date',
        render: ({ date }: RecentInjury) => format(new Date(date), 'MMM dd, yyyy'),
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: RecentInjury) => {
      if (rowData && rowData.playerInfo) navigate(`${ROUTES.PLAYERS}/${rowData.playerInfo.playerId}`);
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      data={injuries ? [...injuries.slice(0, 12)] : []}
      options={{
        sorting: false,
      }}
      onRowClick={handleRowClick}
    />
  );
}

export default RecentInjuries;
