import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'middleware/api';
import { FirebaseUser, User, UserPreferences } from 'types/user';

export interface UserState {
  isLoading: boolean;
  firebaseUser: FirebaseUser | null;
  preferences: UserPreferences | null;
  user: User | null;
}

const initialState: UserState = {
  isLoading: false,
  firebaseUser: null,
  preferences: null,
  user: null,
};

export const getUser = createAsyncThunk('user/getUser', async () => {
  const response = (await api('user', { method: 'GET' }, true)) as User;

  return response;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPreferences(state, action: PayloadAction<UserPreferences>) {
      state.preferences = action.payload;
    },
    setFirebaseUser(state, action: PayloadAction<FirebaseUser>) {
      state.firebaseUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setFirebaseUser, setPreferences } = userSlice.actions;

export default userSlice.reducer;
