export interface SignUpUser {
  email: string;
  password: string;
}

export interface SignInUser {
  email: string;
  password: string;
}

export interface AuthUser {
  userId: number;
  firebaseUserId: string;
  organizationId: number;
  isSuperAdmin: boolean;
  isOrgAdmin: boolean | null;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  isActive: boolean,
  roles: number[]
}

export enum LoggedInState {
  LOADING = 'loading',
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
}
