/* eslint-disable max-len */
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Tab, Tabs } from '@mui/material';
import { ButtonSize, StanleySecondaryButton, StanleySelectOption } from '@project-stanley/cap-management-components';
import { Close } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';

import PlayerContractDataFields from 'components/playerDataFields/playerContractDataFields.component';
import PlayerStatsDataFields from 'components/playerDataFields/playerStatsDataFields.component';

interface PlayerDataFieldsDialogProps {
  dataFields: (number | string)[];
  open: boolean;
  onClose: () => void;
  onDataFieldsChange: (dataFields: (number | string)[]) => void;
}

function PlayerDataFieldsDialog({ dataFields, open, onClose, onDataFieldsChange }: PlayerDataFieldsDialogProps) {
  const [tempDataFields, setTempDataFields] = useState(dataFields);
  const [selectedTabIndex, setSelectedTab] = useState(0);

  useEffect(() => {
    setTempDataFields(dataFields);
  }, [dataFields]);

  const handleDataFieldsChange = useCallback(
    (selectedValue: string | number) => {
      const selectedValues = [...tempDataFields];
      const index = tempDataFields.findIndex((curValue) => curValue === selectedValue);

      if (index !== -1) {
        selectedValues?.splice(index, 1);
      } else {
        selectedValues.push(selectedValue);
      }

      setTempDataFields(selectedValues);
    },
    [tempDataFields],
  );

  const handleSelectAll = useCallback(
    (options: StanleySelectOption[], removeAll = false) => {
      const selectedValues = [...tempDataFields];

      if (removeAll) {
        options.forEach((option) => {
          const index = selectedValues.findIndex((curValue) => curValue === option.value);

          if (index !== -1) {
            selectedValues?.splice(index, 1);
          } else {
            selectedValues.push(option.value);
          }
        });
      } else {
        options.forEach((option) => selectedValues.push(option.value));
      }

      setTempDataFields(selectedValues);
    },
    [tempDataFields],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      PaperProps={{ sx: { height: '38rem', padding: 1, position: 'relative', maxHeight: '100%', width: 'unset' } }}
    >
      <DialogContent sx={{ padding: 0, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Tabs
          indicatorColor="primary"
          scrollButtons={false}
          textColor="primary"
          value={selectedTabIndex}
          variant="scrollable"
          onChange={(e, tabIndex) => setSelectedTab(tabIndex as number)}
        >
          <Tab label="Player & Contract Details" sx={{ textTransform: 'none' }} />
          <Tab label="Performance Details" sx={{ textTransform: 'none' }} />
        </Tabs>
        <Box flex={1} paddingY={1} sx={{ overflowY: 'scroll' }}>
          {selectedTabIndex === 0 && (
            <PlayerContractDataFields
              dataFields={tempDataFields}
              onChange={handleDataFieldsChange}
              onSelectAll={handleSelectAll}
            />
          )}
          {selectedTabIndex === 1 && (
            <PlayerStatsDataFields
              dataFields={tempDataFields}
              onChange={handleDataFieldsChange}
              onSelectAll={handleSelectAll}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size={ButtonSize.SMALL} sx={{ paddingRight: '1rem', textTransform: 'none' }} onClick={() => {}}>
          Reset to Default
        </Button>
        <StanleySecondaryButton
          size={ButtonSize.SMALL}
          sx={{ textTransform: 'none' }}
          onClick={() => {
            onClose();
            onDataFieldsChange(tempDataFields);
          }}
        >
          Save
        </StanleySecondaryButton>
      </DialogActions>
      <IconButton size="small" sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={onClose}>
        <Close />
      </IconButton>
    </Dialog>
  );
}

export default PlayerDataFieldsDialog;
