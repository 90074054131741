import NumberFormat from 'react-number-format';
import { InputAdornment, TextField } from '@mui/material';

interface StanleyTablePercentageEditInputProps {
  className?: string;
  fullWidth?: boolean;
  value: number;
  variant?: string;
  onChange: (value: number) => void;
}

function CustomInput({ variant, ...props }: any) {
  return (<TextField
    inputProps={{
      style: variant === 'outlined' ? { paddingBottom: '6px', paddingTop: '6px' } : {},
    }}
    InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
    variant={variant as string}
    {...props}
  />);
}

function StanleyTablePercentageEditInput({
  value,
  onChange,
  ...props
}: StanleyTablePercentageEditInputProps): JSX.Element {
  return (<NumberFormat
    customInput={CustomInput}
    isAllowed={(values) => {
      const { floatValue } = values;
      return Boolean(floatValue && floatValue > 0);
    }}
    thousandSeparator
    value={value || 0}
    onValueChange={(values) => onChange(values.floatValue || 0)}
    {...props}
  />);
}

export default StanleyTablePercentageEditInput;
