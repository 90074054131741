/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable max-len */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';

import api from 'middleware/api';
import { MovePlayer, TradeSimulation, TradeSimulationPreview } from 'types/trade';
import { PlayerContractSimulationPreview } from 'types/contract';
import { RootState } from 'store/index';
import { Scenario, ScenarioSummary, ScenarioSummaryByTeam } from 'types/scenarios';

export interface ScenariosState {
  contractPreview: PlayerContractSimulationPreview | null;
  isDeletingScenario: boolean;
  isLoading: boolean;
  isLoadingContract: boolean;
  isLoadingPreview: boolean;
  isLoadingSummary: boolean;
  scenarios: Scenario[] | null;
  scenario: Scenario | null;
  scenarioSummary: ScenarioSummaryByTeam | null;
  tradePreview: TradeSimulation | null;
}

const initialState: ScenariosState = {
  contractPreview: null,
  isDeletingScenario: false,
  isLoading: false,
  isLoadingContract: false,
  isLoadingPreview: false,
  isLoadingSummary: false,
  scenarios: null,
  scenario: null,
  scenarioSummary: null,
  tradePreview: null,
};

export const getScenario = createAsyncThunk('scenarios/getScenario', async (scenarioId: string) => {
  const response = await api(`scenarios/${scenarioId}`, { method: 'GET' }, true) as Scenario;

  return response;
});

export const getScenarios = createAsyncThunk('scenarios/getScenarios', async () => {
  const response = await api('scenarios', { method: 'GET' }, true) as Scenario[];

  return response;
});

export const createScenario = createAsyncThunk('scenarios/createScenario', async (name: string) => {
  const response = await api('scenarios', { method: 'PUT', body: { name } }, true) as number;

  return response;
});

export const deleteScenario = createAsyncThunk('scenarios/deleteScenario', async (scenarioId: string) => {
  const response = await api(`scenarios/${scenarioId}`, { method: 'DELETE' }, true) as Scenario;

  return response;
});

export const getScenarioContractPreview = createAsyncThunk(
'scenarios/getScenarioContractPreview',
  async (contractSimulationPreview: PlayerContractSimulationPreview, { getState, rejectWithValue }) => {
    const { scenario } = (getState() as RootState).scenarios;

    if (scenario) {
      const response =
        await api(`scenarios/${scenario.scenarioId.toString()}/contracts/preview`, { method: 'POST', body: contractSimulationPreview }, true) as PlayerContractSimulationPreview;

      return response;
    }

    return rejectWithValue('Error');
  },
);

export const addScenarioContract = createAsyncThunk(
'scenarios/addScenarioContract',
  async (contractSimulation: PlayerContractSimulationPreview, { getState, rejectWithValue }) => {
    const { scenario } = (getState() as RootState).scenarios;

    if (scenario) {
      const response = await api(`scenarios/${scenario.scenarioId.toString()}/contracts`, { method: 'PUT', body: contractSimulation }, true) as number;

      return response;
    }

    return rejectWithValue('Error');
},
);

export const getScenarioTradePreview = createAsyncThunk(
'scenarios/getScenarioTradePreview',
  async (tradeSimulationPreview: TradeSimulationPreview, { getState, rejectWithValue }) => {
    const { scenario } = (getState() as RootState).scenarios;

    if (scenario) {
      const response =
        await api(`scenarios/${scenario.scenarioId.toString()}/trades/preview`, { method: 'POST', body: tradeSimulationPreview }, true) as TradeSimulation;

      return response;
    }

    return rejectWithValue('Error');
},
);

export const addScenarioTrade = createAsyncThunk(
'scenarios/addScenarioTrade',
  async (tradeSimulation: TradeSimulationPreview, { getState, rejectWithValue }) => {
    const { scenario } = (getState() as RootState).scenarios;

    if (scenario) {
      const response =
        await api(`scenarios/${scenario.scenarioId.toString()}/trades`, { method: 'PUT', body: tradeSimulation }, true) as TradeSimulation;

      return response;
    }

    return rejectWithValue('Error');
},
);

export const scenarioMovePlayer = createAsyncThunk(
'scenarios/scenarioMovePlayer',
  async ({ playerId, newStatus }: MovePlayer, { getState, rejectWithValue }) => {
    const { scenario } = (getState() as RootState).scenarios;

    if (scenario) {
      const response =
        await api(`scenarios/${scenario.scenarioId.toString()}/players/${playerId}`, { method: 'POST', body: { newStatus } }, true) as MovePlayer;

      return response;
    }

    return rejectWithValue('Error');
},
);

export const getScenarioSummary = createAsyncThunk(
'scenarios/getScenarioSummary',
  async (scenarioId: number) => {
    const response =
      await api(`scenarios/${scenarioId.toString()}/summary`, { method: 'GET' }, true) as ScenarioSummary;

    return response;
},
);

export const setScenario = createAction<Scenario | null>('scenarios/setScenario');

export const resetScenarios = createAction('scenarios/resetScenarios');

export const resetScenarioSummary = createAction('scenarios/resetScenarioSummary');

export const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createScenario.pending, (state) => { state.isLoading = true; })
      .addCase(createScenario.fulfilled, (state) => { state.isLoading = false; })
      .addCase(createScenario.rejected, (state) => { state.isLoading = false; })
      .addCase(getScenarios.pending, (state) => { state.isLoading = true; })
      .addCase(getScenarios.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.scenarios = payload;
      })
      .addCase(getScenarios.rejected, (state) => { state.isLoading = false; })
      .addCase(getScenario.pending, (state) => { state.isLoading = true; })
      .addCase(getScenario.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.scenario = payload;
      })
      .addCase(getScenario.rejected, (state) => { state.isLoading = false; })
      .addCase(deleteScenario.pending, (state) => { state.isDeletingScenario = true; })
      .addCase(deleteScenario.fulfilled, (state) => { state.isDeletingScenario = false; })
      .addCase(deleteScenario.rejected, (state) => { state.isDeletingScenario = false; })
      .addCase(getScenarioContractPreview.pending, (state) => { state.isLoadingPreview = true; })
      .addCase(getScenarioContractPreview.fulfilled, (state, { payload }) => {
        state.isLoadingPreview = false;
        state.contractPreview = payload as PlayerContractSimulationPreview;
      })
      .addCase(getScenarioContractPreview.rejected, (state) => { state.isLoadingPreview = false; })
      .addCase(addScenarioContract.pending, (state) => { state.isLoadingContract = true; })
      .addCase(addScenarioContract.fulfilled, (state) => { state.isLoadingContract = false; })
      .addCase(addScenarioContract.rejected, (state) => { state.isLoadingContract = false; })
      .addCase(getScenarioTradePreview.pending, (state) => { state.isLoadingPreview = true; })
      .addCase(getScenarioTradePreview.fulfilled, (state, { payload }) => {
        state.isLoadingPreview = false;
        state.tradePreview = payload as TradeSimulation;
      })
      .addCase(getScenarioTradePreview.rejected, (state) => { state.isLoadingPreview = false; })
      .addCase(scenarioMovePlayer.pending, (state) => { state.isLoading = true; })
      .addCase(scenarioMovePlayer.fulfilled, (state) => { state.isLoading = false; })
      .addCase(scenarioMovePlayer.rejected, (state) => { state.isLoading = false; })
      .addCase(getScenarioSummary.pending, (state) => { state.isLoadingSummary = true; })
      .addCase(getScenarioSummary.fulfilled, (state, { payload }) => {
        const scenarioSummaryByTeam: ScenarioSummaryByTeam = {};

        payload.playerContracts.forEach(contract => {
          if (scenarioSummaryByTeam[contract.signingTeam.teamId.toString()]) {
            scenarioSummaryByTeam[contract.signingTeam.teamId.toString()].push(contract);
          } else {
            scenarioSummaryByTeam[contract.signingTeam.teamId.toString()] = [contract];
          }
        });

        payload.statusChanges.forEach(statusChange => {
          if (scenarioSummaryByTeam[statusChange.teamInfo.teamId.toString()]) {
            scenarioSummaryByTeam[statusChange.teamInfo.teamId.toString()].push(statusChange);
          } else {
            scenarioSummaryByTeam[statusChange.teamInfo.teamId.toString()] = [statusChange];
          }
        });

        payload.trades.forEach(trade => {
          if (scenarioSummaryByTeam[trade.teamFrom.teamId.toString()]) {
            scenarioSummaryByTeam[trade.teamFrom.teamId.toString()].push(trade);
          } else {
            scenarioSummaryByTeam[trade.teamFrom.teamId.toString()] = [trade];
          }

          if (scenarioSummaryByTeam[trade.teamTo.teamId.toString()]) {
            scenarioSummaryByTeam[trade.teamTo.teamId.toString()].push(trade);
          } else {
            scenarioSummaryByTeam[trade.teamTo.teamId.toString()] = [trade];
          }
        });

        Object.keys(scenarioSummaryByTeam).forEach(key => {
          scenarioSummaryByTeam[key].sort((a, b): number => {
            if ('signingDate' in a) {
              if ('signingDate' in b) return isBefore(new Date(a.signingDate), new Date(b.signingDate)) ? -1 : 1;
              if ('startDateUtc' in b) return isBefore(new Date(a.signingDate), new Date(b.startDateUtc)) ? -1 : 1;
              if ('createdOnUtc' in b && b.createdOnUtc) return isBefore(new Date(a.signingDate), new Date(b.createdOnUtc)) ? -1 : 1;
            }

            if ('startDateUtc' in a) {
              if ('signingDate' in b) return isBefore(new Date(a.startDateUtc), new Date(b.signingDate)) ? -1 : 1;
              if ('startDateUtc' in b) return isBefore(new Date(a.startDateUtc), new Date(b.startDateUtc)) ? -1 : 1;
              if ('createdOnUtc' in b && b.createdOnUtc) return isBefore(new Date(a.startDateUtc), new Date(b.createdOnUtc)) ? -1 : 1;
            }

            if ('createdOnUtc' in a && a.createdOnUtc) {
              if ('signingDate' in b) return isBefore(new Date(a.createdOnUtc), new Date(b.signingDate)) ? -1 : 1;
              if ('startDateUtc' in b) return isBefore(new Date(a.createdOnUtc), new Date(b.startDateUtc)) ? -1 : 1;
              if ('createdOnUtc' in b && b.createdOnUtc) return isBefore(new Date(a.createdOnUtc), new Date(b.createdOnUtc)) ? -1 : 1;
            }

            return 0;
          });
        });

        state.isLoadingSummary = false;
        state.scenarioSummary = scenarioSummaryByTeam;
      })
      .addCase(getScenarioSummary.rejected, (state) => { state.isLoadingSummary = false; })
      .addCase(setScenario, (state, { payload }) => {
        state.scenario = payload;
      })
      .addCase(resetScenarios, (state) => {
        state.scenarios = null;
        state.scenario = null;
      })
      .addCase(resetScenarioSummary, (state) => {
        state.scenarioSummary = null;
      });
  },
});

export default scenariosSlice.reducer;
