import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CacheBuster, { CacheBusterState } from 'modules/layout/cacheBuster.component';
import Header, { HEADER_HEIGHT_REM } from 'modules/layout/header.component';
import LoadingRoutes from 'modules/layout/loadingRoutes.component';
import PrivateRoutes from 'modules/layout/privateRoutes.component';
import PublicRoutes from 'modules/layout/publicRoutes.component';
import ToastMessage from 'modules/layout/toastMessage';
import { LoggedInState } from 'types/auth';
import { PRIMARY_NAV_ITEMS } from 'utilities/drawer';
import { ROUTES } from 'utilities/routes';
import { selectInitialRoute, selectShowSubNav } from 'modules/layout/layout.selectors';
import { selectLoginState } from 'modules/auth/auth.selectors';
import { setInitialRoute, setSubNavItems } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

let zendeskInterval: ReturnType<typeof setInterval>;

function MainLayout(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const loginState = useSelector(selectLoginState);
  const initialRoute = useSelector(selectInitialRoute);
  const showSubNav = useSelector(selectShowSubNav);

  useEffect(() => {
    if (loginState === LoggedInState.LOGGED_IN && initialRoute) {
      navigate(initialRoute);

      dispatch(setInitialRoute(''));
    }
  }, [dispatch, navigate, initialRoute, loginState]);

  useEffect(() => {
    zendeskInterval = setInterval(() => {
      const zendeskEl = document.querySelector('#launcher');

      if (location.pathname !== ROUTES.SIGNIN) {
        zendeskEl?.setAttribute('class', 'show-zendesk');
      } else {
        zendeskEl?.setAttribute('class', 'hide-zendesk');
      }
    }, 1000);

    return () => {
      clearInterval(zendeskInterval);
    };
  }, [location]);

  useEffect(() => {
    const curParentRoute = PRIMARY_NAV_ITEMS.find((item) => item.path === location.pathname);

    if (curParentRoute?.subItems) dispatch(setSubNavItems(curParentRoute.subItems));
  }, [dispatch, location.pathname]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: CacheBusterState) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <Box display="flex" height="100vh">
              {loginState === LoggedInState.LOGGED_OUT && <PublicRoutes />}
              {loginState === LoggedInState.LOGGED_IN && (
                <>
                  <Header />
                  <Box
                    display="flex"
                    flex="1"
                    marginTop={showSubNav ? `${HEADER_HEIGHT_REM * 2}rem` : `${HEADER_HEIGHT_REM}rem`}
                    maxWidth="100vw"
                    width="100%"
                  >
                    <PrivateRoutes />
                  </Box>
                </>
              )}
              {loginState === LoggedInState.LOADING && <LoadingRoutes />}
            </Box>
            <ToastMessage />
          </>
        );
      }}
    </CacheBuster>
  );
}

export default MainLayout;
