import makeStyles from '@mui/styles/makeStyles';
import { Box, FormControlLabel, ListSubheader, MenuItem, Switch, Typography, styled, useTheme } from '@mui/material';
import {
  Container,
  Item,
  PageActionItem,
  StanleyHoverSelect,
  StanleyPageActions,
} from '@project-stanley/cap-management-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MutableRefObject, ReactInstance, useCallback, useMemo } from 'react';
import { deleteField } from 'firebase/firestore';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Colors from 'styles/colors';
import StanleyNavTabs from 'components/stanleyNavTabs.component';
import useShareMenuItem from 'hooks/useShareMenuItem';
import useSubRoute from 'hooks/useSubRoute';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { PagePrintAction } from 'utilities/pageActions';
import { ROSTER_CONTRACT_FILTER_OPTIONS, ROSTER_FILTER_OPTIONS, ROSTER_PLAYER_FILTER_OPTIONS } from 'utilities/roster';
import { SavedDepthChart, UpdateSavedDepthChart } from 'types/user';
import { TEAM_SUB_ROUTES } from 'utilities/routes';
import { ToastTypes } from 'types/layout';
import { selectDepthCharts, selectPreferences } from 'modules/user/user.selectors';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import { selectTeam, selectTeamContractYears } from 'modules/teams/teams.selectors';
import { setPreferences } from 'modules/user/user.slice';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

const StyledResetIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  width: 1.5rem;
`;

interface RosterViewHeaderProps {
  activeFilter: string;
  contractYear?: number | null;
  contractYears?: number[];
  rosterRef: MutableRefObject<ReactInstance | null>;
  selectedTabIndex?: number;
  showCapitalOutlook?: boolean;
  showDepthChart: boolean;
  showDraftPicks?: boolean;
  showTerms: boolean;
  tabs?: { label: string }[];
  onAfterPrint: () => void;
  onChangeContractYear?: (contractYear: number) => void;
  onChangeRosterStatFilter: (filterKey: string) => void;
  onCopyToClipboard: () => void;
  onDownloadCSV?: () => void;
  onToggleTerms: () => void;
  onViewChange?: (tabIndex: number) => void;
}

function RosterViewHeader({
  activeFilter,
  contractYear,
  contractYears,
  rosterRef,
  selectedTabIndex,
  showCapitalOutlook,
  showDepthChart,
  showDraftPicks = false,
  showTerms,
  tabs,
  onAfterPrint,
  onChangeContractYear,
  onChangeRosterStatFilter,
  onCopyToClipboard,
  onDownloadCSV,
  onToggleTerms,
  onViewChange,
}: RosterViewHeaderProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { teamId } = useParams();
  const subRoute = useSubRoute(3);
  const theme = useTheme();
  const width = useWidth();

  const isPrinting = useSelector(selectIsPrinting);
  const teamContractYears = useSelector(selectTeamContractYears);
  const team = useSelector(selectTeam);
  const depthCharts = useSelector(selectDepthCharts);
  const preferences = useSelector(selectPreferences);

  const handleCopyToClipBoard = useCallback(() => {
    onCopyToClipboard();

    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAMS,
        action: GA_ACTIONS.SHARE_TEAM,
        label: team.name,
        value: `${team.teamId}${subRoute}`,
      });
    }

    dispatch(
      showToast({
        toastMessage: 'Copied Successfully to Clipboard',
        toastType: ToastTypes.SUCCESS,
      }),
    );
  }, [dispatch, onCopyToClipboard, team, subRoute]);

  const shareMenuItem = useShareMenuItem({ onClick: handleCopyToClipBoard });

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS, [width]);

  const renderedRosterFilters = useMemo(() => {
    const filtersToRender = [];

    if (showDepthChart) {
      filtersToRender.push(
        <ListSubheader key="contract-filter-sub" className={classes.subheader}>
          Contract Information
        </ListSubheader>,
      );
    }

    filtersToRender.push(
      ...ROSTER_CONTRACT_FILTER_OPTIONS.map(({ label, value }) => (
        <MenuItem
          className={classes.menuItem}
          key={value}
          value={value}
          onClick={() => onChangeRosterStatFilter(value)}
        >
          {label}
        </MenuItem>
      )),
    );

    if (showDepthChart) {
      filtersToRender.push(
        <ListSubheader key="filter-player-sub" className={classes.subheader}>
          Player Information
        </ListSubheader>,
        ...ROSTER_PLAYER_FILTER_OPTIONS.map(({ label, value }) => (
          <MenuItem
            className={classes.menuItem}
            key={value}
            value={value}
            onClick={() => onChangeRosterStatFilter(value)}
          >
            {label}
          </MenuItem>
        )),
      );
    }

    return filtersToRender;
  }, [classes, showDepthChart, onChangeRosterStatFilter]);

  const hasNavTabs = useMemo(
    () => tabs && selectedTabIndex !== undefined && onViewChange,
    [selectedTabIndex, tabs, onViewChange],
  );

  const contractYearOptions = useMemo(() => {
    if (teamContractYears?.length) {
      return teamContractYears.map((year) => ({ label: `${year} - ${year + 1}`, value: year }));
    }
    if (contractYears?.length) {
      return contractYears.map((year) => ({ label: `${year} - ${year + 1}`, value: year }));
    }

    return [];
  }, [contractYears, teamContractYears]);

  const renderFilters = useCallback(
    () => (
      <>
        {contractYear && onChangeContractYear && showDepthChart && (
          <Box marginRight={theme.spacing(2)}>
            <StanleyHoverSelect
              options={contractYearOptions}
              selectClasses={{ select: classes.selectYear }}
              value={contractYear}
              onChange={(value) => onChangeContractYear(value as number)}
            />
          </Box>
        )}
        {(showDepthChart || showCapitalOutlook) && (
          <StanleyHoverSelect
            options={ROSTER_FILTER_OPTIONS}
            selectClasses={{ select: classes.select }}
            value={activeFilter}
          >
            {renderedRosterFilters}
          </StanleyHoverSelect>
        )}
      </>
    ),
    [
      classes,
      theme,
      activeFilter,
      contractYear,
      contractYearOptions,
      renderedRosterFilters,
      showCapitalOutlook,
      showDepthChart,
      onChangeContractYear,
    ],
  );

  const renderedViewSelection = useMemo(() => {
    if (!tabs || selectedTabIndex === undefined || !onViewChange) return null;

    if (isSmallScreen) {
      return (
        <StanleyHoverSelect
          options={tabs?.map((tab, index) => ({ ...tab, value: index }))}
          selectClasses={{ select: classes.select }}
          value={selectedTabIndex}
          onChange={(tabIndex) => onViewChange(tabIndex as number)}
        />
      );
    }

    return (
      <Box>
        <StanleyNavTabs
          selectedTabIndex={selectedTabIndex}
          tabs={tabs}
          onTabChange={(tabIndex: number) => onViewChange(tabIndex)}
        />
      </Box>
    );
  }, [classes, isSmallScreen, selectedTabIndex, tabs, onViewChange]);

  const handleResetLineUp = useCallback(async () => {
    if (teamId) {
      const updatedSavedLineUps: UpdateSavedDepthChart = { ...depthCharts };
      updatedSavedLineUps[teamId] = deleteField();

      if (team) {
        logGAEvent({
          category: GA_CATEGORIES.TEAMS,
          action: GA_ACTIONS.RESET_LINEUP,
          label: team.name,
          value: team.teamId,
        });
      }

      await writeFirestoreDataByUid({
        path: 'preferences',
        data: {
          [FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART]: updatedSavedLineUps,
        },
      });

      delete updatedSavedLineUps[teamId];
      dispatch(
        setPreferences({
          ...preferences,
          [FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART]: updatedSavedLineUps as SavedDepthChart,
        }),
      );
    }
  }, [dispatch, depthCharts, preferences, teamId, team]);

  const pageMenuItems: PageActionItem[] = useMemo(() => {
    const items: PageActionItem[] = [shareMenuItem, PagePrintAction];

    switch (subRoute) {
      case TEAM_SUB_ROUTES.TEAM_CAPITAL_OUTLOOK:
        items.push({
          text: (
            <>
              <FontAwesomeIcon
                style={{ width: '1.5rem' }}
                color={Colors.BRAND_BLACK}
                icon={solid('file-csv')}
                size="lg"
              />
              <Typography sx={{ marginLeft: 2 }} variant="body1">
                CSV
              </Typography>
            </>
          ),
          onClick: onDownloadCSV,
        });
        break;

      case '/':
        if (teamId && depthCharts && depthCharts[teamId]) {
          items.push({
            text: (
              <>
                <StyledResetIcon color={Colors.ERROR} icon={solid('close')} />
                <Typography sx={{ marginLeft: 2 }} variant="body1">
                  Reset
                </Typography>
              </>
            ),
            onClick: handleResetLineUp,
          });
        }
        break;

      default:
        break;
    }

    return items;
  }, [depthCharts, shareMenuItem, subRoute, teamId, handleResetLineUp, onDownloadCSV]);

  return (
    <Container spacing={2}>
      <Item container xs={showDraftPicks ? 6 : 12} md={6}>
        {!isPrinting && renderedViewSelection}
        {!hasNavTabs && renderFilters()}
      </Item>
      <Item
        container
        xs={showDraftPicks ? 6 : 12}
        md={6}
        alignItems="center"
        justifyContent={!isSmallScreen ? 'flex-end' : 'flex-start'}
      >
        <StanleyPageActions
          printRef={rosterRef}
          actions={pageMenuItems}
          className={classes.printButton}
          tooltipTitle="Actions"
          onAfterPrint={onAfterPrint}
        />
        {showCapitalOutlook && (
          <FormControlLabel
            className={classes.switchControl}
            control={<Switch color="primary" checked={showTerms} onChange={onToggleTerms} />}
            label="Terms"
            labelPlacement="start"
          />
        )}
        {hasNavTabs && renderFilters()}
      </Item>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  activeFilter: {
    opacity: 1,
  },
  switchControl: {
    margin: 'unset',
  },
  select: {
    paddingBottom: `${theme.spacing(0.75)} !important`,
    paddingTop: `${theme.spacing(0.75)} !important`,
    width: '10rem',
  },
  selectYear: {
    paddingBottom: `${theme.spacing(0.75)} !important`,
    paddingTop: `${theme.spacing(0.75)} !important`,
    width: '5.5rem',
  },
  menuItem: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  subheader: {
    alignItems: 'center',
    color: theme.palette.common.black,
    display: 'flex',
    fontSize: '1rem',
    height: '2rem',
  },
  printButton: {
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1),
  },
}));

export default RosterViewHeader;
