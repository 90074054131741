/* eslint-disable max-len */
import { Box, Divider, Typography, styled, useTheme } from '@mui/material';
import { DropResult } from 'react-beautiful-dnd';
import { FirestoreError } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';
import { useSelector } from 'react-redux';

import RosterDnDDepthChart from 'modules/teams/rosterView/rosterDnDDepthChart.component';
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { SavedDepthChart } from 'types/user';
import {
  filterRosterByContractYear,
  mapInactiveRosterByPosition,
  mapRosterByPosition,
  mapRosterByPositionWithIds,
  moveRosterPlayer,
  sortMappedInactiveRosterByPosition,
  sortMappedRosterByPosition,
  sortRosterByPositionByLocalStorage,
} from 'utilities/roster';
import { selectBlockNavigation } from 'modules/layout/layout.selectors';
import { selectDepthCharts } from 'modules/user/user.selectors';
import { selectIsScenarioMode } from 'modules/scenarios/scenarios.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import {
  selectTeam,
  selectTeamActiveRosterOutlook,
  selectTeamActiveRosterOutlookByPosition,
  selectTeamContractYear,
  selectTeamInactiveRosterOutlook,
  selectTeamInactiveRosterOutlookByPosition,
  selectTeamRosterStatFilter,
} from 'modules/teams/teams.selectors';
import { setBlockNavigation } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';
import { writeFirestoreDataByUid } from 'utilities/firebase';

const StyledTypographyDivider = styled(Typography)`
  font-weight: unset;
`;

function TeamDepthChart() {
  const dispatch = useAppDispatch();
  const { teamId } = useParams() as { teamId: string };
  const theme = useTheme();

  const localDepthCharts = useReadLocalStorage(FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART) as SavedDepthChart;

  const activeRosterOutlookByPosition = useSelector(selectTeamActiveRosterOutlookByPosition);
  const activeRosterOutlook = useSelector(selectTeamActiveRosterOutlook);
  const inactiveRosterOutlookByPosition = useSelector(selectTeamInactiveRosterOutlookByPosition);
  const inactiveRosterOutlook = useSelector(selectTeamInactiveRosterOutlook);
  const contractYear = useSelector(selectTeamContractYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const currentSeasonStartYear = useSelector(selectSeasonStartYear);
  const blockNavigation = useSelector(selectBlockNavigation);
  const isScenarioMode = useSelector(selectIsScenarioMode);
  const team = useSelector(selectTeam);
  const firebaseDepthCharts = useSelector(selectDepthCharts);

  const [activeRosterByPosition, setActiveRosterByPosition] = useState(activeRosterOutlookByPosition);
  const [inactiveRosterByPosition, setInactiveRosterByPosition] = useState(inactiveRosterOutlookByPosition);
  const [depthCharts, setDepthCharts] = useState<SavedDepthChart>(localDepthCharts || firebaseDepthCharts || {});

  useEffect(() => {
    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAMS,
        action: GA_ACTIONS.TEAM_PROFILE_VIEW_DEPTH_CHART,
        label: team?.name,
        value: team?.teamId,
      });
    }
  }, [team]);

  useEffect(() => {
    if (localDepthCharts && !firebaseDepthCharts) {
      (async () => {
        try {
          await writeFirestoreDataByUid({
            path: 'preferences',
            data: {
              [FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART]: localDepthCharts,
            },
          });

          localStorage.removeItem(FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART);
        } catch (error) {
          console.log('FIRESTORE ERROR: ', error as FirestoreError);
        }
      })();
    }
  }, [localDepthCharts, firebaseDepthCharts]);

  useEffect(() => {
    if (firebaseDepthCharts) setDepthCharts(firebaseDepthCharts);
  }, [firebaseDepthCharts]);

  useEffect(() => {
    if (!activeRosterOutlook || !inactiveRosterOutlook) return;

    const contractYearActiveRoster = filterRosterByContractYear(
      activeRosterOutlook || [],
      contractYear || currentSeasonStartYear,
    );
    const contractYearInactiveRoster = filterRosterByContractYear(
      inactiveRosterOutlook || [],
      contractYear || currentSeasonStartYear,
    );

    if (depthCharts[teamId] && !isScenarioMode) {
      const localRosterByPosition = depthCharts[teamId];

      if (localRosterByPosition.active) {
        setActiveRosterByPosition(
          sortRosterByPositionByLocalStorage(localRosterByPosition.active, contractYearActiveRoster),
        );
      }
      if (localRosterByPosition.inactive) {
        setInactiveRosterByPosition(
          sortRosterByPositionByLocalStorage(localRosterByPosition.inactive, contractYearInactiveRoster),
        );
      }
      return;
    }

    const activeByPosition = sortMappedRosterByPosition(
      mapRosterByPosition(contractYearActiveRoster),
      rosterStatFilter,
    );
    const inactiveByPosition = sortMappedInactiveRosterByPosition(
      mapInactiveRosterByPosition(contractYearInactiveRoster),
      rosterStatFilter,
    );

    setActiveRosterByPosition(activeByPosition);
    setInactiveRosterByPosition(inactiveByPosition);
  }, [
    activeRosterOutlook,
    contractYear,
    currentSeasonStartYear,
    inactiveRosterOutlook,
    isScenarioMode,
    depthCharts,
    rosterStatFilter,
    teamId,
    setDepthCharts,
  ]);

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult, isActiveRoster?: boolean) => {
      if (
        !destination ||
        (destination.droppableId === source.droppableId && source.index === destination.index) ||
        (isActiveRoster && !activeRosterByPosition) ||
        (!isActiveRoster && !inactiveRosterByPosition)
      ) {
        return;
      }

      const rosterToUpdate = isActiveRoster ? activeRosterByPosition : inactiveRosterByPosition;

      if (rosterToUpdate) {
        const updatedRosterByPosition = moveRosterPlayer(rosterToUpdate, {
          destinationIndex: destination.index,
          destinationPosition: destination.droppableId,
          sourceIndex: source.index,
          sourcePosition: source.droppableId,
        });

        if (isActiveRoster && activeRosterByPosition) {
          setActiveRosterByPosition(updatedRosterByPosition);
        } else if (inactiveRosterByPosition) {
          setInactiveRosterByPosition(updatedRosterByPosition);
        }

        if (!isScenarioMode) {
          const teamToUpdate = isActiveRoster
            ? {
                [teamId]: {
                  active: mapRosterByPositionWithIds(updatedRosterByPosition),
                  inactive: inactiveRosterByPosition ? mapRosterByPositionWithIds(inactiveRosterByPosition) : [],
                },
              }
            : {
                [teamId]: {
                  active: activeRosterByPosition ? mapRosterByPositionWithIds(activeRosterByPosition) : [],
                  inactive: mapRosterByPositionWithIds(updatedRosterByPosition),
                },
              };

          dispatch(
            setBlockNavigation({
              ...blockNavigation,
              blockContent:
                'Warning: Saving these changes will prevent getting daily roster updates for this team. Select "Reset" in the actions button to reset default.',
              shouldBlockNavigation: true,
              actions: [
                ...blockNavigation.actions,
                {
                  storageKey: FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART,
                  data: {
                    ...depthCharts,
                    ...teamToUpdate,
                  },
                },
              ],
            }),
          );
        }
      }

      logGAEvent({
        category: GA_CATEGORIES.TEAM_PROFILE,
        action: GA_ACTIONS.TEAM_PROFILE_DEPTH_CHART_CHANGE,
        label: team?.name,
      });
    },
    [
      dispatch,
      activeRosterByPosition,
      blockNavigation,
      inactiveRosterByPosition,
      isScenarioMode,
      depthCharts,
      teamId,
      team,
    ],
  );

  return (
    <>
      <RosterDnDDepthChart isActiveRoster roster={activeRosterByPosition} onDragEnd={handleDragEnd} />
      <Box marginTop={theme.spacing(3)}>
        <Divider>
          <StyledTypographyDivider variant="h6">Inactive Roster</StyledTypographyDivider>
        </Divider>
        <RosterDnDDepthChart roster={inactiveRosterByPosition} onDragEnd={handleDragEnd} />
      </Box>
    </>
  );
}

export default TeamDepthChart;
