import classNames from 'classnames';
import ordinal from 'ordinal';
import { Box, Checkbox, IconButton, Popover, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { CalendarPicker } from '@mui/lab';
import {
  Container,
  Item,
  StanleyHoverSelect,
  StanleyPrimaryButton,
  StanleySecondaryButton,
  StanleySelect,
} from '@project-stanley/cap-management-components';
import { Delete, InsertInvitation } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StanleyPlayerSearch, { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import useStatsSeasonsOptions from 'hooks/advancedSearch/useStatsSeasonsOptions';
import { ROUTES } from 'utilities/routes';
import { TEAMS_DRAFT_SELECTION_OPTIONS, generateTradeStatsOptions } from 'utilities/teams';
import { TeamsTradeFilters } from 'types/teams';
import { ToastTypes } from 'types/layout';
import { getTrades } from 'modules/teams/teams.slice';
import { selectSeasonStartDay, selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTradesIsLoading } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

interface TeamParamTypes {
  teamId: string;
  [key: string]: string;
}

interface TradesHeaderProps {
  initialTradeSearch?: TeamsTradeFilters;
  numOfTrades?: number;
  numOfTradesToRemove?: number;
  selectedSeason: string;
  onRemoveTrades?: () => void;
  onSearchOptionSelected?: (optionSelected?: StanleyPlayerSearchOption | null) => void;
  onSeasonChange: (e: SelectChangeEvent<unknown>) => void;
  onSelectAllTrades?: () => void;
}

function TradesHeader({
  initialTradeSearch,
  numOfTrades,
  numOfTradesToRemove,
  selectedSeason,
  onRemoveTrades,
  onSearchOptionSelected,
  onSeasonChange,
  onSelectAllTrades,
}: TradesHeaderProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { teamId } = useParams<TeamParamTypes>();

  const seasonsOptions = useStatsSeasonsOptions();

  const seasonStartDate = useSelector(selectSeasonStartDay);
  const isLoading = useSelector(selectTradesIsLoading);
  const seasonStartYear = useSelector(selectSeasonStartYear);

  const [draftRounds, setDraftRounds] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(seasonStartDate ? new Date(seasonStartDate) : new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [playerSearched, setPlayerSearched] = useState<string | null>(null);

  const isPlayerPage = useMemo(() => location.pathname.includes(ROUTES.PLAYERS), [location]);

  useEffect(() => {
    if (initialTradeSearch?.startDate) setStartDate(new Date(initialTradeSearch.startDate));
    if (initialTradeSearch?.endDate) setEndDate(new Date(initialTradeSearch.endDate));
  }, [initialTradeSearch]);

  const handleSearchTrades = useCallback(
    async ({ playerIds, rounds }: { playerIds?: number[] | null; rounds?: number[] }) => {
      const payload: TeamsTradeFilters = {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      };

      if (playerIds) payload.playerIds = playerIds;
      if (playerIds === undefined && playerSearched) payload.playerIds = [Number(playerSearched)];
      if (rounds?.length) payload.draftRounds = rounds;
      if (!rounds && draftRounds.length) payload.draftRounds = draftRounds;
      if (location.pathname === `${ROUTES.TEAMS}/${teamId as string}`) payload.teamIds = [Number(teamId)];

      const response = await dispatch(getTrades(payload));

      if (response.type === getTrades.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'Unable to find trades. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
      }
    },
    [dispatch, location, draftRounds, endDate, playerSearched, startDate, teamId],
  );

  const handleCalendarClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    [setAnchorEl],
  );

  const handleSearchOptionSelected = useCallback(
    (optionSelected?: StanleyPlayerSearchOption | null) => {
      if (onSearchOptionSelected) {
        onSearchOptionSelected(optionSelected);

        return;
      }

      setPlayerSearched(optionSelected ? optionSelected.value : null);

      handleSearchTrades({ playerIds: optionSelected ? [Number(optionSelected.value)] : null });
    },
    [handleSearchTrades, onSearchOptionSelected],
  );

  const handleChangeTradeDate = useCallback(
    (field: string) => (date: Date | null) => {
      switch (field) {
        case 'startDate':
          setStartDate(date);
          break;

        default:
          setEndDate(date);
      }
    },
    [setEndDate, setStartDate],
  );

  const handleSearchDates = useCallback(() => {
    setAnchorEl(null);
    handleSearchTrades({});
  }, [handleSearchTrades, setAnchorEl]);

  const handleClearDates = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
  }, [setEndDate, setStartDate]);

  const handleDraftRoundsChange = useCallback(
    (newDataFields) => {
      if (draftRounds !== newDataFields) {
        setDraftRounds(newDataFields as number[]);

        handleSearchTrades({ rounds: newDataFields as number[] });
      }
    },
    [draftRounds, handleSearchTrades],
  );

  const renderedSeasonFilter = useMemo(
    () => (
      <StanleySelect
        disabled={isLoading}
        displayEmpty
        emptyText="Career Stats"
        options={seasonStartYear ? generateTradeStatsOptions(seasonStartYear) : seasonsOptions}
        selectClasses={{
          select: classes.select,
        }}
        renderValue={(selected: unknown) => {
          if (!selected) return 'Career Stats';

          const seasonOption = seasonsOptions.find((option) => option.value === selected);
          if (seasonOption) return seasonOption.label;

          return selected as string;
        }}
        value={selectedSeason}
        onChange={onSeasonChange}
      />
    ),
    [classes, isLoading, seasonsOptions, seasonStartYear, selectedSeason, onSeasonChange],
  );

  const renderedPlayerPageActions = useMemo(
    () => (
      <>
        <Tooltip title={`Remove Trades (${numOfTradesToRemove || 0})`}>
          <IconButton
            className={classes.iconButton}
            color="primary"
            disabled={!numOfTradesToRemove}
            onClick={onRemoveTrades}
            size="small"
          >
            <Delete />
          </IconButton>
        </Tooltip>
        {onSelectAllTrades && (
          <Tooltip
            title={
              numOfTrades === numOfTradesToRemove
                ? `Desselect All Trades (${numOfTrades || 0})`
                : `Select All Trades (${numOfTrades || 0})`
            }
          >
            <Checkbox checked={numOfTradesToRemove === numOfTrades && numOfTrades !== 0} onChange={onSelectAllTrades} />
          </Tooltip>
        )}
      </>
    ),
    [classes, numOfTrades, numOfTradesToRemove, onRemoveTrades, onSelectAllTrades],
  );

  return (
    <>
      <Container className={classes.container} spacing={1}>
        <Item xs={12} sm={6} className={classes.leftActions}>
          <Box marginRight={2}>
            <StanleyPlayerSearch
              clearOnSelect={false}
              disabled={isLoading}
              TextFieldInputProps={{
                sx: {
                  width: '14rem',
                },
              }}
              onOptionSelected={handleSearchOptionSelected}
            />
          </Box>
          {isPlayerPage && <Box>{renderedSeasonFilter}</Box>}
          {!isPlayerPage && (
            <IconButton size="small" onClick={handleCalendarClick}>
              <InsertInvitation color="primary" />
            </IconButton>
          )}
        </Item>
        <Item xs={12} sm={6} className={classes.rightActions}>
          {isPlayerPage && renderedPlayerPageActions}
          {!isPlayerPage && (
            <>
              <Box marginRight={2}>{renderedSeasonFilter}</Box>
              <StanleyHoverSelect
                changeOnClose
                emptyDisabled
                options={TEAMS_DRAFT_SELECTION_OPTIONS}
                selectClasses={{
                  select: classNames(classes.select, classes.selectDraft),
                }}
                selectProps={{
                  disabled: isLoading,
                  displayEmpty: true,
                  multiple: true,
                  renderValue: (selected) => {
                    if ((selected as number[]).length === 0) return 'Draft Selections';

                    return (selected as number[]).map((round) => ordinal(round)).join(', ');
                  },
                }}
                value={draftRounds}
                onChange={handleDraftRoundsChange}
              />
            </>
          )}
        </Item>
      </Container>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Container>
          <Item xs={12} sm={6}>
            <Typography sx={{ px: 2, pt: 2 }} variant="subtitle2">
              Start Date
            </Typography>
            <CalendarPicker
              date={startDate}
              minDate={new Date('01/01/2018')}
              maxDate={endDate || undefined}
              onChange={handleChangeTradeDate('startDate')}
            />
          </Item>
          <Item xs={12} sm={6}>
            <Typography sx={{ px: 2, pt: 2 }} variant="subtitle2">
              End Date
            </Typography>
            <CalendarPicker
              date={endDate}
              minDate={startDate || undefined}
              maxDate={new Date()}
              onChange={handleChangeTradeDate('endDate')}
            />
          </Item>
        </Container>
        <Box display="flex" justifyContent="space-between" paddingX={2} paddingBottom={2}>
          <StanleySecondaryButton onClick={handleClearDates}>Clear Dates</StanleySecondaryButton>
          <StanleyPrimaryButton onClick={handleSearchDates}>Search</StanleyPrimaryButton>
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '8rem',
  },
  selectDraft: {
    paddingBottom: `${theme.spacing(0.875)} !important`,
    paddingTop: `${theme.spacing(0.875)} !important`,
  },
  leftActions: {
    display: 'flex',
  },
  rightActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    marginRight: theme.spacing(0.5),
  },
}));

export default TradesHeader;
