import { Container, Item, StanleySelectOption } from '@project-stanley/cap-management-components';

import PlayerDataFieldsGroup from 'components/playerDataFields/playerDataFieldsGroup.component';
import {
  CONTRACT_DATA_FIELD_OPTIONS,
  CONTRACT_YEAR_YEAR_OPTIONS,
  PLAYER_DATA_FIELD_OPTIONS,
  PLAYER_TOTAL_CONTRACT_FIELD_OPTIONS,
} from 'constants/playerDataFields';

interface PlayerContractDataFieldsProps {
  dataFields: (number | string)[];

  onChange: (value: string | number) => void;
  onSelectAll: (values: StanleySelectOption[], removeAll?: boolean) => void;
}

function PlayerContractDataFields({ dataFields, onChange, onSelectAll }: PlayerContractDataFieldsProps) {
  return (
    <Container>
      <Item>
        <PlayerDataFieldsGroup
          dataFields={dataFields}
          options={CONTRACT_DATA_FIELD_OPTIONS}
          title="Contract Information"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <PlayerDataFieldsGroup
          dataFields={dataFields}
          options={PLAYER_DATA_FIELD_OPTIONS}
          title="Player Information"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <PlayerDataFieldsGroup
          dataFields={dataFields}
          options={PLAYER_TOTAL_CONTRACT_FIELD_OPTIONS}
          title="Total Contract"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
      <Item>
        <PlayerDataFieldsGroup
          dataFields={dataFields}
          options={CONTRACT_YEAR_YEAR_OPTIONS}
          title="Contract Year (CY)"
          onChange={onChange}
          onSelectAll={onSelectAll}
        />
      </Item>
    </Container>
  );
}

export default PlayerContractDataFields;
