/* eslint-disable max-len */
import { Player, PlayerInfo, PlayerPosition, PlayerShotHand } from 'types/player';

interface PlayerPositions {
  [key: string]: string;
}

/* eslint-disable import/prefer-default-export */
export const PLAYER_POSITIONS: PlayerPositions = Object.freeze({
  LW: 'LW',
  RW: 'RW',
  C: 'C',
  LD: 'LD',
  RD: 'RD',
  G: 'G',
  S: 'S',
  IR: 'IR',
  LTIR: 'LTIR',
});

export const PLAYER_POSITION_ABBR_MAP: PlayerPositions = Object.freeze({
  1: 'LW',
  2: 'RW',
  3: 'C',
  4: 'LD',
  5: 'RD',
  6: 'G',
  7: 'D',
});

export const PLAYER_POSITION_MAP: PlayerPositions = Object.freeze({
  1: 'Left Wing',
  2: 'Right Wing',
  3: 'Center',
  4: 'Left Defense',
  5: 'Right Defense',
  6: 'Goalie',
  7: 'Defense',
});

export const PLAYER_FORWARDS: number[] = [PlayerPosition.LeftWing, PlayerPosition.RightWing, PlayerPosition.Center];

export const PLAYER_SHOT_HAND_MAP: { [key: string]: string } = Object.freeze({
  [PlayerShotHand.Left]: 'Left',
  [PlayerShotHand.Right]: 'Right',
});

export const convertToFeetInches = (heightCM: number): string => {
  const realFeet = (heightCM * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);

  if (inches === 12) return `${feet + 1}'`;

  return `${feet}' ${inches}"`;
};

export const convertToLBS = (weightKG: number): string => `${Math.round(weightKG * 2.2046)}lbs`;

export const getPositionAbbrList = (primaryPosition: PlayerPosition, secondaryPositions: number[] | null): string => {
  const positions: string[] = [PLAYER_POSITION_ABBR_MAP[primaryPosition]];

  if (secondaryPositions) {
    secondaryPositions.forEach((position) => {
      positions.push(PLAYER_POSITION_ABBR_MAP[position]);
    });
  }

  return positions.join(', ');
};

export const getPlayerFullname = (player: Player): string => `${player.firstName} ${player.lastName}`;
export const getPlayerReverseFullname = (player: Player): string => `${player.lastName},${player.firstName}`;

const PLAYER_CONTRACTS = 0;
const REGULAR_SEASON_PLAYER_STATS = 3;
const PLAYOFFS_PLAYER_STATS = 4;
const CONTRACT_COMPARABLES = 1;
const TRADE_COMPARABLES = 2;

export const PLAYER_VIEW_OPTION_VALUES = {
  PLAYER_CONTRACTS,
  REGULAR_SEASON_PLAYER_STATS,
  PLAYOFFS_PLAYER_STATS,
  CONTRACT_COMPARABLES,
  TRADE_COMPARABLES,
};

export const PLAYER_VIEW_OPTIONS = [
  { label: 'Contract History', value: PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS },
  { label: 'Contract Comparables', value: PLAYER_VIEW_OPTION_VALUES.CONTRACT_COMPARABLES },
  { label: 'Trade Comparables', value: PLAYER_VIEW_OPTION_VALUES.TRADE_COMPARABLES },
  { label: 'Regular Season Stats', value: PLAYER_VIEW_OPTION_VALUES.REGULAR_SEASON_PLAYER_STATS },
  { label: 'Playoff Stats', value: PLAYER_VIEW_OPTION_VALUES.PLAYOFFS_PLAYER_STATS },
];

export const getFullName = (playerInfo: PlayerInfo) => `${playerInfo.lastName}, ${playerInfo.firstName}`;
