/* eslint-disable max-len */
import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Report } from 'types/research';

interface ResearchState {
  isLoading: boolean;
  reports: Report[] | null;
}

const initialState: ResearchState = {
  isLoading: false,
  reports: null,
};

export const getAllReports = createAsyncThunk('research/getResearch', async () => {
  const response = (await api('reports/all', { method: 'GET' }, true)) as Report[];

  return response;
});

export const getReport = createAsyncThunk('research/getResearchItem', async (reportId: string) => {
  const response = (await api(`reports/${reportId}`, { method: 'GET' }, true)) as Report;

  return response;
});

export const getPlayerReport = createAsyncThunk('research/getResearchPlayer', async (reportId: string) => {
  const response = (await api(`reports/players/${reportId}`, { method: 'GET' }, true)) as Report;

  return response;
});

export const getLeagueReport = createAsyncThunk('research/getResearchLeague', async (reportId: string) => {
  const response = (await api(`reports/league/${reportId}`, { method: 'GET' }, true)) as Report;

  return response;
});

export const researchSlice = createSlice({
  name: 'research',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReports.pending, (state) => {
        state.isLoading = true;
        state.reports = null;
      })
      .addCase(getAllReports.fulfilled, (state, action: { payload: Report[] }) => {
        state.isLoading = false;
        state.reports = action.payload.map((report) => ({ ...report, reportId: report.id }));
      })
      .addCase(getAllReports.rejected, (state) => {
        state.isLoading = false;
        state.reports = null;
      });
  },
});

export default researchSlice.reducer;
