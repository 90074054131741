/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DocumentSnapshot, FirestoreError } from 'firebase/firestore';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { StanleyConfirmDialog } from '@project-stanley/cap-management-components';
import { Transition } from 'history';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import usePrevious from 'hooks/usePrevious';
import { SavedCompSet, SavedDepthChart } from 'types/localStorage';
import { ToastTypes } from 'types/layout';
import { UserPreferences } from 'types/user';
import { logBlockNavigationEvent } from 'utilities/analytics';
import { readFirestoreData, writeFirestoreDataByUid } from 'utilities/firebase';
import { selectBlockNavigation } from 'modules/layout/layout.selectors';
import { selectPlayer } from 'modules/players/players.selectors';
import { selectTeam } from 'modules/teams/teams.selectors';
import { setBlockNavigation, showToast } from 'modules/layout/layout.slice';
import { setPreferences } from 'modules/user/user.slice';
import { useAppDispatch } from 'store';
import { usePrompt } from 'hooks/useBlocker';

interface BlockNavigationProps {
  children: ReactNode;
}

function BlockNavigation({ children }: BlockNavigationProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { blockContent, shouldBlockNavigation, ...restBlock } = useSelector(selectBlockNavigation);
  const team = useSelector(selectTeam);
  const player = useSelector(selectPlayer);

  const [nextLocation, setNextLocation] = useState<Transition['location'] | null>(null);

  const prevShouldBlockNavigation = usePrevious(shouldBlockNavigation) as boolean;

  useEffect(() => {
    if (prevShouldBlockNavigation && !shouldBlockNavigation) {
      setNextLocation(null);

      if (nextLocation) navigate(nextLocation.pathname);

      dispatch(
        setBlockNavigation({
          shouldBlockNavigation: false,
          actions: [],
        }),
      );
    }
  }, [dispatch, navigate, nextLocation, prevShouldBlockNavigation, restBlock, shouldBlockNavigation]);

  const handleBlockedNavigation = useCallback(
    (location: Transition['location'], action: Transition['action']) => {
      if (shouldBlockNavigation) setNextLocation(location);

      return false;
    },
    [shouldBlockNavigation],
  );

  usePrompt(handleBlockedNavigation, shouldBlockNavigation);

  const resetBlockNavigation = useCallback(() => {
    dispatch(
      setBlockNavigation({
        blockContent,
        shouldBlockNavigation: false,
        actions: [],
      }),
    );
  }, [dispatch, blockContent]);

  const saveAndNavigate = useCallback(async () => {
    if (restBlock.actions.length) {
      const actionKeys: string[] = [];

      const preferences = restBlock.actions.reduce(
        (acc, cur) => ({
          ...acc,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [cur.storageKey]: cur.data,
        }),
        {} as { [key: string | number]: SavedCompSet | SavedDepthChart },
      );

      await writeFirestoreDataByUid({ path: 'preferences', data: preferences });

      logBlockNavigationEvent({
        actions: actionKeys,
        player,
        team,
      });

      dispatch(
        showToast({
          toastMessage: 'Saved Successfully',
          toastType: ToastTypes.SUCCESS,
        }),
      );

      try {
        const preferences = await readFirestoreData({ path: 'preferences' });

        if ((preferences as DocumentSnapshot)?.exists()) {
          dispatch(setPreferences((preferences as DocumentSnapshot).data() as UserPreferences));
        }
      } catch (error) {
        console.log('READING FIRESTORE ERROR: ', error as FirestoreError);
      }
    }

    resetBlockNavigation();
  }, [dispatch, player, restBlock, team, resetBlockNavigation]);

  return (
    <>
      {children}
      <StanleyConfirmDialog
        cancelText="Discard"
        confirmText="Save"
        content={
          blockContent ? (
            <Typography
              sx={{
                color: '#969494',
                marginTop: '1.5rem',
              }}
            >
              {blockContent}
            </Typography>
          ) : undefined
        }
        contentText="Would you like to save your changes?"
        open={Boolean(nextLocation)}
        title="Save Changes"
        onClose={resetBlockNavigation}
        onConfirm={saveAndNavigate}
      />
    </>
  );
}

export default BlockNavigation;
