import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { INITIAL_POSITION_ROSTER } from 'utilities/teams';
import { PlayerPosition } from 'types/player';
import { PositionRosterBreakdown, RosterBreakdown } from 'types/teams';
import { getPositionDetails } from 'utilities/roster';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import {
  selectTeamActiveRosterOutlook,
  selectTeamInactiveRosterOutlook,
  selectTeamRosterStatFilter,
} from 'modules/teams/teams.selectors';

const useRosterBreakdown = (): PositionRosterBreakdown => {
  const activeRosterOutlook = useSelector(selectTeamActiveRosterOutlook);
  const inactiveRosterOutlook = useSelector(selectTeamInactiveRosterOutlook);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const curSeasonStartYear = useSelector(selectSeasonStartYear);

  const [positionRosterBreakdown, setPositionRosterBreakdown] = useState<PositionRosterBreakdown>({
    activeDefensiveRoster: INITIAL_POSITION_ROSTER,
    activeOffensiveRoster: INITIAL_POSITION_ROSTER,
    activeGoaliesRoster: INITIAL_POSITION_ROSTER,
    inactiveDefensiveRoster: INITIAL_POSITION_ROSTER,
    inactiveOffensiveRoster: INITIAL_POSITION_ROSTER,
    inactiveGoaliesRoster: INITIAL_POSITION_ROSTER,
  });

  useEffect(() => {
    const rosterBreakdown: RosterBreakdown = {
      activeDefensiveRoster: [],
      activeOffensiveRoster: [],
      activeGoaliesRoster: [],
      inactiveDefensiveRoster: [],
      inactiveOffensiveRoster: [],
      inactiveGoaliesRoster: [],
    };

    activeRosterOutlook?.forEach(player => {
      switch (player.primaryPosition) {
        case PlayerPosition.Center:
        case PlayerPosition.LeftWing:
        case PlayerPosition.RightWing:
          rosterBreakdown.activeOffensiveRoster.push(player);
          break;

        case PlayerPosition.LeftDefense:
        case PlayerPosition.RightDefense:
          rosterBreakdown.activeDefensiveRoster.push(player);
          break;

        case PlayerPosition.Goalie:
          rosterBreakdown.activeGoaliesRoster.push(player);
          break;

        default:
          break;
      }
    });

    inactiveRosterOutlook?.forEach(player => {
      switch (player.primaryPosition) {
        case PlayerPosition.Center:
        case PlayerPosition.LeftWing:
        case PlayerPosition.RightWing:
          rosterBreakdown.inactiveOffensiveRoster.push(player);
          break;

        case PlayerPosition.LeftDefense:
        case PlayerPosition.RightDefense:
          rosterBreakdown.inactiveDefensiveRoster.push(player);
          break;

        case PlayerPosition.Goalie:
          rosterBreakdown.inactiveGoaliesRoster.push(player);
          break;

        default:
          break;
      }
    });

    setPositionRosterBreakdown({
      activeDefensiveRoster: {
        players: rosterBreakdown.activeDefensiveRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.activeDefensiveRoster,
        }),
      },
      activeOffensiveRoster: {
        players: rosterBreakdown.activeOffensiveRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.activeOffensiveRoster,
        }),
      },
      activeGoaliesRoster: {
        players: rosterBreakdown.activeGoaliesRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.activeGoaliesRoster,
        }),
      },
      inactiveDefensiveRoster: {
        players: rosterBreakdown.inactiveDefensiveRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.inactiveDefensiveRoster,
        }),
      },
      inactiveOffensiveRoster: {
        players: rosterBreakdown.inactiveOffensiveRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.inactiveOffensiveRoster,
        }),
      },
      inactiveGoaliesRoster: {
        players: rosterBreakdown.inactiveGoaliesRoster,
        positionDetails: getPositionDetails({
          contractYear: curSeasonStartYear,
          filterKey: rosterStatFilter,
          players: rosterBreakdown.inactiveGoaliesRoster,
        }),
      },
    });
  }, [activeRosterOutlook, curSeasonStartYear, inactiveRosterOutlook, rosterStatFilter]);

  return positionRosterBreakdown;
};

export default useRosterBreakdown;
