import NumberFormat from 'react-number-format';
import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, InputProps, TextField } from '@mui/material';
import { useMemo } from 'react';

interface StanleyTableDollarEditInputProps {
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  inputProps?: { [key: string]: Record<string, unknown> | string };
  isClearable?: boolean;
  placeholder?: string;
  value: number | string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  onChange?: (value: number | string) => void;
  onClear?: () => void;
}

function CustomInput({ inputProps = {}, isClearable = false, variant, value, onClear, ...props }: any) {
  const newInputProps = useMemo(() => {
    const theseProps: InputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> };

    if (isClearable) {
      theseProps.endAdornment = (
        <IconButton sx={{ visibility: value ? 'visible' : 'hidden' }} size="small" onClick={onClear as () => void}>
          <Clear fontSize="small" />
        </IconButton>
      );
    }

    return theseProps;
  }, [isClearable, value, onClear]);

  return (
    <TextField
      inputProps={inputProps as Record<string, unknown>}
      InputProps={newInputProps}
      sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0.375rem' } }}
      variant={variant as 'standard' | 'filled' | 'outlined' | undefined}
      value={value as string}
      {...props}
    />
  );
}

function StanleyTableDollarEditInput({ value, onChange, ...props }: StanleyTableDollarEditInputProps): JSX.Element {
  return (
    <NumberFormat
      customInput={CustomInput}
      thousandSeparator
      value={value}
      onValueChange={(values) => (onChange ? onChange(values.floatValue || '') : null)}
      {...props}
    />
  );
}

export default StanleyTableDollarEditInput;
