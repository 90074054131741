/* eslint-disable max-len */
import api from 'middleware/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ContractYearStats, PlayerContractYearStats } from 'types/contracts';
import { RootState } from 'store';

export interface PlayersState {
  isLoading: boolean;
  contracts: PlayerContractYearStats;
}

const initialState: PlayersState = {
  isLoading: false,
  contracts: {},
};

export const getContractStats = createAsyncThunk(
  'contracts/getContractStats',
  async (contractId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `contracts/stats/${contractId}?scenarioId=${scenario.scenarioId}`
      : `contracts/stats/${contractId}`;

    return (await api(url, { method: 'GET' }, true)) as ContractYearStats[];
  },
);

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContractStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractStats.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.contracts[payload[0].contractId] = payload;
      })
      .addCase(getContractStats.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default contractsSlice.reducer;
