import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Container, Item, StanleyPrimaryButton } from '@project-stanley/cap-management-components';

import Colors from 'styles/colors';
import { ButtonSize } from 'types/buttons';
import { TradeDraft } from 'types/trade';

interface TradeSimulatorDraftAssetsHeaderProps {
  draftAssets: TradeDraft[];
  isPrinting: boolean;
  onAddDraft: () => void;
  onRemoveDraft: () => void;
}

function TradeSimulatorDraftAssetsHeader({
  draftAssets,
  isPrinting,
  onAddDraft,
  onRemoveDraft,
}: TradeSimulatorDraftAssetsHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container>
      <Item xs={isPrinting ? 6 : 12} sm={6}>
        <Container>
          <Item xs={isPrinting ? 4 : 12} sm={4}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2">Asset(s) Included:</Typography>
              <StanleyPrimaryButton
                className={classes.draftAssetButtons}
                disabled={draftAssets.length === 1}
                size={ButtonSize.SMALL}
                onClick={onRemoveDraft}
              >
                <Remove />
              </StanleyPrimaryButton>
              <StanleyPrimaryButton className={classes.draftAssetButtons} size={ButtonSize.SMALL} onClick={onAddDraft}>
                <Add />
              </StanleyPrimaryButton>
            </Box>
          </Item>
          <Item xs={isPrinting ? 6 : 12} sm={6} />
          <Container item xs={isPrinting ? 2 : 12} sm={2} alignItems="center" justifyContent="center">
            <Typography variant="body2">Conditional</Typography>
          </Container>
        </Container>
      </Item>
      <Item xs={isPrinting ? 6 : 12} sm={6} className={classes.addDetails}>
        <Typography variant="body2">Additional Details</Typography>
      </Item>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  draftAssetButtons: {
    backgroundColor: Colors.LIGHT_GRAY,
    color: theme.palette.secondary.main,
    height: '2rem',
    marginLeft: theme.spacing(1),
    minWidth: 'unset',
    width: '2rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  addDetails: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export default TradeSimulatorDraftAssetsHeader;
