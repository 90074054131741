/* eslint-disable react/jsx-curly-newline */
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Filters, SeasonSelection } from 'types/playerSearch';
import { SEASON_SELECTIONS_ORDER } from 'constants/playerSearch';
import { selectSeasons } from 'modules/season/season.selectors';

interface PlayerSearchSeasonSelectionProps {
  filters: Filters;
  onChange: React.Dispatch<React.SetStateAction<Filters>>;
}

function PlayerSearchSeasonSelection({ filters, onChange }: PlayerSearchSeasonSelectionProps) {
  const seasons = useSelector(selectSeasons);

  const getSeasonLabel = useCallback((season: string): string => {
    const seasonParts = season.split('-');

    return seasonParts.length === 2 ? season : SeasonSelection[season as keyof typeof SeasonSelection];
  }, []);

  const filteredSeasonYears = useMemo(
    () => seasons.filter((season) => !SeasonSelection[season as keyof typeof SeasonSelection]),
    [seasons],
  );

  const filteredContractYears = useMemo(
    () =>
      seasons.filter(
        (season) => SeasonSelection[season as keyof typeof SeasonSelection],
      ) as (keyof typeof SeasonSelection)[],
    [seasons],
  );

  return (
    <Box flex={1}>
      <FormGroup>
        <Container direction="column" spacing={3}>
          {filteredContractYears
            .sort(
              (a, b) =>
                (SEASON_SELECTIONS_ORDER as unknown as 'CAREER_STATS' | 'CTC' | 'PY' | 'PY1' | 'PY2').indexOf(a) -
                (SEASON_SELECTIONS_ORDER as unknown as 'CAREER_STATS' | 'CTC' | 'PY' | 'PY1' | 'PY2').indexOf(b),
            )
            .map((season) => (
              <Item key={season}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.playerStatsSearchCriteria?.seasonYear === season}
                      onClick={() =>
                        onChange({
                          ...filters,
                          playerStatsSearchCriteria: {
                            ...filters.playerStatsSearchCriteria,
                            seasonYear: season,
                          },
                        })
                      }
                    />
                  }
                  label={getSeasonLabel(season)}
                />
              </Item>
            ))}
          {filteredSeasonYears.map((seasonYear) => (
            <Item key={seasonYear}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.playerStatsSearchCriteria?.seasonYear === seasonYear}
                    onClick={() =>
                      onChange({
                        ...filters,
                        playerStatsSearchCriteria: {
                          ...filters.playerStatsSearchCriteria,
                          seasonYear,
                        },
                      })
                    }
                  />
                }
                label={getSeasonLabel(seasonYear)}
              />
            </Item>
          ))}
        </Container>
      </FormGroup>
    </Box>
  );
}

export default PlayerSearchSeasonSelection;
