/* eslint-disable max-len */
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

import {
  ContractClause,
  ContractExpiryStatus,
  ContractStatus,
  ContractType,
  PlayerContractDetails,
  PlayerContractSimulation,
  PlayerContractSimulationDetails,
  PlayerContractSimulationPreviewDetails,
} from 'types/contract';

export const CONTRACT_TYPE_MAP: { [key: string]: string } = Object.freeze({
  [ContractType.ENTRY_LEVEL_CONTRACT]: 'Entry Level',
  [ContractType.STANDARD]: 'Standard',
});

export const CONTRACT_EXPIRY_STATUS_MAP: { [key: string]: string } = Object.freeze({
  [ContractExpiryStatus.UNRESTRICTED_FREE_AGENT]: 'UFA',
  [ContractExpiryStatus.RESTRICTED_FREE_AGENT]: 'RFA',
});

export const CONTRACT_CLAUSE_MAP: { [key: string]: string } = Object.freeze({
  [ContractClause.NO_TRADE]: 'NTC',
  [ContractClause.MODIFIED_NO_TRADE]: 'M-NTC',
  [ContractClause.NO_MOVEMENT]: 'NMC',
});

export const CONTRACT_STATUS_MAP: { [key: string]: string } = Object.freeze({
  [ContractStatus.ACTIVE]: 'NHL',
  [ContractStatus.IR]: 'IR',
  [ContractStatus.IN_ACTIVE]: 'IA',
  [ContractStatus.LTIR]: 'LTIR',
});

export const INITIAL_CONTRACT_SIMULATION_DETAILS: PlayerContractSimulationDetails = {
  averageAnnualValue: 0,
  baseSalary: 0,
  capHit: 0,
  capHitPercentage: 0,
  minorsSalary: 0,
  hasNoMovementClause: false,
  hasNoTradeClause: false,
  performanceBonus: 0,
  season: `${format(new Date(), 'yyyy')} - ${Number(format(new Date(), 'yyyy')) + 1}`,
  signingBonus: 0,
  totalSalary: 0,
};

export const INITIAL_CONTRACT_SIMULATION: PlayerContractSimulation = {
  contractDetails: [],
  contractType: 1,
  signingTeamId: 1,
  totalValue: 0,
};

export const mapContractSimulationToPayload = (
  {
    baseSalary,
    hasNoMovementClause,
    hasNoTradeClause,
    minorsSalary,
    performanceBonus,
    season,
    signingBonus,
  }: PlayerContractSimulationDetails,
  isOneWay: boolean,
): PlayerContractSimulationPreviewDetails => ({
  baseSalary: !baseSalary ? 0 : baseSalary,
  hasNoMovementClause,
  hasNoTradeClause,
  minorsSalary: isOneWay ? baseSalary || 0 : minorsSalary || 0,
  performanceBonus: !performanceBonus ? 0 : performanceBonus,
  season,
  signingBonus: !signingBonus ? 0 : signingBonus,
});

export const getContractSimulationStartYear = (
  playerContractDetails: PlayerContractDetails[],
  currentSeasonEndYear: number,
): string => {
  if (playerContractDetails.length === 0) {
    return `${currentSeasonEndYear} - ${currentSeasonEndYear + 1}`;
  }

  const sortedContractYears = cloneDeep(playerContractDetails).sort(
    (a, b) => a.seasonInfo.seasonYearEnd - b.seasonInfo.seasonYearEnd,
  );
  const contractEndYear = sortedContractYears[sortedContractYears.length - 1].seasonInfo.seasonYearEnd;

  if (contractEndYear <= currentSeasonEndYear) return `${currentSeasonEndYear} - ${currentSeasonEndYear + 1}`;

  return `${contractEndYear} - ${contractEndYear + 1}`;
};
