/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-unused-prop-types */
import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { StanleyTable } from '@project-stanley/cap-management-components';
import { useCallback } from 'react';

import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { useNavigate } from 'react-router-dom';

export interface ScenarioSummaryRow {
  actionDirection: string;
  playerId?: number;
  scenarioText: string;
  scenarioType: string;
}

interface ScenarioSummaryTableProps {
  summary: ScenarioSummaryRow[];
}

function ScenarioSummaryTable({ summary }: ScenarioSummaryTableProps): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const renderActionDirection = useCallback(
    ({ actionDirection }: ScenarioSummaryRow) =>
      actionDirection === 'add' ? (
        <Box alignItems="center" display="flex">
          <Add className={classes.add} />
        </Box>
      ) : (
        <Box alignItems="center" display="flex">
          <Remove className={classes.remove} />
        </Box>
      ),
    [classes],
  );

  const renderScenarioText = useCallback(
    ({ actionDirection, scenarioText }: ScenarioSummaryRow) => (
      <Typography className={actionDirection === 'add' ? classes.add : classes.remove}>{scenarioText}</Typography>
    ),
    [classes],
  );

  const renderScenarioType = useCallback(
    ({ actionDirection, scenarioType }: ScenarioSummaryRow) => (
      <Typography className={actionDirection === 'add' ? classes.add : classes.remove}>{scenarioType}</Typography>
    ),
    [classes],
  );

  const columns = [
    {
      cellStyle: { minWidth: 15, width: 15 },
      render: renderActionDirection,
    },
    {
      cellStyle: { minWidth: 160, width: 160 },
      render: renderScenarioText,
    },
    {
      render: renderScenarioType,
    },
  ];

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: ScenarioSummaryRow) => {
      if (rowData?.playerId) navigate(`${ROUTES.PLAYERS}/${rowData.playerId}`);
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      data={summary}
      options={{ header: false }}
      shouldAlternateRows={false}
      onRowClick={handleRowClick}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  add: {
    color: 'green',
  },
  remove: {
    color: theme.palette.error.main,
  },
}));

export default ScenarioSummaryTable;
