import { RootState } from 'store';
import { Scenario, ScenarioSummaryByTeam } from 'types/scenarios';

export const selectScenariosIsLoading = (state: RootState): boolean => state.scenarios.isLoading;

export const selectScenariosIsDeletingScenario = (state: RootState): boolean => state.scenarios.isDeletingScenario;

export const selectScenariosIsLoadingSummary = (state: RootState): boolean => state.scenarios.isLoadingSummary;

export const selectScenariosIsLoadingContract = (state: RootState): boolean => state.scenarios.isLoadingContract;

export const selectScenariosIsLoadingPreview = (state: RootState): boolean => state.scenarios.isLoadingPreview;

export const selectScenarios = (state: RootState): Scenario[] | null => state.scenarios.scenarios;

export const selectScenario = (state: RootState): Scenario | null => state.scenarios.scenario;

export const selectScenarioSummary = (state: RootState): ScenarioSummaryByTeam | null =>
  state.scenarios.scenarioSummary;

export const selectIsScenarioMode = (state: RootState): boolean => Boolean(state.scenarios.scenario);
