/* eslint-disable max-len */
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Typography, styled } from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';
import { useNavigate } from 'react-router-dom';

import Colors from 'styles/colors';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { ICONS } from 'utilities/icons';
import { ROUTES } from 'utilities/routes';
import { Report } from 'types/research';

interface ResearchCardProps {
  isDownloading: boolean;
  report: Report;
  onDownload: (reportId: string) => void;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const StyledTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 2.5rem;
`;

const StyledDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

function ResearchCard({
  isDownloading,
  report: { description, imageUrl, reportId, teamInfo, title },
  onDownload,
}: ResearchCardProps) {
  const navigate = useNavigate();

  const handleDownloadReport = useCallback(() => {
    if (isDownloading) return;

    logGAEvent({
      category: GA_CATEGORIES.DASHBOARD,
      action: GA_ACTIONS.DOWNLOAD_REPORT,
      label: title,
      value: reportId,
    });

    onDownload(reportId);
  }, [isDownloading, reportId, title, onDownload]);

  return (
    <Card
      elevation={3}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: `0px 3px 1px -2px ${Colors.BRAND_GOLD}, 0px 2px 2px 0px ${Colors.BRAND_GOLD}, 0px 1px 5px 0px ${Colors.BRAND_GOLD}`,
        },
      }}
      onClick={handleDownloadReport}
    >
      {imageUrl && <CardMedia sx={{ backgroundPosition: 'top', height: 140 }} image={imageUrl} />}
      <CardContent sx={{ position: 'relative' }}>
        <StyledTitle
          sx={{
            marginBottom: 1.25,
          }}
          fontWeight="bold"
          variant="body2"
        >
          {title}
        </StyledTitle>
        <StyledDescription variant="body2">{description}</StyledDescription>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {!teamInfo ? (
          <Box height="2.25rem" display="flex" alignItems="center" paddingLeft="0.25rem">
            <StanleySVGIcon height="1.75rem" imageSrc={ICONS.NHL} width="1.75rem" />
          </Box>
        ) : (
          <StyledIconButton
            onClick={(event: MouseEvent<HTMLElement>) => {
              event.stopPropagation();

              navigate(`${ROUTES.TEAMS}/${teamInfo?.teamId}`);
            }}
          >
            <StanleySVGIcon height="1.75rem" imageSrc={ICONS[teamInfo.abrvName]} width="1.75rem" />
          </StyledIconButton>
        )}
        <Typography sx={{ paddingRight: 1 }} color="primary" variant="body1">
          View Report
        </Typography>
      </CardActions>
    </Card>
  );
}

export default ResearchCard;
