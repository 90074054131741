import { Box, Typography, useTheme } from '@mui/material';
import { DropResult } from 'react-beautiful-dnd';
import { makeStyles } from '@mui/styles';
import { useAppDispatch } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import RosterComparisonRosterFilters from 'modules/rosterComparison/rosterComparisonRosterFilters.component';
import RosterDnDDepthChart from 'modules/teams/rosterView/rosterDnDDepthChart.component';
import RosterOutlookTable from 'modules/teams/rosterView/rosterOutlookTable.component';
import { ContractStatus } from 'types/contract';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { Player } from 'types/player';
import {
  ROSTER_CONTRACT_FILTER_KEYS,
  filterRosterByContractYear,
  generateSortedRosterOutlook,
  mapInactiveRosterByPosition,
  mapRosterByPosition,
  moveRosterPlayer,
  sortMappedInactiveRosterByPosition,
  sortMappedRosterByPosition,
} from 'utilities/roster';
import { Team, TeamRosterOutlookByPosition } from 'types/teams';
import { ToastTypes } from 'types/layout';
import { getRosterComparisonTeamOutlook } from 'modules/rosterComparison/rosterComparison.slice';
import { selectRosterComparisonIsLoading } from 'modules/rosterComparison/rosterComparison.selectors';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeamContractYear, selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';
import { showToast } from 'modules/layout/layout.slice';

interface RosterComparisonRosterProps {
  showDepthChart: boolean;
  showTerms: boolean;
  teamId: number;
  teams: Team[];
  onUpdateTeam: (teamId: number) => void;
  updateContractYears: (players: Player[]) => void;
}

const INITIAL_ROSTER_DEPTH_CHART: TeamRosterOutlookByPosition = { C: [], G: [], LD: [], LW: [], RD: [], RW: [] };

function RosterComparisonRoster({
  showDepthChart,
  showTerms,
  teamId,
  teams,
  onUpdateTeam,
  updateContractYears,
}: RosterComparisonRosterProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isLoading = useSelector(selectRosterComparisonIsLoading);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);
  const contractYear = useSelector(selectTeamContractYear);
  const currentSeasonStartYear = useSelector(selectSeasonStartYear);

  const [activeRosterDepthChart, setActiveRosterDepthChart] = useState(INITIAL_ROSTER_DEPTH_CHART);
  const [inactiveRosterDepthChart, setInactiveRosterDepthChart] = useState(INITIAL_ROSTER_DEPTH_CHART);
  const [activeRoster, setActiveRoster] = useState<Player[]>([]);
  const [inactiveRoster, setInactiveRoster] = useState<Player[]>([]);

  const curContractYear = useMemo(() => contractYear || currentSeasonStartYear, [contractYear, currentSeasonStartYear]);

  useEffect(() => {
    if (!curContractYear) return;

    setActiveRosterDepthChart(
      sortMappedRosterByPosition(
        mapRosterByPosition(filterRosterByContractYear(activeRoster || [], curContractYear)),
        rosterStatFilter,
      ),
    );
    setInactiveRosterDepthChart(
      sortMappedInactiveRosterByPosition(
        mapInactiveRosterByPosition(filterRosterByContractYear(inactiveRoster || [], curContractYear)),
        rosterStatFilter,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoster, curContractYear, inactiveRoster]);

  useEffect(() => {
    (async () => {
      const response = await dispatch(getRosterComparisonTeamOutlook(teamId));

      if (response.type === getRosterComparisonTeamOutlook.rejected.toString()) {
        dispatch(
          showToast({
            toastMessage: 'There was an error requesting for a roster. Please try again.',
            toastType: ToastTypes.ERROR,
          }),
        );
        return;
      }

      updateContractYears((response.payload as { players: Player[] }).players);

      const filteredActiveRoster: Player[] = (response.payload as { players: Player[] }).players.filter(
        (player) =>
          player.status === ContractStatus.ACTIVE ||
          player.status === ContractStatus.IR ||
          player.status === ContractStatus.LTIR,
      );
      const filteredInactiveRoster = (response.payload as { players: Player[] }).players.filter(
        (player) =>
          player.status !== ContractStatus.ACTIVE &&
          player.status !== ContractStatus.IR &&
          player.status !== ContractStatus.LTIR,
      );

      setActiveRoster(generateSortedRosterOutlook(filteredActiveRoster));
      setInactiveRoster(generateSortedRosterOutlook(filteredInactiveRoster));
    })();
  }, [dispatch, teamId, updateContractYears]);

  useEffect(() => {
    setActiveRosterDepthChart(
      sortMappedRosterByPosition(mapRosterByPosition(activeRoster), ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL),
    );
    setInactiveRosterDepthChart(
      sortMappedInactiveRosterByPosition(
        mapInactiveRosterByPosition(inactiveRoster),
        ROSTER_CONTRACT_FILTER_KEYS.AVERAGED_ANNUAL,
      ),
    );
  }, [activeRoster, inactiveRoster]);

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult, isActiveRoster?: boolean) => {
      if (!destination || (destination.droppableId === source.droppableId && source.index === destination.index)) {
        return;
      }

      if (isActiveRoster) {
        setActiveRosterDepthChart(
          moveRosterPlayer(activeRosterDepthChart, {
            destinationIndex: destination.index,
            destinationPosition: destination.droppableId,
            sourceIndex: source.index,
            sourcePosition: source.droppableId,
          }),
        );
      } else {
        setInactiveRosterDepthChart(
          moveRosterPlayer(inactiveRosterDepthChart, {
            destinationIndex: destination.index,
            destinationPosition: destination.droppableId,
            sourceIndex: source.index,
            sourcePosition: source.droppableId,
          }),
        );
      }

      logGAEvent({
        category: GA_CATEGORIES.ROSTER_COMPARISON,
        action: GA_ACTIONS.ROSTER_COMPARISON_PLAYER_MOVE,
      });
    },
    [activeRosterDepthChart, inactiveRosterDepthChart, setActiveRosterDepthChart, setInactiveRosterDepthChart],
  );

  const handleTeamChange = useCallback(
    (newTeamId: number | number[]) => {
      if (Array.isArray(newTeamId)) return;

      setActiveRoster([]);
      setInactiveRoster([]);

      logGAEvent({
        category: GA_CATEGORIES.ROSTER_COMPARISON,
        action: GA_ACTIONS.ROSTER_COMPARISON_TEAM_CHANGE,
      });

      onUpdateTeam(newTeamId);
    },
    [onUpdateTeam],
  );

  return (
    <>
      <RosterComparisonRosterFilters teamId={teamId} teams={teams} onTeamChange={handleTeamChange} />
      {showDepthChart ? (
        <Box marginTop={theme.spacing(2)}>
          <RosterDnDDepthChart
            isActiveRoster
            isLoading={isLoading}
            roster={activeRosterDepthChart}
            onDragEnd={handleDragEnd}
          />
          <Box marginTop={theme.spacing(3)}>
            <RosterDnDDepthChart isLoading={isLoading} roster={inactiveRosterDepthChart} onDragEnd={handleDragEnd} />
          </Box>
        </Box>
      ) : (
        <Box marginTop={theme.spacing(1)}>
          <Typography className={classes.groupTitle} variant="h6">
            Active Roster
          </Typography>
          <RosterOutlookTable roster={activeRoster} showTerms={showTerms} />
          <Box marginTop={theme.spacing(3)}>
            <Typography className={classes.groupTitle} variant="h6">
              Inactive Roster
            </Typography>
            <RosterOutlookTable roster={inactiveRoster} showTerms={showTerms} />
          </Box>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  groupTitle: {
    fontWeight: 'unset',
    marginBottom: theme.spacing(1),
  },
}));

export default RosterComparisonRoster;
