/* eslint-disable max-len */
import { FIREBASE_STORAGE_PREFERENCES_KEYS } from 'constants/firebase';
import { GAEvent } from 'types/analytics';
import { PLAYER_VIEW_OPTION_VALUES, getPlayerFullname } from 'utilities/player';
import { Player } from 'types/player';
import { Team } from 'types/teams';
import { analytics } from 'utilities/firebase';
import { logEvent } from 'firebase/analytics';

export const logGAEvent = (event: GAEvent): void => {
  logEvent(analytics, event.action, event);
};

export const CUSTOM_GA_DIMENSIONS = {
  USER_EMAIL: 'userEmail',
  USER_IDENTIFIER: 'userIdentifier',
};

export const GA_CATEGORIES = {
  SCENARIOS: 'SCENARIOS',
  ROSTER_COMPARISON: 'ROSTER_COMPARISON',
  TEAM_PROFILE: 'TEAM_PROFILE',
  PLAYER_PROFILE: 'PLAYER_PROFILE',
  TEAMS: 'TEAMS',
  RESEARCH: 'RESEARCH',
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
  DASHBOARD: 'DASBOARD',
};

export const GA_ACTIONS = {
  CONTRACT_COMP_SEASON_FILTER_CHANGE: 'CONTRACT_COMP_SEASON_FILTER_CHANGE',
  CONTRACT_COMP_STAT_FILTER_CHANGE: 'CONTRACT_COMP_STAT_FILTER_CHANGE',
  CONTRACT_COMP_LIST_CHANGE: 'CONTRACT_COMP_LIST_CHANGE',
  DOWNLOAD_CONTRACT_COMPS: 'DOWNLOAD_CONTRACT_COMPS',
  DOWNLOAD_TEAM_OUTLOOK: 'DOWNLOAD_TEAM_OUTLOOK',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  ADD_PLAYER: 'ADD_PLAYER',
  REMOVE_PLAYERS: 'REMOVE_PLAYERS',
  PLAYER_PROFILE_ADD_CONTRACT_COMPARABLE: 'PLAYER_PROFILE_ADD_CONTRACT_COMPARABLE',
  PLAYER_PROFILE_MOVE_CONTRACT_COMPARABLE: 'PLAYER_PROFILE_MOVE_CONTRACT_COMPARABLE',
  PLAYER_PROFILE_REMOVE_CONTRACT_COMPARABLE: 'PLAYER_PROFILE_REMOVE_CONTRACT_COMPARABLE',
  PLAYER_PROFILE_SEARCH: 'PLAYER_PROFILE_SEARCH',
  PLAYER_PROFILE_VIEW_CONTRACTS: 'PLAYER_PROFILE_VIEW_CONTRACTS',
  PLAYER_PROFILE_VIEW_CONTRACT_COMPARABLES: 'PLAYER_PROFILE_VIEW_CONTRACT_COMPARABLES',
  PLAYER_PROFILE_VIEW_PLAYER: 'PLAYER_PROFILE_VIEW_PLAYER',
  PLAYER_PROFILE_VIEW_PLAYOFF_STATS: 'PLAYER_PROFILE_VIEW_PLAYOFF_STATS',
  PLAYER_PROFILE_VIEW_REGULAR_SEASON_STATS: 'PLAYER_PROFILE_VIEW_REGULAR_SEASON_STATS',
  PLAYER_PROFILE_VIEW_TRADE_COMPARABLES: 'PLAYER_PROFILE_VIEW_TRADE_COMPARABLES',
  REPORTING_PLAYER_PROFILE_CONTRACTS: 'REPORTING_PLAYER_PROFILE_CONTRACTS',
  REPORTING_PLAYER_PROFILE_CONTRACT_COMPARABLES: 'REPORTING_PLAYER_PROFILE_CONTRACT_COMPARABLES',
  REPORTING_PLAYER_PROFILE_PLAYOFF_STATS: 'REPORTING_PLAYER_PROFILE_PLAYOFF_STATS',
  REPORTING_PLAYER_PROFILE_STATS: 'REPORTING_PLAYER_PROFILE_STATS',
  REPORTING_PLAYER_PROFILE_TRADE_COMPARABLES: 'REPORTING_PLAYER_PROFILE_TRADE_COMPARABLES',
  REPORTING_ROSTER_COMPARISON: 'REPORTING_ROSTER_COMPARISON',
  REPORTING_TEAM_PROFILE_DEPTH_CHART: 'REPORTING_TEAM_PROFILE_DEPTH_CHART',
  REPORTING_TEAM_PROFILE_OUTLOOK_TABLE: 'REPORTING_TEAM_PROFILE_OUTLOOK_TABLE',
  REPORTING_TEAM_PROFILE_OUTLOOK_TERMS: 'REPORTING_TEAM_PROFILE_OUTLOOK_TERMS',
  REPORTING_TRADE_SIMULATOR: 'REPORTING_TRADE_SIMULATOR',
  RESET_COMPS: 'RESET_COMPS',
  RESET_LINEUP: 'RESET_LINEUP',
  ROSTER_COMPARISON_PLAYER_MOVE: 'ROSTER_COMPARISON_PLAYER_MOVE',
  ROSTER_COMPARISON_TEAM_CHANGE: 'ROSTER_COMPARISON_TEAM_CHANGE',
  SAVE_LIST: 'SAVE_LIST',
  SAVE_COMPS: 'SAVE_COMPS',
  SAVE_COMPS_DISPLAY_FIELDS: 'SAVE_COMPS_DISPLAY_FIELDS',
  SAVE_LINEUP: 'SAVE_LINEUP',
  SAVE_SEARCH_CRITERIA: 'SAVE_SEARCH_CRITERIA',
  SAVE_SEARCH_DISPLAY_FIELDS: 'SAVE_SEARCH_DISPLAY_FIELDS',
  SCENARIO_CONTRACT_SIMULATED: 'SCENARIO_CONTRACT_SIMULATED',
  SCENARIO_CREATE: 'SCENARIO_CREATE',
  SCENARIO_SELECT: 'SCENARIO_SELECT',
  SCENARIO_TRADE_SIMULATED: 'SCENARIO_TRADE_SIMULATED',
  SHARE_CONTRACT_COMPS: 'SHARE_CONTRACT_COMPS',
  SHARE_PLAYER: 'SHARE_PLAYER',
  SHARE_REPORT: 'SHARE_REPORT',
  SHARE_SEARCH: 'SHARE_SEARCH',
  SHARE_TEAM: 'SHARE_TEAM',
  TEAMS_LEAGUE: 'TEAMS_LEAGUE',
  TEAMS_RESEARCH: 'TEAMS_RESEARCH',
  TEAM_PROFILE_DEPTH_CHART_CHANGE: 'TEAM_PROFILE_DEPTH_CHART_CHANGE',
  TEAM_PROFILE_VIEW_DEPTH_CHART: 'TEAM_PROFILE_VIEW_DEPTH_CHART',
  TEAM_PROFILE_VIEW_DRAFT_PICKS: 'TEAM_PROFILE_VIEW_DRAFT_PICKS',
  TEAM_PROFILE_VIEW_OUTLOOK: 'TEAM_PROFILE_VIEW_OUTLOOK',
  TEAM_PROFILE_VIEW_TERMS: 'TEAM_PROFILE_VIEW_TERMS',
  TEAM_PROFILE_VIEW_TRADE_HISTORY: 'TEAM_PROFILE_VIEW_TRADE_HISTORY',
  TEAM_STAT_FILTER_CHANGE: 'TEAM_STAT_FILTER_CHANGE',
  TEAM_YEAR_FILTER_CHANGE: 'TEAM_YEAR_FILTER_CHANGE',
  VIEW_LEAGUE_REPORT: 'VIEW_LEAGUE_REPORT',
  VIEW_PLAYER_REPORT: 'VIEW_PLAYER_REPORT',
  VIEW_REPORTS: 'VIEW_REPORTS',
  VIEW_RECENT_INJURIES: 'VIEW_RECENT_INJURIES',
  VIEW_RECENT_SIGNINGS: 'VIEW_RECENT_SIGNINGS',
  VIEW_RECENT_TRANSACTIONS: 'VIEW_RECENT_TRANSACTIONS',
};

export const DASHBOARD_GA_ACTIONS: { [key: number]: string } = {
  0: GA_ACTIONS.VIEW_RECENT_INJURIES,
  1: GA_ACTIONS.VIEW_RECENT_TRANSACTIONS,
  2: GA_ACTIONS.VIEW_RECENT_SIGNINGS,
};

export const getReportingTeamAnalyticsAction = ({
  showDepthChart,
  showTerms,
}: {
  showDepthChart: boolean;
  showTerms: boolean;
}): string => {
  if (showDepthChart) return GA_ACTIONS.REPORTING_TEAM_PROFILE_DEPTH_CHART;

  if (showTerms) return GA_ACTIONS.REPORTING_TEAM_PROFILE_OUTLOOK_TERMS;

  return GA_ACTIONS.REPORTING_TEAM_PROFILE_OUTLOOK_TABLE;
};

export const logBlockNavigationEvent = ({
  actions,
  player,
  team,
}: {
  actions: string[];
  player: Player | null;
  team: Team | null;
}) => {
  actions.forEach((action) => {
    switch (action) {
      case FIREBASE_STORAGE_PREFERENCES_KEYS.DEPTH_CHART:
        logGAEvent({
          category: GA_CATEGORIES.TEAM_PROFILE,
          action: GA_ACTIONS.SAVE_LINEUP,
          label: team?.abrvName,
          value: team?.teamId,
        });
        break;

      case FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS:
        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.SAVE_COMPS,
          label: player ? getPlayerFullname(player) : '',
          value: player?.playerId,
        });
        break;

      case FIREBASE_STORAGE_PREFERENCES_KEYS.COMPARABLE_CONTRACTS_DATA_FIELDS:
        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.SAVE_COMPS_DISPLAY_FIELDS,
          label: player ? getPlayerFullname(player) : '',
          value: player?.playerId,
        });
        break;

      case FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_DATA_FIELDS:
        logGAEvent({
          category: GA_CATEGORIES.ADVANCED_SEARCH,
          action: GA_ACTIONS.SAVE_SEARCH_DISPLAY_FIELDS,
        });
        break;

      case FIREBASE_STORAGE_PREFERENCES_KEYS.PLAYER_SEARCH_CRITERIA:
        logGAEvent({
          category: GA_CATEGORIES.ADVANCED_SEARCH,
          action: GA_ACTIONS.SAVE_SEARCH_CRITERIA,
        });
        break;

      default:
        break;
    }
  });

  return 'here';
};

export const REPORTING_PLAYER_ANALYTICS_ACTIONS_MAP: { [key: string]: string } = {
  [PLAYER_VIEW_OPTION_VALUES.REGULAR_SEASON_PLAYER_STATS]: GA_ACTIONS.REPORTING_PLAYER_PROFILE_STATS,
  [PLAYER_VIEW_OPTION_VALUES.PLAYOFFS_PLAYER_STATS]: GA_ACTIONS.REPORTING_PLAYER_PROFILE_PLAYOFF_STATS,
  [PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS]: GA_ACTIONS.REPORTING_PLAYER_PROFILE_CONTRACTS,
  [PLAYER_VIEW_OPTION_VALUES.CONTRACT_COMPARABLES]: GA_ACTIONS.REPORTING_PLAYER_PROFILE_CONTRACT_COMPARABLES,
  [PLAYER_VIEW_OPTION_VALUES.TRADE_COMPARABLES]: GA_ACTIONS.REPORTING_PLAYER_PROFILE_TRADE_COMPARABLES,
};

export const PLAYER_VIEW_ACTIONS_MAP: { [key: string]: string } = {
  [PLAYER_VIEW_OPTION_VALUES.REGULAR_SEASON_PLAYER_STATS]: GA_ACTIONS.PLAYER_PROFILE_VIEW_REGULAR_SEASON_STATS,
  [PLAYER_VIEW_OPTION_VALUES.CONTRACT_COMPARABLES]: GA_ACTIONS.PLAYER_PROFILE_VIEW_CONTRACT_COMPARABLES,
  [PLAYER_VIEW_OPTION_VALUES.PLAYOFFS_PLAYER_STATS]: GA_ACTIONS.PLAYER_PROFILE_VIEW_PLAYOFF_STATS,
  [PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS]: GA_ACTIONS.PLAYER_PROFILE_VIEW_CONTRACTS,
  [PLAYER_VIEW_OPTION_VALUES.TRADE_COMPARABLES]: GA_ACTIONS.PLAYER_PROFILE_VIEW_TRADE_COMPARABLES,
};
