import { IconButton, styled } from '@mui/material';
import { StanleySVGIcon, TableRowClick } from '@project-stanley/cap-management-components';
import { useNavigate } from 'react-router-dom';

import { ICONS } from 'utilities/icons';
import { ROUTES } from 'utilities/routes';
import { TeamInfo } from 'types/teams';

interface StanleyTableTeamIconProps {
  teamInfo?: TeamInfo;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

function StanleyTableTeamIcon({ teamInfo }: StanleyTableTeamIconProps) {
  const navigate = useNavigate();

  if (!teamInfo) {
    return (
      <StyledIconButton disabled size="large">
        <StanleySVGIcon height="1.75rem" imageSrc={ICONS.NHL} width="1.75rem" />
      </StyledIconButton>
    );
  }

  return (
    <StyledIconButton
      onClick={(event: TableRowClick) => {
        event?.stopPropagation();

        navigate(`${ROUTES.TEAMS}/${teamInfo.teamId}`);
      }}
      size="large"
    >
      <StanleySVGIcon height="1.75rem" imageSrc={ICONS[teamInfo.abrvName]} width="1.75rem" />
    </StyledIconButton>
  );
}

export default StanleyTableTeamIcon;
