import { ComparableSeasonStatLine } from 'types/comparableContracts';
import { ContractExpiryStatus, ContractType } from 'types/contract';
import { PlayerPosition, PlayerShotHand } from 'types/player';
import { TeamInfo } from 'types/teams';

export enum SeasonSelection {
  CTC = 'Career To Contract ("CTC")',
  PY = 'Platform Year ("PY")',
  PY1 = 'Platform Year - 1 ("PY-1")',
  PY2 = 'Platfrom Year - 2 ("PY-2")',
  CAREER_STATS = 'Career Stats',
}

export interface ContractFilters {
  arbRights?: string | boolean | null;
  contractExpirationSeasonId?: string | null;
  contractType?: ContractType[] | null;
  expiryStatus?: ContractExpiryStatus[] | null;
  signingStatus?: ContractExpiryStatus[] | null;
  maxAverageAnnualValue?: number | null;
  minAverageAnnualValue?: number | null;
  maxSigningAge?: number | null;
  minSigningAge?: number | null;
  maxContractLength?: number | null;
  minContractLength?: number | null;
  maxCapHit?: number | null;
  minCapHit?: number | null;
  maxContractValue?: number | null;
  minContractValue?: number | null;
  [key: string]: ContractType[] | ContractExpiryStatus[] | number | string | null | undefined | boolean;
}

export interface StatFilters {
  maxPoints?: number | null;
  minPoints?: number | null;
  maxGoals?: number | null;
  minGoals?: number | null;
  maxAssists?: number | null;
  minAssists?: number | null;
  maxGamesPlayed?: number | null;
  minGamesPlayed?: number | null;
  pointsPerGameMin?: number | null;
  pointsPerGameMax?: number | null;
  goalsPerGameMin?: number | null;
  goalsPerGameMax?: number | null;
  assistsPerGameMin?: number | null;
  assistsPerGameMax?: number | null;
  timeOnIcePerGameMin?: number | null;
  timeOnICePerGameMax?: number | null;
  seasonYear?: string | null;
  [key: string]: number | string | null | undefined;
}

export interface Filters {
  contractSearchCriteria?: ContractFilters;
  maxAge?: number;
  minAge?: number;
  playerStatsSearchCriteria?: StatFilters;
  primaryPosition?: PlayerPosition[];
  scenarioId?: number;
  teamId?: number[];
  [key: string]:
    | PlayerPosition[]
    | ContractType
    | ContractExpiryStatus
    | number
    | string
    | Record<string, unknown>
    | null
    | undefined;
}

export interface PlayerSearchStats {
  playerId: number;
  regularSeasonStats: ComparableSeasonStatLine;
  postSeasonStats: {
    assists: number;
    gamesPlayed: number;
    goals: number;
    goalsAgainstAverage: number;
    penaltyInfractionMinutes: number;
    plusMinus: number;
    points: number;
    pointsPerGame: number;
    powerPlayGoals: number;
    savePercentage: number;
    [key: string]: number;
  };
}

export interface PlayerSearchResult {
  contractId: number;
  playerId: number;
  playerInfo: {
    playerId: number;
    firstName: string;
    lastName: string;
    primaryPosition: PlayerPosition;
    shotHand: PlayerShotHand;
    arbRights: boolean;
    qoValue: number | null;
    contractArbRights: boolean;
    signingStatus: ContractExpiryStatus;
    [key: string]: number | string | PlayerPosition | PlayerShotHand | boolean | null;
  };
  age: number;
  currentTeamId: number;
  currentTeam: TeamInfo;
  signingTeam: TeamInfo;
  averageAnnualValue: number;
  baseSalary: number;
  capHit: number;
  careerStats: PlayerSearchStats;
  contractDetailId: number;
  contractExpirationSeasonId: string;
  contractLength: number;
  contractType: ContractType;
  expiryStatus: ContractExpiryStatus;
  minorsSalary: number;
  performanceBonus: number;
  scenarioId: number | null;
  seasonStats: PlayerSearchStats;
  signingBonus: number;
  signingDate: Date;
  totalValue: number;
  startingSeason: number;
  contractTerm: number;
  extension: boolean;
  platformYear: number;
  signingAge: number;
  capHitPercent: number;
  ufaYears: number;
  rfaYears: number;
  totalBonusPct: number;
  totalPerformanceBonusPct: number;
  totalSigningBonusPct: number;
  totalBaseSalaryPct: number;
  [key: string]: number | string | Record<string, unknown> | PlayerSearchStats | null | Date | boolean | TeamInfo;
}

export interface PlayerXGStats {
  expectedGoalsFor: number;
  actualToExpectGoalsFor: number;
  actualFenwicksFor: number;
  expectedGoalsAgainst: number;
  actualToExpectedGoalsForAgainst: number;
  actualFenwicksForAgainst: number;
  expectedGoalsShooter: number;
  actualToExpectedGoalsShooter: number;
  actualFenwicksShooter: number;
  expectedGoalsPct: number;
  [key: string]: number;
}

export interface PlayerSearchTable {
  ufaYears: number;
  rfaYears: number;
  totalBonusPct: number;
  totalPerformanceBonusPct: number;
  totalSigningBonusPct: number;
  totalBaseSalaryPct: number;
  cyStatus: string;
  age: number;
  currentTeamId: number;
  currentTeam: TeamInfo;
  signingTeam: TeamInfo;
  averageAnnualValue: number;
  baseSalary: number;
  capHit: number;
  contractDetailId: number;
  contractExpirationSeasonId: string;
  contractLength: number;
  contractType: ContractType;
  expiryStatus: ContractExpiryStatus;
  minorsSalary: number;
  performanceBonus: number;
  scenarioId: number | null;
  signingBonus: number;
  signingDate: Date;
  totalValue: number;
  startingSeason: number;
  contractTerm: number;
  extension: boolean;
  platformYear: number;
  signingAge: number;
  capHitPercent: number;
  averageTimeOnIceEvenStrength: string;
  averageTimeOnIcePowerPlay: string;
  averageTimeOnIceShifts: string;
  averageTimeOnIceShorthanded: string;
  averageTimeOnIceTotal: string;
  assists: number;
  assistsEvenStrength: number;
  assistsPerGame: number;
  assistsPerGameEvenStrength: number;
  assistsPerGamePowerplay: number;
  assistsPerGameShorthanded: number;
  assistsPowerplay: number;
  assistsShorthanded: number;
  blockedAtt: number;
  blockedShots: number;
  faceoffs: number;
  faceoffsEvenStrength: number;
  faceoffsLost: number;
  faceoffsLostEvenStrength: number;
  faceoffsLostPowerplay: number;
  faceoffsLostShorthanded: number;
  faceoffsPowerplay: number;
  faceoffsShorthanded: number;
  faceoffsWinPct: number;
  faceoffsWinPctEvenStrength: number;
  faceoffsWinPctPowerplay: number;
  faceoffsWinPctShorthanded: number;
  faceoffsWon: number;
  faceoffsWonEvenStrength: number;
  faceoffsWonPowerplay: number;
  faceoffsWonShorthanded: number;
  gamesPlayed: number;
  giveaways: number;
  goals: number;
  goalsEvenStrength: number;
  goalsPerGame: number;
  goalsPerGameEvenStrength: number;
  goalsPerGamePowerplay: number;
  goalsPerGameShortHanded: number;
  goalsPowerplay: number;
  goalsShorthanded: number;
  goalsAgainstAverage: number;
  hits: number;
  missedShots: number;
  missedShotsEvenStrength: number;
  missedShotsPowerplay: number;
  missedShotsShorthanded: number;
  penalties: number;
  penaltyGoals: number;
  penaltyInfractionMinutes: number;
  penaltyMinutes: number;
  penaltyMissedShots: number;
  penaltyShots: number;
  plusMinus: number;
  plusMinusSR: number;
  points: number;
  pointsPerGame: number;
  pointsSR: number;
  pointsSREvenStrength: number;
  pointsSRPerGameEvenStrength: number;
  pointsSRPerGamePowerPlay: number;
  pointsSRPerGameShorthanded: number;
  pointsSRPowerPlay: number;
  pointsSRShorthanded: number;
  powerPlayGoals: number;
  savePercentage: number;
  shootingPct: number;
  shootoutGoals: number;
  shootoutMissedShots: number;
  shootoutShots: number;
  shots: number;
  shotsEvenStrength: number;
  shotsPowerplay: number;
  shotsShorthanded: number;
  starterGames: number;
  takeaways: number;
  timeOnIceAvg: string | null;
  timeOnIceEvenStrength: string | null;
  timeOnIcePowerPlay: string | null;
  timeOnIceShifts: string | null;
  timeOnIceShorthanded: string | null;
  timeOnIceTotal: string | null;
  playerId: number;
  firstName: string;
  lastName: string;
  primaryPosition: PlayerPosition;
  shotHand: PlayerShotHand;
  arbRights: boolean;
  qoValue: number | null;
  contractArbRights: boolean;
  signingStatus: ContractExpiryStatus;
  expectedGoalsFor: number;
  actualToExpectGoalsFor: number;
  actualFenwicksFor: number;
  expectedGoalsAgainst: number;
  actualToExpectedGoalsForAgainst: number;
  actualFenwicksForAgainst: number;
  expectedGoalsShooter: number;
  actualToExpectedGoalsShooter: number;
  actualFenwicksShooter: number;
  expectedGoalsPct: number;
  [key: string]: number | string | null | Date | boolean | TeamInfo | ContractExpiryStatus | ComparableSeasonStatLine;
}
