/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { Box, Paper, styled, useTheme } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import PlayerBioPanel from 'modules/players/playerBio/playerBioPanel.component';
import PlayerComparableContracts from 'modules/players/playerComparableContracts/playerComparableContracts.component';
import PlayerComparableTrades from 'modules/players/playerComparableTrades/playerComparableTrades.component';
import PlayerContracts from 'modules/players/playerContracts/playerContracts.component';
import PlayerDraftStatusPanel from 'modules/players/playerDraftStatus/playerDraftStatusPanel.component';
import PlayerHeader from 'modules/players/playerHeader.component';
import PlayerStatsTable from 'modules/players/playerStatsTable.component';
import PlayerTransactionSummaryPanel from 'modules/players/playerTransactionSummary/playerTransactionSummaryPanel.component';
import usePrevious from 'hooks/usePrevious';
import useQueryParams from 'hooks/useQueryParams';
import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import {
  GA_ACTIONS,
  GA_CATEGORIES,
  PLAYER_VIEW_ACTIONS_MAP,
  REPORTING_PLAYER_ANALYTICS_ACTIONS_MAP,
  logGAEvent,
} from 'utilities/analytics';
import { PLAYER_SUB_ROUTES, ROUTES } from 'utilities/routes';
import { PLAYER_VIEW_OPTION_VALUES, getPlayerFullname } from 'utilities/player';
import { PlayerComparableContract } from 'types/comparableContracts';
import { PlayerPosition } from 'types/player';
import {
  getPlayer,
  getPlayerComparableContractSolo,
  getPlayerComparableContracts,
  getPlayerComparableContractsSubject,
  getPlayerComparableTrades,
  getPlayerContractExpiration,
  getPlayerContracts,
  getPlayerStats,
  resetPlayer,
  setPlayerContractComparables,
} from 'modules/players/players.slice';
import { getTeams } from 'modules/teams/teams.slice';
import { selectIsPrinting } from 'modules/layout/layout.selectors';
import {
  selectPlayer,
  selectPlayerContractExp,
  selectPlayerContracts,
  selectPlayerStats,
  selectPlayersIsLoading,
} from 'modules/players/players.selectors';
import { selectPlayerCompSets } from 'modules/user/user.selectors';
import { selectScenario } from 'modules/scenarios/scenarios.selectors';
import { useAppDispatch } from 'store';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
  paddingBottom: 0,
}));

const StyledBioArea = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{ isSmallScreen: boolean }>(({ isSmallScreen, theme }) => {
  const styles = {
    marginTop: 0,
    paddingBottom: theme.spacing(0.5),
  };

  if (isSmallScreen) return styles;

  return {
    ...styles,
    height: '100%',
    overflowY: 'auto',
    paddingBottom: 0,
  };
});

const StyledPlayerArea = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{ isSmallScreen: boolean }>(({ isSmallScreen, theme }) => {
  const styles = { paddingBottom: theme.spacing(1) };

  if (isSmallScreen) return styles;

  return {
    ...styles,
    height: '100%',
    overflowY: 'auto',
    paddingBottom: 0,
  };
});

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isPrinting' && prop !== 'isSmallScreen',
})<{ isPrinting: boolean; isSmallScreen: boolean }>(({ isPrinting, isSmallScreen, theme }) => {
  const styles = {
    display: 'flex',
    padding: theme.spacing(2),
  };

  if (isPrinting || isSmallScreen) return styles;

  return {
    ...styles,
    height: 'calc(100% - 2.25rem)',
    overflowY: 'hidden',
  };
});

const StyledBioPanelItem = styled(Item)`
  padding-top: 0 !important;
`;

interface PlayerParamTypes {
  playerId: string;
  [key: string]: string;
}

function Player(): JSX.Element {
  const playerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const theme = useTheme();
  const width = useWidth();

  const { playerId } = useParams<PlayerParamTypes>();

  const prevPlayerId = usePrevious(playerId) as string;

  const isLoading = useSelector(selectPlayersIsLoading);
  const player = useSelector(selectPlayer);
  const playerContracts = useSelector(selectPlayerContracts);
  const playerStats = useSelector(selectPlayerStats);
  const playerContractExpiration = useSelector(selectPlayerContractExp);
  const scenario = useSelector(selectScenario);
  const isPrinting = useSelector(selectIsPrinting);
  const playerCompSets = useSelector(selectPlayerCompSets);

  const [selectedTabIndex, setSelectedTabIndex] = useState(PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS);
  const [hasInitialTabIndex, setHasInitialTabIndex] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    if (playerId) {
      dispatch(getPlayer(playerId));
      dispatch(getTeams());
      dispatch(getPlayerContracts(playerId));
      dispatch(getPlayerStats({ playerId }));
      dispatch(getPlayerContractExpiration(playerId));
      dispatch(getPlayerComparableTrades(playerId));
      dispatch(getPlayerComparableContractsSubject(playerId));
    }

    return () => {
      dispatch(resetPlayer());

      setHasRequested(false);
    };
  }, [dispatch, playerId, scenario]);

  const requestIndividualComps = useCallback(
    async (playerIds: string[]) => {
      const fullCompSet: PlayerComparableContract[] = await Promise.all(
        playerIds.map(async (id) => {
          const response = await dispatch(getPlayerComparableContractSolo(id));

          return response.payload as PlayerComparableContract;
        }),
      );

      dispatch(setPlayerContractComparables(fullCompSet.filter((compSet) => compSet !== undefined)));
    },
    [dispatch],
  );

  useEffect(() => {
    if (playerId && !hasRequested) {
      setHasRequested(true);

      const playerCompSet = playerCompSets && playerCompSets[playerId];
      const queryPlayerIds = queryParams.get('playerIds');

      if (queryPlayerIds) {
        requestIndividualComps(
          queryPlayerIds.split(',').filter((queryPlayerId) => Number.isInteger(Number(queryPlayerId))),
        );
        return;
      }

      if (playerCompSet) {
        const playerIds =
          typeof playerCompSet === 'string'
            ? playerCompSet.split(',').filter((localPlayerId) => Number.isInteger(Number(localPlayerId)))
            : playerCompSet;

        requestIndividualComps(playerIds);
        return;
      }

      dispatch(getPlayerComparableContracts(playerId));
    }
  }, [dispatch, playerId, hasRequested, playerCompSets, queryParams, requestIndividualComps]);

  useEffect(() => {
    if (playerId !== prevPlayerId) setSelectedTabIndex(PLAYER_VIEW_OPTION_VALUES.PLAYER_CONTRACTS);
  }, [playerId, prevPlayerId]);

  useEffect(() => {
    if (player) {
      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: GA_ACTIONS.PLAYER_PROFILE_VIEW_PLAYER,
        label: getPlayerFullname(player),
        value: player.playerId,
      });
    }
  }, [player]);

  const playerSubRoutes = useMemo(() => {
    const subRoutes = { ...PLAYER_SUB_ROUTES };

    if (player && player.primaryPosition === PlayerPosition.Goalie) delete subRoutes.PLAYER_CONTRACT_COMPARABLES;

    return subRoutes;
  }, [player]);

  useEffect(() => {
    if (hasInitialTabIndex || !player) return;

    const subRoutes = { ...PLAYER_SUB_ROUTES };
    if (player && player.primaryPosition === PlayerPosition.Goalie) delete subRoutes.PLAYER_CONTRACT_COMPARABLES;

    setHasInitialTabIndex(true);

    const subRouteIndex = Object.values(subRoutes).findIndex((subRoute) => location.pathname.includes(subRoute));

    if (subRouteIndex < 0) {
      setSelectedTabIndex(0);
    } else {
      setSelectedTabIndex(subRouteIndex + 1);
    }
  }, [location, hasInitialTabIndex, player]);

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS || width === BREAK_POINTS.SM, [width]);

  const handleAfterPrint = useCallback(() => {
    logGAEvent({
      category: GA_CATEGORIES.PLAYER_PROFILE,
      action: REPORTING_PLAYER_ANALYTICS_ACTIONS_MAP[selectedTabIndex],
      label: player ? getPlayerFullname(player) : '',
      value: player ? player.playerId : 0,
    });
  }, [selectedTabIndex, player]);

  const handleSelectView = useCallback(
    (tabIndex: number) => {
      setSelectedTabIndex(tabIndex);

      if (!player) return;

      const playerRouteBase = `${ROUTES.PLAYERS}/${player.playerId}`;

      if (tabIndex === 0) {
        navigate(playerRouteBase);
        return;
      }
      navigate(`${playerRouteBase}${Object.values(playerSubRoutes)[tabIndex - 1]}`);

      logGAEvent({
        category: GA_CATEGORIES.PLAYER_PROFILE,
        action: PLAYER_VIEW_ACTIONS_MAP[tabIndex],
        label: player ? getPlayerFullname(player) : '',
        value: player ? player.playerId : 0,
      });
    },
    [navigate, player, playerSubRoutes],
  );

  if (isLoading || isNil(player) || isNil(playerContracts) || isNil(playerStats)) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer direction={isSmallScreen ? 'column' : 'row'} spacing={1} wrap={isSmallScreen ? 'nowrap' : 'wrap'}>
      <StyledBioArea
        alignContent="flex-start"
        isSmallScreen={isSmallScreen}
        item
        sm={isSmallScreen ? false : 12}
        md={isSmallScreen ? false : 3}
        xl={isSmallScreen ? false : 2}
        spacing={1}
      >
        <StyledBioPanelItem xs={12} sm={6} md={12} zeroMinWidth>
          <PlayerBioPanel player={player} />
        </StyledBioPanelItem>
        {player.draftInfo && (
          <Item xs={12} sm={6} md={12} zeroMinWidth>
            <PlayerDraftStatusPanel playerDraftStatus={player.draftInfo} />
          </Item>
        )}
        <Item xs={12} sm={6} md={12} zeroMinWidth>
          <PlayerTransactionSummaryPanel player={player} playerContractExpiration={playerContractExpiration} />
        </Item>
      </StyledBioArea>
      <StyledPlayerArea
        isSmallScreen={isSmallScreen}
        sm={isSmallScreen ? false : 12}
        md={isSmallScreen ? false : 9}
        xl={isSmallScreen ? false : 10}
      >
        <StyledPaper
          ref={playerRef}
          elevation={3}
          isPrinting={isPrinting}
          isSmallScreen={isSmallScreen}
          sx={{ flexDirection: 'column' }}
        >
          <PlayerHeader
            player={player}
            playerRef={playerRef}
            selectedTabIndex={selectedTabIndex}
            onAfterPrint={handleAfterPrint}
            onSelectViewOption={handleSelectView}
          />
          <Box flex="1" marginTop={theme.spacing(2)} overflow={!isPrinting && !isSmallScreen ? 'auto' : 'hidden'}>
            <Routes>
              <Route element={<PlayerContracts contracts={playerContracts} />} path="/" />
              <Route
                element={
                  <PlayerStatsTable isGoalie={player.primaryPosition === PlayerPosition.Goalie} stats={playerStats} />
                }
                path={PLAYER_SUB_ROUTES.PLAYER_REGULAR_SEASON_STATS}
              />
              <Route
                element={
                  <PlayerStatsTable
                    isGoalie={player.primaryPosition === PlayerPosition.Goalie}
                    showPlayoffs
                    stats={playerStats}
                  />
                }
                path={PLAYER_SUB_ROUTES.PLAYER_PLAYOFF_STATS}
              />
              {player.primaryPosition !== PlayerPosition.Goalie && (
                <Route element={<PlayerComparableContracts />} path={PLAYER_SUB_ROUTES.PLAYER_CONTRACT_COMPARABLES} />
              )}
              <Route element={<PlayerComparableTrades />} path={PLAYER_SUB_ROUTES.PLAYER_TRADE_COMPARABLES} />
              <Route path="*" element={<Navigate to={`${ROUTES.PLAYERS}/${playerId || 1}`} />} />
            </Routes>
          </Box>
        </StyledPaper>
      </StyledPlayerArea>
    </StyledContainer>
  );
}

export default Player;
