/* eslint-disable max-len */
import { PageActionItem } from '@project-stanley/cap-management-components';
import { Send } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsScenarioMode } from 'modules/scenarios/scenarios.selectors';

interface UseShareMenuItemArgs {
  disableOnScenario?: boolean;
  tooltip?: string;
  onClick: () => void;
}

const useShareMenuItem = ({ disableOnScenario = true, tooltip, onClick }: UseShareMenuItemArgs) => {
  const isScenarioMode = useSelector(selectIsScenarioMode);

  const [menuItem, setMenuItem] = useState<PageActionItem>({ text: 'Share' });

  useEffect(() => {
    setMenuItem({
      disabled: disableOnScenario && isScenarioMode,
      tooltip:
        disableOnScenario && isScenarioMode
          ? tooltip ||
            "This feature is not available in scenario mode. Please contact an administrator if sharing a scenario is a feature you'd like."
          : undefined,
      text: (
        <>
          <Send color="secondary" />
          <Typography sx={{ marginLeft: 2 }} variant="body1">
            Share
          </Typography>
        </>
      ),
      onClick,
    });
  }, [disableOnScenario, isScenarioMode, tooltip, onClick]);

  return menuItem;
};

export default useShareMenuItem;
