/* eslint-disable react/no-unstable-nested-components */
import { MTableBody } from '@material-table/core';
import { StanleyTable } from '@project-stanley/cap-management-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RosterContractColumn from 'modules/teams/rosterView/rosterContractColumn.component';
import RosterOutlookTableSummary from 'modules/teams/rosterView/rosterOutlookTableSummary.component';
import RosterPositionColumn from 'modules/teams/rosterView/rosterPositionColumn.component';
import RosterTermColumn from 'modules/teams/rosterView/rosterTermColumn.component';
import { CONTRACT_STATUS_MAP } from 'utilities/contract';
import { Player } from 'types/player';
import { ROUTES } from 'utilities/routes';
import { TableRowClick } from 'types/table';
import { formatYearRange } from 'utilities/date';
import { mapFutureRoster } from 'utilities/roster';
import { rosterContractFilterSort, rosterContractTermsSort } from 'utilities/table';
import { selectSeasonStartYear } from 'modules/season/season.selectors';
import { selectTeamRosterStatFilter } from 'modules/teams/teams.selectors';

interface RosterOutlookTableProps {
  roster: Player[] | null;
  showTerms: boolean;
}

function RosterOutlookTable({ roster, showTerms }: RosterOutlookTableProps): JSX.Element {
  const navigate = useNavigate();

  const curSeasonStartYear = useSelector(selectSeasonStartYear);
  const rosterStatFilter = useSelector(selectTeamRosterStatFilter);

  const [mappedRoster, setMappedRoster] = useState<Player[]>([]);

  useEffect(() => {
    if (roster && curSeasonStartYear) setMappedRoster(mapFutureRoster(roster, curSeasonStartYear));
  }, [curSeasonStartYear, roster]);

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const columns = [
      {
        title: 'Players',
        cellStyle: { minWidth: 200 },
        hasSortCellStyle: false,
        customSort: (rowA: Player, rowB: Player) =>
          `${rowA.lastName}, ${rowA.firstName}`.localeCompare(`${rowB.lastName}, ${rowB.firstName}`),
        render: ({ firstName, lastName }: Player) => `${lastName}, ${firstName}`,
      },
      {
        title: 'Position',
        cellStyle: { minWidth: 175 },
        sorting: false,
        render: (rowData: Player) => <RosterPositionColumn player={rowData} />,
      },
      {
        title: 'Status',
        field: 'status',
        cellStyle: { minWidth: 75 },
        hasSortCellStyle: false,
        render: ({ status }: Player) => CONTRACT_STATUS_MAP[status],
      },
      {
        title: 'Age',
        cellStyle: { minWidth: 75 },
        field: 'age',
      },
    ];

    if (!curSeasonStartYear) return columns;

    return !showTerms
      ? [
          ...columns,
          {
            title: formatYearRange(curSeasonStartYear),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(0, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={0} />
            ),
          },
          {
            title: formatYearRange(curSeasonStartYear, 1),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(1, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={1} />
            ),
          },
          {
            title: formatYearRange(curSeasonStartYear, 2),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(2, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={2} />
            ),
          },
          {
            title: formatYearRange(curSeasonStartYear, 3),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(3, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={3} />
            ),
          },
          {
            title: formatYearRange(curSeasonStartYear, 4),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(4, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={4} />
            ),
          },
          {
            title: formatYearRange(curSeasonStartYear, 5),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractFilterSort(5, rosterStatFilter),
            render: (rowData: Player) => (
              <RosterContractColumn rosterStatFilter={rosterStatFilter} player={rowData} contractIndex={5} />
            ),
          },
        ]
      : [
          ...columns,
          {
            title: formatYearRange(curSeasonStartYear),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(0),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={0} />,
          },
          {
            title: formatYearRange(curSeasonStartYear, 1),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(1),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={1} />,
          },
          {
            title: formatYearRange(curSeasonStartYear, 2),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(2),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={2} />,
          },
          {
            title: formatYearRange(curSeasonStartYear, 3),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(3),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={3} />,
          },
          {
            title: formatYearRange(curSeasonStartYear, 4),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(4),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={4} />,
          },
          {
            title: formatYearRange(curSeasonStartYear, 5),
            cellStyle: { minWidth: 100 },
            customSort: rosterContractTermsSort(5),
            render: (rowData: Player) => <RosterTermColumn player={rowData} contractIndex={5} />,
          },
        ];
  }, [curSeasonStartYear, rosterStatFilter, showTerms]);

  const handleRowClick = useCallback(
    (event?: TableRowClick, rowData?: Player) => {
      if (rowData && rowData.playerId) {
        navigate(`${ROUTES.PLAYERS}/${rowData.playerId}`);
      }
    },
    [navigate],
  );

  return (
    <StanleyTable
      columns={columns}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            <RosterOutlookTableSummary players={mappedRoster} rosterStatFilter={rosterStatFilter} />
          </>
        ),
      }}
      data={mappedRoster}
      onRowClick={handleRowClick}
    />
  );
}

export default RosterOutlookTable;
