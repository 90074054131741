/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unsafe-optional-chaining */
import MaterialTable from '@material-table/core';
import makeStyles from '@mui/styles/makeStyles';
import { Box, IconButton, SelectChangeEvent, Tooltip, Typography, useTheme } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import {
  Container,
  Item,
  StanleyConfirmDialog,
  StanleyHoverSelect,
  StanleySelect,
  StanleyTransparentButton,
} from '@project-stanley/cap-management-components';
import { FirestoreError } from 'firebase/firestore';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import ComparableContractDisplayFieldItem from 'modules/players/playerComparableContracts/comparableContractDisplayFieldItem.component';
import ComparableContractsDialog, {
  CompSetActionTypes,
} from 'modules/players/playerComparableContracts/comparableContractsDialog.component';
import StanleyPlayerSearch, { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import {
  CONTRACT_DATA_FIELD_OPTIONS,
  PLATER_EV_STAT_FIELD_OPTIONS,
  PLAYER_DATA_FIELD_OPTIONS,
  PLAYER_PP_STAT_FIELD_OPTIONS,
  PLAYER_SH_STAT_FIELD_OPTIONS,
  PLAYER_TOTAL_STAT_FIELD_OPTIONS,
} from 'constants/comparableContracts';
import { PlayerComparableContract } from 'types/comparableContracts';
import { deleteFirestoreData } from 'utilities/firebase';
import { selectComparableContractsLists } from 'modules/players/playerComparableContracts/comparableContracts.selectors';
import { setCompLists } from 'modules/players/playerComparableContracts/comparableContracts.slice';
import { useAppDispatch } from 'store';

interface ComparableContractTableToolbarProps {
  tableRef: MutableRefObject<MaterialTable<any> | undefined>;
  dataFields: (number | string)[];
  isLoading: boolean;
  selectedCompSet: string;
  selectedRows: (PlayerComparableContract & { id: number })[];
  selectedSeason: string;
  seasonStatFilters: { label: string; value: string }[];
  onDataFieldsChange: (dataFields: number[]) => void;
  onOptionSelected: (selectedOption: StanleyPlayerSearchOption | null) => void;
  onRemoveContracts: (selectedContracts: (PlayerComparableContract & { id: number })[]) => void;
  onSaveCompSet: (value: string, actionType: CompSetActionTypes) => void;
  onSeasonChange: (value: string) => void;
  onSelectCompSet: (value: string) => void;
}

function ComparableContractTableToolbar({
  tableRef,
  dataFields,
  isLoading,
  selectedCompSet,
  selectedRows,
  selectedSeason,
  seasonStatFilters,
  onDataFieldsChange,
  onOptionSelected,
  onRemoveContracts,
  onSaveCompSet,
  onSeasonChange,
  onSelectCompSet,
}: ComparableContractTableToolbarProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const compLists = useSelector(selectComparableContractsLists);

  const [contractInfoOpen, setContractInfoOpen] = useState(true);
  const [playerInfoOpen, setPlayerInfoOpen] = useState(true);
  const [totalInfoOpen, setTotalInfoOpen] = useState(true);
  const [evInfoOpen, setEvtInfoOpen] = useState(true);
  const [ppInfoOpen, setPpInfoOpen] = useState(true);
  const [shInfoOpen, setShInfoOpen] = useState(true);
  const [tempDataFields, setTempDataFields] = useState(dataFields);
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [isSavingCompSet, setIsSavingCompSet] = useState(false);
  const [listToDelete, setListToDelete] = useState<string | null>(null);

  useEffect(() => {
    setTempDataFields(dataFields);
  }, [dataFields]);

  const handleDataFieldsChange = useCallback(
    (selectedValue: string | number) => {
      const selectedValues = [...tempDataFields];
      const index = tempDataFields.findIndex((curValue) => curValue === selectedValue);

      if (index !== -1) {
        selectedValues?.splice(index, 1);
      } else {
        selectedValues.push(selectedValue);
      }

      setTempDataFields(selectedValues);
    },
    [tempDataFields],
  );

  const renderedDisplayFieldOptions = useMemo(
    () => (
      <Container sx={{ width: '100vw', maxWidth: '40rem' }}>
        <Item xs={12} sm={4}>
          <ComparableContractDisplayFieldItem
            dataFields={tempDataFields}
            open={contractInfoOpen}
            options={CONTRACT_DATA_FIELD_OPTIONS}
            title="Contract Information"
            onChange={handleDataFieldsChange}
            onToggle={() => setContractInfoOpen((open) => !open)}
          />
          <Box marginTop="1rem">
            <ComparableContractDisplayFieldItem
              dataFields={tempDataFields}
              open={ppInfoOpen}
              options={PLAYER_PP_STAT_FIELD_OPTIONS}
              title="Power Play(PP)"
              onChange={handleDataFieldsChange}
              onToggle={() => setPpInfoOpen((open) => !open)}
            />
          </Box>
        </Item>
        <Item xs={12} sm={4}>
          <ComparableContractDisplayFieldItem
            dataFields={tempDataFields}
            open={playerInfoOpen}
            options={PLAYER_DATA_FIELD_OPTIONS}
            title="Player Information"
            onChange={handleDataFieldsChange}
            onToggle={() => setPlayerInfoOpen((open) => !open)}
          />
          <Box marginTop="1rem">
            <ComparableContractDisplayFieldItem
              dataFields={tempDataFields}
              open={totalInfoOpen}
              options={PLAYER_TOTAL_STAT_FIELD_OPTIONS}
              title="Total"
              onChange={handleDataFieldsChange}
              onToggle={() => setTotalInfoOpen((open) => !open)}
            />
          </Box>
        </Item>
        <Item xs={12} sm={4}>
          <ComparableContractDisplayFieldItem
            dataFields={tempDataFields}
            open={evInfoOpen}
            options={PLATER_EV_STAT_FIELD_OPTIONS}
            title="Even Strength(EV)"
            onChange={handleDataFieldsChange}
            onToggle={() => setEvtInfoOpen((open) => !open)}
          />
          <Box marginTop="1rem">
            <ComparableContractDisplayFieldItem
              dataFields={tempDataFields}
              open={shInfoOpen}
              options={PLAYER_SH_STAT_FIELD_OPTIONS}
              title="Short Handed(SH)"
              onChange={handleDataFieldsChange}
              onToggle={() => setShInfoOpen((open) => !open)}
            />
          </Box>
        </Item>
      </Container>
    ),
    [
      contractInfoOpen,
      evInfoOpen,
      playerInfoOpen,
      ppInfoOpen,
      shInfoOpen,
      tempDataFields,
      totalInfoOpen,
      handleDataFieldsChange,
    ],
  );

  const toggleSaveAs = useCallback(() => setSaveAsOpen(!saveAsOpen), [saveAsOpen]);

  const handleSaveCompSet = useCallback(
    (value: string, actionType: CompSetActionTypes) => {
      setIsSavingCompSet(true);

      onSaveCompSet(value, actionType);

      setIsSavingCompSet(false);
      toggleSaveAs();
    },
    [onSaveCompSet, toggleSaveAs],
  );

  const handleDeleteSavedCompSet = useCallback(async () => {
    if (listToDelete) {
      setIsSavingCompSet(true);

      try {
        await deleteFirestoreData({ path: `compLists/${listToDelete}` });

        const newCompLists = compLists.filter((item) => item.id !== listToDelete);

        dispatch(setCompLists(newCompLists));
      } catch (error) {
        console.log('FIRESTORE ERROR: ', error as FirestoreError);
      }

      setListToDelete(null);
      setIsSavingCompSet(false);
    }
  }, [dispatch, compLists, listToDelete]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={theme.spacing(2)}>
        <Box display="flex" alignItems="center">
          {selectedRows.length > 0 && (
            <Tooltip title={`Remove ${selectedRows.length} contract(s)`}>
              <IconButton
                color="primary"
                className={classes.removeButton}
                onClick={() => {
                  onRemoveContracts(selectedRows as (PlayerComparableContract & { id: number })[]);

                  const { currentPage, pageSize } = (tableRef?.current as any)?.state;

                  selectedRows.forEach((row: PlayerComparableContract & { id: number }) => {
                    (tableRef?.current as any)?.onRowSelected(
                      { target: { clicked: false } },
                      [row.id + pageSize * currentPage],
                      row,
                    );
                  });
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <StanleyPlayerSearch
            TextFieldInputProps={{ classes: { root: classes.playerSearchRoot } }}
            disabled={isLoading}
            onOptionSelected={onOptionSelected}
          />
          <Box marginLeft={theme.spacing(2)}>
            <StanleyHoverSelect
              changeOnClose
              emptyDisabled
              emptyText="Display Fields"
              menuFieldWidth={false}
              options={[]}
              selectClasses={{
                select: classes.displayFieldsSelect,
              }}
              selectProps={{
                displayEmpty: true,
                fullWidth: true,
                multiple: true,
                renderValue: () => 'Display Fields',
              }}
              TextFieldInputProps={{
                disabled: isLoading,
              }}
              value={tempDataFields}
              onChange={(newDataFields) => onDataFieldsChange(newDataFields as number[])}
            >
              {renderedDisplayFieldOptions}
            </StanleyHoverSelect>
          </Box>
          <Box marginLeft={theme.spacing(2)}>
            <StanleyHoverSelect
              options={seasonStatFilters}
              selectClasses={{ select: classes.select }}
              TextFieldInputProps={{
                disabled: isLoading,
              }}
              value={selectedSeason}
              onChange={(value) => onSeasonChange(value as string)}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <StanleyTransparentButton
            disabled={isLoading}
            sx={{ marginRight: 1, textTransform: 'none', whiteSpace: 'nowrap' }}
            onClick={toggleSaveAs}
          >
            Save As
          </StanleyTransparentButton>
          {compLists.length > 0 && (
            <StanleySelect
              disabled={isLoading}
              displayEmpty
              emptyText="Select Existing List"
              fullWidth
              options={[
                {
                  label: 'Default',
                  value: 'default',
                },
                ...compLists.map((compListItem) => ({
                  label: compListItem.name,
                  value: compListItem.id,
                })),
              ]}
              renderOption={(option) => (
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1">{option.label}</Typography>
                  {option.value !== 'default' && option.value !== selectedCompSet && (
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();

                        setListToDelete(option.value as string);
                      }}
                    >
                      <Close color="primary" />
                    </IconButton>
                  )}
                </Box>
              )}
              renderValue={(value) => {
                if (!value) return 'Select Existing List';
                if (value === 'default') return <Typography variant="body1">Default</Typography>;

                const selectedCompList = compLists.find((item) => item.id === value);

                return <Typography variant="body1">{selectedCompList?.name}</Typography>;
              }}
              selectClasses={{
                select: classes.selectList,
              }}
              value={selectedCompSet}
              onChange={({ target }: SelectChangeEvent<unknown>) => onSelectCompSet(target.value as string)}
            />
          )}
        </Box>
      </Box>
      <ComparableContractsDialog
        isLoading={isSavingCompSet}
        open={saveAsOpen}
        onClose={toggleSaveAs}
        onSubmit={handleSaveCompSet}
      />
      <StanleyConfirmDialog
        cancelText="Cancel"
        confirmText="Delete"
        contentText="Are you sure you want to delete this list?"
        isLoading={isSavingCompSet}
        loadingText="Deleting..."
        open={!isNil(listToDelete)}
        title="Delete List?"
        onClose={() => setListToDelete(null)}
        onConfirm={handleDeleteSavedCompSet}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  removeButton: {
    padding: theme.spacing(0.75),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  removeIcon: {
    color: theme.palette.error.main,
  },
  select: {
    width: '8rem',
  },
  selectList: {
    width: '10rem',
  },
  playerSearchRoot: {
    paddingBottom: `${theme.spacing(0.875)} !important`,
    paddingTop: `${theme.spacing(0.875)} !important`,
    width: '14rem',
  },
  displayFieldsSelect: {
    width: '10rem',
  },
}));

export default ComparableContractTableToolbar;
