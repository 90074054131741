import { ContractExpiryStatus, ContractType } from 'types/contract';
import { Filters } from 'types/playerSearch';
import { FirebaseUser, SavedCompSet, SavedDepthChart } from 'types/user';
import { PlayerPosition } from 'types/player';

export type FirebaseData =
  | FirebaseUser
  | SavedCompSet
  | SavedDepthChart
  | Filters
  | {
      [key: string]:
        | (string | number)[]
        | PlayerPosition[]
        | ContractType
        | ContractExpiryStatus
        | number
        | string
        | Record<string, unknown>
        | null
        | undefined;
    };

export enum FirebaseWriteType {
  ADD = 'ADD',
  SET = 'SET',
}
