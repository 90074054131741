import { Container } from '@project-stanley/cap-management-components';
import { Paper, styled } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import ScenariosTable from 'modules/scenarios/scenariosTable.component';
import { getScenarios } from 'modules/scenarios/scenarios.slice';
import { selectScenarios, selectScenariosIsLoading } from 'modules/scenarios/scenarios.selectors';
import { useAppDispatch } from 'store';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  height: `calc(100% - ${theme.spacing(6)})`,
  overflowY: 'auto',
  width: '100%',
}));

function Scenarios(): JSX.Element {
  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectScenariosIsLoading);
  const scenarios = useSelector(selectScenarios);

  useEffect(() => {
    dispatch(getScenarios());
  }, [dispatch]);

  if (isLoading) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    );
  }

  return (
    <StyledPaper elevation={3}>
      <ScenariosTable scenarios={scenarios} />
    </StyledPaper>
  );
}

export default Scenarios;
