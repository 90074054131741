import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Container, Item, StanleyDollar, StanleySVGIcon } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useWidth, { BREAK_POINTS } from 'hooks/useWidth';
import { ICONS } from 'utilities/icons';
import { PlayerContract } from 'types/contract';
import { ROUTES } from 'utilities/routes';

interface PlayerContractSummaryProps {
  contract: PlayerContract;
}

function Divider(): JSX.Element {
  const classes = useStyles();

  return (
    <Typography className={classes.divider} variant="subtitle1">
      |
    </Typography>
  );
}

function PlayerContractSummary({ contract }: PlayerContractSummaryProps): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const width = useWidth();

  const isSmallScreen = useMemo(() => width === BREAK_POINTS.XS || width === BREAK_POINTS.SM, [width]);

  return (
    <Container>
      <Item sm={12} md={8}>
        <Box display="flex" alignItems="center" marginBottom={theme.spacing(isSmallScreen ? 0 : 2)} flexWrap="wrap">
          <Typography className={classes.detail} variant="caption">
            Value:&nbsp;
            <span>
              <StanleyDollar amount={contract.totalValue} />
            </span>
          </Typography>
          <Divider />
          <Typography className={classes.detail} variant="caption">
            Length:&nbsp;
            {contract.contractLength}
            &nbsp;
            {`year${contract.contractLength > 1 ? 's' : ''}`}
          </Typography>
          <Divider />
          <Typography className={classes.detail} variant="caption">
            Signing&nbsp;Date:&nbsp;
            {format(new Date(contract.signingDate), 'MMM dd, yyyy')}
          </Typography>
          {contract.signingTeam && (
            <>
              <Divider />
              <Typography className={classNames(classes.detail, classes.team)} variant="caption">
                Team:&nbsp;
                <span className={classes.teamLogoWrapper}>
                  {contract.signingTeam.abrvName}
                  <IconButton
                    classes={{ root: classes.logoButtonRoot }}
                    onClick={() => navigate(`${ROUTES.TEAMS}/${contract.signingTeam.teamId}`)}
                    size="large"
                  >
                    <StanleySVGIcon height="1.5rem" imageSrc={ICONS[contract.signingTeam.abrvName]} width="1.5rem" />
                  </IconButton>
                </span>
              </Typography>
            </>
          )}
        </Box>
      </Item>
      <Item sm={12} md={4} sx={{ justifyContent: isSmallScreen ? 'flex-start' : 'flex-end', display: 'flex' }}>
        <Box display="flex" alignItems="center" marginBottom={theme.spacing(2)} flexWrap="wrap">
          <Typography className={classes.detail} variant="caption">
            Qualifying Offer:&nbsp;
            {contract.qoValue ? `$${contract.qoValue.toLocaleString()}` : '-'}
          </Typography>
          <Divider />
          <Typography
            className={classes.detail}
            variant="caption"
            sx={{ display: 'flex', alignItems: 'center', lineHeight: '1.25' }}
          >
            Arb Rights:&nbsp;
          </Typography>
          {contract.arbRights ? (
            <Check color="primary" fontSize="small" />
          ) : (
            <Typography className={classes.detail} variant="caption">
              NO
            </Typography>
          )}
        </Box>
      </Item>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  detail: {
    fontWeight: 300,
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  team: {
    alignItems: 'center',
    display: 'flex',
  },
  teamLogoWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  logoButtonRoot: {
    height: '1.5rem',
    marginLeft: theme.spacing(1),
    padding: 0,
    width: '1.5rem',
  },
}));

export default PlayerContractSummary;
