import makeStyles from '@mui/styles/makeStyles';
import { Container, Item } from '@project-stanley/cap-management-components';
import { Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import TeamsDivision from 'modules/teams/teamsDivision.component';
import { Conferences, Divisions, TeamsByConferenceByDivision } from 'types/teams';
import { selectTeamsByConferenceByDivision } from 'modules/teams/teams.selectors';

function TeamsConferenceView(): JSX.Element | null {
  const classes = useStyles();

  const teamsByConferenceByDivision: TeamsByConferenceByDivision | null = useSelector(
    selectTeamsByConferenceByDivision,
  );

  if (isNil(teamsByConferenceByDivision)) return null;

  return (
    <Container>
      <Item sm={12} md={6}>
        <Typography className={classes.conferenceTitle} align="center" variant="subtitle1">
          {Conferences[2]}
          &nbsp;Conference
        </Typography>
        <Container spacing={2}>
          <Item xs={6}>
            <TeamsDivision division={Divisions[4]} teams={teamsByConferenceByDivision[Conferences[2]][Divisions[4]]} />
          </Item>
          <Item xs={6}>
            <TeamsDivision division={Divisions[3]} teams={teamsByConferenceByDivision[Conferences[2]][Divisions[3]]} />
          </Item>
        </Container>
      </Item>
      <Item sm={12} md={6}>
        <Typography className={classes.conferenceTitle} align="center" variant="subtitle1">
          {Conferences[1]}
          &nbsp;Conference
        </Typography>
        <Container spacing={2}>
          <Item xs={6}>
            <TeamsDivision division={Divisions[1]} teams={teamsByConferenceByDivision[Conferences[1]][Divisions[1]]} />
          </Item>
          <Item xs={6}>
            <TeamsDivision division={Divisions[2]} teams={teamsByConferenceByDivision[Conferences[1]][Divisions[2]]} />
          </Item>
        </Container>
      </Item>
    </Container>
  );
}

const useStyles = makeStyles(() => ({
  conferenceTitle: {
    fontWeight: 500,
  },
}));

export default TeamsConferenceView;
