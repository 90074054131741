/* eslint-disable max-len */
import api from 'middleware/api';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Player, PlayerContractExpiration, PlayerListItem, PlayerStats, PlayerTradeComparables } from 'types/player';
import { PlayerComparableContract } from 'types/comparableContracts';
import { PlayerContract } from 'types/contract';
import { RootState } from 'store';
import { TeamTrade } from 'types/teams';
import { cloneDeep } from 'lodash';

export interface PlayersState {
  isLoading: boolean;
  isLoadingAdvancedSearch: boolean;
  isLoadingComparableContracts: boolean;
  isLoadingComparableTrades: boolean;
  isLoadingContractExpiration: boolean;
  isLoadingSearch: boolean;
  isLoadingStats: boolean;
  player: Player | null;
  playerComparableContractsSubject: PlayerComparableContract | null;
  playerComparableContracts: PlayerComparableContract[] | null;
  playerComparableTrades: TeamTrade[] | null;
  playerContractExpiration: PlayerContractExpiration | null;
  playerContracts: PlayerContract[] | null;
  playerStats: PlayerStats | null;
  players: PlayerListItem[] | null;
}

const initialState: PlayersState = {
  isLoading: false,
  isLoadingAdvancedSearch: false,
  isLoadingComparableContracts: false,
  isLoadingComparableTrades: false,
  isLoadingContractExpiration: false,
  isLoadingSearch: false,
  isLoadingStats: false,
  player: null,
  playerComparableContractsSubject: null,
  playerComparableContracts: null,
  playerComparableTrades: null,
  playerContractExpiration: null,
  playerContracts: null,
  playerStats: null,
  players: null,
};

export const getPlayer = createAsyncThunk('players/getPlayer', async (playerId: string, { getState }) => {
  const { scenario } = (getState() as RootState).scenarios;

  const url = scenario ? `players/${playerId}?scenarioId=${scenario.scenarioId}` : `players/${playerId}`;

  return (await api(url, { method: 'GET' }, true)) as Player;
});

export const getPlayerContracts = createAsyncThunk(
  'players/getPlayerContracts',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/contracts?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/contracts`;

    return (await api(url, { method: 'GET' }, true)) as PlayerContract[];
  },
);

export const getPlayerStats = createAsyncThunk(
  'players/getPlayerStats',
  async ({ playerId, queryParam = '' }: { playerId: string; queryParam?: string }) =>
    (await api(`players/${playerId}/stats?${queryParam}`, { method: 'GET' }, true)) as PlayerStats,
);

export const getPlayerContractExpiration = createAsyncThunk(
  'players/getPlayerContractExpiration',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/contract-expiration?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/contract-expiration`;

    return (await api(url, { method: 'GET' }, true)) as PlayerContractExpiration;
  },
);

export const resetPlayer = createAction('players/resetPlayer');

export const getPlayerComparableContracts = createAsyncThunk(
  'players/getPlayerComparableContracts',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/comparable-contracts?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/comparable-contracts`;

    return (await api(url, { method: 'GET' }, true)) as { results: PlayerComparableContract[] };
  },
);

export const getPlayerComparableContract = createAsyncThunk(
  'players/getPlayerComparableContract',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/comparable-contract-info?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/comparable-contract-info`;

    return (await api(url, { method: 'GET' }, true)) as PlayerComparableContract;
  },
);

export const getPlayerComparableContractSolo = createAsyncThunk(
  'players/getPlayerComparableContractSolo',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/comparable-contract-info?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/comparable-contract-info`;

    return (await api(url, { method: 'GET' }, true)) as PlayerComparableContract;
  },
);

export const getPlayerComparableContractsSubject = createAsyncThunk(
  'players/getPlayerComparableContractSubject',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/comparable-contract-info?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/comparable-contract-info`;

    return (await api(url, { method: 'GET' }, true)) as PlayerComparableContract;
  },
);

export const setPlayerContractComparables = createAction<PlayerComparableContract[] | null>(
  'players/setPlayerContractComparables',
);

export const getPlayerComparableTrades = createAsyncThunk(
  'players/getPlayerComparableTrades',
  async (playerId: string, { getState }) => {
    const { scenario } = (getState() as RootState).scenarios;

    const url = scenario
      ? `players/${playerId}/comparable-trades?scenarioId=${scenario.scenarioId}`
      : `players/${playerId}/comparable-trades`;

    return (await api(url, { method: 'GET' }, true)) as PlayerTradeComparables;
  },
);

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlayer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlayer.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.player = payload;
      })
      .addCase(getPlayer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPlayerContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlayerContracts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.playerContracts = payload;
      })
      .addCase(getPlayerContracts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPlayerStats.pending, (state) => {
        state.isLoadingStats = true;
      })
      .addCase(getPlayerStats.fulfilled, (state, { payload }) => {
        state.isLoadingStats = false;
        state.playerStats = payload;
      })
      .addCase(getPlayerStats.rejected, (state) => {
        state.isLoadingStats = false;
      })
      .addCase(getPlayerContractExpiration.pending, (state) => {
        state.isLoadingContractExpiration = true;
      })
      .addCase(getPlayerContractExpiration.fulfilled, (state, { payload }) => {
        state.isLoadingContractExpiration = false;
        state.playerContractExpiration = payload;
      })
      .addCase(getPlayerContractExpiration.rejected, (state) => {
        state.isLoadingContractExpiration = false;
      })
      .addCase(getPlayerComparableContracts.pending, (state) => {
        state.isLoadingComparableContracts = true;
      })
      .addCase(getPlayerComparableContracts.fulfilled, (state, { payload }) => {
        state.isLoadingComparableContracts = false;
        state.playerComparableContracts = payload.results;
      })
      .addCase(getPlayerComparableContracts.rejected, (state) => {
        state.isLoadingComparableContracts = false;
      })
      .addCase(setPlayerContractComparables, (state, { payload }) => {
        state.playerComparableContracts = payload;
      })
      .addCase(getPlayerComparableContract.pending, (state) => {
        state.isLoadingComparableContracts = true;
      })
      .addCase(getPlayerComparableContract.fulfilled, (state, { payload }) => {
        state.isLoadingComparableContracts = false;

        if (!state.playerComparableContracts) {
          state.playerComparableContracts = [payload];
          return;
        }

        const updatedComparables = cloneDeep(state.playerComparableContracts);

        updatedComparables?.splice(0, 0, payload);

        state.playerComparableContracts = updatedComparables;
      })
      .addCase(getPlayerComparableContract.rejected, (state) => {
        state.isLoadingComparableContracts = false;
      })
      .addCase(getPlayerComparableContractSolo.pending, (state) => {
        state.isLoadingComparableContracts = true;
      })
      .addCase(getPlayerComparableContractSolo.fulfilled, (state) => {
        state.isLoadingComparableContracts = false;
      })
      .addCase(getPlayerComparableContractSolo.rejected, (state) => {
        state.isLoadingComparableContracts = false;
      })
      .addCase(getPlayerComparableContractsSubject.pending, (state) => {
        state.isLoadingComparableContracts = true;
      })
      .addCase(getPlayerComparableContractsSubject.fulfilled, (state, { payload }) => {
        state.isLoadingComparableContracts = false;
        state.playerComparableContractsSubject = payload;
      })
      .addCase(getPlayerComparableContractsSubject.rejected, (state) => {
        state.isLoadingComparableContracts = false;
      })
      .addCase(getPlayerComparableTrades.pending, (state) => {
        state.isLoadingComparableTrades = true;
      })
      .addCase(getPlayerComparableTrades.fulfilled, (state, { payload }) => {
        state.isLoadingComparableTrades = false;
        state.playerComparableTrades = payload.trades;
      })
      .addCase(getPlayerComparableTrades.rejected, (state) => {
        state.isLoadingComparableTrades = false;
      })
      .addCase(resetPlayer, (state) => {
        state.player = null;
        state.playerContracts = null;
        state.playerStats = null;
        state.playerComparableContracts = null;
        state.playerContractExpiration = null;
      });
  },
});

export default playersSlice.reducer;
