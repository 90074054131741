import classNames from 'classnames';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface PlayerTransactionSummaryPanelSectionItem {
  label?: string;
  text: string | number;
  icon?: JSX.Element;
}

interface PlayerTransactionSummaryPanelSectionProps {
  isLastSection?: boolean;
  items: PlayerTransactionSummaryPanelSectionItem[] | string;
  title: string;
}

function PlayerTransactionSummaryPanelSection({
  isLastSection = false,
  items,
  title,
}: PlayerTransactionSummaryPanelSectionProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box paddingBottom={!isLastSection ? '1rem' : ''}>
      <Typography variant="subtitle2">{title}</Typography>
      {typeof items === 'string' ? (
        <Typography key={`transaction-section-${items}}`} className={classes.itemText} variant="caption">
          {items}
        </Typography>
      ) : (
        items.map((item, index) => {
          if (item.label) {
            return (
              <Typography key={`transaction-section-${index}`} className={classes.itemText} variant="caption">
                {item.label}
                :&nbsp;
                {item.text}
              </Typography>
            );
          }

          if (item.icon) {
            return (
              <Typography
                key={`transaction-section-${index}`}
                className={classNames(classes.itemText, classes.iconLabel)}
                variant="caption"
              >
                {item.icon}
                &nbsp;
                {item.text}
              </Typography>
            );
          }

          return (
            <Typography key={`transaction-section-${index}`} className={classes.itemText} variant="caption">
              {item.text}
            </Typography>
          );
        })
      )}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  itemText: {
    display: 'block',
    fontSize: '0.875rem',
  },
  iconLabel: {
    display: 'flex',
  },
}));

export default PlayerTransactionSummaryPanelSection;
