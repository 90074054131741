import makeStyles from '@mui/styles/makeStyles';
import { Box, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { StanleyDollar } from '@project-stanley/cap-management-components';
import { format } from 'date-fns';

import { PlayerContractSimulation } from 'types/contract';

interface PlayerContractSimulatorSummaryProps {
  contract: PlayerContractSimulation;
  isOneWay: boolean;

  onOneWayChange: (value: boolean) => void;
}

function Divider(): JSX.Element {
  const classes = useStyles();

  return (
    <Typography className={classes.divider} variant="subtitle1">
      |
    </Typography>
  );
}

function PlayerContractSimulatorSummary({
  contract,
  isOneWay,
  onOneWayChange,
}: PlayerContractSimulatorSummaryProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" marginBottom={theme.spacing(0.25)}>
      <Typography className={classes.detail} variant="caption">
        Value:&nbsp;&nbsp;
        {contract.totalValue ? (
          <span>
            <StanleyDollar amount={contract.totalValue} />
          </span>
        ) : (
          '--'
        )}
      </Typography>
      <Divider />
      <Typography className={classes.detail} variant="caption">
        Length:&nbsp;
        {contract.contractDetails.length}
        &nbsp;
        {`year${contract.contractDetails.length > 1 ? 's' : ''}`}
      </Typography>
      <Divider />
      <Typography className={classes.detail} variant="caption">
        Signing&nbsp;Date:&nbsp;
        {format(new Date(), 'MMM dd, yyyy')}
      </Typography>
      <Divider />
      <FormControlLabel
        classes={{
          label: classes.label,
          labelPlacementStart: classes.labelPlacementStart,
        }}
        control={
          <Checkbox
            classes={{
              root: classes.checkboxRoot,
            }}
            checked={isOneWay}
            color="primary"
            onChange={(e) => {
              onOneWayChange(e.target.checked);
            }}
          />
        }
        label="One-Way:"
        labelPlacement="start"
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  detail: {
    fontWeight: 300,
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: '0.75rem',
  },
  labelPlacementStart: {
    marginLeft: 0,
  },
  checkboxRoot: {
    padding: '0.325rem',
  },
}));

export default PlayerContractSimulatorSummary;
