import { Box } from '@mui/material';
import { Container } from '@project-stanley/cap-management-components';
import { useSelector } from 'react-redux';

import Loading from 'modules/auth/loading.container';
import Trades from 'components/trades/trades.component';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { selectTeam, selectTrades } from 'modules/teams/teams.selectors';
import { useEffect } from 'react';

function RosterTradeHistory() {
  const team = useSelector(selectTeam);
  const trades = useSelector(selectTrades);

  useEffect(() => {
    if (team) {
      logGAEvent({
        category: GA_CATEGORIES.TEAMS,
        action: GA_ACTIONS.TEAM_PROFILE_VIEW_TRADE_HISTORY,
        label: team?.name,
        value: team?.teamId,
      });
    }
  }, [team]);

  if (!trades) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Box marginTop={2}>
      <Trades trades={trades} />
    </Box>
  );
}

export default RosterTradeHistory;
