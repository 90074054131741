import makeStyles from '@mui/styles/makeStyles';
import { Box, IconButton, Typography } from '@mui/material';
import { StanleySVGIcon } from '@project-stanley/cap-management-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ICONS } from 'utilities/icons';
import { ROUTES } from 'utilities/routes';

interface PlayerBioPanelHeaderProps {
  firstName: string;
  lastName: string;
  teamAbbreviation: string;
  teamId: number | null;
  teamName: string;
}

function PlayerBioPanelHeader({
  firstName,
  lastName,
  teamAbbreviation,
  teamId,
  teamName,
}: PlayerBioPanelHeaderProps): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleTeamClick = useCallback(() => {
    if (teamId) navigate(`${ROUTES.TEAMS}/${teamId}`);
  }, [navigate, teamId]);

  return (
    <Box display="flex">
      <Box flex="1">
        <Typography className={classes.teamName} variant="h6">
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography className={classes.teamName} variant="subtitle1">
          {teamName}
        </Typography>
      </Box>
      <IconButton className={classes.teamIcon} onClick={handleTeamClick} size="large">
        <StanleySVGIcon height="2.5rem" imageSrc={ICONS[teamAbbreviation]} width="2.5rem" />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  teamName: {
    lineHeight: 1.25,
  },
  teamIcon: {
    padding: 0,
  },
}));

export default PlayerBioPanelHeader;
