import {
  CompareArrows,
  FilterList,
  Folder,
  Menu,
  People,
  PlayArrow,
  PowerSettingsNew,
  ViewColumn,
} from '@mui/icons-material';

import { NavItem } from 'types/drawer';
import { ROUTES } from 'utilities/routes';

const PRIMARY_NAV_ITEMS: NavItem[] = [
  {
    icon: Menu,
    path: ROUTES.LEAGUE,
    text: 'League',
  },
  {
    icon: ViewColumn,
    path: ROUTES.ROSTER_COMPARISON,
    text: 'Roster Comparison',
  },
  {
    icon: FilterList,
    path: ROUTES.SEARCH,
    text: 'Advanced Search',
    subItems: [
      {
        path: ROUTES.SEARCH,
        text: 'Players',
      },
      {
        path: ROUTES.SEARCH_TRADES,
        text: 'Trades',
      },
    ],
  },
  {
    icon: CompareArrows,
    path: ROUTES.TRADE_SIMULATOR,
    text: 'Trade Simulator',
  },
  {
    icon: Folder,
    path: ROUTES.SCENARIOS,
    text: 'Scenario Manager',
  },
  {
    icon: Folder,
    path: ROUTES.HELP_CENTER,
    text: 'Help Center',
  },
];

const SECONDARY_DRAWER_ITEMS: NavItem[] = [
  {
    icon: PlayArrow,
    path: ROUTES.HELP_CENTER,
    text: 'Demo Videos',
  },
  {
    icon: PowerSettingsNew,
    path: '/',
    text: 'Logout',
  },
];

const ADMIN_DRAWER_ITEMS: NavItem[] = [
  {
    icon: People,
    text: 'Administration',
    onClick: () => {
      if (process.env.REACT_APP_ADMIN_APP_URL) window.location.href = process.env.REACT_APP_ADMIN_APP_URL;
    },
  },
];

export { ADMIN_DRAWER_ITEMS, PRIMARY_NAV_ITEMS, SECONDARY_DRAWER_ITEMS };
