import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

interface TradeSimulatorTeamSummaryItemProps {
  children: number | string | JSX.Element;
  isDiff?: boolean;
  labelClassname?: string;
  title: string;
}

function TradeSimulatorTeamSummaryItem({
  children,
  isDiff = false,
  labelClassname = '',
  title,
}: TradeSimulatorTeamSummaryItemProps): JSX.Element {
  const classes = useStyles();

  const className = useMemo(() => {
    if (!isDiff || typeof children !== 'number') return '';

    return Number(children) < 0 ? classes.negative : classes.positive;
  }, [classes, children, isDiff]);

  return (
    <>
      <Typography align="center" variant="body1">
        {title}
      </Typography>
      <Typography align="center" className={classNames(className, labelClassname)} variant="body1">
        {children}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  negative: {
    color: theme.palette.error.main,
  },
  positive: {
    color: 'green',
  },
}));
export default TradeSimulatorTeamSummaryItem;
