import makeStyles from '@mui/styles/makeStyles';
import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  Container,
  Item,
  StanleyPageActions,
  StanleySecondaryButton,
} from '@project-stanley/cap-management-components';
import { DatePicker } from '@mui/lab';
import { MutableRefObject, ReactInstance } from 'react';

import TradeSimulatorHeaderItem from 'modules/tradeSimulator/tradeSimulatorHeaderItem.component';

interface TradeSimulatorHeaderProps {
  disableComplete: boolean;
  isPrinting: boolean;
  tradeRef: MutableRefObject<ReactInstance | null>;
  transactionDateInfo: {
    selectedTransactionDate: Date | null;
    transactionDay: number | undefined;
    daysRemaining: number | undefined;
  };
  onAfterPrint: () => void;
  onDateChange: (date: Date | null, keyboardInputValue?: string) => void;
  onComplete: () => void;
  onPreview: () => void;
}

function TradeSimulatorHeader({
  disableComplete,
  isPrinting,
  tradeRef,
  transactionDateInfo: { selectedTransactionDate, transactionDay, daysRemaining },
  onAfterPrint,
  onDateChange,
  onComplete,
  onPreview,
}: TradeSimulatorHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container alignItems="center">
      <Item className={classes.headerItemsContainer} xs={isPrinting ? 8 : 12} md={8}>
        <Box className={classes.headerItem}>
          <TradeSimulatorHeaderItem
            label="Date"
            content={
              <DatePicker
                disablePast
                value={selectedTransactionDate}
                renderInput={(props: TextFieldProps) => (
                  <TextField
                    className={classes.dateTextField}
                    size="small"
                    {...props}
                    InputProps={{
                      classes: {
                        input: classes.dateInput,
                      },
                      ...props.InputProps,
                    }}
                  />
                )}
                OpenPickerButtonProps={{
                  className: classes.adornment,
                }}
                onChange={onDateChange}
              />
            }
          />
        </Box>
        <Box display="flex" alignItems="center" className={classes.headerItem}>
          <TradeSimulatorHeaderItem
            label="Transaction Day"
            content={<Typography>{transactionDay || '--'}</Typography>}
          />
        </Box>
        <Box display="flex" alignItems="center" className={classes.headerItem}>
          <TradeSimulatorHeaderItem label="Days Remaining" content={<Typography>{daysRemaining || '--'}</Typography>} />
        </Box>
      </Item>
      <Item xs={isPrinting ? 4 : 12} md={4}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <StanleyPageActions className={classes.printButton} printRef={tradeRef} onAfterPrint={onAfterPrint} />
          <StanleySecondaryButton className={classes.actionButton} onClick={onPreview}>
            Preview
          </StanleySecondaryButton>
          <StanleySecondaryButton className={classes.actionButton} disabled={disableComplete} onClick={onComplete}>
            Complete
          </StanleySecondaryButton>
        </Box>
      </Item>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
  },
  formLabel: {
    marginBottom: theme.spacing(2.5),
    marginLeft: 0,
    marginRight: 0,
  },
  checkBox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  actionButton: {
    height: '2rem',
    marginLeft: theme.spacing(1),
    width: '6rem',
  },
  printButton: {
    height: '2.25rem',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1),
  },
  headerItemsContainer: {
    display: 'flex',
  },
  headerItem: {
    marginRight: theme.spacing(4),
  },
  dateTextField: {
    maxWidth: '10.65rem',
  },
  dateInput: {
    padding: theme.spacing(0.75),
  },
  adornment: {
    color: theme.palette.primary.main,
  },
}));

export default TradeSimulatorHeader;
