/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { ContractExpiryStatus, ContractType } from 'types/contract';
import { cloneDeep, isEmpty } from 'lodash';

import { ARRAY_FILTERS, CONTRACT_FILTERS, FILTERS_TOP_LEVEL } from 'constants/playerSearch';
import { ComparableSeasonStatLine } from 'types/comparableContracts';
import { ContractYearStats } from 'types/contracts';
import { DECIMAL_DATA_FIELDS, NEEDS_CALC_DATA_FIELDS, TIME_DATA_FIELDS } from 'constants/comparableContracts';
import { Filters, PlayerSearchResult, PlayerSearchTable } from 'types/playerSearch';
import { PlayerPosition } from 'types/player';
import { Scenario } from 'types/scenarios';
import { formatTOI, getCalcStat } from 'utilities/comparableContracts';

export const INITIAL_FILTERS: Filters = {
  contractSearchCriteria: {
    arbRights: null,
    contractExpirationSeasonId: null,
    contractType: [],
    expiryStatus: [],
    signingStatus: null,
    maxAverageAnnualValue: null,
    minAverageAnnualValue: null,
    maxSigningAge: null,
    minSigningAge: null,
    maxContractLength: null,
    minContractLength: null,
    maxCapHit: null,
    minCapHit: null,
    maxContractValue: null,
    minContractValue: null,
  },
  maxAge: 45,
  minAge: 18,
  playerStatsSearchCriteria: {
    maxPoints: null,
    minPoints: null,
    maxGoals: null,
    minGoals: null,
    maxAssists: null,
    minAssists: null,
    maxGamesPlayed: null,
    minGamesPlayed: null,
    pointsPerGameMin: null,
    pointsPerGameMax: null,
    goalsPerGameMin: null,
    goalsPerGameMax: null,
    assistsPerGameMin: null,
    assistsPerGameMax: null,
    seasonYear: null,
  },
  primaryPosition: [],
};

export const sortPlayersByName = (rowA: PlayerSearchResult, rowB: PlayerSearchResult): number =>
  `${rowA.playerInfo.lastName}, ${rowA.playerInfo.firstName}`.localeCompare(
    `${rowB.playerInfo.lastName}, ${rowB.playerInfo.firstName}`,
  );

export const sortPlayerByPosition = (rowA: PlayerSearchResult, rowB: PlayerSearchResult): number =>
  rowA.playerInfo.primaryPosition - rowB.playerInfo.primaryPosition;

export const formatSearchTOI = (value: string) => {
  const toi = '00:';

  const parts = value.split(':');

  if (parts.length === 1) return `${toi}${value}:00`;

  return `${toi}${value}`;
};

export const prepareSearchPayload = ({
  filters,
  scenario,
}: {
  filters: Filters;
  scenario: Scenario | null;
}): Filters => {
  const payload = cloneDeep(filters);

  if (scenario) payload.scenarioId = scenario.scenarioId;
  if (!payload.teamId) delete payload.teamId;

  if (payload.playerStatsSearchCriteria) {
    if (payload.playerStatsSearchCriteria.seasonYear === null || payload.playerStatsSearchCriteria.seasonYear === '') {
      delete payload.playerStatsSearchCriteria;
    } else {
      Object.keys(payload.playerStatsSearchCriteria as Record<string, unknown>).forEach((statsCriteriaKey) => {
        if (payload.playerStatsSearchCriteria) {
          if (
            payload.playerStatsSearchCriteria[statsCriteriaKey] === null ||
            payload.playerStatsSearchCriteria[statsCriteriaKey] === ''
          ) {
            delete payload.playerStatsSearchCriteria[statsCriteriaKey];
          } else if (statsCriteriaKey.includes('PerGame')) {
            if (statsCriteriaKey.includes('timeOnIce')) {
              payload.playerStatsSearchCriteria[statsCriteriaKey] = formatSearchTOI(
                payload.playerStatsSearchCriteria[statsCriteriaKey] as string,
              );
            } else {
              payload.playerStatsSearchCriteria[statsCriteriaKey] = Number(
                payload.playerStatsSearchCriteria[statsCriteriaKey],
              );
            }
          }
        }
      });
    }
  }

  if (!payload.minAge) delete payload.minAge;
  if (!payload.maxAge) delete payload.maxAge;

  if (isEmpty(payload.playerStatsSearchCriteria)) {
    delete payload.playerStatsSearchCriteria;
  }

  if (payload.primaryPosition?.length === 0) {
    delete payload.primaryPosition;
  } else {
    payload.primaryPosition = payload.primaryPosition?.map((position) => Number(position));
  }

  if (payload.contractSearchCriteria !== undefined) {
    Object.keys(payload.contractSearchCriteria).forEach((contractCriteriaKey) => {
      if (!payload.contractSearchCriteria) return;

      if (
        payload.contractSearchCriteria[contractCriteriaKey] === null ||
        payload.contractSearchCriteria[contractCriteriaKey] === '' ||
        (Array.isArray(payload.contractSearchCriteria[contractCriteriaKey]) &&
          isEmpty(payload.contractSearchCriteria[contractCriteriaKey]))
      ) {
        delete payload.contractSearchCriteria[contractCriteriaKey];
      }

      if (
        payload.contractSearchCriteria[contractCriteriaKey] &&
        (contractCriteriaKey === 'arbRights' || contractCriteriaKey === 'signingStatus')
      ) {
        if (
          payload.contractSearchCriteria[contractCriteriaKey] === '' ||
          payload.contractSearchCriteria[contractCriteriaKey] === null
        ) {
          delete payload.contractSearchCriteria[contractCriteriaKey];
          return;
        }

        if (contractCriteriaKey === 'arbRights') {
          payload.contractSearchCriteria.arbRights = payload.contractSearchCriteria.arbRights === 'true';
        }

        if (contractCriteriaKey === 'signingStatus') {
          payload.contractSearchCriteria.signingStatus = [Number(payload.contractSearchCriteria.signingStatus)];
        }
      }
    });
  }

  if (isEmpty(payload.contractSearchCriteria)) {
    delete payload.contractSearchCriteria;
  } else if (payload.contractSearchCriteria) {
    if (payload.contractSearchCriteria.contractType) {
      payload.contractSearchCriteria.contractType = payload.contractSearchCriteria.contractType.map((contractType) =>
        Number(contractType),
      );
    }

    if (payload.contractSearchCriteria.expiryStatus) {
      payload.contractSearchCriteria.expiryStatus = payload.contractSearchCriteria.expiryStatus.map((expiryStatus) =>
        Number(expiryStatus),
      );
    }
  }

  return payload;
};

export const mapFiltersToQueryParams = (filters: Filters): string => {
  let queryParams = '';

  Object.keys(filters).forEach((key) => {
    if (key === 'playerStatsSearchCriteria') {
      const { playerStatsSearchCriteria } = filters;

      if (playerStatsSearchCriteria) {
        Object.keys(playerStatsSearchCriteria).forEach((statsKey) => {
          const statValue = playerStatsSearchCriteria[statsKey];

          if (!statValue) return;

          if (Array.isArray(statValue)) {
            if (statValue.length) queryParams += `&${statsKey}=${statValue.join(',')}`;
          } else {
            queryParams += `&${statsKey}=${statValue}`;
          }
        });
      }
    }

    if (key === 'contractSearchCriteria') {
      const { contractSearchCriteria } = filters;

      if (contractSearchCriteria) {
        Object.keys(contractSearchCriteria).forEach((statsKey) => {
          const statValue = contractSearchCriteria[statsKey];

          if (!statValue) return;

          if (Array.isArray(statValue)) {
            if (statValue.length) queryParams += `&${statsKey}=${statValue.join(',')}`;
          } else {
            queryParams += `&${statsKey}=${statValue.toString()}`;
          }
        });
      }
    }

    const statValue = filters[key];

    if (!statValue || (typeof statValue === 'object' && !Array.isArray(statValue))) return;
    if (Array.isArray(statValue)) {
      if (statValue.length) queryParams += `&${key}=${statValue.join(',')}`;
    } else {
      queryParams += `&${key}=${statValue}`;
    }
  });

  if (!queryParams.startsWith('&')) return queryParams;

  return queryParams.substring(1);
};

export const mapQueryParamsToFilters = (queryParams: URLSearchParams): Filters => {
  const filters = { ...INITIAL_FILTERS };

  queryParams.forEach((value, key) => {
    if (FILTERS_TOP_LEVEL.includes(key)) {
      if (!ARRAY_FILTERS.includes(key)) filters[key] = value;
      else if (key === 'primaryPosition') filters[key] = value.split(',') as unknown as PlayerPosition[] | number[];
      else filters[key] = value.split(',').map((item) => Number(item));
    } else if (CONTRACT_FILTERS.includes(key) && filters.contractSearchCriteria) {
      if (!ARRAY_FILTERS.includes(key)) filters.contractSearchCriteria[key] = value;
      else filters.contractSearchCriteria[key] = value.split(',') as unknown as ContractType[] | ContractExpiryStatus[];
    } else if (filters.playerStatsSearchCriteria) {
      if (key === 'timeOnIcePerGameMax' || key === 'timeOnIcePerGameMax') {
        const parts = value.split(':');

        if (parts.length === 1) filters.playerStatsSearchCriteria[key] = `${parts[0]}:00`;
        else filters.playerStatsSearchCriteria[key] = value;
      } else {
        filters.playerStatsSearchCriteria[key] = value;
      }
    }
  });

  return filters;
};

export const getYear =
  (yearKey: string) =>
  (player: PlayerSearchResult): string => {
    const year = player[yearKey] as number;

    return `${year - 1}-${year}`;
  };

const convertPlayerSeasonStat = (statKey: string, seasonStats: ComparableSeasonStatLine): string => {
  const isDecimalKey = Boolean(DECIMAL_DATA_FIELDS[statKey]);
  const isTOIKey = Boolean(TIME_DATA_FIELDS[statKey]);
  const isNeedsCalc = Boolean(NEEDS_CALC_DATA_FIELDS[statKey]);

  if (isNeedsCalc) {
    const calcStat = getCalcStat(seasonStats, statKey);

    return typeof calcStat === 'string' ? calcStat : calcStat.toFixed(2);
  }

  const stat = seasonStats[statKey];
  const isString = typeof stat === 'string';

  if ((!stat && stat !== 0) || typeof stat === 'object') return '--';
  if (isTOIKey && isString) return formatTOI(stat);

  return !isDecimalKey || isString ? stat.toString() : stat.toFixed(2);
};

export const mapPlayerSearchResult = (data: PlayerSearchResult[]) =>
  data.map((player) => {
    const mappedPlayer: any = player;

    mappedPlayer.totalBonusPct *= 100;
    mappedPlayer.totalPerformanceBonusPct *= 100;
    mappedPlayer.totalSigningBonusPct *= 100;
    mappedPlayer.totalBaseSalaryPct *= 100;

    Object.keys(player.playerInfo).forEach((key) => {
      if (key !== 'age') mappedPlayer[key] = player.playerInfo[key];
    });

    Object.keys(player.seasonStats.regularSeasonStats).forEach((key) => {
      if (key === 'xgStats') {
        Object.keys(player.seasonStats.regularSeasonStats.xgStats).forEach((xgStatKey) => {
          mappedPlayer[xgStatKey] = Number(player.seasonStats.regularSeasonStats.xgStats[xgStatKey].toFixed(2));
        });
        return;
      }

      mappedPlayer[key] = convertPlayerSeasonStat(key, player.seasonStats.regularSeasonStats);
    });

    return mappedPlayer as PlayerSearchTable;
  });

export const mapContractYearResult = (data: ContractYearStats[]) =>
  data.map((player) => {
    const mappedPlayer: any = cloneDeep(player);

    mappedPlayer.bonusPct *= 100;
    mappedPlayer.performanceBonusPct *= 100;
    mappedPlayer.signingBonusPct *= 100;
    mappedPlayer.baseSalaryPct *= 100;

    Object.keys(player.stats).forEach((key) => {
      if (key === 'xgStats' && player.stats.xgStats) {
        Object.keys(player.stats.xgStats).forEach((xgStatKey) => {
          mappedPlayer[xgStatKey] = Number(player.stats.xgStats[xgStatKey].toFixed(2));
        });
        return;
      }

      mappedPlayer[key] = convertPlayerSeasonStat(key, player.stats);
    });

    return mappedPlayer as ContractYearStats;
  });
