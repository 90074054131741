/* eslint-disable @typescript-eslint/unbound-method */
import { Droppable } from 'react-beautiful-dnd';

import RosterPositionList from 'modules/teams/rosterView/rosterPositionList.component';
import { Player, PlayerPosition } from 'types/player';

interface RosterDroppablePositionListProps {
  players: Player[];
  position: PlayerPosition | string;
  title: string;
}

function RosterDroppablePositionList({ players, position, title }: RosterDroppablePositionListProps): JSX.Element {
  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'inherit',
  });

  return (
    <Droppable droppableId={position.toString()}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
          <RosterPositionList isDnD players={players} title={title} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default RosterDroppablePositionList;
