/* eslint-disable max-len */
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { Container, Item } from '@project-stanley/cap-management-components';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ScenarioSummaryTeamCard from 'modules/scenarios/scenarioSummary/scenarioSummaryTeamCard.component';
import { Scenario } from 'types/scenarios';
import { getScenarioSummary, resetScenarioSummary } from 'modules/scenarios/scenarios.slice';
import { selectScenarioSummary, selectScenariosIsLoadingSummary } from 'modules/scenarios/scenarios.selectors';
import { useAppDispatch } from 'store';

interface ScenarioSummaryDetailPanelProps {
  rowData: Scenario;
}

function ScenarioSummaryDetailPanel({ rowData }: ScenarioSummaryDetailPanelProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isLoading = useSelector(selectScenariosIsLoadingSummary);
  const summary = useSelector(selectScenarioSummary);

  useEffect(() => {
    dispatch(getScenarioSummary(rowData.scenarioId));

    return () => {
      dispatch(resetScenarioSummary());
    };
  }, [dispatch, rowData]);

  if (isLoading || isNil(summary)) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (isEmpty(summary)) {
    return (
      <Box className={classes.loadingContainer}>
        <Typography>No Actions Taken</Typography>
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(2)}>
      <Container spacing={2}>
        {Object.keys(summary).map((key) => (
          <Item key={key} xs={12} sm={6} lg={4}>
            <ScenarioSummaryTeamCard scenario={rowData} teamId={key} teamScenarioSummary={summary[key]} />
          </Item>
        ))}
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default ScenarioSummaryDetailPanel;
