import { AppBar, Box, Toolbar, styled } from '@mui/material';
import { StanleyDrawerFooter } from '@project-stanley/cap-management-components';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeaderLink from 'modules/layout/headerLink.component';
import ScenarioToggle from 'modules/scenarios/scenarioToggle.component';
import StanleyPlayerSearch, { StanleyPlayerSearchOption } from 'components/fields/stanleyPlayerSearch.component';
import { GA_ACTIONS, GA_CATEGORIES, logGAEvent } from 'utilities/analytics';
import { NavItem } from 'types/drawer';
import { PRIMARY_NAV_ITEMS } from 'utilities/drawer';
import { ROUTES, ROUTE_PARAMS } from 'utilities/routes';
import { selectShowSubNav, selectSubNavItems } from 'modules/layout/layout.selectors';
import { selectTeam } from 'modules/teams/teams.selectors';
import { setSubNavItems } from 'modules/layout/layout.slice';
import { useAppDispatch } from 'store';

export const HEADER_HEIGHT_REM = 4;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'inset 0 -2px 0 0 #e9eef6',
  height: `${HEADER_HEIGHT_REM}rem`,
}));

const StyledSubAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: `${HEADER_HEIGHT_REM}rem`,
}));

const StyledToolbar = styled(Toolbar)`
  margin: 0 0.5rem;
`;

function Header(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const subNavItems = useSelector(selectSubNavItems);
  const showSubNav = useSelector(selectShowSubNav);
  const team = useSelector(selectTeam);

  useEffect(() => {
    const navItem = PRIMARY_NAV_ITEMS.find(
      (drawerItem) => drawerItem.path && location.pathname.includes(drawerItem.path),
    );

    if (navItem && navItem.subItems) {
      dispatch(setSubNavItems(navItem.subItems));
      return;
    }

    dispatch(setSubNavItems(null));
  }, [dispatch, location]);

  const handleSearchOptionSelected = useCallback(
    (optionSelected: StanleyPlayerSearchOption | null) => {
      if (optionSelected) {
        const { label, value } = optionSelected;

        logGAEvent({
          category: GA_CATEGORIES.PLAYER_PROFILE,
          action: GA_ACTIONS.PLAYER_PROFILE_SEARCH,
          label,
          value: Number(value),
        });

        navigate(`${ROUTES.PLAYERS}/${value}`);
      }
    },
    [navigate],
  );

  const handleSetSubNav = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (subNavItems?: NavItem[]) => dispatch(setSubNavItems(subNavItems || null)),
    [dispatch],
  );

  const navigatePage = useCallback(
    (page: NavItem) => {
      if (!page.subItems) handleSetSubNav();

      navigate(page.path || ROUTES.TEAMS);
    },
    [navigate, handleSetSubNav],
  );

  const navigateSubPage = useCallback(
    (page: NavItem) => {
      if (page.path) {
        if (team && page.path.includes(ROUTE_PARAMS.TEAM_ID)) {
          const subRoute = page.path.split(ROUTE_PARAMS.TEAM_ID)[1];

          navigate(`${ROUTES.TEAMS}/${team.teamId}${subRoute}`);
          return;
        }

        navigate(page.path || ROUTES.TEAMS);
      }
    },
    [navigate, team],
  );

  return (
    <StyledAppBar elevation={0} position="fixed">
      <StyledToolbar disableGutters>
        <StanleyDrawerFooter iconHeight="2rem" onToggleDrawer={() => navigate(ROUTES.TEAMS)} />
        <Box display="flex" flex={2} height="100%" marginLeft={1} marginRight={1}>
          {PRIMARY_NAV_ITEMS.map((item) => (
            <HeaderLink
              key={item.text}
              link={item}
              onClick={item.subItems ? () => handleSetSubNav(item.subItems) : () => navigatePage(item)}
            />
          ))}
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end" marginLeft="1rem">
          <Box marginRight="0.5rem" marginY="auto">
            <ScenarioToggle />
          </Box>
          <StanleyPlayerSearch onOptionSelected={handleSearchOptionSelected} />
        </Box>
      </StyledToolbar>
      {showSubNav && (
        <StyledSubAppBar elevation={0} position="fixed" sx={{ marginTop: `${HEADER_HEIGHT_REM}rem` }}>
          <StyledToolbar disableGutters>
            <Box display="flex" flex={1} height="100%">
              {subNavItems.map((item) => (
                <HeaderLink key={item.text} isSubLink link={item} onClick={() => navigateSubPage(item)} />
              ))}
            </Box>
          </StyledToolbar>
        </StyledSubAppBar>
      )}
    </StyledAppBar>
  );
}

export default Header;
