/* eslint-disable max-len */
import { Player, PlayerContractExpiration, PlayerStats } from 'types/player';
import { PlayerComparableContract } from 'types/comparableContracts';
import { PlayerContract } from 'types/contract';
import { RootState } from 'store';
import { TeamTrade } from 'types/teams';

export const selectPlayersIsLoading = (state: RootState): boolean => state.players.isLoading;

export const selectPlayersIsLoadingSearch = (state: RootState): boolean => state.players.isLoadingSearch;

export const selectPlayersIsLoadingStats = (state: RootState): boolean => state.players.isLoadingStats;

export const selectPlayersIsLoadingContractExp = (state: RootState): boolean =>
  state.players.isLoadingContractExpiration;

export const selectPlayerIsLoadingComparableContracts = (state: RootState): boolean =>
  state.players.isLoadingComparableContracts;

export const selectPlayerIsLoadingComparableTrades = (state: RootState): boolean =>
  state.players.isLoadingComparableTrades;

export const selectPlayer = (state: RootState): Player | null => state.players.player;

export const selectPlayerComparableContracts = (state: RootState): PlayerComparableContract[] | null =>
  state.players.playerComparableContracts;

export const selectPlayerComparableContractsSubject = (state: RootState): PlayerComparableContract | null =>
  state.players.playerComparableContractsSubject;

export const selectPlayerComparableTrades = (state: RootState): TeamTrade[] | null =>
  state.players.playerComparableTrades;

export const selectPlayerContracts = (state: RootState): PlayerContract[] | null => state.players.playerContracts;

export const selectPlayerStats = (state: RootState): PlayerStats | null => state.players.playerStats;

export const selectPlayerContractExp = (state: RootState): PlayerContractExpiration | null =>
  state.players.playerContractExpiration;
